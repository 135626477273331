<template>
  <div class="container">
    <div class="news-con">
      <i class="el-icon-loading loading" style="margin-top: 40vh" v-if="newsLists.length == 0"></i>
      <div class="news-item" v-for="(item, idx) in newsLists" :key="idx">
        <div class="img-con">
          <el-image :src="item.image" fit="cover" lazy class="cover"></el-image>
        </div>
        <div class="content-con">
          <div class="title">
            <div class="flex-1">{{$i18n.locale == 'zh' ? (item.titleCn || item.title) : item.title}}</div>
            <span class="star" v-if="item.isBig">
              <i class="el-icon-star-on"></i>
              <i class="el-icon-star-on"></i>
              <i class="el-icon-star-on"></i>
            </span>
          </div>
          <div class="body" v-html="$i18n.locale == 'zh' ? (item.bodyCn || item.titleCn || item.body || item.title) : (item.body || item.title)"></div>
          <div class="brief" v-if="item.suggestions.length > 0">
            <div class="relative">{{$t('related')}}:</div>
            <div class="symbols">
              <span translate="no" v-for="(sg, sn) in item.suggestions" :key="sn" :title="sg.exchange">{{sg.symbol}}</span>
            </div>
            <div class="time">
              {{item.created}}
            </div>
          </div>
          <div class="symbols-list">
            <div class="symbols-title">消息发布后7天的价格趋势</div>
            <div class="table-head">
              <div class="symbol">Symbol</div>
              <div class="number">当时价格</div>
              <div class="number">最高价</div>
              <div class="number">最低价</div>
<!--              <div class="number">-5天波动率</div>-->
<!--              <div class="number">-1天波动率</div>-->
<!--              <div class="number">后1天波动率</div>-->
<!--              <div class="number">后3天波动率</div>-->
<!--              <div class="number">后7天波动率</div>-->
              <div class="number">最大涨幅</div>
              <div class="number">最大跌幅</div>
              <div class="number">价格趋势</div>
              <div class="handle"></div>
            </div>
            <div class="table-row" v-for="(sg, sn) in item.suggestions" :key="sn" v-if="symbolsStatistic[sg.key]">
              <div class="symbol" translate="no">{{sg.symbol}}</div>
              <div class="number">{{parseFloat(symbolsStatistic[sg.key].startPrice)}}</div>
              <div class="number">{{parseFloat(symbolsStatistic[sg.key].maxPrice)}}</div>
              <div class="number">{{parseFloat(symbolsStatistic[sg.key].minPrice)}}</div>
<!--              <div class="number">{{symbolsStatistic[sg.key].prev5SD}}</div>-->
<!--              <div class="number">{{symbolsStatistic[sg.key].prev1SD}}</div>-->
<!--              <div class="number">{{symbolsStatistic[sg.key].next1SD}}</div>-->
<!--              <div class="number">{{symbolsStatistic[sg.key].next3SD}}</div>-->
<!--              <div class="number">{{symbolsStatistic[sg.key].next7SD}}</div>-->
              <div class="number">
                {{symbolsStatistic[sg.key].maxProfit}}%
                ({{parseFloat((symbolsStatistic[sg.key].maxPriceTime - item.timestamp)/3600).toFixed(1)}}h)
              </div>
              <div class="number">
                {{symbolsStatistic[sg.key].minProfit}}%
                ({{parseFloat((symbolsStatistic[sg.key].minPriceTime - item.timestamp)/3600).toFixed(1)}}h)
              </div>
              <div class="number">
                {{symbolsStatistic[sg.key].diffTime > 0 ? '先跌后涨' : '先涨后跌'}}
              </div>
              <div class="handle">
                <router-link target="_blank" :to="{path:'/symbol-info', query:{ news: JSON.stringify(item), symbol: sg.symbol }}">
                  <i class="el-icon-s-data"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-con" v-if="total > 0">
      <div class="next-page" @click="onChangePage(page + 1)">{{$t('loadMore')}}</div>
<!--      <el-pagination-->
<!--          layout="prev, pager, next"-->
<!--          :total="total"-->
<!--          @current-change=""-->
<!--      >-->
<!--      </el-pagination>-->
    </div>

  </div>
</template>

<script>
import * as newsApi from "@/api/news";
import * as coinsApi from "@/api/coins";

export default {
  data(){
    return {
      newsLists: [],
      page: 1,
      total: 0,
      cursor: null,
      symbols: {},
      symbolsStatistic: {},
      stopLoopStatistic: false,
      logs: [],
      waitDestroy: null
    }
  },
  mounted() {
    this.loadHistoryNews();
  },
  beforeDestroy() {
    this.waitDestroy = true;
  },
  methods: {
    /**
     * 历史消息
     * @returns {Promise<void>}
     */
    onChangePage(p){
      this.page = p;
      this.total = 0;
      this.stopLoopStatistic = true;
      this.loadHistoryNews();
    },
    /**
     * 加载历史新闻
     * @returns {Promise<void>}
     */
    async loadHistoryNews(){
      this.symbols = {};
      this.newsLists = [];
      // 加载消息
      const params = {
        page: this.page,
        size: 25,
        sort: 'desc',
        cursor: this.cursor
      }
      const res = await newsApi.historyNews(params);
      this.stopLoopStatistic = false;
      if(res && res.data){
        this.cursor = res.data.lists.length > 0 ? res.data.lists[res.data.lists.length - 1].timestamp : null;
        this.total = res.data.total;
        const symbols = {};
        const linkRegex = /(https?:\/\/\S+)/g;
        for(let i in res.data.lists){
          const item = res.data.lists[i];

          res.data.lists[i].body = item.body ? item.body.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;
          res.data.lists[i].title = item.title ? item.title.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;
          res.data.lists[i].bodyCn = item.bodyCn ? item.bodyCn.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;
          res.data.lists[i].titleCn = item.titleCn ? item.titleCn.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;

          for(let sn in item.suggestions){
            const sg = item.suggestions[sn];
            const key = sg.symbol + '_' + item.timestamp;
            const hourTimestamp = item.timestamp - item.timestamp%3600;
            symbols[key] = {
              hourTimestamp,
              newsIdx: i
            };
            res.data.lists[i].suggestions[sn].key = key;
          }
        }
        this.symbols = symbols;
        this.newsLists = res.data.lists;

        this.loadStatistic();
      }
    },
    /**
     * 统计结果
     */
    async loadStatistic(){
      for(let k in this.symbols){
        if(this.waitDestroy) break;
        if(this.stopLoopStatistic){
          break;
        }
        const item = this.symbols[k];
        const symbol = k.split('_')[0];
        const startTime = k.split('_')[1];
        let res = null;
        try{
          res = await coinsApi.statistic({
            symbol: symbol.replace('USDT', ''),
            startTime
          });
        }catch (err){
          console.log(err.message);
        }

        if(res && res.data){
          // 最大最小收益的时间差
          const diffTime = parseFloat((res.data.maxPriceTime - res.data.minPriceTime)/3600).toFixed(1);
          res.data.diffTime = diffTime;
          this.$set(this.symbolsStatistic, k, res.data);
          const newsIdx = item.newsIdx;
          const star= this.cauStar(res.data, symbol);
          if(typeof this.newsLists[newsIdx].star != 'undefined' && star > this.newsLists[newsIdx].star){
            this.newsLists[newsIdx].star = star;
          } else if(typeof this.newsLists[newsIdx].star == 'undefined'){
            this.newsLists[newsIdx].star = star;
          }
        }
        await this.$func.sleep(100);
      }

      this.logs = [];
      for(let i in this.newsLists){
        const row = this.newsLists[i];
        if(typeof row.star == 'undefined'){
          continue;
        }
        const txt = row.body || row.title || '';
        const prefix = txt.split(':')[0];
        this.logs.push({
          title: txt.replace(prefix + ':', ''),
          star: row.star,
          source: row.source
        })
      }

      this.waitDestroy = null;
    },
    /**
     * 计算星级
     * @param data
     */
    cauStar(data, symbol){
      let star = 0;
      // 最大收益和最小收益的差值
      const startTime = data.startTime;
      let realProfit = null;
      const diffTimeMax = data.maxPriceTime - startTime; // 与开始时间的时差（s）
      const diffTimeMin = data.minPriceTime - startTime;
      // data.diffTime为负数，表示先涨后跌
      const direct = data.diffTime > 5 ? 1 : (data.diffTime < 5 ? -1 : 0);// 1: ↓↑ -1: ↑↓
      if(direct == 1){
        // 先跌后涨
        realProfit = parseFloat(data.maxProfit) - parseFloat(data.minProfit);
        if (realProfit >= 15 && diffTimeMax > 10) {
          star = 3;
        } else if(realProfit >= 10 && diffTimeMax > 10) {
          star = 2;
        } else if(realProfit >= 5 && diffTimeMax > 10) {
          star = 1;
        }
        // console.log(symbol)
        // console.log(`diffTimeMax:${diffTimeMax},diffTimeMin:${diffTimeMin},realProfit:${realProfit}`)
        // console.log(data)
      } else if(direct == -1){
        // 先涨后跌
        realProfit = parseFloat(data.maxProfit);
        if(data.maxProfit >= 15 && diffTimeMax > 10 && diffTimeMin > 15){
          star = 3;
        } else if(data.maxProfit >= 10 && diffTimeMax > 10 && diffTimeMin > 15) {
          star = 2;
        } else if(data.maxProfit >= 5 && diffTimeMax > 10 && diffTimeMin > 15) {
          star = 1;
        }
      }

      return star;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.news-con{
  margin: 0 auto 20px auto;
  width: 1200px;
  position: relative;
}
.news-item{
  display: flex;
  margin-top: 20px;
  background-color: @section-bg-color;
  padding: @mg;
  border-radius: 25px;

  .img-con{
    width: 50px;
    height: 50px;
    margin-right: 15px;

    .cover{
      width: 50px;
      height: 50px;
      border-radius: 25px;

      /deep/.el-image__error, /deep/.el-image__placeholder{
        background-color: @content-bg-color;
        background-image: url("../assets/imgs/logo-icon.png");
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center;
        filter: grayscale(1);
        opacity: 0.15;
        font-size: 12px;
      }
    }
  }
  .content-con{
    flex: 1;
    .title{
      padding-bottom: 10px;
      display: flex;
      align-items: center;

      .star{
        color: @tag-color;
        font-size: 14px;
      }
    }
    .body {
      font-size: 15px;
      font-weight: bold;
      line-height: 24px;

      /deep/a{
        color: #409EFF;

        &:hover{
          color: #409EFF;
        }
      }
    }
    .brief{
      display: flex;
      font-size: 12px;
      margin-top: 10px;
      align-items: center;

      .relative {
        min-width: 30px;
        padding-right: 3px;
      }
      .symbols {
        display: flex;
        flex-wrap: wrap;
        max-width: 60%;

        span{
          padding: 0 3px;
          color: @light-color;
        }
      }
      .time {
        flex: 1;
        text-align: right;
      }
    }
    .symbols-list{
      margin-top: 10px;
      background-color: @content-bg-color;
      border-radius: @mg;
      padding: @mg;

      .symbols-title{
        font-size: 12px;
        padding-bottom: 8px;
        color: @sub-font-color;
      }
      .table-head, .table-row{
        display: flex;
        color: @sub-font-color;

        .symbol{
          text-align: left;
          width: 120px;
        }
        .number{
          flex: 1;
        }
        .handle{
          width: 40px;
          font-size: 16px;
          a{
            color: @light-color !important;
          }
        }
      }
      .table-head{
        border-bottom: 1px dashed @input-bg-color;
        padding: 8px 0;
        font-weight: bold;
        font-size: 12px;
      }
      .table-row{
        font-size: 13px;
        border-bottom: 1px dashed @input-bg-color;
        padding: 8px 0;
      }
    }
  }
}
.page-con {
  text-align: center;
  padding-bottom: 20px;

  .next-page{
    font-size: 13px;
    color: @font-color;
    cursor: pointer;
  }
  .el-pagination{
    /deep/button{
      background: transparent;
      color: @font-color;
    }
    /deep/button:disabled{
      color: @sub-font-color;
    }
    /deep/.el-pager li{
      background: transparent;
      color: @font-color;
      &.active{
        color: @light-color;
      }
    }
  }
}
</style>
