<template>
  <div class="section-container">
    <div class="form-con">
      <div class="flex">
        <el-input v-model="form.apiKey" placeholder="Api Key"></el-input>
        <div class="w-32"></div>
        <el-input v-model="form.apiSecret" type="password" placeholder="Api Secret"></el-input>
      </div>
      <div class="flex mgt-10 fs-12 text-dark">
        <div class="flex-1">刷单总额(USDC)</div>
        <div class="w-16"></div>
        <div class="flex-1">每笔额度(USDC)</div>
        <div class="w-16"></div>
        <div class="flex-1">每笔交易间隔时间(s)</div>
        <div class="w-16"></div>
        <div class="flex-1">卖出等待时间(s)</div>
      </div>
      <div class="flex mgt-5">
        <el-input v-model="form.totalVol" placeholder="刷单总额(USDC)"></el-input>
        <div class="w-32"></div>
        <el-input v-model="form.perVol" placeholder="每笔额度(USDC)"></el-input>
        <div class="w-32"></div>
        <el-input v-model="form.stepTime" placeholder="每笔交易间隔时间(s)"></el-input>
        <div class="w-32"></div>
        <el-input v-model="form.sellWaitTime" placeholder="卖出等待时间(s)"></el-input>
      </div>

      <div class="mgt-10 fs-14">USDC: {{currentUsdc}}</div>

      <div class="flex mgt-10 flex-end">
        <div class="flex-1"></div>
        <el-button @click="saveConfig" size="small">保存配置</el-button>
        <el-button @click="runTask" type="success" size="small" v-if="!isRunning">启动</el-button>
        <el-button @click="stopTask" type="danger" size="small" v-if="isRunning">停止</el-button>
      </div>
    </div>

    <div class="logs">
      <div>日志：</div>
      <div class="item" v-for="(item, idx) in logs" :key="idx">
        {{item}}
      </div>
    </div>
  </div>
</template>

<script>
import * as backpackApi from '@/api/backpack';

export default {
  data(){
    return {
      form: {
        apiKey: null,
        apiSecret: null,
        totalVol: 1000,
        perVol: 100,
        stepTime: 2,
        sellWaitTime: 1
      },
      finishedVol: 0,
      currentUsdc: 0,
      isRunning: false,
      logs: []
    }
  },
  mounted() {
    const config = localStorage.getItem('PACKBACK_CONFIG');
    if(config){
      this.form = Object.assign(this.form, JSON.parse(config));
    }

    if(this.form.apiKey && this.form.apiSecret){
      setTimeout(async () => {
        const res = await this.getBalance('USDC');
        if(res) this.currentUsdc = res.available;
      }, 1000);
      setInterval(async () => {
        const res = await this.getBalance('USDC');
        if(res) this.currentUsdc = res.available;
      }, 30*1000);
    }
  },
  methods: {
    saveConfig(){
      localStorage.setItem('PACKBACK_CONFIG', JSON.stringify(this.form));
      this.$message.success('保存成功');
      setTimeout(() => { location.reload() }, 2000);
    },
    async runTask(){
      if(this.currentUsdc - this.form.perVol < 0){
        this.$message.error('USDC余额不足');
        return;
      }
      this.isRunning = true;
      while(true){
        if(!this.isRunning) { this.log('已停止'); break; }
        if(this.finishedVol - this.form.totalVol >= 0){
          this.log('任务完成');
          this.isRunning = false;
          break;
        }
        await this.makeOrder();
        await this.$func.sleep(1000 + this.form.stepTime * 1000);
      }
    },
    stopTask(){
      this.isRunning = false;
    },
    async getBalance(symbol){
      const res = await backpackApi.getBalance();
      if(res && res.data){
        return res.data[symbol.replace('_USDC', '')];
      }
      return null;
    },
    async makeOrder(){
      if(!this.form.perVol){
        this.log('每笔交易额度未设置！');
        return;
      } else if(!this.form.totalVol){
        this.log('交易总额未设置！');
        return;
      }
      const symbol = 'SOL_USDC';
      this.log(`查询${symbol}最近价格`);
      const lastPrice = parseFloat(await backpackApi.getLastPrice(symbol));
      this.log(`${symbol}最新价格${lastPrice}`);
      let decimals = lastPrice.toString().split('.');
      decimals = decimals[1] ? decimals[1].length : 0;
      const limitPrice = parseFloat(lastPrice * 1.006).toFixed(decimals);

      const balance = await this.getBalance('USDC');
      if(!balance){
        this.log('余额获取失败');
        return;
      }
      this.currentUsdc = balance.available;

      const quantity = this.roundToDecimal(this.form.perVol/limitPrice, decimals);
      if(quantity === 0){
        this.log(`购买数量太少(${quantity})`);
        return;
      }
      const params = {
        "side": "Bid",
        "symbol": symbol,
        "orderType": "Limit",
        "quantity": quantity,
        "price": limitPrice
      }
      this.log(`买入 ${symbol} (${quantity}) (≈${this.form.perVol}USDC)`);
      const res = await backpackApi.makeOrder(params);
      if(res && res.data){
        this.finishedVol += parseFloat(this.form.perVol);
        this.log(res.data.id ? `买入成功 (已完成${this.finishedVol}USDC)` : '买入失败');
        await this.$func.sleep(this.form.sellWaitTime * 1000);
        await this.sellOrder(res.data);
      }
    },
    async sellOrder(order){
      if(order.status !== 'Filled'){
        return;
      }
      const symbol = order.symbol;
      this.log(`查询${symbol}最新价格`);
      const lastPrice = parseFloat(await backpackApi.getLastPrice(symbol));
      this.log(`${symbol}最新价格${lastPrice}`);
      let decimals = lastPrice.toString().split('.');
      decimals = decimals[1] ? decimals[1].length : 0;
      const limitPrice = parseFloat(lastPrice * (1 - 0.006)).toFixed(decimals);

      const balance = await this.getBalance(symbol);
      const quantity = this.roundToDecimal(balance ? balance.available : order.quantity, decimals);
      if(quantity == 0){
        return;
      }

      const params = {
        "side": "Ask",
        "symbol": symbol,
        "orderType": "Limit",
        "quantity": quantity,
        "price": limitPrice
      }

      this.log(`卖出 ${symbol} (${quantity})`);
      const res = await backpackApi.makeOrder(params);
      if(res && res.data){
        this.log(res.data.id ? '卖出成功' : '卖出失败');
      }
    },
    roundToDecimal(num, decimalPlaces) {
      let multiplier = Math.pow(10, decimalPlaces);
      return Math.floor(num * multiplier) / multiplier;
    },
    log(msg){
      msg = this.$func.getDate() + ' ' + msg;
      console.log(`${msg}`);
      this.logs.unshift(msg);
      this.logs = this.logs.slice(0, 100);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.form-con {
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
}
.logs {
  font-size: 13px;
  line-height: 24px;
  color: @sub-font-color;
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
}
</style>
