<template>
  <div class="main-con">
    <div class="flex">
      <el-input size="small" v-model="pairName"></el-input>
      <el-input size="small" class="mgl-10" v-model="date"></el-input>
      <el-button size="small" class="mgl-10" @click="searchSymbol">查询</el-button>
    </div>
    <div class="fs-12">{{log}}</div>
    <div class="mini-chart-con">
      <canvas :id="'miniChart-' + i" v-for="i in 12" width="120" height="200" :key="i"></canvas>
    </div>
    <div style="overflow-x: scroll">
      <canvas id="klineChart" width="28000" height="600" @click="onClickChart"></canvas>
    </div>
  </div>
</template>

<script>
import tradeLibs from "@/libs/trade";
import * as predictApi from "@/api/predict";
import * as coinsApi from "@/api/coins";
import * as newsApi from "@/api/news";
import * as filterUtil from '@/utils/filterUtil';
import * as indicatorUtil from "@/utils/indicator";

let canvas = null, ctx = null;
const candleWidth = 2; // K线柱的宽度
const space = 1; // K线柱之间的间距
const marginTop = 30; // 顶部留白
const marginBottom = 20; // 底部留白
let klineData = [];

export default {
  data() {
    return {
      pairName: null,
      markTime: null,
      date: null,
      hourMarks: [],
      news: [],
      threshold: 0.3,
      thresholdRealChange: 2,
      log: null
    }
  },
  async mounted() {
    const { symbol, date } = this.$route.query;
    this.pairName = symbol;
    this.date = date || this.$func.getDate();
    this.markTime = parseInt(new Date(this.date).getTime()/1000);

    canvas = document.getElementById('klineChart');
    ctx = canvas.getContext('2d');
  },
  methods: {
    async searchSymbol(){
      this.markTime = parseInt(new Date(this.date).getTime()/1000);
      this.hourMarks = [];
      klineData = [];

      this.log = '加载详情';

      const startTime = this.markTime - 3500 * 3*60;
      const resInfo = await coinsApi.info({ coin: this.pairName.replace('USDT', '') });
      const info = resInfo && resInfo.data ? resInfo.data.baseInfo : null;
      if(info && info.brief){
        const address = info.brief.contractAddress ? info.brief.contractAddress.Ethereum : null;
        const circulatingSupply = info.circulatingSupply;

        this.log = '加载预测信号';
        if(address){
          const res = await predictApi.hourDataSource({ address, startTime });
          if(!res || !res.data || !res.data.hourData){
            return;
          }
          const hourData = res.data.hourData;
          const resPoints = await predictApi.pickPoints({
            points: hourData.map(r => parseFloat(r.out/circulatingSupply*100).toFixed(2))
          });
          const hourMarks = [];
          for(let i in resPoints.data){
            const idx = resPoints.data[i];
            hourMarks.push(hourData[idx]);
          }
          this.hourMarks = hourMarks;
        }

        this.log = '加载新闻';
        const resNews = await newsApi.searchNews({ symbol: this.pairName });
        this.news = resNews && resNews.data ? resNews.data : [];
      }

      this.log = '渲染图表';
      await this.generateCanvas();
    },

    async onClickChart(e){
      const { offsetX, offsetY } = e;
      const startX = this.getStartX();

      const idx = Math.round((offsetX - startX)/(candleWidth + space));

      let y1 = null, y2 = null;

      const scaleY = this.scaleYaxis();
      const startIdx = idx - 480;
      if(startIdx >= 0){
        // 4h稳定价
        const stablePrice_6h = indicatorUtil.cauStablePrice(klineData.slice(idx - 120, idx));
        const stablePrice_6h_Y = Math.round(scaleY(stablePrice_6h));
        this.drawLine([0, stablePrice_6h_Y], [canvas.width, stablePrice_6h_Y], '#8cb0fc');

        this.drawRect([0, stablePrice_6h_Y - 3], [canvas.width, stablePrice_6h_Y + 3, 'rgb(245,2,2)']);

        // 1天的稳定价
        const candles = klineData.slice(startIdx, idx);
        const startPrice = indicatorUtil.cauStablePrice(candles);
        const startY = Math.round(scaleY(startPrice));
        this.drawLine([0, startY], [canvas.width, startY], '#ffff00');
        y1 = startY - 10;
        // 3天前的startPrice
        /*const startTime_3d = klineData[idx].time - 3*24*3600;
        tradeLibs.candles(this.pairName, '3m', startTime_3d, 480).then(res => {
          if(res && res.length === 480){
            const prevStartPrice = indicatorUtil.cauStablePrice(res);
            const startY = Math.round(scaleY(prevStartPrice));
            this.drawLine([0, startY], [canvas.width, startY], '#ff0000');
          }
        });
        // 7天前的startPrice
        /*const startTime_7d = klineData[idx].time - 7*24*3600;
        tradeLibs.candles(this.pairName, '3m', startTime_7d, 480).then(res => {
          if(res && res.length === 480){
            const prevStartPrice = indicatorUtil.cauStablePrice(res);
            const startY = Math.round(scaleY(prevStartPrice));
            this.drawLine([0, startY], [canvas.width, startY], '#ffffff');
            y2 = startY + 10;
          }
        });*/

        setTimeout(() => {
          // 截取缩略图
          let startX = offsetX - 480*(candleWidth + space);
          for(let i = 1; i <= 24; i++){
            const miniCanvas = document.getElementById('miniChart-' + i);
            const miniCtx = miniCanvas.getContext('2d');
            miniCtx.clearRect(0, 0, miniCanvas.width, miniCanvas.height);
            const imageData = ctx.getImageData(startX + (i-1)*120, y2 - 200, 120, 200);
            miniCtx.putImageData(imageData, 0, 0);
          }
        }, 2000);
      }
    },
    getStartX(){
      const totalWidth = candleWidth * klineData.length + space * (klineData.length - 1);
      return parseInt((canvas.width - totalWidth) / 2);
    },
    /**
     * 绘制线段
     * @param point1
     * @param point2
     * @param color
     */
    drawLine(point1, point2, color, width = 1){
      ctx.strokeStyle = color;
      ctx.lineWidth = width;
      if(width === 0.5){
        ctx.globalAlpha = 0.5;
      } else {
        ctx.globalAlpha = 1;
      }
      ctx.beginPath();
      ctx.moveTo(point1[0], point1[1]);
      ctx.lineTo(point2[0], point2[1]);
      ctx.stroke();
    },
    /**
     * 绘制标记点
     * @param point
     * @param color
     */
    drawMark(point, color){
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(point[0], point[1], 3, 0, Math.PI * 2);
      ctx.fill();
    },
    async generateCanvas(){
      klineData = [];

      let lists = [];
      for(let num = 0; num < 7; num++){
        const startTime = this.markTime - (num + 1) * 1000 * 3 * 60;
        const res = await tradeLibs.candles(this.pairName, '3m', startTime, 1000);
        if(res && res.length === 1000){
          lists = res.concat(lists);
        } else {
          break;
        }
      }

      let changes = [], realChanges = [];
      let thresholdChange = 0.3, thresholdRealChange = 2;
      let stablePriceDay1 = null;
      for(let i = 0; i < lists.length; i++){
        const item = lists[i];
        const row = {
          time: parseInt(item.openTime/1000),
          open: parseFloat(item.open),
          high: parseFloat(item.high),
          low: parseFloat(item.low),
          close: parseFloat(item.close),
          volume: parseFloat(item.volume),
          change: parseFloat(parseFloat(parseFloat((item.close - item.open)/item.open*100).toFixed(2))),
          maxChange: parseFloat(parseFloat(parseFloat((item.high - item.low)/item.low*100).toFixed(2))),
          realChange: stablePriceDay1 ? (item.open - stablePriceDay1)/stablePriceDay1 * 100 : 0,
          stablePriceDay1
        };
        row.realChange = parseFloat(parseFloat(row.realChange).toFixed(2));

        if(row.time % 3600 === 0 && i >= 480){
          stablePriceDay1 = indicatorUtil.cauStablePrice(lists.slice(i-480, i));
          thresholdChange = filterUtil.cauThreshold(changes, 0.80, 1);
          thresholdRealChange = filterUtil.cauThreshold(realChanges, 0.68, 5);
        }

        /*let bad = false;
        if(Math.abs(row.realChange) - thresholdRealChange > 0){
          if(Math.abs(row.maxChange) - thresholdChange < 0){
            bad = true;
          }
        } else if(Math.abs(row.change) - thresholdChange < 0) {
          bad = true;
        }
        if(bad){
          row.open = row.close;
          row.high = row.open;
          row.low = row.open;
        }*/

        realChanges.push(row.realChange);
        realChanges = realChanges.slice(-480);

        changes.push(row.change);
        changes = changes.slice(-480);

        klineData.push(row);
      }

      this.drawKLineChart();
    },
    /**
     * 绘制矩形
     * @param pointA
     * @param pointB
     */
    drawRect(pointA, pointB, color){
      ctx.globalAlpha = 0.2;
      ctx.fillStyle = color;
      ctx.fillRect(Math.min(pointA[0], pointB[0]), Math.min(pointA[1], pointB[1]), Math.abs(pointB[0] - pointA[0]), Math.abs(pointB[1] - pointA[1]));
    },
    /**
     * 绘制蜡烛
     * @param x
     * @param candle
     * @param scaleY
     */
    drawCandle(x, candle, scaleY) {
      const openY = Math.round(scaleY(candle.open));
      const highY = Math.round(scaleY(candle.high));
      const lowY = Math.round(scaleY(candle.low));
      const closeY = Math.round(scaleY(candle.close));

      const color = candle.open > candle.close ? '#ED534F' : '#26A59A';

      const pointX = x + candleWidth / 2;

      // 绘制上下阴线
      this.drawLine(
          [pointX, highY],
          [pointX, lowY],
          color
      );
      // 绘制实体
      ctx.fillStyle = color;
      ctx.fillRect(x, Math.min(openY, closeY), candleWidth, Math.abs(openY - closeY));

      // 绘制change
      this.drawLine(
          [pointX, marginTop - 5],
          [pointX, marginTop - Math.abs(candle.change)*2 - 5],
          '#26A59A'
      );

      // 绘制时间点标记
      if(candle.time - this.markTime === 0){
        this.drawMark([x, Math.max(openY, closeY) + 10], 'yellow');
      }
      // 绘制蓝色信号点
      for(let i in this.hourMarks){
        const point = this.hourMarks[i];
        const time = point.timestamp - point.timestamp%180;
        if(candle.time - time === 0){
          this.drawMark([x - 3, Math.max(openY, closeY) + 15], '#00E1D9');
        }
      }
      // 绘制新闻点
      for(let i in this.news){
        const newsPoint = this.news[i];
        const time = newsPoint.timestamp - newsPoint.timestamp%180;
        if(candle.time - time === 0){
          this.drawMark([x, Math.max(openY, closeY) + 15], '#ff3773');
        }
      }

      // 绘制异常标识
      if(candle.realChange - this.thresholdRealChange > 0 && candle.realChange - this.thresholdRealChange < 8){
        this.drawLine(
            [pointX, marginTop + 5],
            [pointX, marginTop + 7],
            '#08ecd1'
        );
      }
    },
    /**
     * 缩放Y轴
     * @returns {function(*)}
     */
    scaleYaxis(){
      const maxPrice = Math.max(...klineData.map(candle => candle.high));
      const minPrice = Math.min(...klineData.map(candle => candle.low));
      const priceRange = maxPrice - minPrice;

      const enableHeight = (priceRange/minPrice*100) * 3; // 3px代表1%
      const scaleY = price => marginTop + enableHeight * (1 - (price - minPrice) / priceRange);

      return scaleY;
    },
    drawKLineChart() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.imageSmoothingEnabled = false;
      const scaleY = this.scaleYaxis();
      let startX = this.getStartX();
      klineData.forEach(candle => {
        this.drawCandle(startX, candle, scaleY);
        startX += candleWidth + space;
      });
    },
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

#klineChart {
  background-color: @content-bg-color;
  margin: 0 50px;
}
.mini-chart-con {
  height: 200px;
  background-color: #eee;
  canvas {
    margin: 0 1px;
    background-color: @content-bg-color;
  }
}
</style>