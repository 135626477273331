<template>
  <div class="main-con">
    <div class="form-con">
      <div class="flex mgt-16">
        <el-input v-model="splitTime" size="small" placeholder="播报时间间隔s">
          <template slot="append">s</template>
        </el-input>
        <div class="mgl-10"></div>
        <el-input v-model="splitPerTime" size="small" placeholder="单项播报时间间隔s">
          <template slot="append">s</template>
        </el-input>
        <el-checkbox v-model="silence" class="mgl-10">静默播报</el-checkbox>
      </div>

      <div class="form-item mgt-10" v-for="(item, idx) in formRows" :key="idx">
        <el-input v-model="form.symbols[idx]" size="small" placeholder="监控系列" class="w-200px"></el-input>
        <el-input v-model="remarks[form.symbols[idx]]" size="small" placeholder="备注名" class="w-150px mgl-10"></el-input>
        <el-input v-model="form.addresses[idx]" size="small" placeholder="监控地址" class="mgl-10 flex-1"></el-input>
        <el-input v-model="addressRemarks[form.addresses[idx]]" size="small" placeholder="地址备注名" class="w-150px mgl-10"></el-input>
        <i class="el-icon-delete text-red mgl-10" @click="formRows-=1,form.symbols.splice(idx,1),form.addresses.splice(idx,1)" v-if="idx!==0"></i>
      </div>
      <div class="btn-group flex mgt-16">
        <el-button size="small" @click="formRows+=1">添加</el-button>
        <el-button size="small" type="warning" @click="saveConfig">保存</el-button>
      </div>
    </div>
    <div class="logs-con scroll-con">
      <div class="log-row" v-for="(row, idx) in speaks" :key="idx">{{row}}</div>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      formRows: 1,
      splitTime: 30,
      splitPerTime: 3,
      silence: false,
      form: {
        symbols: [],
        addresses: []
      },
      remarks: {},
      addressRemarks: {},
      configs: {},
      prevFloorPrices: {},
      floorPrices: {},
      pendingCounts: {},
      prevFirstPos: {},
      tokenPos: {},
      speaks: []
    }
  },
  mounted() {
    this.loadConfig();
    this.runMonitor();
  },
  methods: {
    loadConfig(){
      let configs = localStorage.getItem('NftMonitorConfig');
      configs = configs ? JSON.parse(configs) : {};
      this.configs = configs;
      let rows = 0;
      for(let symbol in configs){
        for(let address in configs[symbol]){
          this.form.symbols.push(symbol);
          this.form.addresses.push(address);
          rows++;
        }
      }
      this.formRows = rows;
      // remarks
      const remarks = localStorage.getItem('NftMonitorRemarks');
      this.remarks = remarks ? JSON.parse(remarks) : {};
      const addressRemarks = localStorage.getItem('NftMonitorAddressRemarks');
      this.addressRemarks = addressRemarks ? JSON.parse(addressRemarks) : {};
      this.splitTime = localStorage.getItem('NftMonitorSplitTime') || 30;
    },
    async runMonitor(){
      while(true){
        const symbols = Array.from(new Set(this.form.symbols));
        for(let i in symbols){
          const symbol = symbols[i];
          const addresses = {};
          for(let address in this.configs[symbol]){
            this.configs[symbol][address].map(type => {
              !addresses[type] && (addresses[type] = []);
              addresses[type].push(address);
            });
          }
          await this.loadTokens(symbol, addresses.list);
          await this.$func.sleep(2*1000);
        }

        await this.$func.sleep(10*1000);
      }
    },
    /**
     * 加载挂单的token列表
     * @returns {Promise<void>}
     */
    async loadTokens(symbol, addresses = []) {
      try {
        const res = await magicApi.getCollectionTokens({
          collectionSymbol: symbol,
          disablePendingTransactions: false, // 显示pending的tokens
          limit: 40,
          offset: 0
        });

        this.tokenPos[symbol] = {};
        this.pendingCounts[symbol] = 0;
        let floor = null;
        if(res.data && res.data.tokens){
          let noPendingIdx = 1;
          for(let idx = 0; idx < res.data.tokens.length; idx++){
            const row = res.data.tokens[idx];
            // 记录地板
            if(!floor && !row.mempoolTxId){
              floor = true;
              this.floorPrices[symbol] = row.listedPrice/Math.pow(10, 8);
            }

            // 记录全部位置
            if(addresses.indexOf(row.owner) > -1){
              !this.tokenPos[symbol][row.owner] && (this.tokenPos[symbol][row.owner] = []);
              this.tokenPos[symbol][row.owner].push(noPendingIdx);
            }

            // 统计
            if(idx - 10 < 0 && row.mempoolTxId){
              // 前10中正在pending的数量
              this.pendingCounts[symbol]++;
            } else if(!row.mempoolTxId) {
              noPendingIdx++;
            }
          }

          const now = parseInt(new Date().getTime()/1000);
          const speaks = {};
          // 播报正在pending(非静默模式)
          for(let symbol in this.pendingCounts){
            if(!this.silence && this.pendingCounts[symbol] - 1 > 0){
              const symbolName = this.remarks[symbol] || symbol;
              !speaks[symbolName] && (speaks[symbolName] = []);
              speaks[symbolName].push('重要：' + symbolName + ' 前10中' + this.pendingCounts[symbol] + '个正在pending');
              speaks[symbolName].push('重要：' + symbolName + ' 前10中' + this.pendingCounts[symbol] + '个正在pending');
              speaks[symbolName].push('重要：' + symbolName + ' 前10中' + this.pendingCounts[symbol] + '个正在pending');
            }
          }
          // 播报最小位置
          for(let address in this.tokenPos[symbol]){
            const ranks = this.tokenPos[symbol][address];
            const name = this.addressRemarks[address] ? this.addressRemarks[address] : address.slice(-4).split('').join(' ');
            this.speaks.unshift(name + ' 的 ' + (this.remarks[symbol] || symbol) + ' 全部排名 [' + ranks.join(',') + ']');
            if(ranks.length === 0) break;
            if(
                !this.prevFirstPos[symbol] || !this.prevFirstPos[symbol][address]
                || this.prevFirstPos[symbol][address].pos - ranks[0] !== 0
                || now - this.prevFirstPos[symbol][address].time - this.splitTime > 0
            ){
              !this.prevFirstPos[symbol] && (this.prevFirstPos[symbol] = {});
              this.prevFirstPos[symbol][address] = {
                pos: ranks[0],
                time: now
              };

              !speaks[name] && (speaks[name] = []);
              if(ranks[0] - 5 <= 0){
                // 静默模式小于5的播报
                speaks[name].push('重要提醒：' + name + ' 的 ' + (this.remarks[symbol] || symbol) + ' 最小排名第 ' + ranks[0]);
                // 音乐
                if(this.silence){
                  const audio = new Audio(require('@/assets/mp3/2.mp3'));
                  setTimeout(() => {
                    audio.play();
                  }, 5000);
                  setInterval(() => {
                    audio.play();
                  }, 30*1000);
                }
              } else if(!this.silence){
                speaks[name].push(name + ' 的 ' + (this.remarks[symbol] || symbol) + ' 最小排名第 ' + ranks[0]);
              }
            }
          }
          // 播报地板
          if(!this.silence){
            // 非静默模式播报地板
            for(let symbol in this.floorPrices){
              const floorPrice = this.floorPrices[symbol];
              if(
                  !this.prevFloorPrices[symbol]
                  || floorPrice - this.prevFloorPrices[symbol].price !== 0
                  || now - this.prevFloorPrices[symbol].time - this.splitTime*2 > 0
              ){
                this.prevFloorPrices[symbol] = {
                  price: floorPrice,
                  time: now
                };
                !speaks['floor'] && (speaks['floor'] = []);
                speaks['floor'].push((this.remarks[symbol] || symbol) + ' 当前地板价 ' + floorPrice);
              }
            }
          }

          // 播报
          const speakRows = [];
          for(let name in speaks){
            speakRows.push(speaks[name].join(';'));
          }
          if(speakRows.length > 0){
            this.speaks = speakRows.concat(this.speaks);
            this.speaks.length > 100 && (this.speaks = this.speaks.slice(0, 100));

            for (let j in speakRows) {
              await this.$speak(speakRows[j]);
              await this.$func.sleep(this.splitPerTime * 1000);
            }
          }
        }
      } catch (err) {
        console.log(err)
        console.log('Error: loadTokens -> ' + (err.message || err.msg));
      }
    },
    /**
     * 保存配置
     */
    saveConfig(){
      const configs = {};
      for(let i in this.form.symbols){
        const symbol = this.form.symbols[i];
        const address = this.form.addresses[i];
        const type = 'list';
        if(!symbol || !address){
          continue;
        }
        if(!configs[symbol]){
          configs[symbol] = {};
        }
        if(!configs[symbol][address]){
          configs[symbol][address] = [];
        }
        if(configs[symbol][address].indexOf(type) === -1){
          configs[symbol][address].push(type);
        }
      }
      localStorage.setItem('NftMonitorConfig', JSON.stringify(configs));
      localStorage.setItem('NftMonitorRemarks', JSON.stringify(this.remarks));
      localStorage.setItem('NftMonitorAddressRemarks', JSON.stringify(this.addressRemarks));
      localStorage.setItem('NftMonitorSplitTime', this.splitTime);
      this.$message.success('配置已保存');
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.main-con {
  position: relative;
  flex: 1;
  margin-top: @mg;
  margin-bottom: @mg;
  margin-right: @mg;
  padding: 0 @mg @mg @mg;
  display: flex;
  flex-direction: column;
  background-color: @section-bg-color;
  border-radius: @mg;
  height: calc(100vh - 75px - 20px - @mg*2);
}

.form-con {
  .form-item {
    display: flex;
    align-items: center;
  }
}

.logs-con {
  margin-top: @mg;
  background-color: @content-bg-color;
  border-radius: @mg;
  padding: @mg;
  height: 300px;
  .log-row {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
</style>