<template>
  <div class="container">
    <div class="base-info" v-if="info">
      <div class="title">
        <span>{{info.title}}</span>
        <div class="right">发布人:{{info.author}} | 盈利率:{{info.profit*100}}%</div>
      </div>
      <div class="brief">{{info.brief}}</div>
    </div>
    <div class="config-panel" v-if="info">
      <news-keywords :info="info" :config="config" :config-key="configKey" :key="info.id"></news-keywords>
    </div>
    <div class="handle-btns">
      <el-button size="medium" type="info" @click="saveStrtegy(info)">保存</el-button>
      <el-button size="medium" type="primary" @click="goToSimulate">模拟</el-button>
      <el-button size="medium" type="success">启动</el-button>
    </div>
  </div>
</template>

<script>
import NewsKeywords from './components/StrategyConfig/NewsKeywords';
export default {
  components:{
    NewsKeywords
  },
  data(){
    return {
      info: null,
      config: null,
      configKey: null
    }
  },
  mounted() {
    const { info, configKey } = this.$route.query;
    this.info = JSON.parse(info);
    if(configKey){
      let myStrategy = localStorage.getItem('MY_STRATEGY')
      myStrategy = myStrategy ? JSON.parse(myStrategy) : {};
      this.config = myStrategy[configKey].ruleForm;
      this.configKey = configKey;
    }
    console.log(this.config)

    this.$eventBus.$on('SAVE_STRTEGY_SUCCESS', ruleForm => {
      this.config = ruleForm;
    });
  },
  methods:{
    /**
     * 保存策略
     * @param info
     */
    saveStrtegy(info){
      this.$eventBus.$emit('SAVE_STRTEGY', info);
    },
    /**
     * 前往到模拟
     */
    goToSimulate(){
      if(!this.config){
        this.$message.error('请先配置并保存参数');
        return;
      }
      this.$router.push({ path: '/simulate', query: { strategy: JSON.stringify(this.info), configKey: this.configKey } });
    }
  }
}
</script>

<style lang="less" scoped>
.container{
  width: 1200px;
  margin: 0 auto;
}
.base-info{
  margin: 15px 0;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      flex: 1;
      font-size: 15px;
      font-weight: bold;
    }
    .right{
      font-size: 12px;
      font-weight: normal;
      color: #666;
    }
  }
  .brief{
    background-color: #efefef;
    font-size: 13px;
    color: #999;
    padding: 12px;
    margin-top: 10px;
  }
}
.handle-btns{
  text-align: center;
}
</style>