import Binance from 'binance-api-node';

let accountConfig = localStorage.getItem('ACCOUNT_CONFIG');
accountConfig = accountConfig ? JSON.parse(accountConfig) : {};

const binanceClient = new Binance({
    httpBase: 'https://data-api.binance.vision'
});
let binanceClientPrivate = {};
(async ()=>{
    const timeRes = await getTime();
    const timeDiff = timeRes.serverTime - timeRes.localTime;
    const apiKey = '8AsOOW0Q7D1mc4BxZ2BJ5WjbwLipt1oR1CZmSd9msluYBI03BbBNoDQlKP7uyc3H',//accountConfig.binance ? accountConfig.binance.apiKey : null,
        apiSecret = 'u3EX4kQFfNPqcjDX23pdOw02uZ5HSRGsRr6tzXIa3rkVBR8e52kLUJKS426KQXoc'//accountConfig.binance ? accountConfig.binance.secretKey : null;
    binanceClientPrivate = new Binance({
        apiKey: apiKey,
        apiSecret: apiSecret,
        getTime: () => {
            return new Date().getTime() + timeDiff
        },
        httpBase: 'https://api.binance.com'
    });
})();

async function getTime(){
    const res = {
        serverTime: await binanceClient.time(),
        localTime: new Date().getTime()
    };
    return res;
}

export default {
    check: async (checkApiKey, checkApiSecret) => {
        const checkBinanceClient = new Binance({
            apiKey: checkApiKey,
            apiSecret: checkApiSecret,
            getTime: () => {
                return new Date().getTime() - 759
            },
            httpBase: 'https://api.ai-dex.online/binance/api?url='
        });
        const res = await checkBinanceClient.accountInfo();
        return res;
    },
    time: async () => {
        const res = await getTime();
        res.diff = res.serverTime - res.localTime;
        return res;
    },
    exchangeInfo: async (pairName) => {
        const params = {};
        if(pairName){
            params.symbol = pairName;
        }
        const res = await binanceClient.exchangeInfo(params);
        const symbols = res ? res.symbols : [];
        if(pairName){
            let row = null;
            for(let i in symbols){
                if(symbols[i].symbol == pairName){
                    row = symbols[i];
                    break;
                }
            }
            return row;
        }
        return symbols;
    },
    accountSnapshot: async () => {
        try {
            const res = await binanceClientPrivate.accountSnapshot({
                "type": "SPOT"
            });
            return res;
        } catch (err) {
            console.log(err.message);
            return null;
        }
    },
    // 获取spot账户信息
    accountInfo: async () => {
        const res = await binanceClientPrivate.accountInfo();
        if(res && res.balances){
            res.balances = res.balances.filter(r => {
                return parseFloat(r.free) > 0 || parseFloat(r.locked) > 0;
            });
        }
        return res;
    },
    // startTime: s
    candles: async (pairName, interval, startTime, limit) => {
        limit = limit ? limit : 500;
        interval = interval ? interval : '5m';
        const params = {
            symbol: pairName,
            interval,
            limit
        }
        startTime && (params.startTime = startTime*1000);
        const res = await binanceClient.candles(params);
        return res;
    },
    spotBuy: async (options) => {
        options.side = 'BUY';
        const res = await binanceClientPrivate.order(options);
        return res;
    },
    spotSell: async (options) => {
        options.side = 'SELL';
        const res = await binanceClientPrivate.order(options);
        return res;
    },
    stopLossSell: async (options) => {
        const params = {
            symbol: options.pairName,
            side: 'SELL',
            type: 'STOP_LOSS_LIMIT',
            quantity: options.quantity,
            price: options.price ? options.price : options.stopPrice,
            stopPrice: options.stopPrice
        }
        const res = await binanceClientPrivate.order(params);

        return res;
    },
    cancleOrder: async (params) => {
        const res = await binanceClientPrivate.cancelOrder(params);
        return res;
    },
    allOrders: async (params) => {
        const res = await binanceClientPrivate.allOrders(params);
        return res;
    },
    openOrders: async (params) => {
        const res = await binanceClientPrivate.openOrders(params);
        return res;
    },
    tradeRecords: async (params) => {
        const res = await binanceClientPrivate.myTrades(params);
        return res;
    },
    lastTrades: async (params) => {
        const res = await binanceClient.trades(params);
        return res;
    },
    dailyStats: async (params) => {
        const res = await binanceClient.dailyStats(params);
        return res;
    },
    avgPrice: async (params) => {
        const res = await binanceClient.avgPrice(params);
        return res;
    },
    prices: async (pairName) => {
        const params = {};
        if(pairName){
            params.symbol = pairName;
        }
        const res = await binanceClient.prices(params);
        return res;
    },
}