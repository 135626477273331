<template>
  <div class="container">
    <div class="left-panel left-side scroll-con">
      <!-- search -->
      <div class="search-input">
        <el-input v-model="filterKeyword" size="mini">
          <template slot="append">
            <i class="el-icon-search"></i>
          </template>
        </el-input>
      </div>
      <div
          class="menu-item"
          :class="{active: coinActive === item.pair}"
          @click="onChangeCoin(item)"
          v-for="(item, idx) in lists"
          :key="idx"
          v-if="!filterKeyword || item.pair.indexOf(filterKeyword.toUpperCase()) > -1"
      >
        <span>{{item.pair}}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="main-con">
      <kline :coinl-info="currentCoin" :key="currentCoin.pair" v-if="currentCoin"></kline>
      <div class="kline-handle">
        <el-button size="mini" @click="showOrHideStablePrice">{{showStablePrice ? '隐藏支撑位' : '显示支撑位'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as coinsApi from '@/api/coins';
import Kline from './components/HotCoins/Kline';

export default {
  components: { Kline },
  data() {
    return {
      coinActive: 'BTCUSDT',
      currentCoin: null,
      lists: [],
      filterKeyword: null,
      showStablePrice: true
    }
  },
  mounted() {
    const { symbol } = this.$route.query;
    if(symbol){
      this.coinActive = symbol;
    }
    this.loadCoinsLists();
  },
  methods: {
    onChangeCoin(row){
      this.coinActive = row.pair;
      this.currentCoin = row;
    },
    showOrHideStablePrice(){
      this.showStablePrice = !this.showStablePrice;
      this.$eventBus.$emit('SHOW_OR_HIDE_STABLEPRICE', this.showStablePrice);
    },
    async loadCoinsLists(){
      const res = await coinsApi.lists();
      if(res && res.data){
        this.lists = res.data;
        for(let i in this.lists){
          if(this.lists[i].pair === this.coinActive){
            this.currentCoin = this.lists[i];
            break;
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.container {
  display: flex;
  justify-content: space-between;
}

.left-side{
  width: 200px;
  background-color: @section-bg-color;

  .search-input{
    position: sticky;
    top: 0;
    margin: 0 0 0 0;
    padding: 15px 15px 15px 15px;
    width: calc(100%);
    box-sizing: border-box;
    z-index: 1;
    background-color: @section-bg-color;
  }
  .menu-item {
    margin-top: 0 !important;
  }
}
.main-con {
  margin: @mg auto;
  padding: 0 @mg @mg @mg;
  border-radius: 25px;
  position: relative;
  flex: 1;
  background-color: @section-bg-color;
  height: calc(100vh - 75px - @mg*3 - 5px);
}
</style>