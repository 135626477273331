<template>
  <div class="main-con">
    <div class="offers-con scroll-con">
      <i class="el-icon-loading loading" v-if="offers === null"></i>
      <div class="thead nft-item">
        <div class="price">价格</div>
        <div class="w-150px">数量</div>
        <div class="w-300px">用户地址</div>
        <div class="w-300px">过期时间</div>
        <div class="w-300px">更新时间</div>
        <div class="flex-1"></div>
      </div>
      <div class="nft-item" v-for="(item, idx) in offers" :key="idx">
        <div class="price">{{item.price}}<span class="mark">BTC</span></div>
        <div class="w-150px">{{item.quantity}}</div>
        <div class="w-300px">{{$com.formatString(item.maker, 6, 8)}} <span class="self-mark" v-if="currentAccount === item.maker">自己</span></div>
        <div class="w-300px">{{$func.getDate(item.expireTime)}}</div>
        <div class="w-300px">{{$func.getDate(item.updateTime)}}</div>
        <div class="flex-1 text-right">
          <el-button size="mini" type="warning" @click="onEditCollectionOffer(item)" v-if="currentAccount === item.maker">修改</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  props: ['info'],
  data(){
    return {
      currentAccount: window.currentAccount,
      offers: null,
      waitDestroy: null
    }
  },
  mounted() {
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.$set(this, 'currentAccount', account.address);
    });
    this.$eventBus.$on('UPDATE_COLLECTION_OFFERS', () => {
      this.loadCollectionOffers();
    });

    this.loopCollectionOffers();
  },
  beforeDestroy() {
    this.waitDestroy = true;
  },
  methods: {
    async loopCollectionOffers(){
      while(true){
        if(this.waitDestroy) break;
        await this.loadCollectionOffers();
        await this.$func.sleep(15*1000);
      }
      this.waitDestroy = null;
    },
    /**
     * 加载系列offers
     * @returns {Promise<void>}
     */
    async loadCollectionOffers(){
      console.log('loadCollectionOffers')
      const offers = await magicApi.getCollectionOffers({ symbol: this.info.collectionSymbol });
      this.offers = offers;
    },
    onEditCollectionOffer(item){
      this.$eventBus.$emit('EDIT_COLLECTION_OFFER', item);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.offers-con {
  margin-bottom: @mg;
  padding: @mg;
  background-color: @section-bg-color;
  border-radius: @mg;
  height: calc(100vh - 75px - 82px - 20px - @mg*4 - 36px);
  position: relative;
}
.nft-item {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
  font-size: 13px;

  &:first-child {
    margin-top: 0;
  }
  &.thead{
    padding: 10px @mg;
    position: sticky;
    top: 0;
    &::before {
      content: ' ';
      position: absolute;
      top: -@mg;
      left: 0;
      right: 0;
      background-color: @section-bg-color;
      height: @mg;
    }
  }
  .price {
    width: 200px;
  }
  .mark {
    color: @sub-font-color;
    font-size: 12px;
    padding-left: 5px;
  }
  .self-mark {
    color: @green;
    font-size: 13px;
  }
}
</style>