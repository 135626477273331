<template>
  <div class="main-con">
    <div class="tab-menus mgt-16">
      <div class="tab-item" :class="{active: activeTab === 'collection-offer'}" @click="onChangeTab('collection-offer')">系列Offer</div>
      <div class="tab-item" :class="{active: activeTab === 'token-offer'}" @click="onChangeTab('token-offer')">Token Offer</div>
    </div>
    <div class="offers-con scroll-con">
      <i class="el-icon-loading loading" v-if="offers === null"></i>
      <div class="nft-item" v-for="(item, idx) in offers" :key="idx">
        <div class="img-con">
          <el-image
              :src="'https://img-cdn.magiceden.dev/rs:fit:100:0:0/plain/https%3A%2F%2Frenderer.magiceden.dev%2Fv2%2Frender%3Fid%3D' + item.tokenId"
              fit="cover"
              lazy
              class="cover"
          >
            <div slot="error" class="error-image">
              <img :src="item.token.contentURI"/>
            </div>
          </el-image>
        </div>
        <div class="data-col name-col">
          <div class="title">
            <a :href="'https://magiceden.io/ordinals/item-details/' + item.tokenId" target="_blank">
              {{item.token.meta.name}}
            </a>
          </div>
          <div class="sub-title mgt-10">#{{item.token.inscriptionNumber}}</div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">OfferPrice:</span> {{item.price/Math.pow(10, 8)}}
          </div>
          <div class="mgt-10">
            <span class="label">ListPrice:</span> {{item.token.listedPrice/Math.pow(10, 8)}}
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Expire:</span> {{$func.getDate(item.expirationDate)}}
          </div>
          <div class="mgt-10">
            <span class="label">Seller:</span> {{$com.formatString(item.sellerReceiveAddress, 8, 8)}}
          </div>
        </div>
        <div class="flex-1"></div>
        <div class="data-col handle-col">
          <i
              class="el-icon-error text-danger"
              @click="cancelOffer(item, idx)"
          ></i>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
          :page-size="pageSize"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="total"
          @current-change="onChangePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  data() {
    return {
      currentAccount: window.currentAccount,
      activeTab: 'token-offer',
      offers: null,
      pageSize: 40,
      currentPage: 1,
      total: 0
    }
  },
  mounted() {
    this.$eventBus.$on('ACCOUNT_CONNECTED',(account) => {
      this.currentAccount = account.address;
      this.loadMyOffers();
    });
    this.loadMyOffers();
  },
  methods: {
    /**
     * 切换系列类型
     * @param name
     */
    onChangeTab(name){
      if(this.activeTab === name){
        return;
      }
      this.activeTab = name;
      if(this.activeTab === 'collection-offer'){

      }
    },
    onChangePage(p){
      this.currentPage = p;
      this.loadMyOffers();
    },
    /**
     * 我的offers
     */
    async loadMyOffers(){
      if(!this.currentAccount){
        this.$message.error('Wallet not connected!');
        return;
      }
      const res = await magicApi.getOffers({
        wallet_address_buyer: this.currentAccount,
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize
      });
      if(res && res.data && res.data.offers){
        this.offers = res.data.offers;
        this.total = parseInt(res.data.total);
      } else {
        this.offers = [];
      }
    },
    /**
     * 取消offer
     */
    async cancelOffer(item, idx){
      const res = await magicApi.cancelOffer({ offerId: item.id }, this.currentAccount);
      if(res && res.code == 200){
        this.offers.splice(idx, 1);
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.main-con {
  position: relative;
  flex: 1;
}
.offers-con {
  margin-bottom: @mg;
  padding: 0 @mg @mg @mg;
  display: flex;
  flex-wrap: wrap;
  background-color: @section-bg-color;
  border-radius: @mg;
  height: calc(100vh - 75px - 20px - @mg*2 - 5px - 32px - 36px);
}
.nft-item {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .img-con{
    width: 40px;
    height: 40px;
    margin-right: 15px;

    .cover{
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &.el-image {
        .error-image {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      /deep/.el-image__error, /deep/.el-image__placeholder{
        background-color: @content-bg-color;
        background-image: url("../../../assets/imgs/logo-icon.png");
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center;
        filter: grayscale(1);
        opacity: 0.15;
        font-size: 12px;
      }
    }
  }

  .data-col {
    min-width: 200px;
    font-size: 13px;

    &.name-col {
      width: 240px;

      a{
        text-decoration: none;
        color: @light-color;
      }
    }
    &.handle-col {
      padding: 0 15px;
      min-width: auto;
      width: 100px;
      display: flex;
      justify-content: right;
      align-items: center;

      i {
        font-size: 18px;
        cursor: pointer;
        color: @font-color;
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
      }
      .view-btn{
        margin-left: 15px;
      }
      .like-btn {
        font-size: 18px;
        &.on{
          color: @light-color;
          font-size: 20px;
        }
      }
    }
    .title {
      color: @light-color;
    }
    .sub-title {
      color: @font-color;
    }
    .label {
      color: @sub-font-color;
    }
    .currency {
      font-size: 12px;
      color: @sub-font-color;
    }
  }
}
.tab-menus {
  .tab-item {
    &.active {
      background-color: @section-bg-color;
    }
  }
}
</style>