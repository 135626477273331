<template>
  <div class="main-con">
    <div class="ststistic-con scroll-con">
      <div class="flex">
        <el-input size="small" v-model="filterSymbol"></el-input>
        <el-button size="small" @click="searchSymbol" class="mgl-10">查询</el-button>
      </div>
      <div class="fs-12 mgt-10">
        <span>全部: {{ Object.keys(lists).length }}</span>
        <span class="pdl-20"><10min: {{ percent.m10 }}</span>
        <span class="pdl-20"><20min: {{ percent.m20 }}</span>
        <span class="pdl-20"><30min: {{ percent.m30 }}</span>
        <span class="pdl-20"><60min: {{ percent.m60 }}</span>
        <span class="pdl-20"><120min: {{ percent.m120 }}</span>
        <span class="pdl-20">other: {{ percent.other }}</span>
      </div>
      <div
          class="statistic-item fs-12 mgt-10"
          :class="{light: item.accepted.time - currentTime > 0}"
          v-for="(item, idx) in lists"
          :key="idx"
      >
        <div style="width: 460px;">
          <a :href="'https://magiceden.io/ordinals/item-details/' + item.list.tokenId" target="_blank">{{item.list.tokenId}}</a>
        </div>
        <div class="pdl-20" style="width: 220px;">挂单: {{item.list.date}} ({{item.list.listedPrice/Math.pow(10, 8)}})</div>
        <div class="pdl-20" style="width: 240px;">接单: {{item.accepted.date}} ({{item.accepted.listedPrice/Math.pow(10, 8)}})</div>
        <div class="pdl-20" style="width: 220px;">{{item.accepted.mode}}</div>
        <div class="pdl-20">时差: {{parseInt((item.accepted.time - item.list.time)/60)}} min</div>
        <div class="pdl-20">
          价差: {{ parseFloat((item.list.listedPrice - item.accepted.listedPrice)/item.accepted.listedPrice*100).toFixed(2)}}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  data(){
    return {
      currentTime: new Date().getTime()/1000 - 24*3600,
      filterSymbol: null,
      lists: {},
      percent: {
        m10: 0,
        m20: 0,
        m30: 0,
        m60: 0,
        m120: 0,
        other: 0
      }
    }
  },
  async mounted() {
    let { symbols } = this.$route.query;
    if(symbols){
      symbols = symbols.split(',');
      for(let i in symbols){
        await this.getListAndAcceptedStatistic(symbols[i]);
      }
    }
  },
  methods: {
    searchSymbol(){
      if(this.filterSymbol){
        this.lists = {};
        this.percent = {
          m10: 0,
          m20: 0,
          m30: 0,
          m60: 0,
          m120: 0,
          other: 0
        };
        this.getListAndAcceptedStatistic(this.filterSymbol);
      }
    },
    /**
     * 挂单和被接offer统计
     * @returns {Promise<void>}
     */
    async getListAndAcceptedStatistic(symbol){
      let lists = {};
      const acceptedOffers = await this.getAllAcceptedOffers(symbol);
      for(let i = 0; i < acceptedOffers.length; i++){
        const item = acceptedOffers[i];
        this.getTokenActivities(item.tokenId).then(activities => {
          for(let j in activities){
            const row = activities[j];
            if(row.txId === item.txId && (!lists[item.txId] || !lists[item.txId].accepted)){
              !lists[item.txId] && (lists[item.txId] = {});
              row.time = new Date(row.createdAt).getTime()/1000;
              row.date = this.$func.getDate(row.time);
              row.mode = row.kind === 'offer_accepted_broadcasted' ? 'Token Offer' : '系列offer';
              if(row.kind === 'coll_offer_fulfill_broadcasted'){
                row.listedPrice = row.txValue;
              }
              lists[item.txId].time = row.time;
              lists[item.txId].accepted = row;
            }
            if(lists[item.txId] && lists[item.txId].accepted && row.kind === 'list'){
              row.time = new Date(row.createdAt).getTime()/1000;
              row.date = this.$func.getDate(row.time);
              lists[item.txId].list = row;
              this.$set(this.lists, item.txId, lists[item.txId]);
              break;
            }
          }
        });
        if((i + 1)%5 === 0){
          await this.$func.sleep(200);
        }
      }

      await this.$func.sleep(2000);
      // 将对象转换为数组
      const dataArray = Object.entries(lists);
      for(let i in dataArray){
        dataArray[i] = dataArray[i][1];
        const item = dataArray[i];
        if(item.accepted.time - item.list.time < 10*60){
          this.percent.m10++;
        } else if(item.accepted.time - item.list.time < 20*60){
          this.percent.m20++;
        } else if(item.accepted.time - item.list.time < 30*60){
          this.percent.m30++;
        } else if(item.accepted.time - item.list.time < 60*60){
          this.percent.m60++;
        } else if(item.accepted.time - item.list.time <= 120*60){
          this.percent.m120++;
        } else if(item.accepted.time - item.list.time > 120*60){
          this.percent.other++;
        }
      }

      // 按时间排序
      dataArray.sort((a, b) => b.time - a.time);
      this.lists = dataArray;
    },
    /**
     * 获取所有accepted offer
     */
    async getAllAcceptedOffers(symbol){
      const res = await magicApi.getActivities({
        collectionSymbol: symbol,
        kind: ['offer_accepted_broadcasted', 'coll_offer_fulfill_broadcasted']
      });
      if(!res || !res.data || !res.data.activities){
        return [];
      }
      return res.data.activities;
    },
    async getTokenActivities(tokenId){
      const res = await magicApi.getActivities({
        tokenId,
        kind: ['offer_accepted_broadcasted', 'coll_offer_fulfill_broadcasted', 'list']
      });
      if(!res || !res.data || !res.data.activities){
        return [];
      }

      return res.data.activities;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.main-con {
  position: relative;
  flex: 1;
}
.ststistic-con {
  margin-top: @mg;
  margin-bottom: @mg;
  padding: 0 @mg @mg @mg;
  display: flex;
  flex-direction: column;
  background-color: @section-bg-color;
  border-radius: @mg;
  height: calc(100vh - 75px - 20px - @mg*2 - 5px - 32px);

  .statistic-item {
    display: flex;

    &.light {
      color: @light-color;
      a {
        color: @light-color;
      }
    }
    a {
      color: @font-color;
      text-decoration: none;
    }
  }
}
</style>