<template>
  <div class="content-con">
    <div class="left-panel">
      <el-tabs v-model="activeTab" @tab-click="onChangeTab" class="scroll-con" style="height: 400px;">
        <el-tab-pane label="活跃地址">
          <div class="token-item" v-for="(item, idx) in hot" :key="idx">
            <div class="address">
              <span @click="onChangeTransferChart(null, item.address)" :class="{'text-danger': viewAddress == item.address}">
                {{$com.formatString(item.address, 6, 8)}}
              </span>
              <i @click="$com.copyText(item.address)" class="el-icon-copy-document"></i>
            </div>
            <div class="number">{{item.count}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="新闻动态">
          <div class="trade-logs" v-for="(item, idx) in tradeRecords" :key="idx">
            <div class="log-item" :class="{'text-danger': item.side == 'sell'}">
              <div class="time">{{$func.getDate(item.time)}}</div>
              <div class="side">{{item.side}}</div>
              <div class="price">{{item.price}}</div>
              <div class="amount">{{item.amount}}</div>
              <div class="amount">{{item.side == 'sell' ? item.rate : ''}}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="symbolInfo.baseInfo">
        <div>{{symbolInfo.baseInfo.symbol}}</div>
        <div>circulatingSupply:{{symbolInfo.baseInfo.circulatingSupply}}</div>
        <div>totalSupply:{{symbolInfo.baseInfo.totalSupply}}</div>
        <div>marketCap:${{symbolInfo.marketCap}}</div>
      </div>

      <div>时间：{{lastDate}}</div>
      <el-button size="mini" @click="pauseAnimate = !pauseAnimate">暂停</el-button>
      <el-button size="mini" @click="animateSpeed = 20">20</el-button>
      <el-button size="mini" @click="animateSpeed = 50">50</el-button>
      <el-button size="mini" @click="animateSpeed = 80">80</el-button>
      <el-button size="mini" @click="animateSpeed = 200">200</el-button>
      <el-button size="mini" @click="animateSpeed = 1000">1000</el-button>
    </div>
    <div class="main-panel">
      <div id="kline-chart-con" style="height: 600px; width: 100%;"></div>
      <div class="chart-box" :class="{'enable-scroll': chartWidth != '100%'}">
        <div class="right-btns">
          <el-button size="mini" :type="chartTransferTerm == '24H' ? 'danger' : 'info'" @click="onChangeTransferChart('24H')">24H</el-button>
          <el-button size="mini" :type="chartTransferTerm == '3D' ? 'danger' : 'info'" @click="onChangeTransferChart('3D')">3D</el-button>
          <el-button size="mini" :type="chartTransferTerm == '7D' ? 'danger' : 'info'" @click="onChangeTransferChart('7D')">7D</el-button>
          <el-button size="mini" :type="chartTransferTerm == 'ALL' ? 'danger' : 'info'" @click="onChangeTransferChart('ALL')">ALL</el-button>
          <el-button size="mini" :type="chartTransferTerm == 'MORE' ? 'danger' : 'info'" @click="onChangeTransferChart('MORE')">MORE</el-button>
        </div>
        <div id="chart-transfer" class="chart-con" :style="{width: chartWidth}"></div>
      </div>
    </div>


<!--    <div class="title">清洗过的数据</div>-->
<!--    <div class="chart-box">-->
<!--      <div id="chart-new" class="chart-con" :style="{width: 8000 + 'px'}"></div>-->
<!--    </div>-->

<!--    <div class="title">频次</div>-->
<!--    <div class="chart-box">-->
<!--      <div id="chart-count" class="chart-con" :style="{width: 8000 + 'px'}"></div>-->
<!--    </div>-->

<!--    <div class="title">Out总量</div>-->
<!--    <div class="chart-box">-->
<!--      <div id="chart-out" class="chart-con" :style="{width: 30000 + 'px'}"></div>-->
<!--    </div>-->

<!--    <div class="title">Inner总量</div>-->
<!--    <div class="chart-box">-->
<!--      <div id="chart-inner" class="chart-con" :style="{width: 8000 + 'px'}"></div>-->
<!--    </div>-->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import * as func from '@/utils/func';
import { analysis } from "@/api/predict";

let klineChart = null, candleSeries = null, volumeSeries = null, changeSeries = null, changeEmaSeries = null, volumeOutSeries = null, volumeInSeries = null, emaSeries = null, emaSlowSeries = null;
const minVisibleCount = 500;
const option = {
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      const date = params[0].axisValue;
      const inValue = params[0].data.in;
      const outValue = params[1].data.out;
      const percentIn = params[0].data.percent;
      const percentOut = params[1].data.percent;

      return `Date: ${date}<br>Withdrawals: ${outValue}<br>Deposit: ${inValue}<br>PercentIn: ${percentIn}<br>PercentOut: ${percentOut}`;
    },
  },
  grid: {
    left: 100,
    right: 100
  },
  legend: {
    data: ['Deposit', 'Withdrawals']
  },
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: [{
    type: 'value',
    name: 'Amount',
  }, {
    type: 'value',
    name: 'Change',
    min: -5,
    max: 5
  }],
  barWidth: 5,
  dataZoom: [
    {
      type: 'slider', // 使用滑块型数据区域缩放组件
      xAxisIndex: [0], // 关联X轴索引
      startValue: 0, // 设置滚动窗口的起始值
      minValueSpan: 20
    }
  ]
};
let myBarChart = null;

import * as predictApi from '@/api/predict';
import * as coinsApi from '@/api/coins';
import tradeLibs from '@/libs/trade';
import * as newsApi from "@/api/news";

export default {
  data() {
    return {
      symbol: null,
      address: null,
      symbolInfo: {},
      activeTab: 0,
      hot: [],
      timeRange: [],
      lastDate: '',
      klineInterval: '3m',
      klineData: [],
      showKlineData: [],
      overallSlope: null,
      overallSlopeQuick: null,
      emaData: [],
      lastKlineChange: [], // 记录最后的几个涨幅
      lastGoodChange: [],
      lastEmaSlowData: [],
      lastEmaData: [],
      lastProfit: [],
      buyPoints: null, // 最近一个买点
      waitBuy: null,
      forceSell: false,
      tradeRecords: [], // 买卖记录
      afterBuyMaxPrice: null,
      nextKlineStartTime: null,
      isLoadingKlineData: false,
      dataSource: {},
      countSource: {},
      countIdx: 0,
      outGroupHourly: [],
      showBarData: [],
      chartTransferTerm: null,
      viewAddress: null,
      chartWidth: '100%',
      changeRates: [],
      xAxisData: [],
      seriesInData: [],
      seriesOutData: [],
      seriesRateData: [],
      markersData: [],
      animateSpeed: 50,
      pauseAnimate: false,
      showNewsIdx: 0,
      showNews: [],
      weekMarkOut: [],
      weekTotalOut: 0,
      buyTrainsData: [],
    }
  },
  async mounted() {
    const { address, symbol } = this.$route.query;
    this.address = address;
    this.symbol = symbol;

    try {
      this.getSymbolInfo();
      await this.getTransfers();
      console.log('dataSource -> ' + this.timeRange[0] + ' -> ' + this.timeRange[1])
      await this.getKlineData(symbol + 'USDT', this.nextKlineStartTime, this.klineInterval);

      // chart
      this.showKlineData = this.klineData.slice(0, 1);
      this.drawKline(this.showKlineData, {});

      this.onChangeTransferChart('ALL', this.viewAddress);

      // 运行模拟
      this.simulateData();
      this.onUpdateBarChart();
      this.onUpdateKline()
    } catch (err) {
      console.log(err);
      this.$message.error('数据加载失败');
    }
  },
  methods: {
    onChangeTab(){

    },
    /**
     * 模拟运行
     */
    async simulateData(){
      // 每一步推进1min
      let idx = 1, prevBarIdx = -1;
      while(true) {
        await func.sleep(this.animateSpeed);
        if(this.pauseAnimate){
          await func.sleep(1000);
          continue;
        }
        // k线
        const newData = this.klineData[idx];
        if(!newData){
          break;
        }
        this.$eventBus.$emit('KLINE-DATA', newData, idx);
        idx++;
        if(this.klineData.length - idx < 500 && !this.isLoadingKlineData && this.nextKlineStartTime < this.timeRange[1]){
          this.getKlineData(this.symbol + 'USDT', this.nextKlineStartTime, this.klineInterval);
        }
        // txs柱状图
        const entTime = newData.time;
        this.lastDate = func.getDate(entTime); // 时间进度
        for(let i = prevBarIdx + 1; i < this.outGroupHourly.length; i++){
          const item = this.outGroupHourly[i];
          if(item.key - entTime > 0){
            break;
          }
          this.$eventBus.$emit('BAR-DATA', item, i);
          prevBarIdx = i;
        }
      }
    },
    /**
     * 监听k线更新
     * @returns {Promise<void>}
     */
    onUpdateKline(){
      this.$eventBus.$on('KLINE-DATA', (newData, idx) => {
        // 新闻
        if(this.symbolInfo.news && this.symbolInfo.news[this.showNewsIdx] && newData.time > this.symbolInfo.news[this.showNewsIdx].timestamp){
          this.markersData.push({
            time: this.symbolInfo.news[this.showNewsIdx].timestamp,
            position: 'aboveBar' ,
            color: 'rgba(0,255,80,0.63)',
            shape: 'arrowDown',
            text: 'NEWS',
            size: 1
          });
          candleSeries.setMarkers(this.markersData);
          this.showNews.push({
            title: this.symbolInfo.news[this.showNewsIdx].title,
            time: this.symbolInfo.news[this.showNewsIdx].timestamp
          });
          this.showNewsIdx++;
        }
        // 计算斜率
        this.overallSlope = this.calculateOverallSlope(this.showKlineData, 50);
        this.overallSlopeQuick = this.calculateOverallSlope(this.showKlineData, 20);
        // change
        const changeData = this.getChangeData([this.klineData[idx - 1], newData]);
        changeSeries.update(changeData[0]);
        // change ema
        /*if(changeData[0].value >= 0){
          this.lastGoodChange.push(changeData[0]);
          this.lastGoodChange = this.lastGoodChange.slice(-3);
        }
        if(this.lastGoodChange.length == 3){
          const changeEmaDataRes = func.calculateEMA(this.lastGoodChange, 3);
          const changeEmaData = {
            time: changeData[0].time,
            value: changeEmaDataRes[0]
          };
          changeEmaSeries.update(changeEmaData);
        }*/

        // 记录最近10个点
        this.lastKlineChange.unshift({
          time: newData.time,
          open: newData.open,
          close: newData.close,
          maxChange: changeData[0].value
        });
        this.lastKlineChange = this.lastKlineChange.slice(0, 10);
        // 检测是否接针
        if(this.waitBuy && newData.time - this.waitBuy.time < 120){
          console.log('涨幅突增，准备接针')
          this.doBuy(newData, { status: true, wait: true })
        }
        // 检测卖点
        /*const checkSellRes = this.checkSell(newData, changeData[0]);
        if(checkSellRes && checkSellRes.status){
          this.markersData.push({
            time: newData.time,
            position: 'aboveBar' ,
            color: '#ff0000',
            shape: 'arrowDown',
            text: parseFloat(checkSellRes.value).toFixed(2).toString(),
            size: 1
          });
          candleSeries.setMarkers(this.markersData);
        }*/

        // 显示k线
        this.showKlineData.push(newData);
        candleSeries.update(newData);
        // countMark
        const countMarkData = this.countSource[this.countIdx];
        if(countMarkData && newData.time - countMarkData.timestamp > 0){
          this.markersData.push({
            time: countMarkData.timestamp,
            position: 'belowBar' ,
            color: '#ff00fa',
            shape: 'arrowDown',
            text: countMarkData.count.toString(),
            size: 1
          });
          candleSeries.setMarkers(this.markersData);
          this.countIdx++;
        }
        // 检测买点
        const checkBuyRes = this.checkBuy(newData, changeData[0]);
        if(checkBuyRes && checkBuyRes.status){
          // 标记点
          this.markersData.push({
            time: newData.time,
            position: 'belowBar' ,
            color: checkBuyRes.status ? (!checkBuyRes.wait ? '#ffc400' : '#937300') : '#777764',
            shape: 'arrowUp',
            text: parseFloat(checkBuyRes.value).toFixed(2).toString(),
            size: 1
          });
          candleSeries.setMarkers(this.markersData);

          this.markersData.push({
            time: newData.time,
            position: 'aboveBar' ,
            color: '#49cbf3',
            shape: 'arrowDown',
            text: (this.overallSlope ? this.overallSlope.toString() : 'x') + ',' +  (this.overallSlopeQuick ? this.overallSlopeQuick.toString() : 'x'),
            size: 1
          });
          candleSeries.setMarkers(this.markersData);

          this.doBuy(newData, checkBuyRes);

          if(this.overallSlope && this.overallSlopeQuick){

          }
          const lastNews = this.showNews[this.showNews.length - 1];
          const newsCount = this.showNews.filter(r => newData.time - r.time < 7*24*3600).length;
          this.buyTrainsData.push([
            (lastNews && newData.time - lastNews.time < 7*24*3600 ? 1 : 0),
            newsCount,
            parseFloat(this.weekTotalOut/this.symbolInfo.baseInfo.circulatingSupply).toFixed(3),
            this.overallSlope.toString(),
            this.overallSlopeQuick.toString(),
            parseFloat(checkBuyRes.value).toFixed(2),
              0
          ]);
        }
        // 交易量
        // volumeSeries.update({ time: newData.time, value: newData.volume });

        // ema
        const cauEMAData = this.showKlineData.slice(-3);
        if(cauEMAData.length == 3){
          const emaDataRes = func.calculateEMA(cauEMAData, 3);
          const emaData = {
            time: newData.time,
            value: emaDataRes[0]
          };
          emaSeries.update(emaData);
          this.lastEmaData.push(emaData);
          this.lastEmaData = this.lastEmaData.slice(-5);
        }

        // ema slow
        const cauEMASlowData = this.showKlineData.slice(-8);
        if(cauEMASlowData.length == 8){
          const emaDataRes = func.calculateEMA(cauEMASlowData, 8);
          const emaSlowData = {
            time: newData.time,
            value: emaDataRes[0]
          };
          emaSlowSeries.update(emaSlowData);
          this.lastEmaSlowData.push(emaSlowData);
          this.lastEmaSlowData = this.lastEmaSlowData.slice(-5);
        }
      });
    },
    /**
     * 检测买点
     * @param klineData
     * @param changeData
     */
    checkBuy(klineData, changeData){
      if(this.lastKlineChange.length < 3){
        return false;
      }
      const isSell = this.tradeRecords.length == 0 || this.tradeRecords[this.tradeRecords.length - 1].side == 'sell';
      if(!isSell){
        // 最后一个单子没有卖掉
        // return false;
      }
      // 计算近期平均变化率
      let total = 0, count = 0;
      for(let i = 1; i < this.lastKlineChange.length; i++){
        total += this.lastKlineChange[i].maxChange;
        count++;
      }
      const aveChange = total/count;
      const cond1 = this.lastKlineChange[0].maxChange > 0 && this.lastKlineChange[1].maxChange > 0;
      const cond2 = aveChange < 0.1 && this.lastKlineChange[0].maxChange > 1;
      if(cond1 || cond2){
        const firstOpen = this.lastKlineChange[1].open;
        const lastClose = this.lastKlineChange[0].close;

        const v = (lastClose - firstOpen)/firstOpen*100;

        const res = {
          value: v,
          status: false
        }
        if(v > 0.5 && this.lastEmaData.length > 0 && this.lastEmaSlowData.length > 0){
          const emaDirect = this.lastEmaData[this.lastEmaData.length - 1].value - this.lastEmaSlowData[this.lastEmaSlowData.length - 1].value;
          if(emaDirect < 0){
            return false;
          }
          res.status = true;
          if(v > 2){
            // 瞬间涨幅太大，等待接针
            res.wait = true;
            this.waitBuy = {
              maxPrice: klineData.close,
              time: klineData.time
            };
          }
          if(this.buyPoints && klineData.time - this.buyPoints.time < 3600 && klineData.close - this.buyPoints.price > 0){
            // 近一个小时提醒过，且新提醒价格更高，不再提醒
            res.status = false;
          }
          this.buyPoints = {
            time: klineData.time,
            price: klineData.close
          };

          return res;
        }
      }
      return false;
    },
    /**
     * 执行买入
     * @param klineData
     * @param buyCheckRes
     */
    doBuy(klineData, buyCheckRes){
      if(!buyCheckRes.status){
        return;
      }
      if(buyCheckRes.wait){
        // 等待接针
        const bestPrice = Math.min(klineData.open, klineData.close);
        if(bestPrice - this.waitBuy.maxPrice < 0){
          this.waitBuy = null;
          this.tradeRecords.push({
            side: 'buy',
            time: klineData.time,
            price: bestPrice,
            amount: 1000
          });
          this.afterBuyMaxPrice = klineData.close;
        }
      } else {
        this.waitBuy = null;
        this.tradeRecords.push({
          side: 'buy',
          time: klineData.time,
          price: klineData.close,
          amount: 1000
        });
        this.afterBuyMaxPrice = klineData.close;
      }
    },
    /**
     * 检测卖点
     * @param klineData
     */
    checkSell(klineData, changeData){
      if(this.lastKlineChange.length < 5){
        return false;
      }
      if(!this.buyPoints){
        return false;
      }
      const lastOrder = this.tradeRecords.length > 0 ? this.tradeRecords[this.tradeRecords.length - 1] : null;
      if(!lastOrder || lastOrder.side == 'sell'){
        // 最后一个单子已经卖掉
        return false;
      }
      const profitChange = (klineData.close - lastOrder.price)/lastOrder.price * 100;
      this.lastProfit.push(profitChange);
      this.lastProfit = this.lastProfit.slice(-5);

      const res = {
        value: profitChange,
        status: false
      }
      if(
          (profitChange >= 1 || (this.forceSell && profitChange >= 0.3))
          && this.lastProfit[1] >= this.lastProfit[2]
          && this.lastProfit[2] >= this.lastProfit[3]
          && this.lastProfit[3] >= this.lastProfit[4]
      ){
        console.log('收益持续降低 -> ' + profitChange)
        res.status = true;
        this.tradeRecords.push({
          side: 'sell',
          time: klineData.time,
          price: klineData.close,
          amount: lastOrder.amount,
          rate: parseFloat((klineData.close - lastOrder.price)/lastOrder.price * 100).toFixed(2)
        });
        this.afterBuyMaxPrice = null;
        this.forceSell = false;

        return res;
      }
      if(profitChange < -2){
        console.log('收益低于-2%，强制止损');

        return;
      }
      // 强制卖出
      // 1、超过一定时间一直横盘（找机会卖出）
      if(klineData.time - lastOrder.time > 3600 && profitChange > 0 && profitChange < 1){
        console.log('超过3600秒横盘');
        this.forceSell = true;
      } else if(klineData.time - lastOrder.time > 2*3600 && profitChange > 0 && profitChange < 1) {
        console.log('超过7200秒横盘');
        this.forceSell = true;
      }

      // 挂了买单后进入缓慢下跌趋势怎么办

      // 计算近期平均变化率
      let total = 0, count = 0;
      for(let i = 1; i < this.lastKlineChange.length; i++){
        total += this.lastKlineChange[i].maxChange;
        count++;
      }
      const aveChange = total/count;
      // 检测是否会是高点
      const cond1 = this.lastKlineChange[0].maxChange < 0
          && this.lastKlineChange[1].maxChange > 0
          && this.lastKlineChange[2].maxChange > 0
          && this.lastKlineChange[3].maxChange > 0;
      const cond2 = aveChange > 0.5;
      if(cond1 && cond2){
        res.status = true;
        const lastClose = this.lastKlineChange[0].close;
        const firstOpen = this.lastKlineChange[2].open;
        const v = (lastClose - firstOpen)/firstOpen*100;
        res.value = v;

        this.tradeRecords.push({
          side: 'sell',
          time: klineData.time,
          price: klineData.close,
          amount: lastOrder.amount,
          rate: parseFloat((klineData.close - lastOrder.price)/lastOrder.price * 100).toFixed(2)
        });
        this.afterBuyMaxPrice = null;
        this.forceSell = false;

        return res;
      }

      // 更新最大价
      if(klineData.close - this.afterBuyMaxPrice > 0){
        this.afterBuyMaxPrice = klineData.close;
      }

      // 计算近期平均变化率
      /*let total = 0, count = 0;
      for(let i = 1; i < this.lastKlineChange.length; i++){
        total += this.lastKlineChange[i].maxChange;
        count++;
      }
      const aveChange = total/count;
      const cond1 = this.lastKlineChange[0].maxChange < 0 && this.lastKlineChange[1].maxChange < 0;
      const cond2 = aveChange < 0.1 && this.lastKlineChange[0].maxChange < -1;
      if(cond1 || cond2){
        // ====TODO 从上一个open到当前close的总跌幅，超过-1，则...
        const firstOpen = this.lastKlineChange[2].maxChange < 0 ? this.lastKlineChange[2].open : this.lastKlineChange[1].open;
        const lastClose = this.lastKlineChange[0].close;
        const v = (lastClose - firstOpen)/firstOpen*100;

        console.log('0: ' + this.lastKlineChange[0].maxChange + ' -> 1: ' + this.lastKlineChange[1].maxChange)
        console.log('firstOpen -> ' + firstOpen + ' -> lastClose: ' + lastClose)
        console.log(v)

        const res = {
          value: v,
          status: false
        }
        if(v < -1) {
          res.status = true;
          if (v < -2) {
            // 瞬间跌幅太大，等待接针卖出
            res.wait = true;
          }

          if(res.status && !res.wait){
            // 直接卖出
            this.tradeRecords.push({
              side: 'sell',
              time: klineData.time,
              price: klineData.close,
              amount: lastOrder.amount,
              rate: parseFloat((klineData.close - lastOrder.price)/lastOrder.price * 100).toFixed(2)
            });
            this.afterBuyMaxPrice = null;
          }

          return res;
        }
      }

      */
    },
    /**
     * 计算k线在某个时间窗口的斜率
     * @param klineData
     * @param windowSize
     * @returns {number}
     */
    calculateOverallSlope(klineData, windowSize) {
      if (klineData.length < windowSize) {
        return;
      }

      let totalSlope = 0;

      for (let i = klineData.length - windowSize; i < klineData.length - 1; i++) {
        const currentClose = klineData[i].close;
        const nextClose = klineData[i + 1].close;
        const slope = (nextClose - currentClose) / currentClose;
        totalSlope += slope;
      }

      return parseFloat(totalSlope / windowSize * 100).toFixed(3); // 返回平均斜率
    },
    /**
     * 监听柱状图更新
     */
    onUpdateBarChart(){
      this.$eventBus.$on('BAR-DATA', (newData) => {
        //this.showBarData.push(newData);
        //this.onChangeTransferChart('ALL', this.viewAddress, true);
        const timeKey = newData.key;
        this.showBarData[timeKey] = newData;
        // out
        const outRow = {
          time: timeKey,
          value: this.showBarData[timeKey].amount - 0.05 > 0 ? -this.showBarData[timeKey].amount : -0.001
        }
        if(newData.is_mark == 1){
          outRow.color = '#ffee00';
          this.weekMarkOut.push({
            timestamp: newData.timestamp,
            value: outRow.value
          });
          const startTime = newData.timestamp - 7*24*3600;
          this.weekMarkOut = this.weekMarkOut.filter(r => r.timestamp >= startTime)
          for(let i in this.weekMarkOut){
            this.weekTotalOut += this.weekMarkOut[i].value;
          }
        }
        volumeOutSeries.update(outRow);
        // in
        const inRow = {
          time: timeKey,
          value: newData.side == 'in' ? this.showBarData[timeKey].in : 0
        }
        if(newData.is_mark == 1){
          inRow.color = '#ffee00';
        }
        volumeInSeries.update(inRow);
      });
    },
    /**
     * symbol info
     * @returns {Promise<void>}
     */
    async getSymbolInfo(){
      const res = await coinsApi.info({
        symbol: this.symbol
      });
      this.symbolInfo = res ? res.data : {}

      const newsRes = await newsApi.searchNews({
        symbol: this.symbol
      });
      console.log(newsRes);
      this.symbolInfo.news = newsRes;
    },
    async getKlineData(pairName, startTime, interval){
      if(this.isLoadingKlineData){
        return;
      }
      this.isLoadingKlineData = true;
      const intervalSecond = {
        '1m': 60,
        '1h': 3600
      };
      const res = await tradeLibs.candles(pairName, interval, startTime, 1000);
      this.nextKlineStartTime = res[res.length - 1].openTime/1000;
      const lists = res.map(item => ({
        time: parseInt(item.openTime/1000),
        open: parseFloat(item.open),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        close: parseFloat(item.close),
        volume: parseFloat(item.volume)
      }))
      this.klineData = this.klineData.concat(lists);
      this.isLoadingKlineData = false;
      // 波动率
      this.cauChangeRates();
    },
    /**
     * 生成波动数据
     * @param klineData
     * @returns {*[]}
     */
    getChangeData(klineData){
      let prevData = null;
      const changeData = [];
      for(let i in klineData){
        if(i == 0){
          prevData = klineData[i];
          continue;
        }
        const row = klineData[i];
        let change = parseFloat((row.high - row.low)/row.low*100).toFixed(2);
        const direct = row.close - row.open > 0 ? 1 : -1;
        change = change * direct;
        // prev check
        let prevChange = parseFloat((prevData.high - prevData.low)/prevData.low*100).toFixed(2);
        const prevDirect = prevData.close - prevData.open > 0 ? 1 : -1;
        prevChange = prevChange * prevDirect;
        if((change > 0 && prevChange > 0) || (change < 0 && prevChange < 0)){
          change += prevChange;
        }

        changeData.push({
          time: row.time,
          value: Math.abs(change) < 0.5 ? 0 : change
        });
      }

      return changeData;
    },
    /**
     * 绘制k线
     * @param klineData
     */
    drawKline(klineData, txsData){
      const domElement = document.getElementById('kline-chart-con');
      klineChart = LightweightCharts.createChart(domElement, {
        layout: {
          background: {
            color: '#23262F',
          },
          textColor: '#777E90',
        },
        crosshair: {
          mode: 0,
        },
        grid: {
          horzLines: { color: '#353945' },
          vertLines: { color: '#353945' },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
          rightOffset: 20,
          timezone: 'Asia/Shanghai'
        },
        priceScale: {
          priceFormat: {
            type: 'price',
            // precision: 1
          },
        },
      });
      // 设置时区为北京时间
      klineChart.applyOptions({
        localization: {
          timeFormatter: (businessDayOrTimestamp) => {
            // 返回按北京时间格式化的日期和时间
            return func.getDate(businessDayOrTimestamp);
          },
        },
        priceScale: {
          autoScale: false,
          mode: 1, // 固定比例模式
          scale: 0.6, // 缩放比例设置为1，保持不缩放
        },
        timeScale: {
          autoScale: false,
        },
      });
      // 右侧价格轴
      klineChart.priceScale('right').applyOptions({
        ticksVisible: true, // 刻度
        autoSize: false,
      });
      // k线
      candleSeries = klineChart.addCandlestickSeries();
      candleSeries.setData(klineData);
      // 变化率
      this.drawChangeSeries(klineData);
      // 交易量
      // this.drawVolumeSeries(klineData);
      // 转出图
      this.drawOutSeries(txsData);
      // 转入图
      this.drawInSeries(txsData);
      // ema 3 line
      this.drawEmaSeries(klineData);
      // ema slow line
      this.drawSlowEmaSeries(klineData);
      // 提示
      this.drawToolTip();
    },
    drawChangeSeries(klineData){
      changeSeries = klineChart.addBaselineSeries({
        price: 10,
        color: 'red',
        lineWidth: 1,
        lineStyle: LightweightCharts.LineStyle.Solid,
        // title: 'Change',
        overlay: true,
        priceScaleId: 'ChangeLine',
      });
      changeSeries.applyOptions({
        priceLineVisible: false, // 实时价格线
        lastValueVisible: false, // 右侧实时价格
      });
      changeSeries.priceScale().applyOptions({
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        }
      });
      const changeData = this.getChangeData(klineData);
      changeSeries.setData(changeData);

      // change ema
      changeEmaSeries = klineChart.addLineSeries({
        color: '#d2d0d0', // 基线颜色
        lineWidth: 1, // 基线的线宽度
        lineStyle: LightweightCharts.LineStyle.Solid, // 基线的线样式
        priceLineVisible: false, // 实时价格线
        lastValueVisible: false, // 右侧实时价格
        overlay: true,
        priceScaleId: 'ChangeLine',
      });
      if(changeData.length > 3){
        const emaDataRes = func.calculateEMA(changeData, 3);
        const changeEmaData = [];
        for(let i in changeData){
          if(i >= 3){
            changeEmaData.push({
              time: changeData[i].time,
              value: emaDataRes[i - 3]
            });
          }
        }
        changeEmaSeries.setData(changeEmaData);
      }
    },
    drawVolumeSeries(klineData){
      volumeSeries = klineChart.addHistogramSeries({
        color: "#385263",
        lineWidth: 2,
        priceFormat: {
          type: "volume"
        },
        overlay: true,
        priceScaleId: 'VolumeBar',
      });
      volumeSeries.priceScale().applyOptions({
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        }
      });
      const volumeData = klineData.map(row => {
        return {
          time: row.time,
          value: row.volume
        }
      });
      volumeSeries.setData(volumeData);
    },
    drawOutSeries(txsData){
      volumeOutSeries = klineChart.addHistogramSeries({
        color: "#a300e7",
        lineWidth: 2,
        priceFormat: {
          type: "volume"
        },
        overlay: true,
        priceScaleId: 'OutVolumeBar',
      });
      volumeOutSeries.priceScale().applyOptions({
        scaleMargins: {
          top: 0.1,
          bottom: 0.8,
        }
      });
      volumeOutSeries.applyOptions({
        priceLineVisible: false, // 实时价格线
        lastValueVisible: false, // 右侧实时价格
      });
      const outTxsData = [];
      for(let k in txsData){
        const row = txsData[k];
        outTxsData.push({
          time: row.timestamp - row.timestamp % (3*60),
          value: row.side == 'out' ? -row.sendAmount : 0
        });
      }
      volumeOutSeries.setData(outTxsData);
    },
    drawInSeries(txsData){
      volumeInSeries = klineChart.addHistogramSeries({
        color: "#00ece3",
        lineWidth: 2,
        priceFormat: {
          type: "volume"
        },
        overlay: true,
        priceScaleId: 'InVolumeBar',
      });
      volumeInSeries.priceScale().applyOptions({
        scaleMargins: {
          top: 0,
          bottom: 0.9,
        }
      });
      volumeInSeries.applyOptions({
        priceLineVisible: false, // 实时价格线
        lastValueVisible: false, // 右侧实时价格
      });
      const inTxsData = [];
      for(let k in txsData){
        const row = txsData[k];
        inTxsData.push({
          time: row.timestamp - row.timestamp % (3*60),
          value: row.side == 'in' ? row.sendAmount : 0
        });
      }
      volumeInSeries.setData(inTxsData);
    },
    drawEmaSeries(klineData){
      emaSeries = klineChart.addLineSeries({
        color: '#d2d0d0', // 基线颜色
        lineWidth: 1, // 基线的线宽度
        lineStyle: LightweightCharts.LineStyle.Solid, // 基线的线样式
        priceLineVisible: false, // 实时价格线
        lastValueVisible: false, // 右侧实时价格
        // title: 'EMA', // 基线的标题（可选）
        // overlay: true,
        // priceScaleId: 'EMALine',
      });
      // 添加基线数据点
      if(klineData.length > 3){
        const emaDataRes = func.calculateEMA(klineData, 3);
        const emaData = [];
        for(let i in klineData){
          if(i >= 3){
            emaData.push({
              time: klineData[i].time,
              value: emaDataRes[i - 3]
            });
          }
        }
        emaSeries.setData(emaData);
      }
    },
    drawSlowEmaSeries(klineData){
      emaSlowSeries = klineChart.addLineSeries({
        color: '#b675cc', // 基线颜色
        lineWidth: 1, // 基线的线宽度
        lineStyle: LightweightCharts.LineStyle.Solid, // 基线的线样式
        priceLineVisible: false, // 实时价格线
        lastValueVisible: false, // 右侧实时价格
        // title: 'EMA', // 基线的标题（可选）
        // overlay: true,
        // priceScaleId: 'EMALine',
      });
      // 添加基线数据点
      if(klineData.length > 8){
        const emaDataRes = func.calculateEMA(klineData, 8);
        const emaSlowData = [];
        for(let i in klineData){
          if(i >= 8){
            emaSlowData.push({
              time: klineData[i].time,
              value: emaDataRes[i - 8]
            });
          }
        }
        emaSlowSeries.setData(emaSlowData);
      }
    },
    drawToolTip(){
      const container = document.getElementById('kline-chart-con');
      // tool tip
      const toolTipWidth = 150;
      const toolTipHeight = 100;
      const toolTipMargin = 15;
      // Create and style the tooltip html element
      const toolTip = document.createElement('div');
      toolTip.style = `width: 150px; height: 100px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTip.style.background = 'white';
      toolTip.style.color = 'black';
      toolTip.style.borderColor = '#2962FF';
      container.appendChild(toolTip);
      klineChart.subscribeCrosshairMove(param => {
        if (
            param.point === undefined ||
            !param.time ||
            param.point.x < 0 ||
            param.point.x > container.clientWidth ||
            param.point.y < 0 ||
            param.point.y > container.clientHeight
        ) {
          toolTip.style.display = 'none';
        } else {
          // time will be in the same format that we supplied to setData.
          // thus it will be YYYY-MM-DD
          const dateStr = func.getDate(param.time);
          const timeKey = param.time - param.time % (3600);
          toolTip.style.display = 'block';
          const data = param.seriesData.get(candleSeries);
          const dataChange = param.seriesData.get(changeSeries);
          if(!data || !dataChange){
            return;
          }
          const dataOut = this.showBarData[timeKey];
          const outValue = dataOut ? dataOut.amount : 0;
          const outPercent = parseFloat(outValue/this.symbolInfo.baseInfo.circulatingSupply * 100).toFixed(5);
          const changeValue = dataChange.value;
          const price = data.value !== undefined ? data.value : data.close;

          const priceStr = `open:${data.open}|close:${data.close}|high:${data.high}|low:${data.low}`;

          toolTip.innerHTML = `
<div style="color: ${'#2962FF'}">Out: ${outValue}(${outPercent})</div>
<div style="font-size: 18px; margin: 4px 0px; color: ${'black'}">
${Math.round(100 * price) / 100}
</div>
<div style="font-size:12px;">${priceStr}</div>
<div style="color: ${'#2962FF'}">Change: ${changeValue}</div>
<div style="color: ${'black'}">
${dateStr}
</div>
`;

          const coordinate = candleSeries.priceToCoordinate(price);
          let shiftedCoordinate = param.point.x - 75;
          if (coordinate === null) {
            return;
          }
          shiftedCoordinate = Math.max(
              0,
              Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
          );
          const coordinateY =
              coordinate - toolTipHeight - toolTipMargin > 0
                  ? coordinate - toolTipHeight - toolTipMargin
                  : Math.max(
                      0,
                      Math.min(
                          container.clientHeight - toolTipHeight - toolTipMargin,
                          coordinate + toolTipMargin
                      )
                  );
          toolTip.style.left = shiftedCoordinate + 'px';
          toolTip.style.top = coordinateY + 'px';
        }
      });
    },
    /**
     * token transfers
     * @returns {Promise<void>}
     */
    async getTransfers(){
      const res = await predictApi.analysis({
        address: this.address
      });
      if(res && res.data){
        if(res.data.hot){
          const hots = [];
          let i = 0;
          for(let address in res.data.hot){
            hots.push({
              address,
              count: res.data.hot[address]
            });
            i++;
            if(i > 20){
              break;
            }
          }
          this.hot = hots;
        }
        res.data.original_data.reverse();
        this.dataSource = res.data.original_data;
        this.outGroupHourly = res.data.out_group_hourly;
        this.countSource = res.data.count_lists.filter(r => r.is_mark == 1);

        // 数据的时间范围
        this.timeRange = [this.dataSource[0].timestamp, this.dataSource[this.dataSource.length - 1].timestamp];
        this.nextKlineStartTime = this.timeRange[0];
      }
    },
    /**
     * change term
     * @param term
     */
    onChangeTransferChart(term, address, forceUpdate){
      address = address || null;
      if(!forceUpdate && this.chartTransferTerm == term && this.viewAddress == address){
        return;
      }
      if(this.dataSource.length == 0){
        return;
      }
      const endTime = this.dataSource[this.dataSource.length - 1].timestamp;
      term && (this.chartTransferTerm = term);
      this.viewAddress = address;
      const termsTime = {
        '24H': 24*3600,
        '3D': 3*24*3600,
        '7D': 7*24*3600,
        'MORE': null,
        'ALL': null
      }

      const step = term == 'ALL' ? 3600 : 3600;
      const groupData = this.groupByHour(this.dataSource, termsTime[this.chartTransferTerm], address, step, endTime);
      if(term == 'ALL'){
        option.barWidth = 2;
        // this.chartWidth = (groupData.length * 6) + 'px';
        option.xAxis.scale = true;
      } else {
        option.barWidth = 5;
        this.chartWidth = '100%';
        option.xAxis.scale = false;
      }

      this.drawBarChart(
          'chart-transfer',
          groupData,
          'timestamp'
      );
    },
    /**
     * groupByHour
     * @param dataLists
     * @returns {{}}
     */
    groupByHour(dataLists, term, address, step, endTime){
      if(dataLists.length == 0){
        return [];
      }
      console.log('数据分组 -> ' + dataLists.length)
      let groupData = {};
      const now = new Date().getTime()/1000;
      const startTime = term ? now - term : dataLists[0].timestamp;
      for(let i in dataLists){
        const item = dataLists[i];
        if(item.timestamp - startTime < 0){
          break;
        }
        if(address && item.from != address && item.recipient != address){
          continue;
        }
        if(['in', 'out'].indexOf(item.side) == -1){
          continue;
        }
        const hourTime = item.timestamp - item.timestamp % step;
        const hourKey = func.getDate(hourTime);
        if(!groupData[hourKey]){
          groupData[hourKey] = {
            out: 0,
            in: 0,
            timestamp: hourTime
          };
        }
        groupData[hourKey][item.side] += item.sendAmount;
      }
      for(let time = startTime; time < (endTime ? endTime : now); time += step){
        const hourTime = time - time % step;
        const hourKey = func.getDate(hourTime);
        if(!groupData[hourKey]){
          groupData[hourKey] = {
            out: 0,
            in: 0,
            timestamp: hourTime
          };
        }
      }
      groupData = func.sortObject(groupData, 'timestamp').reverse();

      return groupData;
    },
    generateCharts(dataLists){
      /*const xAxisData = [], seriesDataCount = [], seriesDataOut = [];
      // 按out总量
      for(let i in this.dataSource.out_group_hourly){
        const item = this.dataSource.out_group_hourly[i];
        xAxisData.push(func.getDate(item.key));
        seriesDataOut.push({
          value: item.amount,
          timestamp: item.timestamp,
          itemStyle: {
            color: item.is_mark ? 'blue' : 'grey'
          }
        });
      }

      // 按频次统计
      for(let i in this.dataSource.count_lists){
        const item = this.dataSource.count_lists[i];
        seriesDataCount.push({
          value: item.count,
          timestamp: item.timestamp,
          itemStyle: {
            color: item.is_mark ? 'blue' : 'grey'
          }
        });
      }

      const dom = document.getElementById('chart-all');
      const myChart = echarts.init(dom);
      option.xAxis.data = xAxisData;
      option.series = [{
        name: '数值',
        type: 'bar',
        data: seriesDataOut,
        yAxisIndex: 0
      }, {
        name: '数值',
        type: 'scatter',
        data: seriesDataCount,
        yAxisIndex: 1
      }];
      myChart.setOption(option);*/

      // 按频次统计
      this.drawBarChart('chart-count', this.dataSource.count_lists, 'count', 'timestamp', 'scatter');
      // 按out总量
      this.drawBarChart('chart-out', this.dataSource.out_group_hourly, 'amount', 'key');

      // inner_lists
      this.drawBarChart('chart-inner', this.dataSource.inner_lists.reverse(), 'sendAmount', 'timestamp');

      // new_data
      this.drawBarChart('chart-new', this.dataSource.new_data.reverse(), 'sendAmount', 'timestamp');

      // chart-original
      const original_data = this.dataSource.original_data.reverse();
      const groupData = {}
      for(let i in original_data){
        const item = original_data[i];
        const hourTime = item.timestamp - item.timestamp % 3600;
        const hourDate = func.getDate(hourTime);
        if(!groupData[hourDate]){
          groupData[hourDate] = {
            out: 0,
            in: 0,
            inner: 0,
            other: 0
          }
        }
        groupData[hourDate][item.side ? item.side : 'other'] += item.sendAmount;
      }

      this.drawBarChart('chart-original', original_data, 'sendAmount', 'timestamp');
    },
    drawCountChart(){

    },
    cauChangeRates(){
      // 波动率
      const changeRates = {};
      for(let i in this.klineData){
        const item = this.klineData[i];
        const openTime = item.time - item.time % (3*60);
        const timeKey = func.getDate(openTime);
        const change = (item.close - item.open)/item.open * 100;
        if(!changeRates[timeKey]){
          changeRates[timeKey] = {
            total: change,
            count: 1,
            ave: change
          };
        } else {
          changeRates[timeKey].total += change;
          changeRates[timeKey].count++;
          changeRates[timeKey].ave = changeRates[timeKey].total/changeRates[timeKey].count;
        }
      }
      this.changeRates = changeRates;
    },
    /**
     * 绘制柱状图
     * @param dataLisst
     */
    drawBarChart(domId, dataLists, xKey, type, noMark){
      // 绘制柱状图
      const circulatingSupply = this.symbolInfo.baseInfo.circulatingSupply;

      noMark = noMark ? noMark : false;
      type = type ? type : 'bar';
      let xAxisData = [], seriesInData = [], seriesOutData = [], seriesRateData = [];
      let yMin = null, yMax = null;
      for(let i in dataLists){
        const item = dataLists[i];
        noMark && (item.is_mark = 0)
        const dateKey = func.getDate(item[xKey]);
        xAxisData.push(dateKey);

        // 取最大最小值
        !yMin && (yMin = item['out'] * -1);
        item['out'] * -1 < yMin && (yMin = item['out'] * -1);
        !yMax && (yMax = item['in']);
        item['in'] - yMax > 0 && (yMax = item['in']);

        seriesInData.push({
          value: item['in'],
          percent: parseFloat(item['in']/circulatingSupply*100).toFixed(4),
          in: item['in'],
          out: item['out'],
          timestamp: item.timestamp,
          itemStyle: {
            color: item.is_mark == 1 ? '#002aff' : '#67C23A'
          }
        });
        seriesOutData.push({
          value: item['out'] * -1,
          percent: parseFloat(item['out']/circulatingSupply*100).toFixed(4),
          in: item['in'],
          out: item['out'],
          timestamp: item.timestamp,
          itemStyle: {
            color: item.is_mark == 1 ? '#002aff' : '#F56C6C'
          }
        });
        seriesRateData.push({
          value: this.changeRates[dateKey] ? this.changeRates[dateKey].ave : 0,
          timestamp: dateKey,
          itemStyle: {
            color: !this.changeRates[dateKey] ? 'grey' : (this.changeRates[dateKey].ave > 0 ? '#67C23A' : '#F56C6C')
          }
        });
      }

      const dom = document.getElementById(domId);
      myBarChart = !myBarChart ? echarts.init(dom) : myBarChart;
      option.xAxis.data = xAxisData;
      option.yAxis[0].min = yMin;
      option.yAxis[0].max = yMax;
      option.series = [{
        name: 'Deposit',
        type,
        data: seriesInData
      }, {
        name: 'Withdrawals',
        type,
        data: seriesOutData
      }, {
        name: 'Changes',
        type: 'line',
        data: seriesRateData,
        yAxisIndex: 1
      }];
      option.dataZoom[0].startValue = xAxisData.length - minVisibleCount;

      myBarChart.setOption(option);
    },
    getColor(item){
      if(!item){
        return 'grey';
      }
      if(item.side == 'inner'){
        // 所到所
        return 'orange';
      }
      if(item.side == 'out'){
        // 从所出
        return 'red';
      }
      if(item.side == 'in'){
        // 进所
        return 'green';
      }

      return 'grey';
    }
  }
}
</script>

<style lang="less" scoped>
.content-con {
  display: flex;

  .left-panel {
    min-width: 280px;
    padding: 0 10px;

    .token-item {
      display: flex;
      border-bottom: 1px solid #eee;
      font-size: 12px;
      padding: 5px 0;

      .address{
        flex: 1;
        cursor: pointer;

        i {
          margin-left: 5px;
        }
      }
    }
  }
  .main-panel {
    flex: 1;
  }
}
.chart-box {
  width: 100%;
  height: 420px;
  position: relative;

  &.enable-scroll{
    overflow-x: scroll;
  }
  .chart-con{
    height: 400px;
    width: 100%;
  }
  .right-btns {
    position: absolute;
    right: 100px;
    top: 20px;
    z-index: 1;
  }
}
#kline-chart-con{
  position: relative;
}
.trade-logs{
  font-size: 12px;
  .log-item{
    display: flex;
    align-items: center;

    .side {
      padding-right: 10px;
    }
    .time {
      padding-right: 10px;
    }
    .price {
      padding-right: 10px;
    }
    .amount {
      padding-right: 10px;
    }
  }
}
</style>