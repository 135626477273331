<template>
  <div class="container">
    <div class="left-panel left-side">
      <div class="menu-item" :class="{active: menuActive === 'collections'}" @click="onChangeMenu('collections')">
        <span>全部系列</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuActive === 'collections-like'}" @click="onChangeMenu('collections-like')">
        <span>关注的系列</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuActive === 'my'}" @click="onChangeMenu('my')">
        <span>我的NFT</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuActive === 'offers'}" @click="onChangeMenu('offers')">
        <span>我的Offers</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuActive === 'monitor'}" @click="onChangeMenu('monitor')">
        <span>数据监控</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuActive === 'statistic'}" @click="onChangeMenu('statistic')">
        <span>统计分析</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuActive === 'analysis'}" @click="onChangeMenu('analysis')">
        <span>数据回测</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <collections
        :current-account="currentAccount"
        :show-mode="menuActive === 'collections-like' ? 'like' : 'all'"
        v-if="menuActive === 'collections' || menuActive === 'collections-like'"
        key="collections"
    ></collections>

    <my-offers :current-account="currentAccount" v-if="menuActive === 'offers'"></my-offers>

    <statistic :current-account="currentAccount" v-if="menuActive === 'statistic'"></statistic>

    <analysis :current-account="currentAccount" v-if="menuActive === 'analysis'"></analysis>

    <monitor :current-account="currentAccount" v-if="menuActive === 'monitor'"></monitor>

  </div>
</template>

<script>
import Collections from "@/pages/components/Nft/Collections";
import MyOffers from "@/pages/components/Nft/MyOffers";
import Statistic from "@/pages/components/Nft/Statistic";
import Analysis from "@/pages/components/Nft/Analysis";
import Monitor from "@/pages/components/Nft/Monitor";

export default {
  components: { Collections, MyOffers, Statistic, Analysis, Monitor },
  data(){
    return {
      currentAccount: window.currentAccount || null,
      menuActive: 'collections',
    }
  },
  created() {
    // 当前登录账号
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.currentAccount = account.address;
    });
  },
  methods: {
    /**
     * 切换菜单
     * @param name
     */
    onChangeMenu(name){
      if(name === this.menuActive){
        return;
      }
      this.menuActive = name;
    },
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.container {
  display: flex;
  justify-content: space-between;
}

.left-side{
  width: 200px;
  background-color: @section-bg-color;
}

</style>
