<template>
  <div class="container">
    <div class="left-panel left-side scroll-con">
      <div style="height:16px;"></div>
      <div
          class="menu-item"
          :class="{active: menuActive === item.router}"
          @click="onChangeMenu(item)"
          v-for="(item, idx) in menus"
          :key="idx"
      >
        <span>{{item.name}}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="main-con scroll-con">
      <utxos v-if="menuActive === 'utxos'"></utxos>
      <panic-pool v-else-if="menuActive === 'panic-pool'"></panic-pool>
      <magiceden v-else-if="menuActive === 'magiceden'"></magiceden>
      <geniidata v-else-if="menuActive === 'geniidata'"></geniidata>
      <backpack v-else-if="menuActive === 'backpack'"></backpack>
      <tx-info v-else-if="menuActive === 'tx-info'"></tx-info>
      <data-panel-nft v-else-if="menuActive === 'data-panel-nft'"></data-panel-nft>
      <data-panel-rune v-else-if="menuActive === 'data-panel-rune'"></data-panel-rune>
    </div>
  </div>

</template>

<script>
import Utxos from "@/pages/tools/Utxos";
import TxInfo from "@/pages/tools/TxInfo";
import Backpack from "@/pages/tools/Backpack";
import PanicPool from "@/pages/tools/PanicPool";
import Geniidata from "@/pages/tools/Geniidata";
import Magiceden from "@/pages/tools/Magiceden";
import DataPanelNft from "@/pages/tools/DataPanelNft";
import DataPanelRune from "@/pages/tools/DataPanelRune";

export default {
  components: { Utxos, DataPanelNft, DataPanelRune, TxInfo, Backpack, PanicPool, Magiceden, Geniidata },
  data(){
    return {
      menus: [{
        name: '我的UTXOS',
        router: 'utxos'
      }, {
        name: 'NFT数据看板',
        router: 'data-panel-nft'
      }, {
        name: '符文数据看板',
        router: 'data-panel-rune'
      }, {
        name: '交易分析',
        router: 'tx-info'
      }, {
        name: 'BRC内存池狙击',
        router: 'panic-pool'
      }, {
        name: 'Magiceden抢单',
        router: 'magiceden'
      }, {
        name: 'Geniidata抢单',
        router: 'geniidata'
      }, {
        name: 'BP刷单',
        router: 'backpack'
      }],
      menuActive: 'utxos'
    }
  },
  mounted() {
    const { action } = this.$route.query;
    if(action){
      this.menuActive = action;
    }
  },
  methods: {
    /**
     * 切换菜单
     */
    onChangeMenu(menu){
      this.menuActive = menu.router;
      this.$router.replace('tools?action=' + this.menuActive);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.container {
  display: flex;
  justify-content: space-between;
}

.left-side{
  width: 200px;
  background-color: @section-bg-color;

  .menu-item {
    margin-top: 0 !important;
  }
}
.main-con {
  margin: @mg auto;
  padding: 0 @mg @mg @mg;
  border-radius: 25px;
  position: relative;
  flex: 1;
  background-color: @section-bg-color;
  height: calc(100vh - 75px - @mg*3);
}
</style>