/**
 * 数据处理工具库
 */
import kmeans from 'kmeansjs';

export function findLargestCluster(data, minClusters, maxClusters) {
    const dataArray = [];
    for(let i = 0; i < data.length; i++){
        const v = Math.abs(data[i]);
        if(v - 0.1 < 0){
            continue;
        }
        dataArray.push([i, v]);
    }

    console.log(dataArray)
    // 存储每个簇的数据点数量
    kmeans(dataArray, 3, function(err, res) {
        if (err) throw new Error(err)

        console.log(res)
        //do something with the result
    });
}

/**
 * 计算阈值
 * @param data
 * @returns {*}
 */
export function cauThreshold(data, p = 0.85, filter = null){
    if(filter !== null){
        data = data.filter(v => Math.abs(v) < filter);
    }
    data.sort((a, b) => b - a);
    const percentile = 1 - p;
    const numOToTake = Math.ceil(data.length * percentile);
    const arr = data.slice(0, numOToTake);

    return arr[arr.length - 1];
}