<template>
  <div class="section-con">
    <div class="nft-con scroll-con">
      <i class="el-icon-loading loading" v-if="collectionLists === null || showLoading"></i>

      <div class="nft-item" v-for="(item, idx) in collectionLists" :key="idx" v-if="isShowItem(item)">
        <div class="img-con">
          <el-image :src="item.image" fit="cover" lazy class="cover"></el-image>
        </div>
        <div class="data-col name-col">
          <div class="title">
            <a :href="'https://magiceden.io/ordinals/marketplace/' + item.symbol" target="_blank">{{item.name}}</a>
          </div>
          <div class="sub-title mgt-10">{{item.symbol}}</div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Floor:</span> {{item.me_floorPrice}} <span class="currency">{{item.currency}}</span>
          </div>
          <div class="mgt-10">
            <span class="label">MCAP:</span> {{$com.fixed(item.marketCap, 2)}} <span class="currency">{{item.currency}}</span>
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Supply:</span> {{item.totalSupply}}
          </div>
          <div class="mgt-10">
            <span class="label">Pending:</span> {{item.pending}} ...
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Volume:</span> {{$com.fixed(item.totalVolume, 2)}} <span class="currency">{{item.currency}}</span>
          </div>
          <div class="mgt-10">
            <span class="label">Listed:</span> {{item.me_listedCount}}
          </div>
        </div>
        <div class="flex-1"></div>
        <div class="data-col handle-col">
          <el-button size="mini" type="success" @click="showPendingTokens(item)">详情</el-button>
        </div>
      </div>
    </div>

    <el-dialog
        :title="handleItem.collectionSymbol + '正在pending:'"
        class="pending-dialog"
        :visible.sync="showPendingDialog"
        v-if="handleItem"
    >
      <pending-lists :symbol="handleItem.collectionSymbol" :key="handleItem.collectionSymbol"></pending-lists>
    </el-dialog>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";
import PendingLists from "@/pages/components/Nft/PendingLists";
import {getHasPendingCollections} from "@/api/magic";

export default {
  components: { PendingLists },
  data(){
    return {
      collectionLists: null,
      showLoading: false,
      handleItem: null,
      showPendingDialog: false
    }
  },
  async mounted() {
    await this.$func.sleep(500);
    this.initData();
  },
  methods: {
    async initData(){
      while(true){
        await this.loadCollections();
        await this.$func.sleep(15 * 1000);
      }
    },
    showPendingTokens(item){
      this.handleItem = item;
      this.showPendingDialog = true;
    },
    /**
     * 列表中是否显示
     * @param item
     * @returns {boolean}
     */
    isShowItem(item){
      if(item.pending > 0){
        return true;
      }
      return false;
    },
    /**
     * 加载全部系列
     * @returns {Promise<void>}
     */
    async loadCollections(){
      const res = await magicApi.getHasPendingCollections({
        offset: 0,
        limit: 100
      });
      const lists = res && res.data ? res.data : [];
      lists.sort((a, b) => b.pending - a.pending);
      this.showLoading = false;
      this.collectionLists = lists;
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.section-con {
  position: relative;
  flex: 1;
}

.nft-con{
  border-radius: 25px;
  position: relative;
  flex: 1;
  background-color: @section-bg-color;
  height: calc(100vh - 75px - @mg*2);
}
.nft-item {
  display: flex;
  align-items: center;
  margin-top: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .img-con{
    width: 40px;
    height: 40px;
    margin-right: 15px;

    .cover{
      width: 40px;
      height: 40px;
      border-radius: 50%;

      /deep/.el-image__error, /deep/.el-image__placeholder{
        background-color: @content-bg-color;
        background-image: url("../../assets/imgs/logo-icon.png");
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center;
        filter: grayscale(1);
        opacity: 0.15;
        font-size: 12px;
      }
    }
  }

  .data-col {
    min-width: 200px;
    font-size: 13px;

    &.name-col {
      width: 240px;

      a{
        text-decoration: none;
        color: @light-color;
      }
    }
    &.handle-col {
      padding: 0 15px;
      min-width: auto;
      width: 200px;
      display: flex;
      justify-content: right;
      align-items: center;

      i {
        font-size: 18px;
        cursor: pointer;
        color: @font-color;
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #fff;
        span {
          padding-left: 3px;
        }
      }
      .view-btn{
        margin-left: 15px;
      }
      .like-btn {
        font-size: 18px;
        &.on{
          color: @light-color;
          font-size: 20px;
        }
      }
      .setting-btn {
        margin-left: 15px;
        font-size: 17px;

        &.on{
          color: @light-color;
        }
      }
      .text-bth {
        margin-left: 15px;
        font-size: 12px;
        cursor: pointer;
        border: 1px solid @font-color;
        padding: 2px 3px;
        border-radius: 5px;
      }
    }
    .title {
      color: @light-color;
    }
    .sub-title {
      color: @font-color;
    }
    .label {
      color: @sub-font-color;
    }
    .currency {
      font-size: 12px;
      color: @sub-font-color;
    }
  }
}

.pending-dialog {
  /deep/.el-dialog{
    width: 860px;
  }
}
</style>