import { eventBus } from '@/utils/eventBus.js';
import { io } from "socket.io-client";

const wsApi = 'wss://test.ai-dex.online'; // ws://3.88.113.162:9095'; //wss://news.treeofalpha.com/ws';

/*export default {
    init: (callback) => {
        listener.listen({
            url: wsApi,
            onopen: (s, e) => {
                callback & callback(s, e);
                console.log("News websocket connected.");
            },
            onmessage: (s, e) => {
                let msg = e.data.replace(/^\d+/, '');
                if(func.isJSON(msg)){
                    msg = JSON.parse(msg);
                } else {
                    msg = {};
                }
                eventBus.$emit('LASTEST_NEWS', msg);
                console.log(msg);
            }
        });
    }
}*/


export default {
    init: (callback) => {
        const socket = io(wsApi, {
            reconnection: true, // 开启自动重连
            reconnectionDelay: 1000, // 重连延迟时间（毫秒）
            reconnectionAttempts: 10, // 重连尝试次数
            transports: ['websocket']
        });
        socket.on('connect', () => {
            console.log(wsApi + ' -> websocket connected');
            socket.emit('subscribe', 'News');
            socket.emit('subscribe', 'Singals');
        });
        socket.on('disconnect',  () => {
            console.log(wsApi + ' -> 连接断开，1s后尝试重连')
        });
        socket.on('connect_error',  (err) => {
            console.log(wsApi + ' -> 连接失败，1s后尝试重连');
            console.log(err);
        });
        socket.on('LAST_NEWS', (data) => {
            console.log('news')
            console.log(data)
            eventBus.$emit('LASTEST_NEWS', data);
        });
        socket.on('LAST_SINGAL', (data) => {
            console.log('singal')
            console.log(data);
            eventBus.$emit('LASTEST_SINGAL', data);
        });
    }
}
