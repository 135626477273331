import request from '@/utils/request.js';

const prefix = 'http://binance:';

export async function tradeRecords(pairName) {
    return request.get({ url: prefix + 'tradeRecords', params: { symbol: pairName }, target: 'fireBot' });
}

export async function accountInfo() {
    return request.get({ url: prefix + 'accountInfo', params: null, target: 'fireBot' });
}

export async function openOrders(pairName) {
    return request.get({ url: prefix + 'openOrders', params: { symbol: pairName }, target: 'fireBot' });
}

export async function allOrders(pairName) {
    return request.get({ url: prefix + 'allOrders', params: { symbol: pairName }, target: 'fireBot' });
}

export async function dailyStats(pairName) {
    return request.get({ url: prefix + 'dailyStats', params: { symbol: pairName }, target: 'fireBot' });
}

export async function cancleOrder(pairName, orderId) {
    return request.post({ url: prefix + 'cancleOrder', params: { symbol: pairName, orderId }, target: 'fireBot' });
}

export async function spotBuy(params) {
    return request.post({ url: prefix + 'spotBuy', params, target: 'fireBot' });
}

export async function spotSell(params) {
    return request.post({ url: prefix + 'spotSell', params, target: 'fireBot' });
}

