import { ethers } from "ethers";
import * as config from '../config/constants';
import { getBalance as getAddressBalance } from "@/api/mempool";

/**
 * 创建provider
 */
export function getProvider(){
    const k = Math.floor((0 + 1 - config.alchemyKeys.length) * Math.random() + config.alchemyKeys.length);
    const RpcURL = 'https://eth-mainnet.alchemyapi.io/v2/' + config.alchemyKeys[k];

    return new ethers.providers.JsonRpcProvider(RpcURL);
}

/**
 * 获取日期
 */
export function getDate(timestamp) {
    const len = timestamp ? timestamp.toString().length : 0;
    let ms = 0;
    if(len == 13){
        ms = timestamp%1000;
    }
    const date = timestamp ? new Date(len == 13 ? timestamp : timestamp * 1000) : new Date();//当前时间
    const month = zeroFill(date.getMonth() + 1);//月
    const day = zeroFill(date.getDate());//日
    const hour = zeroFill(date.getHours());//时
    const minute = zeroFill(date.getMinutes());//分
    const second = zeroFill(date.getSeconds());//秒

    //当前时间
    const curTime = date.getFullYear() + "-" + month + "-" + day
        + " " + hour + ":" + minute + ":" + second + (len == 13 ? '.' + padWithZero(ms, 3) : '');

    return curTime;
}
function zeroFill(i){
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}

/**
 * 数字前面补0
 * @param number
 * @param length
 * @returns {string}
 */
export function padWithZero(number, length) {
    // 使用String()将数字转换为字符串
    let strNumber = String(number);

    // 如果字符串长度不足3位，则在前面补零
    while (strNumber.length < length) {
        strNumber = '0' + strNumber;
    }

    return strNumber;
}

/**
 * 休眠 ms
 */
export async function sleep(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
}

/*
 * 随机数，前后全包含
 */
export function random (min, max) {
    return Math.round(Math.random() * (max - min)) + min;
}

export function isNumeric(value) {
    // 使用正则表达式检查是否只包含数字字符
    return /^\d+$/.test(value);
}

export function isJSON(str) {
    if (typeof str == 'string') {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    }
    return false;
}

/**
 * 对object排序
 * @param obj
 * @returns {{[p: string]: unknown}}
 */
export function sortObject(obj, key, sort = 'desc'){
    // 将对象转换为数组，每个元素是一个包含键和值的数组
    const arr = Object.entries(obj);
    // 对数组进行排序，根据值从大到小排序
    arr.sort(
        (a, b) => {
            if(key){
                return b[1][key] - a[1][key]
            } else {
                return b[1] - a[1]
            }
        }
    );
    const sortedArr = arr.map(r => Object.assign({}, r[1], { key: r[0] }))
    if(sort === 'asc'){
        sortedArr.reverse();
    }
    return sortedArr;
}

/**
 * 按key字典序排序
 * @param obj
 * @returns {{}}
 */
export function sortObjectDict(obj) {
    // 获取对象的所有键并按字典序排序
    const sortedKeys = Object.keys(obj).sort();

    // 创建一个新的对象，按排序后的键顺序添加属性
    const sortedObj = {};
    sortedKeys.forEach(key => {
        sortedObj[key] = obj[key];
    });

    return sortedObj;
}

/**
 * 通过滑动窗口检测异常值
 * @param data
 * @param windowSize
 * @param thresholdMultiplier
 * @returns {*[]}
 */
export function detectOutliers(data, windowSize, thresholdMultiplier) {
    const outliers = [];

    // 滑动窗口检测异常值
    for (let i = 0; i < data.length - windowSize + 1; i++) {
        const window = data.slice(i, i + windowSize);
        const windowMean = calculateMean(window);
        const windowStd = calculateStdDev(window);

        // 计算阈值
        const threshold = windowMean + thresholdMultiplier * windowStd;

        // 找到窗口内的异常值
        const windowOutliers = window.filter((value) => value > threshold);

        // 将窗口内的异常值添加到总异常值列表中
        outliers.push(...windowOutliers);
    }

    return outliers;
}

// 计算数组的平均值
export function calculateMean(arr) {
    const sum = arr.reduce((acc, value) => acc + parseFloat(value), 0);
    return sum / arr.length;
}

// 计算数组的标准差
export function calculateStdDev(arr) {
    const mean = calculateMean(arr);
    const squaredDifferences = arr.map((value) => Math.pow(value - mean, 2));
    const variance = calculateMean(squaredDifferences);
    return Math.sqrt(variance);
}

export function timeToLocal(originalTime) {
    const d = new Date(originalTime * 1000);
    return (
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            d.getMilliseconds()
        ) / 1000
    );
};

/**
 * 计算EMA
 * @param data
 * @param period
 * @returns {*[]}
 */
export function calculateEMA(klineData, period, initialEMA = null) {
    if (klineData.length < period) {
        return [];
    }

    const ema = [];
    const multiplier = 2 / (period + 1);

    // 计算EMA的初始值
    let initialSum = 0;
    for (let i = 0; i < period; i++) {
        const value = klineData[i].close || klineData[i].value;
        initialSum += value;
    }
    const initialSMA = initialSum / period;

    const firstEMA = initialEMA !== null ? initialEMA : initialSMA;
    ema.push(firstEMA);

    // 计算后续EMA值
    for (let i = period; i < klineData.length; i++) {
        const value = klineData[i].close || klineData[i].value;
        const currentEMA = (value - ema[i - period]) * multiplier + ema[i - period];
        ema.push(currentEMA);
    }

    return ema;
}

/**
 * 计算波动率
 */
export function cauStandardDeviation(data, key) {
    const n = data.length;

    // 计算均值
    const mean = data.reduce((sum, value) => sum + key ? value[key] : value, 0) / n;

    // 计算平方差值的和
    const squaredDifferencesSum = data.reduce((sum, value) => sum + Math.pow(key ? value[key] : value - mean, 2), 0);

    // 计算标准差
    const standardDeviation = Math.sqrt(squaredDifferencesSum / n);

    return parseFloat(standardDeviation).toFixed(8);
}

/**
 * 计算曲线在某个时间窗口的斜率
 * @param klineData
 * @param windowSize
 * @returns {number}
 */
export function calculateOverallSlope(lineData, windowSize, key) {
    if (lineData.length < windowSize) {
        return;
    }

    let totalSlope = 0;
    for (let i = lineData.length - windowSize; i < lineData.length - 1; i++) {
        const currentClose = key ? lineData[i][key] : lineData[i];
        const nextClose = key ? lineData[i + 1][key] : lineData[i];
        const slope = (nextClose - currentClose) / currentClose;
        totalSlope += slope;
    }

    // 返回平均斜率
    return parseFloat(totalSlope / windowSize * 100).toFixed(3);
}

/**
 * 数据归一化
 * @param data
 * @returns {*}
 */
export function minMaxNormalization(data, newMin, newMax) {
    if (!Array.isArray(data)) {
        throw new Error('Input data must be an array.');
    }

    if (data.length === 0) {
        throw new Error('Input data is empty.');
    }

    const min = Math.min(...data);
    const max = Math.max(...data);

    if (min === max) {
        throw new Error('Min and max values are equal, cannot perform normalization.');
    }

    return data.map((value) => ((value - min) / (max - min)) * (newMax - newMin) + newMin);
}

/**
 * 通过时间戳获取区块编号
 */
export async function getBlockNumberByTime(timestamp){
    timestamp = parseInt(timestamp);
    const provider = getProvider();
    const now = new Date().getTime()/1000;
    const currentBlockNumber = await provider.getBlockNumber();
    const diffBlock = (now - timestamp)/12;
    let blockNumber = parseInt(currentBlockNumber - diffBlock);
    try {
        for(let i = 0; i < 10; i++){
            // 核对时间
            const blockInfo = await provider.getBlock(blockNumber);
            const blockTimestamp = blockInfo.timestamp;
            const diffTime = timestamp - blockTimestamp;

            if(Math.abs(diffTime) < 30){
                break;
            }
            blockNumber = blockNumber + parseInt(diffTime/12);
        }
    } catch (err) {
        return blockNumber;
    }
}

/**
 * 处理下单参数
 * @param params
 * @param filters
 * @returns {*}
 */
export function filterParams(params, filters){
    for(let i in filters){
        const filter = filters[i];
        if(filter.filterType === 'PRICE_FILTER'){
            filter.tickSize = parseFloat(filter.tickSize);
            const arr = filter.tickSize.toString().split('.');
            const d = arr[1] ? arr[1].length : 0;
            let s = parseFloat(params.price % filter.tickSize).toFixed(d);
            s = s - filter.tickSize == 0 ? 0 : s;
            params.price = parseFloat(params.price - s).toFixed(d);
            params.priceDecimals = d;
        } else if(filter.filterType === 'LOT_SIZE') {
            filter.stepSize = parseFloat(filter.stepSize);
            const arr = filter.stepSize.toString().split('.');
            const d = arr[1] ? arr[1].length : 0;
            let s = parseFloat(params.quantity % filter.stepSize).toFixed(d);
            s = s - filter.stepSize == 0 ? 0 : s;
            params.quantity = parseFloat(parseFloat(params.quantity - s).toFixed(d));
            params.quantityDecimals = d;
        }
    }
    return params;
}

/**
 * 获取当前登录信息
 * @returns {Promise<{address: *, pubkey: *}>}
 */
export async function getAccount(){
    const unisat = window.unisat;
    const accounts = await unisat.requestAccounts();
    const address = accounts[0];
    const pubkey = await unisat.getPublicKey();
    if(address){
        window.currentAccount = address;
        window.currentPubkey = pubkey;
    }
    return {
        address,
        pubkey
    };
}

/**
 * 获取余额
 * @returns {Promise<number>}
 */
export async function getBalance(){
    try {
        if(window.firebotClient){
            const res = await getAddressBalance(window.currentAccount);
            if(res && res.chain && res.chain.balance){
                return res.chain.balance/Math.pow(10, 8);
            }
        } else if(window.unisat) {
            const accountBalance = await window.unisat.getBalance();
            return accountBalance.confirmed/Math.pow(10, 8);
        }
    } catch (err) {
        console.log(err);
    }

    return 0;
}