import * as indicatorUtil from '@/utils/indicator';
import * as func from '@/utils/func';

let cacheData = {
    lastZeroChangeAvePrice: null,
    lastZeroChangeAveChange: null,
    lastAvePrice_15m: [],
    lastAvePrice_2h: [],
    lastDiffAvePrice_2h_4h: [],
    lastChange_2h: [0],
    prevMaxPrice: null,
    prevMaxChange: null,
    last1hSingalCount: 0,
    lastSingalTime: 0,
    singal: {},
    lastATRs: []
};

export function checkBuy(klineData, needReset){
    if(klineData.length < 120){
        return null;
    }
    if(needReset){
        cacheData = {
            lastZeroChangeAvePrice: null,
            lastZeroChangeAveChange: null,
            lastAvePrice_15m: [],
            lastAvePrice_2h: [],
            lastDiffAvePrice_2h_4h: [],
            lastChange_2h: [0],
            prevMaxPrice: null,
            prevMaxChange: null,
            last1hSingalCount: 0,
            lastSingalTime: 0,
            singal: {},
            lastATRs: []
        };
    }

    const lastCandle = klineData[klineData.length - 1];
    const now = lastCandle.time;
    const result = {
        time: now,
        price: lastCandle.close
    };
    // 均价
    result.avePrice_15m = indicatorUtil.cauAvePrice(klineData.slice(-5));
    result.avePrice_1h = indicatorUtil.cauAvePrice(klineData.slice(-20));
    result.avePrice_2h = indicatorUtil.cauAvePrice(klineData.slice(-40));
    result.avePrice_4h = indicatorUtil.cauAvePrice(klineData.slice(-80));

    // 记录最近3个均价
    cacheData.lastAvePrice_15m.push(result.avePrice_15m);
    cacheData.lastAvePrice_15m = cacheData.lastAvePrice_15m.slice(-3);
    cacheData.lastAvePrice_2h.push(result.avePrice_2h);
    cacheData.lastAvePrice_2h = cacheData.lastAvePrice_2h.slice(-3);
    cacheData.lastDiffAvePrice_2h_4h.push(result.avePrice_2h - result.avePrice_4h);
    cacheData.lastDiffAvePrice_2h_4h = cacheData.lastDiffAvePrice_2h_4h.slice(-5);
    // 平均change
    result.aveChange_15m = Math.abs(indicatorUtil.cauAveChange(klineData.slice(-5), true));
    result.aveChange_2h = Math.abs(indicatorUtil.cauAveChange(klineData.slice(-80, -40)));
    result.aveChange_6h = Math.abs(indicatorUtil.cauAveChange(klineData.slice(-120, -80)));
    // ATR
    result.atr_30m = indicatorUtil.cauATR(klineData.slice(-10));
    result.atr_2h = indicatorUtil.cauATR(klineData.slice(-80));
    result.atr_6h = indicatorUtil.cauATR(klineData.slice(-120, -80));

    // cache
    result.zeroChangeRate = indicatorUtil.cauZeroChangeCount(klineData.slice(-40));
    if(result.zeroChangeRate - 90 >= 0){
        // 最近的平稳状态的2h均价
        cacheData.lastZeroChangeAvePrice = result.avePrice_2h;
        // 最近的平稳状态的2h平均变化率
        cacheData.lastZeroChangeAveChange = result.aveChange_2h;
        // 最近20个平稳状态change_2h
        cacheData.lastChange_2h.push(result.aveChange_2h);
        cacheData.lastChange_2h = cacheData.lastChange_2h.slice(-20);
    }
    result.maxChange_2h = Math.max(...cacheData.lastChange_2h);
    result.lastZeroChangeK = cacheData.lastZeroChangeK;

    // 均价涨幅
    if(cacheData.lastZeroChangeAvePrice){
        result.avePriceChange_15m_2h = (result.avePrice_15m - cacheData.lastZeroChangeAvePrice)/cacheData.lastZeroChangeAvePrice * 100;
        // 记录前一个最高价
        if(!cacheData.prevMaxPrice || lastCandle.high - cacheData.prevMaxPrice > 0){
            cacheData.prevMaxPrice = lastCandle.high;
            cacheData.prevMaxChange = (lastCandle.high - cacheData.lastZeroChangeAvePrice)/cacheData.lastZeroChangeAvePrice*100;
        }
    }

    //
    const closeChange = (lastCandle.close - result.avePrice_2h)/result.avePrice_2h * 100;
    result.closeChange = closeChange;

    const aveChange = result.aveChange_15m - result.maxChange_2h - Math.abs(result.aveChange_2h - result.aveChange_6h);
    result.aveChange = aveChange;
    result.change = lastCandle.change;
    // 初次检测信号
    if(
        aveChange > 0
        && aveChange - result.maxChange_2h > 0
        && aveChange - result.aveChange_6h > 0
        && lastCandle.change - 0.2 > 0
        && result.avePrice_15m - result.avePrice_2h > 0
        && result.avePriceChange_15m_2h
        && result.avePriceChange_15m_2h - 1 > 0
        && now - cacheData.lastSingalTime > 3600
        && isIncrement(cacheData.lastAvePrice_15m)
        || (isAllGoodChange(klineData.slice(-5).map(r => r.change)) && result.closeChange - 1.5 > 0)
    ){
        result.singal = true;
        if(now - cacheData.lastSingalTime < 2*60*60){
            cacheData.last1hSingalCount++;
        } else {
            cacheData.last1hSingalCount = 1;
        }
        cacheData.lastSingalTime = now;
    }
    // 核对信号
    const prevGoodSingalTime = cacheData.singal.time || 0;
    const stepOk = now - prevGoodSingalTime < 0 || now - prevGoodSingalTime > 15*60;
    if(
        result.singal
        && cacheData.last1hSingalCount - 3 >= 0
        && stepOk
    ){
        let isOk = false;
        const last3Candles = klineData.slice(-3);
        // 连续3个change大于0，且最新一个change大于1.5
        if(
            last3Candles[0].change > 0
            && last3Candles[1].change > 0
            && last3Candles[2].change > 1.5
        ){
            isOk = true;
            result.remark = 'B1';
        }
        if(
            result.zeroChangeRate - 80 >= 0
            && closeChange - 1.5 > 0
        ){
            isOk = true;
            result.remark = 'B2';
        }
        if(incrementPercent(cacheData.lastDiffAvePrice_2h_4h) - 80 >= 0){
            isOk = true;
            result.remark = 'B3';
        }

        if(isOk){
            // 记录good信号的状态
            result.goodSingal = true;
            cacheData.singal.good = true;
            cacheData.singal.status = true;
            cacheData.singal.time = now;
        }
    }

    // candle方向
    const direct = lastCandle.close - lastCandle.open < 0 ? -1 : 1;

    return result;
}

let sellCacheData = {
    buyTime: null,
    maxProfit: 0,
    lastGoodCandleOpen: 0,
    lastDiffAvePrice_2h_4h: [],
};
export function checkSell(klineData, buyPoint){
    if(!buyPoint || klineData.length < 120){
        return null;
    }
    const lastCandle = klineData[klineData.length - 1];
    const now = lastCandle.time;
    if(now - buyPoint.time == 0){
        return null;
    }
    // reset
    if(sellCacheData.buyTime != buyPoint.time){
        sellCacheData = {
            buyTime: buyPoint.time,
            maxProfit: 0,
            lastGoodCandleOpen: 0,
            lastDiffAvePrice_2h_4h: [],
        };
    }

    if(lastCandle.close - lastCandle.open > 0){
        sellCacheData.lastGoodCandleOpen = lastCandle.open;
    }
    const result = {
        time: now
    };

    // 均价
    result.avePrice_2h = indicatorUtil.cauAvePrice(klineData.slice(-40));
    result.avePrice_4h = indicatorUtil.cauAvePrice(klineData.slice(-80));
    sellCacheData.lastDiffAvePrice_2h_4h.push(result.avePrice_2h - result.avePrice_4h);
    sellCacheData.lastDiffAvePrice_2h_4h = cacheData.lastDiffAvePrice_2h_4h.slice(-5);

    // 收益
    const profit = (lastCandle.high - buyPoint.price)/buyPoint.price * 100;
    if(profit - sellCacheData.maxProfit > 0){
        sellCacheData.maxProfit = profit;
    }
    result.profit = profit;
    if(now - buyPoint.time > 4*3600 && result.profit - 1 > 0){
        // 超过4小时，大于1个点就卖
        result.singal = true;
        result.remark = 'S1';
    } else if(now - buyPoint.time > 6*3600 && result.profit - 0.2 > 0){
        // 超过6小时，大于0.2个点就卖
        result.singal = true;
        result.remark = 'S2';
    } else {
        // 4个小时以内的
        if(profit - 5 > 0){
            // 小于上一个绿柱
            if(lastCandle.close - sellCacheData.lastGoodCandleOpen < 0){
                result.singal = true;
                result.remark = 'S3';
            }
            // 回撤3%
            if(sellCacheData.maxProfit - profit > 3){
                result.profit = sellCacheData.maxProfit - 3;
                result.singal = true;
                result.remark = 'S4';
            }
        } else if(profit - 3 > 0 && sellCacheData.maxProfit - profit > 1.5) {
            result.profit = sellCacheData.maxProfit - 1.5;
            result.singal = true;
            result.remark = 'S5';
        } else if(profit - 1.5 > 0 && sellCacheData.maxProfit - profit > 1) {
            result.profit = sellCacheData.maxProfit - 1;
            result.singal = true;
            result.remark = 'S6';
        } else if(profit - 1 > 0 && sellCacheData.maxProfit - profit > 0.5) {
            result.profit = sellCacheData.maxProfit - 0.5;
            result.singal = true;
            result.remark = 'S7';
        } else if(decreasePercent(sellCacheData.lastDiffAvePrice_2h_4h) - 80 >= 0 && profit - 0.2 > 0){
            result.singal = true;
            result.remark = 'S9';
        } else if(result.avePrice_2h - result.avePrice_4h < 0 && profit - (-1) > 0){
            result.singal = true;
            result.remark = 'S10';
        } else if(profit - (-3) < 0){
            result.singal = true;
            result.remark = 'S8';
        }
    }

    return result;
}

/**
 * 是否持续递增
 * @param arr
 * @returns {boolean}
 */
function isIncrement(arr){
    let flag = true;
    for(let i in arr){
        if(i == 0){
            continue;
        }
        if(arr[i] - arr[i - 1] < 0){
            flag = false;
            break;
        }
    }
    return flag;
}

/**
 * 增长趋势占比
 * @param arr
 * @returns {number}
 */
function incrementPercent(arr){
    let count = 0;
    for(let i in arr){
        if(i == 0){
            continue;
        }
        if(arr[i] - arr[i - 1] > 0){
            count++;
        }
    }
    return count/arr.length * 100;
}

/**
 * 减小趋势占比
 * @param arr
 * @returns {number}
 */
function decreasePercent(arr){
    let count = 0;
    for(let i in arr){
        if(i == 0){
            continue;
        }
        if(arr[i] - arr[i - 1] < 0){
            count++;
        }
    }
    return count/arr.length * 100;
}

function isAllGoodChange(arr){
    let flag = true;
    arr.map(v => {
        if(v < 0){
            flag = false;
        }
    });
    return false;
}