<template>
  <div class="container">
    <div class="left-side">
      <div class="menu-item" :class="{active: menuCate == 'all'}" @click="listsCate('all')">
        <span>全部</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="data-con">
      <div class="filter-con">
        <el-input
            placeholder="Coin symbol"
            v-model="filterCoin"
            size="mini"
            class="filter-coin-input"
            @input="onFilterCoins"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <el-radio v-model="filter.statusStr" label="全部" @change="v => {filterLists(null)}">全部</el-radio>
        <el-radio v-model="filter.statusStr" label="胜利" @change="v => {filterLists({ statusStr: '胜利'})}">胜利</el-radio>
        <el-radio v-model="filter.statusStr" label="失败" @change="v => {filterLists({ statusStr: '失败'})}">失败</el-radio>
        <el-radio v-model="filter.statusStr" label="其他" @change="v => {filterLists({ statusStr: ''})}">其他</el-radio>
        <el-checkbox v-model="filter.afterPrice" label="信号后价格" @change="v => {filterLists({ afterPrice: v})}">信号后价格</el-checkbox>

        <div class="fs-12 mgl-10">有效数据：{{totalCount}}</div>
        <div class="fs-12 mgl-10">胜率：{{profitPercent}}%</div>
        <div class="fs-12 mgl-10">综合盈利率：{{totalProfitPercent}}%</div>
        <div class="fs-12 mgl-10">累计盈利：{{allProfit}}%</div>
      </div>

      <el-table
          :data="lists"
          :height="windowHeight - 100"
          class="data-lists"
      >
        <el-table-column
            fixed
            label="Currency"
            width="120">
          <template slot-scope="scope">
            <div class="bold" :style="{color: scope.row.color}">{{scope.row.pairName}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="信号时间"
            width="150">
          <template slot-scope="scope">
            <div>{{scope.row.date}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="信号价格"
            width="85">
          <template slot-scope="scope">
            <div>{{scope.row.Price}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            label="买入价格"-->
<!--            width="85">-->
<!--          <template slot-scope="scope">-->
<!--            <div>{{scope.row.simulate ? scope.row.simulate.buyPrice : '-'}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            label="Upbit"-->
<!--            width="55">-->
<!--          <template slot-scope="scope">-->
<!--            <div :style="{color: scope.row.existsUpbit ? '#00720c' : '#e3001c'}">{{scope.row.existsUpbit ? '有' : '无'}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            label="首次"-->
<!--            width="50">-->
<!--          <template slot-scope="scope">-->
<!--            <div :style="{color: scope.row.isFirst ? '#00720c' : '#e3001c'}">{{scope.row.isFirst ? '是' : '否'}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="7天异动">-->
<!--          <el-table-column-->
<!--              label="信号前"-->
<!--              width="80">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{scope.row.chainAnalysis ? scope.row.chainAnalysis.beforePercent : '-'}}%</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              label="信号后"-->
<!--              width="80">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{scope.row.chainAnalysis ? scope.row.chainAnalysis.afterPercent : '-'}}%</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="7天新闻">-->
<!--          <el-table-column-->
<!--              label="信号前"-->
<!--              width="60">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{scope.row.newsAnaysis.before}}次</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              label="信号后"-->
<!--              width="60">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{scope.row.newsAnaysis.after}}次</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table-column>-->
        <el-table-column label="K线异动">
<!--          <el-table-column-->
<!--              label="前3天G"-->
<!--              width="68">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{scope.row.klineRes.beforeDay3GoodPercent}}%</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              label="前1天G"-->
<!--              width="68">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{scope.row.klineRes.beforeDay1GoodPercent}}%</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              label="前2H AVE"
              width="90">
            <template slot-scope="scope">
              <div>{{scope.row.klineRes.before2HourAvePrice}}</div>
            </template>
          </el-table-column>

          <el-table-column
              label="0变率"
              width="65">
            <template slot-scope="scope">
              <div>{{scope.row.klineRes.zeroChangePercent}}%</div>
            </template>
          </el-table-column>

          <el-table-column
              label="Chart"
              width="200">
            <template slot-scope="scope">
              <canvas :id="'chart-' + scope.$index" width="200" height="50"></canvas>
            </template>
          </el-table-column>
          <el-table-column
              label="MDP"
              width="60">
            <template slot-scope="scope">
              <span>{{parseFloat(scope.row.maxDiffPercent).toFixed(2)}}%</span>
            </template>
          </el-table-column>
          <el-table-column
              label="STP"
              width="65">
            <template slot-scope="scope">
              <div>{{parseFloat(scope.row.startPercent).toFixed(2)}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="maxProfit"
              width="80">
            <template slot-scope="scope">
              <div :style="{color: getProfitColor(scope.row.klineRes.profitAnalysis.maxProfit)}">{{scope.row.klineRes.profitAnalysis.maxProfit}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="maxTime"
              width="80">
            <template slot-scope="scope">
              <div :style="{color: getTimeColor(scope.row.klineRes.profitAnalysis.maxTime)}">{{scope.row.klineRes.profitAnalysis.maxTime}}h</div>
            </template>
          </el-table-column>

          <el-table-column
              label="模拟"
              width="80">
            <template slot-scope="scope">
              <div :style="{color: scope.row.simulateStatus == '胜利' ? '#00720c' : '#e3001c'}">{{scope.row.simulateStatus}}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="收益"
              width="80">
            <template slot-scope="scope">
              <div>{{scope.row.simulate.profit}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="持仓"
              width="80">
            <template slot-scope="scope">
              <div>{{parseFloat(scope.row.long/3600).toFixed(2)}}H</div>
            </template>
          </el-table-column>
          <el-table-column
              label="备注"
              width="300">
            <template slot-scope="scope">
              <div :style="{color: scope.row.simulateStatus != scope.row.statusStr ? '#e3001c' : '#00720c'}">{{scope.row.simulate.msg}}</div>
            </template>
          </el-table-column>

          <el-table-column
              label="BTC"
              width="60">
            <template slot-scope="scope">
              <span>{{parseFloat(scope.row.beforeTotalChange).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="MADP"
              width="60">
            <template slot-scope="scope">
              <span>{{parseFloat(scope.row.maxAfterDiffPercent).toFixed(2)}}</span>
            </template>
          </el-table-column>

          <el-table-column
              label="good"
              width="60">
            <template slot-scope="scope">
              <span>{{scope.row.isGood}}</span>
            </template>
          </el-table-column>

          <el-table-column
              label="c1"
              width="65">
            <template slot-scope="scope">
              <div>{{parseFloat(scope.row.klineRes.after6min._c1).toFixed(3)}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="c2"
              width="65">
            <template slot-scope="scope">
              <div>{{parseFloat(scope.row.klineRes.after6min._c2).toFixed(3)}}%</div>
            </template>
          </el-table-column>

          <el-table-column
              label="15minProfit"
              width="90">
            <template slot-scope="scope">
              <div>{{scope.row.stepProfit.max15min}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="30minProfit"
              width="90">
            <template slot-scope="scope">
              <div>{{scope.row.stepProfit.max30min}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="60minProfit"
              width="90">
            <template slot-scope="scope">
              <div>{{scope.row.stepProfit.max60min}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="120minProfit"
              width="90">
            <template slot-scope="scope">
              <div>{{scope.row.stepProfit.max120min}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="6hProfit"
              width="90">
            <template slot-scope="scope">
              <div>{{scope.row.stepProfit.max6h}}%</div>
            </template>
          </el-table-column>
        </el-table-column>


        <el-table-column label="分析结果">

          <el-table-column
              label="minProfit"
              width="80">
            <template slot-scope="scope">
              <div>{{scope.row.klineRes.profitAnalysis.minProfit}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              label="minTime"
              width="80">
            <template slot-scope="scope">
              <div :style="{color: getTimeColor(scope.row.klineRes.profitAnalysis.minTime)}">{{scope.row.klineRes.profitAnalysis.minTime}}h</div>
            </template>
          </el-table-column>

          <el-table-column
              label="状态"
              width="80">
            <template slot-scope="scope">
              <div :style="{color: scope.row.color}">{{scope.row.klineRes.profitAnalysis.direct}}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="胜负"
              width="80">
            <template slot-scope="scope">
              <div :style="{color: scope.row.statusStr == '胜利' ? '#00720c' : '#e3001c'}">{{scope.row.statusStr}}</div>
            </template>
          </el-table-column>

          <el-table-column
              label="status"
              width="80">
            <template slot-scope="scope">
              <div>{{!scope.row.status ? '作废' : '-'}}</div>
            </template>
          </el-table-column>

        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import * as singalsApi from "@/api/singals";
import tradeLibs from "@/libs/trade";

export default {
  data(){
    return {
      windowHeight: window.innerHeight,
      menuCate: 'all',
      filterCoin: null,
      filter:{
        statusStr: null
      },
      allLists: [],
      lists: [],
      totalCount: 0, // 有效数据数量
      profitPercent: 0,
      totalProfitPercent: 0, // 综合盈利率
      allProfit: 0
    }
  },
  async mounted() {

    const res = await tradeLibs.accountInfo();
    console.log(res)

    await this.getLists();
    // drawChart
    this.drawLineChart();
  },
  methods: {
    /**
     * 筛选
     * @param cate
     */
    listsCate(cate) {
      if (this.menuCate == cate) {
        return;
      }
      this.menuCate = cate;
    },
    /**
     * 过滤
     * @param coin
     */
    onFilterCoins(coin){
      if(coin && coin != ''){
        coin = coin.toUpperCase() + 'USDT';
        let lists = this.allLists;
        lists = lists.filter(r => r.pairName == coin);
        this.lists = lists;
      } else {
        this.lists = this.allLists;
      }
    },

    drawLineChart(){
      this.lists.forEach((row, rowIndex) => {
        const canvas = document.getElementById('chart-' + rowIndex);
        const ctx = canvas.getContext('2d');

        const data = row.groupAvePrice;
        const before2HourAvePrice = row.klineRes.before2HourAvePrice;
        const maxValue = Math.max(...data.map(r => r.price), before2HourAvePrice);
        const minValue = Math.min(...data.map(r => r.price), before2HourAvePrice);
        // 计算 Y 轴范围
        const yRange = maxValue - minValue;
        // 将 canvas.height 分成 20 份
        const yStep = yRange / 20;

        // 设置折线图的样式
        ctx.lineWidth = 1;

        // 前两小时均价
        const bfy = canvas.height - (before2HourAvePrice - minValue) / yRange * canvas.height;
        ctx.strokeStyle = 'pink';
        ctx.moveTo(0, bfy);
        ctx.lineTo(canvas.width, bfy);
        ctx.stroke();

        // 信号价
        const sP = canvas.height - (row.Price - minValue) / yRange * canvas.height;
        ctx.strokeStyle = 'blue';
        ctx.moveTo(0, sP);
        ctx.lineTo(canvas.width/2, sP);
        ctx.stroke();

        ctx.strokeStyle = 'grey';
        // 绘制折线图
        ctx.beginPath();
        data.forEach((obj, index) => {
          const x = index * (canvas.width / data.length);
          const y = canvas.height - (obj.price - minValue) / yRange * canvas.height;
          if (index === 0) {
            ctx.moveTo(x, y);
          } else {
            if(obj.isBefore == 1){
              // 设置圆的半径
              const radius = 2;
              // 设置圆的填充颜色
              ctx.fillStyle = 'blue';
              // 绘制圆
              ctx.beginPath();
              ctx.arc(x, y, radius, 0, Math.PI * 2);
              ctx.fill();
            }

            ctx.lineTo(x, y);
          }
          ctx.stroke();
        });
      });
    },

    filterLists(cond){
      if(!cond){
        this.lists = this.allLists;
        return;
      }
      this.lists = this.allLists.filter(r => {
        let flag = true;
        for(let k in cond){
          if(['statusStr'].indexOf(k) > -1){
            if(r[k] != cond[k]){
              flag = false;
              break;
            }
          } else if(k == 'afterPrice'){
            if(r.klineRes.after1minClose - r.Price < 0){
              flag = false;
            }
          }
        }

        // if(!r.chainAnalysis || r.chainAnalysis.beforePercent - 1 < 0){
        //   flag = false;
        // }
        return flag;
      });
    },
    async getLists(){
      const firstPrice = {};
      const res = await singalsApi.analysisLists();
      if(res && res.data){

        const allPairs = {};
        let lists = [];
        for(let i in res.data){
          const item = res.data[i];

          if(item.beforeTotalChange >= 0){
            //continue;
          }
          if(item.isGood - 1 < 0){
            // continue;
          }

          const simulate = item.simulate;

          if(parseFloat(simulate.profit) > 0){
            item.simulateStatus = '胜利';
          } else {
            item.simulateStatus = '失败';
          }
          // 持仓时间
          res.data[i].long = item.simulate.time - item.simulate.buyTime;
          if(res.data[i].long - 2*3600 > 0){
            //continue;
          }

          // 信号后3分钟分析
          const klineRes = item.klineRes;
          if(item.startPercent - 3 > 0){
            //continue;
          }
          if(item.startPercent - (-5) < 0){
            //continue;
          }
          const after6min = klineRes.after6min;

          item.klineRes.after6min._sc1 = after6min._c1 - item.startPercent;
          if(after6min.c1 - 1 < 0){
            //continue;
          }
          if(item.simulateStatus != '失败'){
            // continue;
          }
          if(!(after6min.c3 - after6min.c1 > 0.2 || after6min.c2 - after6min.c1 > 0.5)){
            //continue;
          }

          if(klineRes.zeroChangePercent - 95 >= 0){
            //continue;
          }

          if(klineRes.beforeDay1GoodPercent - klineRes.beforeDay3GoodPercent < 0){
            //continue;
          }

          // if(item.profit15min - klineRes.after6min.c1 <= 0.5){
          //   continue;
          // }
          if(item.profit60min - item.profit15min < 0){
            //continue;
          }
          // 标记颜色
          let color = '#999';
          const profitAnalysis = klineRes.profitAnalysis;
          if(profitAnalysis.direct == '先涨后跌'){
            color = this.getProfitColor(profitAnalysis.maxProfit);
          } else {
            color = '#e3001c';
          }
          res.data[i].color = color;
          if(!allPairs[item.pairName] || item.Timestamp - allPairs[item.pairName] > 24 * 3600){
            res.data[i].isFirst = true;
            allPairs[item.pairName] = item.Timestamp;
            firstPrice[item.pairName] = item.Price;
          } else {
            res.data[i].isFirst = false;
          }

          res.data[i].statusStr = this.checkProfitStatus(item);

          res.data[i].status = typeof(res.data[i]['作废']) != 'undefined' ? false : true;

          if(res.data[i].profit60min - (1) >= 0){
            // continue;
          }
          if(klineRes.profitAnalysis.maxTime - 24 < 0){
            // continue;
          }
          if(klineRes.profitAnalysis.maxProfit - 5 <= 0){
            //continue;
          }
          if(item['备注'] == '从高点价格回调'){
            //continue;
          }

          const stepProfit = {
            max15min: parseFloat((klineRes.profitAnalysis.price15m - item.Price)/item.Price*100).toFixed(2),
            max30min: parseFloat((klineRes.profitAnalysis.price30m - item.Price)/item.Price*100).toFixed(2),
            max60min: parseFloat((klineRes.profitAnalysis.price60m - item.Price)/item.Price*100).toFixed(2),
            max120min: parseFloat((klineRes.profitAnalysis.price120m - item.Price)/item.Price*100).toFixed(2),
            max6h: parseFloat((klineRes.profitAnalysis.price6h - item.Price)/item.Price*100).toFixed(2)
          }
          item.stepProfit = stepProfit;

          if(item.stepProfit.max15min - (0.5) >= 0){
            // continue;
          }

          this.allProfit += parseFloat(res.data[i].simulate.profit);
          lists.push(res.data[i]);
        }

        let totalProfits = 0;
        for(let i in lists){
          const item = lists[i];
          const klineRes = item.klineRes;
          totalProfits += Math.max(item.profit15min, item.profit30min, item.profit60min);
        }
        console.log('totalProfits -> ' + totalProfits);

        // 过滤掉不会开单的信号
        /*const orders = {};
        lists = lists.filter(r => {
          if(r.pairName != 'STRAXUSDT'){
            return false;
          }
          if(!orders[r.pairName]){
            orders[r.pairName] = r;
            return true;
          } else {
            const minTime = Math.min(parseFloat(r.klineRes.profitAnalysis.maxTime), parseFloat(r.klineRes.profitAnalysis.minTime));
            const endTime = parseInt(orders[r.pairName].Timestamp) + minTime*3600;
            if(r.Timestamp - endTime < 0){
              return false;
            }
            orders[r.pairName] = r;
            return true;
          }
        });*/

        lists.sort((a, b) => a.simulate.buyTime - b.simulate.buyTime);

        let successCount = 0;
        lists.map(r => {
          r.simulateStatus == '胜利' && (successCount++);
        });

        this.lists = lists;
        this.allLists = lists;
        this.profitPercent = parseFloat(successCount/lists.length * 100).toFixed(2);
        this.totalCount = this.lists.length;
        this.totalProfitPercent = parseFloat((successCount*0.35 - (lists.length - successCount)*0.3)/(this.totalCount*10)*100).toFixed(2);
      }
    },
    checkProfitStatus(item){
      let statusStr = '';
      const profitAnalysis = item.klineRes.profitAnalysis;

      if(profitAnalysis.direct == '先涨后跌'){
        if(profitAnalysis.maxProfit - 3.5 > 0){
          statusStr = '胜利';
        } else if(-3 - profitAnalysis.minProfit > 0){
          statusStr = '失败';
        }
      } else {
        if(-3 - profitAnalysis.minProfit < 0 && profitAnalysis.maxProfit - 3.5 > 0){
          statusStr = '胜利';
        } else {
          statusStr = '失败';
        }
      }
      if(item.profit60min - 2 > 0){
        statusStr = '胜利';
      }

      return statusStr;
    },
    getProfitColor(value){
      let color = '#999';
      if(value - 10 > 0){
        color = '#00720c';
      } else if(value - 5 > 0){
        color = '#02c29d';
      } else if(value - 3 > 0){
        color = '#0278c2';
      } else if(value - 1.5 > 0){
        color = '#5e45f8';
      } else if(parseFloat(value) + 1.5 > 0){
        color = '#f845f5';
      } else {
        color = '#e3001c';
      }

      return color;
    },
    getTimeColor(hour){
      let color = '#999';
      if(hour - 1 < 0){
        color = '#00720c';
      } else if(hour - 2 < 0){
        color = '#02c29d';
      } else if(hour - 6 < 0){
        color = '#0278c2';
      } else if(hour - 12 < 0){
        color = '#5e45f8';
      } else if(hour - 24 < 0){
        color = '#f845f5';
      } else {
        color = '#e3001c';
      }

      return color;
    }
  }
}
</script>

<style lang="less" scoped>
.container{
  max-width: 100%;
  display: flex;
  justify-content: space-between;

  .left-side{
    display: none;
  }
  .data-con{
    flex: 1;
    box-sizing: border-box;
    width: calc(100% - 280px);

    .filter-con{
      display: flex;
      align-items: center;

      .filter-coin-input{
        width: 300px;
      }
    }

    /deep/.el-table {
      .el-table__cell{
        text-align: center;
      }
    }
  }
}
</style>