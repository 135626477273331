<template>
  <div class="tabs-con">
    <div
        class="tab-item"
        :class="{active: idx === activeTab}"
        v-for="(item, idx) in tabs"
        :key="idx"
        @click="onChangeTab(idx)"
    >
      {{item.symbol}}{{lastPrice[item.symbol] ? '(' + lastPrice[item.symbol] + ')' : ''}}
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      sg: null,
      currentSymbol: null,
      activeTab: 0,
      tabs: [],
      lastPrice: {}
    }
  },
  created() {
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      if (!sg || !sg.symbol) {
        return;
      }
      this.sg = sg;
      this.currentSymbol = sg.symbol.replace('USDT', '');
      let flag = false;
      for(let i = 0; i < this.tabs.length; i++){
        const item = this.tabs[i];
        if(item.symbol === sg.symbol){
          flag = true;
          this.onChangeTab(i);
          break;
        }
      }
      // tabs中没有此symbol
      if(!flag){
        this.tabs.unshift({
          symbol: sg.symbol
        });
      }
    });
    // 刷新任务列表
    this.$eventBus.$on('REFRESH_TASKS', () => {
      this.loadTabs();
    });
  },
  mounted() {
    this.loadTabs();
  },
  methods: {
    onChangeTab(idx){
      if(this.activeTab === idx){
        return;
      }
      this.activeTab = idx;
      const sg = {
        symbol: this.tabs[idx].symbol,
        exchange: 'binance'
      };
      this.currentSymbol = sg.symbol.replace('USDT', '');
      this.$router.replace({
        path: '/trade',
        query: { symbol: sg.symbol }
      });
      localStorage.setItem('CURRENT_SYMBOL', JSON.stringify(sg));
      this.$eventBus.$emit('CHANGE_SYMBOL', sg);
    },
    loadTabs(){
      this.tabs.map(item => {
        this.$eventBus.$off('CANDLE_DATA_' + item.symbol); // 取消监听旧的
      });
      const waitSendOrders = localStorage.getItem('WAIT_SEND_ORDERS');
      const tasks = waitSendOrders ? JSON.parse(waitSendOrders) : [];
      const _symbols = {};
      this.tabs = [];
      for(let i = 0; i < tasks.length; i++){
        const item = tasks[i];
        if(!item.symbol || item.symbol.replace('USDT', '') === 'null'){
          continue;
        }
        if(_symbols[item.symbol]){
          continue;
        }
        _symbols[item.symbol] = 1;
        this.tabs.push({
          symbol: item.symbol
        });
      }
      setTimeout(() => {
        this.tabs.map(item => {
          this.$eventBus.$on('CANDLE_DATA_' + item.symbol, (candle) => {
            this.$set(this.lastPrice, item.symbol, candle.close);
          });
        });
      }, 1000);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars";
@import "../../../assets/css/dark";

.tabs-con {
  display: flex;
  align-items: center;

  .tab-item {
    padding: 0 10px;
    height: 24px;
    background-color: @section-bg-color;
    border-radius: 10px 10px 0 0;
    margin-right: 1px;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active {
      background-color: @content-bg-color;
      color: @light-color;
      font-size: 13px;
    }
  }
}
</style>