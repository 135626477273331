/**
 * 获取趋势状态
 * @param candlesGroup
 * @returns {null|{averageVolatility: number, time, avePrice: number}}
 */
export function getTrendValues(candlesGroup, prevTrendData){
    const groupLength = 80;
    // 分组最后一根蜡烛
    const lastCandle = candlesGroup[candlesGroup.length - 1];
    // 分组倒数第二根蜡烛
    const prevCandle = candlesGroup[candlesGroup.length - 2];
    const stepTime = lastCandle.time - prevCandle.time;
    // 分组的平均波动率
    let v = calculateAverageVolatility(candlesGroup);
    if (!v) {
        return null;
    }
    // 分组均价
    const avePrice = calculateAveragePrice(candlesGroup);
    const res = {
        time: lastCandle.time,
        change: lastCandle.change,
        aveVolatility: parseFloat(v).toFixed(2),
        avePrice
    };

    // 计算分组内最高价
    let maxPrice = null, maxTime = null, totalChange = 0, count = 0;
    for(let i in candlesGroup){
        const r = candlesGroup[i];
        if(!maxPrice || r.high - maxPrice > 0){
            maxPrice = r.high;
            maxTime = r.time;
        }
        totalChange += parseFloat(r.change);
        count++;
    }

    if(prevTrendData.length == 80){
        const prevGroupData = prevTrendData[prevTrendData.length - 80];
        const prevData = prevTrendData[prevTrendData.length - 1];
        const pprevData = prevTrendData[prevTrendData.length - 5];
        // 当前组maxPrice相对上一组avePrice的change
        const maxChange = (maxPrice - prevGroupData.avePrice)/prevGroupData.avePrice * 100;
        // 和上一组波动率的比值
        const aveVolatilityRate = (res.aveVolatility - prevGroupData.aveVolatility)/prevGroupData.aveVolatility * 100;
        res.aveVolatilityRate = parseFloat(aveVolatilityRate).toFixed(2);
        // 和上一组均价的比值
        const avePriceChangeRate = (res.avePrice - prevGroupData.avePrice)/prevGroupData.avePrice * 100;
        res.avePriceChangeRate = parseFloat(avePriceChangeRate).toFixed(2);
        // 和上一个平均波动率的比值
        const aveVolatilityRate_1 = (res.aveVolatility - prevData.aveVolatility)/prevData.aveVolatility * 100;
        res.aveVolatilityRate_1 = parseFloat(aveVolatilityRate_1).toFixed(2);
        // 和上上个平均波动率的比值
        const aveVolatilityRate_2 = (res.aveVolatility - pprevData.aveVolatility)/pprevData.aveVolatility * 100;
        res.aveVolatilityRate_2 = parseFloat(aveVolatilityRate_2).toFixed(2);

        res.vv = res.aveVolatilityRate * res.avePriceChangeRate;
        // candel是否最高价
        res.isMax = lastCandle.time == maxTime;
        res.isAfterMax = maxChange - 10 > 0 && (lastCandle.time - maxTime)/stepTime - 20 < 0;
    }

    return res;



    return {
        time: lastCandle.time,
        aveVolatility: v,
        aveVolatilityRate,
        avePrice,
        change: lastCandle.change,
        aveChange: parseFloat(totalChange/count).toFixed(2),
        diffPrevRate: parseFloat((lastCandle.close - prevCandle.open)/prevCandle.open * 100).toFixed(2),
        diffMaxRate: parseFloat((lastCandle.close - maxClose)/maxClose * 100).toFixed(2),
        maxPrice,
        isMax: lastCandle.high - maxPrice > 0 ? true : false
    }
}

/**
 * 检测异常
 * @param klineData
 */
export function getTrendDiff(klineData){
    let prevValue = null;
    const limitRate = 0.8;
    const changeRateArr = [];
    let candlesGroup = [], realValue = [];
    for(let i in klineData) {
        const row = klineData[i];
        candlesGroup.push(row);
        if(i % 40 == 0) {
            // 平均波动率
            let v = calculateAverageVolatility(candlesGroup);
            if (!v) {
                continue;
            }
            // 真实值
            v = parseFloat(v).toFixed(2);
            realValue.push(v);
            realValue = realValue.slice(5);
            // 均价
            const avePrice = calculateAveragePrice(candlesGroup);
            // 相对上一个的变化率
            const changeRate = prevValue ? (v - prevValue)/prevValue : 0;
            if(changeRate - limitRate >= 0){

            }

            changeRateArr.push({
                time: row.time,
                averageVolatility: v,
                changeRate,
                avePrice
            });

            candlesGroup = [];
            prevValue = v;
        }
    }

    return changeRateArr;
}

/**
 * 计算均价
 * @param candlestickData
 * @returns {number}
 */
function calculateAveragePrice(candlestickData){
    let totalPrice = 0, count = 0;
    for (let i = 1; i < candlestickData.length; i++) {
        count++;
        totalPrice += parseFloat(candlestickData[i].close);
    }

    return parseFloat(totalPrice/count);
}
/**
 * 计算平均波动率
 * @param candlestickData
 * @returns {number}
 */
export function calculateAverageVolatility(candlestickData) {
    const returns = calculateReturns(candlestickData);
    const averageVolatility = calculateVolatility(returns);

    return averageVolatility * 100;
}

/**
 * 计算收益率
 * @param candlestickData
 * @returns {*[]}
 */
function calculateReturns(candlestickData) {
    const returns = [];
    for (let i = 1; i < candlestickData.length; i++) {
        const returnPercentage = (candlestickData[i].close - candlestickData[i - 1].close) / candlestickData[i - 1].close;
        returns.push(returnPercentage);
    }
    return returns;
}

/**
 * 计算波动率
 * @param returns
 * @returns {number}
 */
function calculateVolatility(returns) {
    const mean = returns.reduce((acc, val) => acc + val, 0) / returns.length;
    const variance = returns.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / returns.length;
    const stdDev = Math.sqrt(variance);
    return stdDev;
}