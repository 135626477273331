import request from "@/utils/request";

const prefix = 'https://mempool.space/api/';

/**
 * 获取最新gas
 * @returns {Promise<unknown>}
 */
export async function getGas(){
    const options = { url: prefix + 'v1/fees/recommended', target: 'fireBot' };

    return request.get(options);
}

/**
 * 获取最新btc prices
 * @returns {Promise<unknown>}
 */
export async function getPrices(){
    const options = { url: prefix + 'v1/prices', target: 'fireBot' };

    return request.get(options);
}

/**
 * 获取指定地址的pending tx
 * @param address
 * @returns {Promise<*>}
 */
export async function getPendingTxsByAddress(address){
    const options = { url: prefix + 'address/' + address + '/txs/mempool', target: 'fireBot' };

    return request.get(options).then(res => {
        return res.data.map(row => {
            row.segwitSize = row.weight - row.size;
            row.vsize = row.weight/4;
            row.feeRate = parseFloat(parseFloat(row.fee/row.vsize).toFixed(2));
            return row;
        });
    });
}

/**
 * 获取指定地址的全部tx
 * @param address
 * @returns {Promise<*>}
 */
export async function getAllTxsByAddress(address){
    const options = { url: prefix + 'address/' + address + '/txs/mempool', target: 'fireBot' };

    return request.get(options).then(res => {
        return res.data.map(row => {
            row.segwitSize = row.weight - row.size;
            row.vsize = row.weight/4;
            row.feeRate = parseFloat(parseFloat(row.fee/row.vsize).toFixed(2));
            if(row.status && row.status.confirmed){
                row.time = row.status.block_time;
                row.blockHeight = row.status.block_height;
            }
            return row;
        });
    });
}

/**
 * pending交易的首次上链时间
 * @param txId
 * @returns {Promise<* | null>}
 */
export async function getPendingTxTime(txId){
    const options = { url: prefix + 'v1/transaction-times?txId%5B%5D=' + txId, target: 'fireBot' };

    return request.get(options).then(res => {
        return res && res[0] ? res[0] : null;
    });
}

/**
 * 获取替换的txs
 * @param txId
 * @returns {Promise<* | null>}
 */
export async function getReplacesTxs(txId){
    const options = { url: prefix + 'v1/tx/' + txId + '/rbf', target: 'fireBot' };

    return request.get(options).then(res => {
        if(!res || !res.data || !res.data.replacements){
            return [];
        }
        const loopTx = (childs) => {
            let txs = [];
            for(let i = 0; i < childs.length; i++){
                const tx = childs[i].tx;
                tx.feeRate = parseFloat(parseFloat(tx.rate).toFixed(2));
                tx.status = {
                    confirmed: tx.mined ? true : false,
                    replaced: true
                };
                txs.push(tx);
                if(childs[i].replaces && childs[i].replaces.length > 0){
                    txs = txs.concat(loopTx(childs[i].replaces));
                }
            }
            return txs;
        };
        let lists = [];
        const tx = res.data.replacements.tx;
        tx.feeRate = parseFloat(parseFloat(tx.rate).toFixed(2));
        tx.status = {
            confirmed: tx.mined ? true : false
        };
        lists.push(tx);
        lists = lists.concat(loopTx(res.data.replacements.replaces));

        return lists;
    });
}

/**
 * 查询tx详情
 * @param txId
 * @returns {Promise<unknown>}
 */
export async function getTxInfo(txId, isReplaced = false){
    if(!txId){
        console.log('txId无效')
        return null;
    }
    const url = isReplaced ? prefix + 'v1/tx/' + txId + '/cached' : prefix + 'tx/' + txId;
    const options = { url, target: 'fireBot' };

    return request.get(options).then(res => {
        if(!res || !res.data || res.data === 'Transaction not found'){
            return null;
        }
        const info = res.data;
        info.segwitSize = info.weight - info.size;
        info.vsize = info.weight/4;
        info.feeRate = parseFloat(parseFloat(info.fee/info.vsize).toFixed(2));

        return info;
    });
}

/**
 * 广播交易
 * @param txHex
 * @returns {Promise<unknown>}
 */
export async function postTx(txHex){
    const options = { url: prefix + 'tx', params: txHex, target: 'fireBot' };
    return request.post(options).then(res => {
        if(res && res.data && typeof res.data === 'string' && res.data.indexOf('error:') > -1){
            try {
                res = JSON.parse(res.data.split('error:')[1]);
                res.msg = res.message;
                res.code = res.code < 0 ? 0 : res.code;
                delete res.message;
            } catch (err) {}
        }
        return res;
    });
}

/**
 * 生成psbt
 */
export async function generatePsbt(params){
    return request.post({ action: 'Generate-PSBT', params, target: 'fireBot' });
}

/**
 * 获取全部utxos
 * @param address
 * @returns {Promise<unknown>}
 */
export async function getAllUtxos(address){
    const url = prefix + 'address/' + address + '/utxo';

    return request.get({ url, target: 'fireBot' });
}

/**
 * 获取可用的utxos
 */
export async function getAvailableUtxos(address){
    const url = 'https://turbo.ordinalswallet.com/wallet/' + address + '/all-utxos';

    return request.get({ url, target: 'fireBot' });
}

/**
 * 获取指定地址的Inscriptions utxo列表
 * @param address
 * @returns {Promise<unknown>}
 */
export async function getInscriptionsUtxos(address){
    const url = 'https://turbo.ordinalswallet.com/wallet/' + address;

    return request.get({ url, target: 'fireBot' });
}

/**
 * 通过输出位置获取utxo详情
 * @param output
 * @returns {Promise<unknown>}
 */
export function parseUtxoInfoByPosition(output){
    const url = 'https://ord-mirror.magiceden.dev/output/' + output;
    return request.get({ url, target: 'fireBot' }).then(res => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(res.data, 'text/html');
        const a = doc.querySelector('.thumbnails a');
        const href = a?.getAttribute('href');
        const inscriptionId = href?.replace('/inscription/', '');
        const a2 = doc.querySelector('dd.monospace a');
        const address = a2?.textContent;
        const a3 = doc.querySelector('a.monospace');
        const txId = a3?.textContent;
        if(address && inscriptionId && txId){
            return {
                txId,
                address,
                inscriptionId
            };
        }
        return null;
    });
}

/**
 * 解析psbt
 * @param psbtHex
 * @returns {Promise<unknown>}
 */
export async function decodePsbt(psbtHex){
    const url = 'https://wallet-api.unisat.io/v5/tx/decode';
    const params = {
        psbtHex
    };

    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
        //'X-Address': '',
        'X-Channel': 'store',
        'X-Client': 'UniSat Wallet',
        'X-Flag': 0,
        //'X-Udid': '',
        'X-Version': '1.2.6'
    };
    return request.post({ url, params, headers, target: 'fireBot' }).then(res => {
        const data = res.data && res.data.data ? res.data.data : null;
        if(data){
            data.feeRate = parseFloat(data.feeRate.replace('≈', ''));
            data.vsize = parseFloat(parseFloat(data.fee/data.feeRate).toFixed(2));
        }
        return data;
    });
}

/**
 * 获取地址余额
 */
export async function getBalance(address){
    const url = prefix + 'address/' + address;
    const params = {};
    params.r = Math.random();

    return request.get({ url, target: 'fireBot' }).then(res => {
        if(!res || !res.data || !res.data.chain_stats){
            return null;
        }
        const chain_stats = res.data.chain_stats;
        chain_stats.balance = chain_stats.funded_txo_sum - chain_stats.spent_txo_sum;
        const mempool_stats = res.data.mempool_stats;

        return {
            address: res.data.address,
            chain: chain_stats,
            mempool: mempool_stats
        };
    });
}