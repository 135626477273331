<template>
  <div class="container">
    <div class="left-side">
      <div class="menu-item" :class="{active: menuCate == 'strategyMarket'}" @click="onChangeLeftMenu('strategyMarket')">
        <span>策略市场</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuCate == 'strategyTemplate'}" @click="onChangeLeftMenu('strategyTemplate')">
        <span>策略模板</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuCate == 'myStrategy'}" @click="onChangeLeftMenu('myStrategy')">
        <span>我的策略</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuCate == 'customStrategy'}" @click="onChangeLeftMenu('customStrategy')">
        <span>自定义策略</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="main-panel">
      <el-row :gutter="20" v-if="menuCate == 'strategyTemplate'">
        <el-col :span="6" v-for="(item, idx) in strategyTemplateLists" :key="idx">
          <el-card class="box-card">
            <div slot="header" class="card-header">
              <span class="title">{{item.title}}</span>
              <router-link :to="'/strategy-info?info=' + JSON.stringify(item)" target="_blank">使用</router-link>
            </div>
            <div class="card-content">{{item.brief}}</div>
            <div class="card-bottom">
              <div class="name">创作人:{{item.author}}</div>
              <div class="profit-rate text-danger">免费</div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20" v-if="menuCate == 'myStrategy'">
        <el-col :span="6" v-for="(item, idx) in myStrategyLists" :key="idx">
          <el-card class="box-card">
            <div slot="header" class="card-header">
              <span class="title">{{item.info.title}}</span>
              <router-link :to="'/strategy-info?info=' + JSON.stringify(item.info) + '&configKey=' + idx" target="_blank">编辑</router-link>
            </div>
            <div class="card-content">{{item.info.brief}}</div>
            <div class="card-bottom">
              <div class="name">发布人:{{item.info.author}}</div>
              <div class="profit-rate">{{$func.getDate(item.updateTime)}}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      menuCate: 'strategyTemplate',
      strategyTemplateLists: [{
        id: 1,
        slug: 'news_keywords',
        title: '新闻关键词',
        brief: '通过监测新闻关键词，一旦发现指定的关键词时，自动买入或者卖出。',
        author: '0xSun',
        profit: '0.86'
      }, {
        id: 2,
        slug: 'grab_order',
        title: '抢单',
        brief: '选择指定交易对，并设定启动时间，轮询一旦发现交易对开通交易，立即买入。',
        author: '0xSun',
        profit: '0.86'
      }, {
        id: 3,
        slug: 'changes_chain',
        title: '链上异动',
        brief: '监控链上异动，一旦发现某些特定行为，立即买入。',
        author: '0xSun',
        profit: '0.86'
      }, {
        id: 4,
        slug: 'follow_orders_chain',
        title: '指定地址跟单',
        brief: '监控链上指定地址买入，一旦发现自动跟单买入。',
        author: '0xSun',
        profit: '0.86'
      }, {
        id: 4,
        slug: 'auto_profut_loss',
        title: '自动止盈止损',
        brief: '根据收到的信号，按设定的条件自动执行止盈止损。',
        author: '0xSun',
        profit: '0.86'
      }],
      myStrategyLists: []
    }
  },
  mounted() {
    const myStrategy = localStorage.getItem('MY_STRATEGY')
    this.myStrategyLists = myStrategy ? JSON.parse(myStrategy) : {};
    console.log(this.myStrategyLists)
  },
  methods:{
    /**
     * 切换左侧菜单
     * @param cate
     */
    onChangeLeftMenu(cate){
      if(this.menuCate == cate){
        return;
      }
      this.menuCate = cate;
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;

  .main-panel{
    flex: 1;
    box-sizing: border-box;
    margin-right: 15px;
    padding-top: 15px;
  }

  .box-card {
    width: 100%;
    margin-bottom: 15px;

    .title {
      font-size: 14px;
    }
    .card-header{
      display: flex;
      justify-content: space-between;

      a{
        font-size: 14px;
        color: #3a8ee6;
        text-decoration: none;
        &:active{
          color: #3a8ee6;
        }
      }
    }
    .card-content {
      font-size: 14px;
      line-height: 1.6em;
      color: #666;
    }
    .card-bottom {
      margin-top: 15px;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999;
    }
  }
}
</style>