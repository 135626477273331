import { correlation } from 'node-correlation';

/**
 * 去除最大最小值，计算平均change
 * @param candles
 */
export function cauAveChange(candles, isAll){
    let data = candles.map(row => {
        return Math.abs((row.close - row.open) / row.open * 100);
    });
    if(!isAll){
        data.sort((a, b) => a - b);
        const clearNum = parseInt(data.length * 0.1);
        data = data.slice(clearNum, -clearNum);
    }
    let total = 0;
    data.map(row => {
        total += parseFloat(row);
    });
    return parseFloat(parseFloat(total/data.length).toFixed(2));
}

/**
 * 去除最大最小值，计算平均价
 * @param candles
 * candles: 参与计算的蜡烛
 * isAll: true 不去除最大最小值
 */
export function cauAvePrice(candles, isAll = false){
    if(!isAll){
        // 是否过滤最大最小值
        candles.sort((a, b) => a.close - b.close);
        const clearNum = parseInt(candles.length * 0.2);
        candles = clearNum > 0 ? candles.slice(clearNum, -clearNum) : candles;
    }
    let totalClosePrice = 0;
    candles.map(row => {
        totalClosePrice += parseFloat(row.close);
    });
    return parseFloat(totalClosePrice/candles.length);
}

export function cauZeroChangeRate(candles, limit = 0.3, isAll = false){
    if(!isAll){
        // 是否过滤最大最小值
        candles.sort((a, b) => {
            const aChange = (a.close - a.open)/a.open*100;
            const bChange = (b.close - b.open)/b.open*100;
            return Math.abs(aChange) - Math.abs(bChange);
        });
        const clearNum = parseInt(candles.length * 0.2);
        candles = clearNum > 0 ? candles.slice(clearNum, -clearNum) : candles;
    }
    let total = 0;
    candles.map((row, idx) => {
        // 此change为绝对值
        if(Math.abs(row.change) - limit >= 0){
            total++;
        }
    });
    return parseFloat(parseFloat(total/candles.length * 100).toFixed(2));
}

/**
 * 计算布林带
 */
export function calculateBollingerBands(prices, period = 20, deviations = 2) {
    // 计算中间线（SMA）
    let sma = [];
    for (let i = period - 1; i < prices.length; i++) {
        let sum = 0;
        for (let j = 0; j < period; j++) {
            sum += prices[i - j];
        }
        sma.push(sum / period);
    }

    // 计算标准差
    let stdDev = [];
    for (let i = period - 1; i < prices.length; i++) {
        let sumSquaredDiff = 0;
        for (let j = 0; j < period; j++) {
            let diff = prices[i - j] - sma[i - (period - 1 - j)];
            sumSquaredDiff += diff * diff;
        }
        stdDev.push(Math.sqrt(sumSquaredDiff / period));
    }

    // 计算上下带状线
    let upperBand = [];
    let lowerBand = [];
    for (let i = 0; i < sma.length; i++) {
        upperBand.push(sma[i] + deviations * stdDev[i]);
        lowerBand.push(sma[i] - deviations * stdDev[i]);
    }

    return { sma, upperBand, lowerBand };
}

export function cauATR(candles) {
    if (candles.length < 2) {
        throw new Error("Not enough data to calculate ATR");
    }

    // period 等于 candles 数组的长度
    const period = candles.length;

    // 存储真实范围的数组
    let trueRanges = [];

    // 遍历最后 period 天，计算每一天的真实范围
    for (let i = 1; i < period; i++) {
        let highLowDiff = candles[i].high - candles[i].low;
        let highPrevCloseDiff = Math.abs(candles[i].high - candles[i - 1].close);
        let lowPrevCloseDiff = Math.abs(candles[i].low - candles[i - 1].close);

        let trueRange = Math.max(highLowDiff, highPrevCloseDiff, lowPrevCloseDiff);
        trueRanges.push(trueRange);
    }

    // 计算平均真实范围，即真实范围的平均值
    let atr = trueRanges.reduce((sum, value) => sum + value, 0) / period;

    return atr;
}

export function cauEMA(candles, period) {
    if (candles.length < period) {
        throw new Error(`Input data should have at least ${period} elements.`);
    }

    const closes = candles.map(candle => candle.close);
    const alpha = 1 / (period + 1);
    let ema = closes.slice(0, period).reduce((sum, close) => sum + close, 0) / period;

    for (let i = period - 1; i < closes.length; i++) {
        ema = alpha * closes[i] + (1 - alpha) * ema;
    }

    return ema;
}

export function cauROC(candles) {
    // 如果数据不足两条，无法计算 ROC
    if (candles.length < 2) {
        throw new Error("Not enough data to calculate ROC");
    }

    // 取最后两天的数据
    let currentCandle = candles[candles.length - 1];
    let pastCandle = candles[candles.length - 2];

    // 当前收盘价
    let currentClose = currentCandle.close;

    // N天前的收盘价
    let pastClose = pastCandle.close;

    // 计算ROC值，公式为 ((当前收盘价 - N天前的收盘价) / N天前的收盘价) * 100
    let roc = ((currentClose - pastClose) / pastClose) * 100;

    return roc;
}

/**
 * 计算平均值
 * @param arr
 * @returns {number}
 */
export function cauAveValue(arr, isAll = false){
    if(!isAll){
        // 是否过滤最大最小值
        arr.sort((a, b) => {
            return a - b;
        });
        const clearNum = parseInt(arr.length * 0.25);
        arr = clearNum > 0 ? arr.slice(clearNum, -clearNum) : arr;
    }

    let totalCount = 0, totalValue = 0;
    arr.map(v => {
        totalValue += parseFloat(v);
        totalCount++;
    });

    return totalValue/totalCount;
}

/**
 * 计算占比
 * @param arr
 * @returns {number}
 */
export function cauAveRatio(arr, limit = 0.3, isAll = false){
    if(!isAll){
        // 是否过滤最大最小值
        arr.sort((a, b) => {
            return a - b;
        });
        const clearNum = parseInt(arr.length * 0.25);
        arr = clearNum > 0 ? arr.slice(clearNum, -clearNum) : arr;
    }

    let totalCount = 0;
    arr.map(v => {
        if(v - limit >= 0){
            totalCount++;
        }
    });

    return parseFloat(parseFloat(totalCount/arr.length*100).toFixed(8));
}

/**
 * 动态时间规整
 * @param series1
 * @param series2
 * @returns {any}
 */
export function dynamicTimeWarping(series1, series2) {
    series1 = series1.map(r => Math.abs(r.change));
    series2 = series2.map(r => Math.abs(r.change));

    const len1 = series1.length;
    const len2 = series2.length;

    // 创建一个二维数组用于存储部分距离
    const distanceMatrix = new Array(len1);

    for (let i = 0; i < len1; i++) {
        distanceMatrix[i] = new Array(len2);
    }

    // 初始化第一行和第一列
    distanceMatrix[0][0] = Math.abs(series1[0] - series2[0]);

    for (let i = 1; i < len1; i++) {
        distanceMatrix[i][0] = distanceMatrix[i - 1][0] + Math.abs(series1[i] - series2[0]);
    }

    for (let j = 1; j < len2; j++) {
        distanceMatrix[0][j] = distanceMatrix[0][j - 1] + Math.abs(series1[0] - series2[j]);
    }

    // 填充其余的部分距离矩阵
    for (let i = 1; i < len1; i++) {
        for (let j = 1; j < len2; j++) {
            const cost = Math.abs(series1[i] - series2[j]);
            distanceMatrix[i][j] = cost + Math.min(
                distanceMatrix[i - 1][j],
                distanceMatrix[i][j - 1],
                distanceMatrix[i - 1][j - 1]
            );
        }
    }

    // 返回动态时间规整距离
    return parseFloat(parseFloat(distanceMatrix[len1 - 1][len2 - 1]).toFixed(2));
}

/**
 * 计算欧氏距离
 * @param series1
 * @param series2
 * @returns {number}
 */
export function euclideanDistance(series1, series2) {
    series1 = series1.map(v => Math.abs(v));
    series2 = series2.map(v => Math.abs(v));

    // 计算欧氏距离的平方和
    const squaredSum = series1.reduce((sum, value, index) => {
        const difference = value - series2[index];
        return sum + difference * difference;
    }, 0);

    // 返回欧氏距离
    return Math.sqrt(squaredSum);
}

/**
 * 计算余弦相似度
 * @param vectorA
 * @param vectorB
 * @returns {number}
 */
export function cosineSimilarity(series1, series2) {
    series1 = series1.map(r => Math.abs(r.change));
    series2 = series2.map(r => Math.abs(r.change));

    // 计算向量的点积
    const dotProduct = series1.reduce((sum, value, index) => sum + value * series2[index], 0);

    // 计算向量的范数（模）
    const magnitudeA = Math.sqrt(series1.reduce((sum, value) => sum + value * value, 0));
    const magnitudeB = Math.sqrt(series2.reduce((sum, value) => sum + value * value, 0));

    // 计算余弦相似度
    if (magnitudeA === 0 || magnitudeB === 0) {
        // 处理分母为零的情况
        return 0;
    } else {
        return dotProduct / (magnitudeA * magnitudeB);
    }
}

/**
 * 计算曼哈顿距离
 * @param series1
 * @param series2
 * @returns {*}
 */
export function manhattanDistance(series1, series2) {
    series1 = series1.map(r => Math.abs(r.change));
    series2 = series2.map(r => Math.abs(r.change));

    return series1.reduce((distance, value1, index) => {
        return distance + Math.abs(value1 - series2[index]);
    }, 0);
}

/**
 * 计算闵可夫斯基距离
 * @param series1
 * @param series2
 * @param p 当 p=1 时，闵可夫斯基距离等同于曼哈顿距离；当 p=2 时，等同于欧氏距离
 * @returns {number}
 */
export function minkowskiDistance(series1, series2, p = 2) {
    series1 = series1.map(r => Math.abs(r.change));
    series2 = series2.map(r => Math.abs(r.change));

    const distance = series1.reduce((sum, value1, index) => {
        return sum + Math.abs(value1 - series2[index]) ** p;
    }, 0);

    return Math.pow(distance, 1 / p);
}

/**
 * 计算均值
 */
function cauMean(data) {
    return data.reduce((sum, value) => sum + value, 0) / data.length;
}

/**
 * 计算标准差
 * @param data
 * @returns {number}
 */
function standardDeviation(data) {
    const dataMean = cauMean(data);
    const squaredDifferences = data.map(value => Math.pow(value - dataMean, 2));
    const variance = cauMean(squaredDifferences);
    return Math.sqrt(variance);
}

/**
 * 计算离散系数
 * @param data
 * @returns {null|number}
 */
export function coefficientOfVariation(data) {
    const dataMean = cauMean(data);
    const dataStdDev = standardDeviation(data);

    // 防止除以零
    if (dataMean === 0) {
        console.error("Mean is zero, cannot calculate coefficient of variation.");
        return 0;
    }

    return (dataStdDev / dataMean) * 100;
}

/**
 * z-score
 * @param data
 * @returns {*}
 */
export function normalizeToZeroOne(data) {
    const mean = cauMean(data);
    const stdDev = Math.sqrt(data.map(value => Math.pow(value - mean, 2)).reduce((sum, value) => sum + value, 0) / data.length);

    return data.map(value => sigmoid((value - mean) / stdDev));
}

// Sigmoid函数
function sigmoid(x) {
    return 1 / (1 + Math.exp(-x));
}

/**
 * 计算初始稳定价格
 * @param candles
 * @returns {number}
 */
export function cauStablePrice(candles){
    let totalPrice = 0, count = 0;
    for(let i = 0; i < candles.length; i++){
        if(i < 5){
            continue;
        }
        const prevRow = candles[i - 5];
        const row = candles[i];
        row.change = row.change ? row.change : (row.close - row.open)/row.open * 100;
        if(Math.abs(row.change) - 0.3 > 0){
            continue;
        }
        const preAvePrice = (parseFloat(prevRow.open) + parseFloat(prevRow.close))/2;
        const atr = (row.close - preAvePrice)/preAvePrice * 100;
        if(Math.abs(atr) - 0.5 > 0){
            continue;
        }

        const avePrice = (parseFloat(row.open) + parseFloat(row.close))/2;

        totalPrice += avePrice;
        count++;
    }

    return totalPrice/count;
}

/**
 * 计算初始稳定change
 * @param candles
 * @returns {number}
 */
export function cauStableChange(candles){
    let totalChange = 0, count = 0;
    for(let i = 0; i < candles.length; i++){
        if(i < 5){
            continue;
        }
        const prevRow = candles[i - 5];
        const row = candles[i];
        if(isNaN(row.change)){
            continue;
        }
        if(Math.abs(row.change) - 1 > 0){
            continue;
        }
        const preAvePrice = (parseFloat(prevRow.open) + parseFloat(prevRow.close))/2;
        const atr = (row.close - preAvePrice)/preAvePrice * 100;
        if(Math.abs(atr) - 1 > 0){
            continue;
        }

        totalChange += parseFloat(row.change);
        count++;
    }

    return count > 0 ? parseFloat(parseFloat(totalChange/count).toFixed(2)) : 0;
}

/**
 * arr值的趋势
 * @param arr
 * @param count
 * @returns {number}
 * limit: 允许误差 percent 符合条件值的占比
 */
export function checkArrTrend(arr, count = 4, limit = 0.02, percent = 1){
    if(arr.length - count < 0){
        return 0;
    }
    let v1 = 0, v2 = 0;
    for(let i in arr){
        if(i == 0) continue;
        const c = (arr[i] - arr[i - 1])/Math.abs(arr[i - 1]) * 100;
        if(c - limit > 0){
            v1++;
        } else if(c - (-limit) < 0){
            v2++;
        }
    }
    if(v1 >= arr.length * percent){
        return 1;
    }
    if(v2 >= arr.length * percent){
        return -1;
    }
    return 0;
}