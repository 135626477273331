<template>
  <div class="chart-con">
    <div id="kline-chart-con"></div>
    <div class="interval-selector" v-if="klineData.length > 0">
      <el-tag size="mini" effect="plain" :type="intervalType == '1m' ? 'warning' : 'info'" @click="onChangeKlineInterval('1m')">1M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '3m' ? 'warning' : 'info'" @click="onChangeKlineInterval('3m')">3M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '5m' ? 'warning' : 'info'" @click="onChangeKlineInterval('5m')">5M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '15m' ? 'warning' : 'info'" @click="onChangeKlineInterval('15m')">15M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1h' ? 'warning' : 'info'" @click="onChangeKlineInterval('1h')">1H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '4h' ? 'warning' : 'info'" @click="onChangeKlineInterval('4h')">4H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1d' ? 'warning' : 'info'" @click="onChangeKlineInterval('1d')">1D</el-tag>
    </div>
    <div id="realtime-values" class="realtime-values"></div>
    <i class="el-icon-loading loading" :class="{opacity: klineData.length > 0}"></i>
  </div>
</template>

<script>
import * as func from "@/utils/func";
import binanceLibs from "@/libs/trade";
import * as coinsApi from '@/api/coins';
import * as aiV9 from '@/strategy/ai-v9';

const intervalSecond = {
  '1m': 60,
  '3m': 180,
  '5m': 300,
  '15m': 900,
  '1h': 3600,
  '4h': 4*3600,
  '1d': 24*3600
};
let klineChart;
let candleSeries;
let testSeries1, testSeries2, testSeries3, testSeries4, testSeries5, barSeries;

export default {
  props: {
    coinlInfo: {
      type: Object
    }
  },
  data(){
    return {
      symbol: null,
      stablePrice: {},
      intervalType: '3m',
      targetTime: null,
      currentPrecision: 8,
      klineData: [],
      klineChartVisibleRange: null,
      isLoadingKlineData: false,
      priceLines: {},
      klineMarkersData: []
    }
  },
  async mounted() {
    const { date } = this.$route.query;
    if(date){
      this.targetTime = parseInt(new Date(date).getTime()/1000);
    }
    this.listenEvents();
    this.symbol = this.coinlInfo.pair;
    this.stablePrice = this.coinlInfo.stablePrice;
    this.resetKline();
    await this.initKline();
  },
  methods: {
    listenEvents(){
      this.$eventBus.$on('SHOW_OR_HIDE_STABLEPRICE', showStablePrice => {
        if(showStablePrice){
          this.generateStablePrice(this.stablePrice);
        } else {
          this.removeStablePrice(this.stablePrice);
        }
      });
    },
    /**
     * 获取k线开始时间
     * @returns {number}
     */
    getStartTime() {
      let startTime;
      if(this.targetTime){
        startTime = this.targetTime - 1000 * intervalSecond[this.intervalType];
      } else {
        startTime = new Date().getTime()/1000 - 1000 * intervalSecond[this.intervalType];
      }
      return startTime;
    },
    /**
     * 重置k线
     */
    resetKline(){
      this.klineData = [];
      this.priceLines = {};
      this.klineMarkersData = [];
      klineChart = null;
      candleSeries = null;

      testSeries1 = null;
      testSeries2 = null;
      testSeries3 = null;
    },
    /**
     * 初始化k线
     * @returns {Promise<void>}
     */
    async initKline(){
      this.initKlineChart();
      const startTime = this.getStartTime();
      await this.getKlineData(this.symbol, startTime, this.intervalType);
      this.generateKlineChartData(this.klineData);
      this.generateStablePrice(this.stablePrice);
    },
    /**
     * kline data
     * @param pairName
     * @param startTime
     * @param interval
     * @returns {Promise<void>}
     */
    async getKlineData(pairName, startTime, interval, scrollDirect){
      if(this.isLoadingKlineData){
        return;
      }
      this.isLoadingKlineData = true;

      const res = await binanceLibs.candles(pairName, interval, startTime, 1000);
      this.nextKlineStartTime = res[res.length - 1].openTime/1000;
      let lists = res.map(item => ({
        time: parseInt(item.openTime/1000),
        open: parseFloat(item.open),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        close: parseFloat(item.close),
        volume: parseFloat(item.volume),
        change: parseFloat((item.close - item.open)/item.open*100).toFixed(2)
      }));

      if(scrollDirect === 'prev'){
        lists = lists.filter(item => item.time < this.klineData[0].time);
      } else if(this.klineData.length > 0) {
        lists = lists.filter(item => item.time > this.klineData[this.klineData.length - 1].time);
      }

      this.klineData = scrollDirect === 'prev' ? lists.concat(this.klineData) : this.klineData.concat(lists);
      this.isLoadingKlineData = false;
    },
    /**
     * init kline chart
     */
    initKlineChart(){
      if(klineChart){
        return;
      }
      // init klineChart
      const domElement = document.getElementById('kline-chart-con');
      const options = {
        layout: {
          background: {
            color: '#242424',
          },
          textColor: '#B0A492',
        },
        crosshair: {
          mode: 0,
        },
        priceScale: {
          mode: LightweightCharts.PriceScaleMode.Normal
        },
        grid: {
          horzLines: { color: '#353945' },
          vertLines: { color: '#353945' },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
          rightOffset: 20
        },
      };
      if(this.$i18n.locale === 'zh'){
        options.timeScale.timezone = 'Asia/Shanghai';
        options.localization = {
          timeFormatter: (businessDayOrTimestamp) => {
            return func.getDate(businessDayOrTimestamp);
          },
        }
      } else {
        options.timeScale.timezone = 'America/New_York';
        options.localization = {
          timeFormatter: (businessDayOrTimestamp) => {
            return new Date(businessDayOrTimestamp*1000).toLocaleString('en-US', { timeZone: 'America/New_York' });
          },
        }
      }
      klineChart = LightweightCharts.createChart(domElement, options);
      // 价格tip
      this.drawToolTip();

      // scroll event
      klineChart.timeScale().subscribeVisibleTimeRangeChange((visibleRange, _) => {
        let scrollDirect = null;
        if(visibleRange && this.klineChartVisibleRange && this.klineChartVisibleRange.length == 3){
          const offsetTime = visibleRange.from - this.klineChartVisibleRange[0];
          if(offsetTime < 0){
            scrollDirect = 'prev';
          } else if(offsetTime > 0) {
            scrollDirect = 'next';
          } else {
            scrollDirect = this.klineChartVisibleRange[2];
          }
        }
        this.klineChartVisibleRange = visibleRange ? [visibleRange.from, visibleRange.to, scrollDirect] : null;
      });
      // 记录鼠标操作事件的位置
      const mouseDownPos = {};
      domElement.addEventListener("mousedown", async (e) => {
        if(!this.klineChartVisibleRange){
          return;
        }
        mouseDownPos.x = e.offsetX;
        mouseDownPos.y = e.offsetY;
      });
      domElement.addEventListener("mouseup", async (e) => {
        if(!this.klineChartVisibleRange){
          return;
        }
        let isLoadMore = false, startTime = null;
        // 当前已加载的范围
        const currentRange = [this.klineData[0].time, this.klineData[this.klineData.length - 1].time];
        if(
            this.klineChartVisibleRange[2] === 'prev'
            && this.klineChartVisibleRange[0] - currentRange[0] < 1000 * intervalSecond[this.intervalType]
        ){
          startTime = currentRange[0] - 1000 * intervalSecond[this.intervalType];
          isLoadMore = true;
        } else if(
            this.klineChartVisibleRange[2] === 'next'
            && currentRange[1] - this.klineChartVisibleRange[1] < 1000 * intervalSecond[this.intervalType]
            && new Date().getTime()/1000 - currentRange[1] > intervalSecond[this.intervalType]
        ){
          startTime = currentRange[1];
          isLoadMore = true;
        }
        if(isLoadMore){
          await this.getKlineData(this.symbol, startTime, this.intervalType, this.klineChartVisibleRange[2]);
          this.generateKlineChartData(this.klineData);
        }
      });
      klineChart.subscribeClick((e) => {
        this.onKlineChartClick(e);
      });
    },
    /**
     * 绘制实时数据
     */
    drawToolTip(){
      const toolTip = document.getElementById('realtime-values');
      klineChart.subscribeCrosshairMove(param => {
        if (!param.time) {
          toolTip.style.display = 'none';
        } else {
          toolTip.style.display = 'flex';
          const data = param.seriesData.get(candleSeries);
          if(!data){
            return;
          }
          const changeValue = parseFloat((data.close - data.open)/data.open*100).toFixed(2);
          const amplitude = parseFloat((data.high - data.low)/data.low*100).toFixed(2);
          const price = data.value !== undefined ? data.value : data.close;

          const priceStr = `
<span>${this.$t('kline.open')}:${data.open}</span>
<span>${this.$t('kline.close')}:${data.close}</span>
<span>${this.$t('kline.high')}:${data.high}</span>
<span>${this.$t('kline.low')}:${data.low}</span>
            `;
          const changeStr = `
<span>${this.$t('kline.change')}: ${changeValue}</span>
<span>${this.$t('kline.amplitude')}: ${amplitude}</span>
          `;

          toolTip.innerHTML = `
<div class="price-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${priceStr}</div>
<div class="change-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${changeStr}</div>
`;
          const coordinate = candleSeries.priceToCoordinate(price);
          if (coordinate === null) {
            return;
          }
        }
      });
    },
    /**
     * 重置价格精度
     */
    resetKlineChartPricePrecision(){
      const firstCandle = this.klineData.length > 0 ? this.klineData[0] : null;
      let precision = 8, minMove = 0.00000001;
      if(firstCandle){
        const priceArr = [
          firstCandle.open.toString(),
          firstCandle.close.toString(),
          firstCandle.high.toString(),
          firstCandle.low.toString()
        ];
        let len = 0;
        for(let i in priceArr){
          const _arr = priceArr[i].split('.');
          if(_arr[1] && _arr[1].length - len > 0){
            len = _arr[1].length;
          }
        }
        precision = len ? len : 2;
        minMove = parseFloat(Math.pow(10, -precision)).toFixed(precision);
      }
      candleSeries.applyOptions({
        priceFormat: {
          type: 'price',
          precision,
          minMove
        },
      });

      this.currentPrecision = precision;
    },
    /**
     * k线图点击事件
     */
    async onKlineChartClick(e){
      this.removeStablePrice(this.stablePrice);
      const res = await coinsApi.stablePrice({ symbol: this.symbol, startTime: e.time });
      if(res && res.data){
        this.stablePrice = res.data;
        // 重新画价格线
        this.generateStablePrice(this.stablePrice);
      }

      // 找到对应时间的索引
      let idx = null;
      for(let i in this.klineData){
        if(this.klineData[i].time - e.time === 0){
          idx = i;
          break;
        }
      }

      const colors = [], sp_6h = [], sc_6h = [], atrs = [];
      for(let i = idx - 480; i <= idx; i++){
        const singal = aiV9.checkPoint(this.symbol, this.klineData.slice(i - 480 + 1, i + 1));
        if(singal.hourMark || singal.time === e.time){
          colors.push(singal.color);
          sp_6h.push(singal.stablePrice_6h);
          sc_6h.push(singal.stableChange_6h);

          const p0 = (this.klineData[i - 5].low + this.klineData[i - 5].high)/2;
          const p1 = (this.klineData[i].low + this.klineData[i].high)/2;
          const atr = parseFloat(parseFloat((p1 - p0)/p0 * 100).toFixed(2));
          atrs.push(atr);
        }
      }
      console.log(e.time);
      console.log(this.$func.getDate(e.time));
    },
    /**
     * 生成价格线
     * @param price
     * @param color
     * @param title
     */
    createPriceLine(price, color, title){
      title = !title ? '' : title;
      if(this.priceLines[this.symbol + '_' + title + '_' + price]){
        return;
      }
      const priceLine = candleSeries.createPriceLine({
        price,
        color,
        lineWidth: 1,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title
      });
      this.priceLines[this.symbol + '_' + title + '_' + price] = priceLine;
    },
    removePriceLine(price, title){
      if(!candleSeries){
        return;
      }
      if(title && price){
        candleSeries.removePriceLine(this.priceLines[this.symbol + '_' + title + '_' + price]);
        delete this.priceLines[this.symbol + '_' + title + '_' + price];
      } else {
        const regex = title ? this.symbol + '_' + title + '_*' : this.symbol + '_*_' + price;
        const reg = new RegExp(regex);
        for(let key in this.priceLines){
          if(reg.test(key)){
            candleSeries.removePriceLine(this.priceLines[key]);
            delete this.priceLines[key];
          }
        }
      }
    },
    /**
     * 添加marker
     * @param time
     * @param text
     * @param position
     * @param shape
     * @param color
     */
    addMarker(time, text, color, position, shape, type){
      let hasMarker = false;
      if(type){
        hasMarker = this.klineMarkersData.some(r => r.time - time === 0 && r.type === type);
      }
      !hasMarker && this.klineMarkersData.push({
        time,
        position: position || 'belowBar',
        color: color || 'rgb(246, 174, 45)',
        shape: shape || 'arrowUp',
        text: text ? text.toString() : '',
        size: 1,
        type
      });
      if(arguments && typeof(arguments[arguments.length - 1]) === 'boolean' && arguments[arguments.length - 1] === true){
        this.klineMarkersData.sort((a, b) => a.time - b.time);
        candleSeries.setMarkers(this.klineMarkersData);
      }
    },
    /**
     * 绘制k线数据
     * @param klineData
     */
    generateKlineChartData(klineData){
      this.generateCandleSeries(klineData);

      this.generateTestData(klineData);
    },
    /**
     * 生成k线蜡烛图
     * @param klineData
     * @returns {Promise<void>}
     */
    async generateCandleSeries(klineData){
      // kline
      if(!candleSeries){
        candleSeries = klineChart.addCandlestickSeries();
        candleSeries.priceScale().applyOptions({
          autoScale: true
        });
        this.resetKlineChartPricePrecision();
      }
      candleSeries.setData(klineData);
    },
    /**
     * 多个支撑位
     */
    removeStablePrice(stablePrice){
      if(!stablePrice){
        return;
      }
      stablePrice.day1 && this.removePriceLine(null, '参考1');
      stablePrice.day3 && this.removePriceLine(null, '参考2');
      stablePrice.day7 && this.removePriceLine(null,'参考3');

      stablePrice.day1 && this.removePriceLine(null,'参考');
    },
    generateStablePrice(stablePrice){
      if(!stablePrice){
        return;
      }
      /*stablePrice.day1 && this.createPriceLine(parseFloat(stablePrice.day1).toFixed(this.currentPrecision), 'rgba(73,203,243,.4)', '参考1');
      stablePrice.day3 && this.createPriceLine(parseFloat(stablePrice.day3).toFixed(this.currentPrecision), 'rgba(255,55,115,.4)', '参考2');
      stablePrice.day7 && this.createPriceLine(parseFloat(stablePrice.day7).toFixed(this.currentPrecision), 'rgba(255,238,0,.4)', '参考3');

      stablePrice.day1 && this.createPriceLine(parseFloat(stablePrice.day1*1.015).toFixed(this.currentPrecision), 'rgba(255,238,0,.6)', '参考');*/
    },
    /**
     * 测试数据或图表
     */
    generateTestData(klineData){
      if(!testSeries1){
        testSeries1 = klineChart.addLineSeries({
          //price: 10,
          color: 'green',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          // overlay: true,
          // priceScaleId: 'testSeries',
        });
        testSeries1.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        /*testSeries1.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });*/

        testSeries2 = klineChart.addLineSeries({
          //price: 10,
          color: 'yellow',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          // overlay: true,
          // priceScaleId: 'testSeries',
        });
        testSeries2.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        /*testSeries2.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });*/

        testSeries3 = klineChart.addLineSeries({
          price: 10,
          color: 'blue',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'testSeries',
        });
        testSeries3.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        testSeries3.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });

        testSeries4 = klineChart.addLineSeries({
          price: 10,
          color: 'pink',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'testSeries',
        });
        testSeries4.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        testSeries4.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });

        testSeries5 = klineChart.addLineSeries({
          price: 10,
          color: 'pink',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'testSeries',
        });
        testSeries5.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        testSeries5.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });

        barSeries = klineChart.addHistogramSeries({
          color: "#a300e7",
          lineWidth: 2,
          priceFormat: {
            type: "volume"
          },
          overlay: true,
          priceScaleId: 'VolumeBar',
        });
        barSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.99,
            bottom: 0,
          }
        });
      }

      const dataArr1 = [], dataArr2 = [], dataArr3 = [], dataArr4 = [], dataArr5 = [], barData = [];
      for(let i = 480; i < klineData.length; i++){
        const candles = klineData.slice(i - 480, i);
        klineData[i].ap = (klineData[i].close + klineData[i].open)/2;
        if(i === 0){
          continue;
        }

        const res = aiV9.checkPoint(this.symbol, candles);

        dataArr1.push({
          time: res.time,
          value: res.stablePrice_6h * (1 + 0.015),
          color: '#ffee00'
        });
        dataArr2.push({
          time: res.time,
          value: res.stablePrice_6h * (1 - 0.015),
          color: 'rgb(255,55,115)'
        });

        dataArr3.push({
          time: res.time,
          value: res.change,
          color: '#b675cc'
        });
        dataArr4.push({
          time: res.time,
          value: res.stableChange_6h + 0.5,
          color: '#e5e079'
        });
        dataArr5.push({
          time: res.time,
          value: res.stableChange_6h - 0.5,
          color: '#d56401'
        });

        if(res.time % 3600 === 0){
          this.addMarker(res.time, '', res.markColor, 'belowBar', 'circle', 'MARK');
        }

        if(res.singal){
          this.addMarker(res.time, res.realChange_6h, res.singalColor, 'belowBar', 'arrowUp', 'SINGAL');
        }
      }
      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);

      // barSeries.setData(barData);
      testSeries1.setData(dataArr1);
      testSeries2.setData(dataArr2);
      testSeries3.setData(dataArr3);
      testSeries4.setData(dataArr4);
      testSeries5.setData(dataArr5);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.chart-con {
  position: relative;
  height: calc(100vh - @header-height - @mg-height - 36px - 60px - 32px - 32px - 16px);
  width: 100%;

  #kline-chart-con{
    width: 100%;
    height: 100%;
  }
}

.interval-selector {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 999;

  /deep/.el-tag{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &.el-tag--plain.el-tag--info{
      color: @font-color;
    }
    &.el-tag--plain.el-tag--warning{
      color: @light-color;
    }
  }
}

.realtime-values {
  position: absolute;
  left: 226px;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  height: 20px;

  /deep/.price-str{
    span {
      padding-right: 10px;
    }
  }
  /deep/.change-str{
    span {
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .realtime-values{
    left: 20px;
    top: 30px;
    display: block !important;

    div{
      clear: both;
    }
  }
}

.loading {
  z-index: 1;
  &.opacity {
    opacity: 0;
  }
}
</style>