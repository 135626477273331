import request from '@/utils/request.js';
import * as func from '@/utils/func.js';
import * as bitcoin from "bitcoinjs-lib";
import * as MempoolApi from "@/api/mempool";
import * as MempoolUtil from "@/utils/mempool";
import { eventBus } from "@/utils/eventBus";
import Crypto from 'crypto-js';

const prefix = 'https://stats-mainnet.magiceden.io/';
const prefixV2 = 'https://api-mainnet.magiceden.io/v2/';
const prefixServer = 'https://brc.ai-dex.online/';

export async function getTopCollections(params = {}){
    !params.window && (params.window = '1d');
    !params.limit && (params.limit = 20);
    !params.offset && (params.offset = 0);
    !params.sort && (params.sort = 'volume');
    !params.direction && (params.direction = 'desc');
    params.r = Math.random();
    const options = { url: prefix + 'collection_stats/search/bitcoin', params };

    options.target = 'fireBot';

    return request.get(options);
}

export async function getHasPendingCollections(){
    const options = { url: prefixServer + 'magic/collections-has-pending' };
    return request.get(options);
}

export async function getCollectionInfo(params) {
    const url = prefixV2 + 'ord/btc/collections/' + params.symbol;

    return request.get({ url, target: 'fireBot' });
}

export async function getCollectionStat(params) {
    const url = prefixV2 + 'ord/btc/stat?collectionSymbol=' + params.symbol;

    return request.get({ url, target: 'fireBot' });
}

/**
 * 查询tokens
 * @param params
 * @returns {Promise<unknown>}
 */
export async function getCollectionTokens(params) {
    if(!params.collectionSymbol){
        return [];
    }
    const url = prefixV2 + 'ord/btc/tokens';
    !params.limit && (params.limit = 100);
    !params.offset && (params.offset = 0);
    !params.sortBy && (params.sortBy = 'priceAsc');
    !params.minPrice && (params.minPrice = 0);
    !params.maxPrice && (params.maxPrice = 0);
    !params.maxPrice && (params.maxPrice = 0);
    !params.disablePendingTransactions && (params.disablePendingTransactions = false);
    params.r = Math.random();

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 获取tokenInfo
 * @param params
 * @returns {Promise<unknown>}
 */
export async function getTokenInfo(params) {
    const url = prefixV2 + 'ord/btc/tokens/' + params.tokenId;

    return request.get({ url, target: 'fireBot' });
}

export async function getOffers(params) {
    const url = prefixV2 + 'ord/btc/offers';
    params.status === undefined && (params.status = 'valid');
    !params.limit && (params.limit = 40);
    !params.offset && (params.offset = 0);
    !params.sortBy && (params.sortBy = 'priceDesc');

    return request.get({ url, target: 'fireBot', params });
}

export async function getTokenTopOffer(params) {
    if(!params.token_id){
        return null;
    }
    params.limit = 2;
    params.offset = 0;
    const res = await getOffers(params);
    if(res.data && res.data.offers && res.data.offers[0]){
        if(res.data.offers[1] && res.data.offers[0].buyerReceiveAddress === res.data.offers[1].buyerReceiveAddress){
            res.data.offers[0].isRepeat = true;
        }
        return res.data.offers[0];
    }
    return null;
}

export async function getCollectionFloorPrice(params){
    if(!params.symbol){
        return null;
    }
    const res = await getCollectionTokens({
        collectionSymbol: params.symbol,
        disablePendingTransactions: true, // 不显示pending的tokens
        limit: 20,
        offset: 0,
        sortBy: 'priceAsc'
    });

    if(res && res.data && res.data.tokens && res.data.tokens[0]){
        const row = res.data.tokens[0];
        const returnData = {
            floorPrice: row.listedPrice/Math.pow(10, 8)
        };
        return returnData;
    }
    return null;
}

export async function loadInfo(params){
    const basicInfo = await getCollectionInfo(params);
    const stat = await getCollectionStat(params);

    if(basicInfo && basicInfo.data && stat && stat.data){
        const data = Object.assign(basicInfo.data, stat.data);
        const info = {
            name: data.name,
            collectionSymbol: data.symbol,
            image: data.imageURI,
            fp: data.floorPrice/Math.pow(10, 8),
            listedCount: data.totalListed,
            totalSupply: data.supply,
            totalVol: data.totalVolume/Math.pow(10, 8),
            marketCap: 0,
            pending: data.pendingTransactions,
            ownerCount: data.owners,
            currency: data.chain.toUpperCase(),
            txns: '-'
        };

        return info;
    }
    return null;
}

/**
 * 获取token基本信息
 * @param params
 * @returns {Promise<unknown>}
 */
export async function getTokenBasicInfo(params) {
    const url = prefixV2 + 'unifiedSearch/nft/' + params.inscriptionId + '?edge_cache=true';

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 已关注的系列
 * @param params
 * @returns {Promise<unknown>}
 */
export async function likeCollections(params){
    if(!params.address){
        return null;
    }
    const url = prefixServer + 'magic/like?userAddress=' + params.address;

    return request.get({ url });
}

/**
 * 关注系列
 * @param params
 * @returns {Promise<unknown>}
 */
export async function excuteLike(params){
    if(!params.userAddress || !params.symbol){
        return null;
    }
    const url = prefixServer + 'magic/like';

    return request.post({ url, params });
}

/**
 * 配置
 * @param params
 * @returns {Promise<unknown>}
 */
export async function getConfig(params){
    if(!params.address){
        return null;
    }
    const url = prefixServer + 'magic/config?userAddress=' + params.address;

    return request.get({ url });
}

/**
 * 保存配置
 * @param params
 * @returns {Promise<unknown>}
 */
export async function saveConfig(params){
    if(!params.userAddress || !params.configParams){
        return null;
    }
    const url = prefixServer + 'magic/config';

    return request.post({ url, params });
}

/**
 * 预备发送offer
 * @param params
 * @returns {Promise<unknown>}
 */
export async function preOfferCreate(params){
    if(!params.tokenId || !params.price){
        return null;
    }
    if(!params.buyerTokenReceiveAddress || !params.buyerPaymentAddress || !params.buyerPaymentPublicKey){
        return null;
    }
    if(!params.expirationDate){
        params.expirationDate = new Date().getTime() + 7*3600*24*1000;
    }
    !params.feerateTier && (params.feerateTier = 'halfHourFee');
    const url = prefixV2 + 'ord/btc/offers/create';

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 发送offer
 * @param params
 * @returns {Promise<unknown>}
 */
export async function offerCreate(params){
    if(!params.tokenId || !params.price){
        return null;
    }
    if(!params.signedPSBTBase64){
        return null;
    }
    if(!params.buyerReceiveAddress || !params.buyerPaymentAddress || !params.buyerPaymentPublicKey){
        return null;
    }
    if(!params.expirationDate){
        params.expirationDate = new Date().getTime() + 7*3600*24*1000;
    }
    !params.feerateTier && (params.feerateTier = 'halfHourFee');
    const url = prefixV2 + 'ord/btc/offers/create';

    return request.post({ url, target: 'fireBot', params });
}

/**
 * 预备取消offer
 * @param params
 * @returns {Promise<unknown>}
 */
export async function preOfferCancel(params){
    if(!params.offerId){
        return null;
    }
    const url = prefixV2 + 'ord/btc/offers/cancel';

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 取消offer
 * @param params
 * @returns {Promise<unknown>}
 */
export async function offerCancel(params){
    if(!params.offerId){
        return null;
    }
    if(!params.signedPSBTBase64){
        return null;
    }
    const url = prefixV2 + 'ord/btc/offers/cancel';

    return request.post({ url, target: 'fireBot', params });
}

export async function getActivities(params) {
    const url = prefixV2 + 'ord/btc/activities';
    !params.status && (params.status = 'valid');
    !params.limit && (params.limit = 100);
    !params.offset && (params.offset = 0);

    const queryString = Object.entries(params)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                // 如果值是数组，则使用 kind[] 形式
                return value.map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`).join('&');
            } else {
                // 否则，正常处理键值对
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        })
        .join('&');

    return request.get({ url: url + '?' + queryString, target: 'fireBot' });
}

/**
 * 获取系列offers
 * @param params
 * @returns {Promise<unknown>}
 */
export async function getCollectionOffers(params){
    const url = prefixV2 + 'ord/btc/collection-offers/collection/' + params.symbol + '?sort=priceDesc&status[]=valid&offset=0';

    return request.get({ url, target: 'fireBot' }).then(res => {
        if(!res || !res.data || !res.data.offers){
            return [];
        }
        const offers = [];
        res.data.offers.map(row => {
            if(row.chain === 'bitcoin' && row.status === 'valid'){
                offers.push({
                    symbol: row.collectionSymbol,
                    price: row.price.amount/Math.pow(10, 8),
                    quantity: row.quantity,
                    offerId: row.id,
                    maker: row.maker,
                    expireTime: parseInt(new Date(row.expiresAt).getTime()/1000),
                    updateTime: parseInt(new Date(row.updatedAt).getTime()/1000)
                });
            }
        });
        return offers;
    });
}

/**
 * 获取指定用户的系列offers
 * @param params
 * @returns {Promise<unknown>}
 */
export async function getUserCollectionOffers(params){
    const url = prefixV2 + 'ord/btc/collection-offers/wallet/' + params.address + '?status[]=valid&sort=priceDesc&offset=0';

    return request.get({ url, target: 'fireBot' }).then(res => {
        if(!res || !res.data || !res.data.offers){
            return [];
        }
        const offers = [];
        res.data.offers.map(row => {
            if(row.chain === 'bitcoin' && row.status === 'valid'){
                offers.push({
                    symbol: row.collectionSymbol,
                    price: row.price.amount/Math.pow(10, 8),
                    quantity: row.quantity,
                    offerId: row.id,
                    maker: row.maker,
                    expireTime: parseInt(new Date(row.expiresAt).getTime()/1000),
                    updateTime: parseInt(new Date(row.updatedAt).getTime()/1000)
                });
            }
        });
        return offers;
    });
}

/**
 * 系列offer预处理
 * price 已带精度（上一步已乘以精度）
 * expire: 单位 s
 */
export async function preCollectionOffer(params){
    if(!params.symbol || !params.price){
        return null;
    }
    if(!params.buyerPaymentType || !params.buyerAddress || !params.buyerPublicKey){
        return null;
    }

    params.collectionSymbol = params.symbol;
    delete params.symbol;

    params.priceSats = params.price;
    delete params.price;

    !params.quantity && (params.quantity = 1);

    !params.expire && (params.expire = 10*60);
    params.expirationAt = new Date(new Date().getTime() + params.expire * 1000).toISOString();
    delete params.expire;

    if(params.feeRate){
        params.feeSatsPerVbyte = params.feeRate;
        delete params.feeRate;
    }

    params.makerPaymentType = params.buyerPaymentType;
    delete params.buyerPaymentType;
    params.makerReceiveAddress = params.buyerAddress;
    delete params.buyerAddress;
    params.makerPublicKey = params.buyerPublicKey;
    delete params.buyerPublicKey;

    const url = prefixV2 + 'ord/btc/collection-offers/psbt/create';

    return request.get({ url, target: 'fireBot', params}).then(res => {
        if(res && res.data && res.data.error){
            res.code = 0;
            res.msg = res.data.error;
            return res;
        } else if(res && res.data && res.data.offers && res.data.offers[0]){
            res.data = res.data.offers[0]
            return res;
        }
        return null;
    });
}

export async function sendCollectionOffer(params){
    const url = prefixV2 + 'ord/btc/collection-offers/psbt/create';

    return request.post({ url, target: 'fireBot', params });
}

/**
 * 发送系列offer
 * @param symbol
 * @param price 不需要带精度
 * @param currentAccount
 * @param currentPublicKey
 * @returns {Promise<{msg, code: number}|{msg: string, code: number}>}
 */
export async function makeCollectionOffer(symbol, price, currentAccount, currentPublicKey, expire = 3600, useSigner = false) {
    if(!price){
        return { code: 0, msg: 'Invalid price!' };
    }
    const _price = price;
    price = Math.round(price * Math.pow(10, 8));
    const buyerPaymentType = 'p2tr';
    const params = {
        symbol,
        price,
        quantity: 1,
        expire,
        feeRate: 15,
        buyerPaymentType,
        buyerAddress: currentAccount,
        buyerPublicKey: currentPublicKey
    };
    try {
        const res = await preCollectionOffer(params);
        if(res && res.error){
            return { code: 0, msg: res.error };
        }
        if(!res || !res.data || !res.data.psbtBase64){
            return { code: 0, msg: res && res.msg ? res.msg : 'Invalid psbt!' };
        }
        const psbt = bitcoin.Psbt.fromBase64(res.data.psbtBase64);
        const psbtCancel = bitcoin.Psbt.fromBase64(res.data.cancelPsbtBase64);

        let signedPSBTBase64 = null, cancelSignedPSBTBase64 = null;
        if(useSigner){
            // sign offer
            const signedHex = await toSignPsbt(psbt.toHex(), {
                autoFinalized: false,
                msg: `创建${symbol}系列offer -> ${_price}`
            });
            signedPSBTBase64 = bitcoin.Psbt.fromHex(signedHex).toBase64();
            // sign cancel
            const cancelSignedHex = await toSignPsbt(psbtCancel.toHex(), {
                autoFinalized: false,
                msg: `创建${symbol}系列offer(cancel) -> ${_price}`
            });
            cancelSignedPSBTBase64 = bitcoin.Psbt.fromHex(cancelSignedHex).toBase64();
        } else {
            const signedHex = await window.unisat.signPsbt(psbt.toHex(), {
                autoFinalized: false
            });
            signedPSBTBase64 = bitcoin.Psbt.fromHex(signedHex).toBase64();
            const cancelSignedHex = await window.unisat.signPsbt(psbtCancel.toHex(), {
                autoFinalized: false
            });
            cancelSignedPSBTBase64 = bitcoin.Psbt.fromHex(cancelSignedHex).toBase64();
        }

        // submit
        const now = new Date().getTime();
        const sendData = {
            collectionSymbol: symbol,
            quantity: params.quantity,
            priceSats: price,
            expirationAt: new Date(now + expire * 1000).toISOString(),
            makerPublicKey: currentPublicKey,
            makerPaymentType: buyerPaymentType,
            makerReceiveAddress: currentAccount,
            offers: [{
                signedPsbtBase64: signedPSBTBase64,
                signedCancelPsbtBase64: cancelSignedPSBTBase64
            }]
        };

        const offerRes = await sendCollectionOffer(sendData);
        if(offerRes && offerRes.data && offerRes.data.error){
            offerRes.msg = offerRes.data.error;
        }
        return offerRes;
    } catch (err) {
        return { code: 0, msg: err.message };
    }
}

/**
 * 预备编辑系列offer
 * price 已带精度(上一步已乘过)
 */
async function preEditCollectionOffer(params){
    if(!params.symbol || !params.price || !params.offerId){
        console.log(params.symbol + ' -> preEditCollectionOffer -> 无效的参数');
        return null;
    }
    params.collectionSymbol = params.symbol;
    delete params.symbol;
    params.priceSats = params.price;
    delete params.price;
    params.expirationAt = new Date(params.expire).toISOString();
    delete params.expire;
    params.makerPublicKey = params.pubkey;
    delete params.pubkey;
    params.feeSatsPerVbyte = params.feeRate;
    delete params.feeRate;
    !params.makerPaymentType && (params.makerPaymentType = 'p2tr');

    const url = prefixV2 + 'ord/btc/collection-offers/psbt/edit?offerIds[]=' + params.offerId;
    delete params.offerId;

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 更新系列offer
 * price 不需要带精度
 */
export async function editCollectionOffer(symbol, price, offerId, userAddress, pubkey, expire = 3600, useSigner = false) {
    const _price = price;
    price = Math.round(price * Math.pow(10, 8)); // 乘以精度
    const now = new Date().getTime();
    expire = now + expire * 1000;

    try {
        const res = await preEditCollectionOffer({
            symbol,
            price,
            expire,
            pubkey,
            feeRate: 15,
            offerId
        });
        if(!res || !res.data || !res.data.offers || !res.data.offers[0]){
            console.log(symbol + ' -> ' + price + ' -> preEditCollectionOffer -> 预加载PSBT失败');
            console.log(userAddress);
            console.log(res);
            return { code: 0, msg: res && res.data && res.data.error ? res.data.error : '预加载PSBT失败' };
        }
        const offer = res.data.offers[0];
        const psbt = bitcoin.Psbt.fromBase64(offer.psbtBase64);
        const psbtCancel = bitcoin.Psbt.fromBase64(offer.cancelPsbtBase64);

        let signedPSBTBase64 = null, cancelSignedPSBTBase64 = null;
        if(useSigner){
            // sign offer
            const signedHex = await toSignPsbt(psbt.toHex(), {
                autoFinalized: false,
                msg: `修改${symbol}系列offer -> ${_price}`
            });
            signedPSBTBase64 = bitcoin.Psbt.fromHex(signedHex).toBase64();
            // sign cancel
            const cancelSignedHex = await toSignPsbt(psbtCancel.toHex(), {
                autoFinalized: false,
                msg: `修改${symbol}系列offer(cancel) -> ${_price}`
            });
            cancelSignedPSBTBase64 = bitcoin.Psbt.fromHex(cancelSignedHex).toBase64();
        } else {
            const signedHex = await window.unisat.signPsbt(psbt.toHex(), {
                autoFinalized: false
            });
            signedPSBTBase64 = bitcoin.Psbt.fromHex(signedHex).toBase64();
            const cancelSignedHex = await window.unisat.signPsbt(psbtCancel.toHex(), {
                autoFinalized: false
            });
            cancelSignedPSBTBase64 = bitcoin.Psbt.fromHex(cancelSignedHex).toBase64();
        }

        // 发送请求
        const url = prefixV2 + 'ord/btc/collection-offers/psbt/edit';
        const params = {
            collectionSymbol: symbol,
            expirationAt: new Date(expire).toISOString(),
            makerPublicKey: pubkey,
            offers: [{
                offerId: offer.offerId,
                signedCancelPsbtBase64: cancelSignedPSBTBase64,
                signedPsbtBase64: signedPSBTBase64
            }],
            priceSats: price,
            userPaymentType: 'p2tr'
        };

        return request.post({ url, target: 'fireBot', params }).then(res => {
            if(res && res.data && res.data.error){
                return { code: 0, msg: res.data.error };
            }
            return res;
        });
    } catch (err) {
        return { code: 0, msg: err.message };
    }
}

/**
 * 通过activitys批量获取topOffer
 * @param params
 * @param page
 * @param myAddress
 * @returns {Promise<{}|null>}
 */
export async function getTopOffersByActivities(params, page = 20, myAddress){
    if(!params.collectionSymbol){
        return null;
    }
    params.kind = ['offer_placed', 'offer_cancelled', 'offer_accepted_broadcasted'];
    let activities = {};
    for(let i = 0; i < page; i++){
        params.offset = i * 100;
        const res = await getActivities(params);
        if(!res || !res.data || !res.data.activities){
            break;
        }
        const now = parseInt(new Date().getTime()/1000);
        for(let idx in res.data.activities){
            const item = res.data.activities[idx];
            const createTime = parseInt(Date.parse(item.createdAt)/1000);
            if(item.kind === 'offer_placed' && item.newOwner === myAddress && now - createTime > 3600*24){
                // 过期offer
                continue;
            }
            if(!activities[item.tokenId]){
                activities[item.tokenId] = [];
            }
            const price = item.listedPrice/Math.pow(10, 8);
            activities[item.tokenId].push({
                kind: item.kind,
                inscriptionNumber: item.token.inscriptionNumber,
                price,
                userAddress: item.newOwner,
                createdAt: item.createdAt,
                date: func.getDate(new Date(item.createdAt).getTime())
            });
        }
    }

    const topOffers = {};
    for(let tokenId in activities){
        let rows = activities[tokenId];
        rows.reverse();

        const keysData = {};
        for(let i in rows){
            const item = rows[i];
            if(!keysData[item.userAddress + '_' + item.price]){
                keysData[item.userAddress + '_' + item.price] = [];
            }
            keysData[item.userAddress + '_' + item.price].push(i + ':' + item.kind);
        }

        let topOffer = null;
        for(let k in keysData){
            const keys = keysData[k];
            const lastKey = keys[keys.length - 1];
            const lastKeyArr = lastKey.split(':');
            if(lastKeyArr[1] === 'offer_placed') {
                const lastOffer = rows[lastKeyArr[0]];
                if(!topOffer || lastOffer.price - topOffer.price >= 0){
                    const prevTopOffer = topOffer;
                    if(prevTopOffer && prevTopOffer.userAddress === lastOffer.userAddress){
                        lastOffer.isRepeat = true;
                        lastOffer.repeat = prevTopOffer;
                    }
                    topOffer = lastOffer;
                }
            } else if(lastKeyArr[1] === 'offer_cancelled'){
                continue;
            }
        }

        topOffers[tokenId] = topOffer;
    }

    return topOffers;
}

export function generatorToSignInputs(signature, types = null){
    const toSignInputs = signature.flatMap(item => {
        // 确保 signingIndexes 不是 null 或 undefined
        let indexes, arr;
        return null !== (arr = null === (indexes = item.signingIndexes) || void 0 === indexes ? void 0 : indexes.map(idx => ({
            index: idx,
            address: item.address,
            sighashTypes: types ? types : [ bitcoin.Transaction.SIGHASH_DEFAULT, bitcoin.Transaction.SIGHASH_ALL ]
        }))) && void 0 !== arr ? arr : [];
    });

    return toSignInputs;
}

/**
 * 发送offer
 * @param item
 * @param price 不需要带精度
 * @param currentAccount
 * @param currentPublicKey
 * @returns {Promise<{msg, code: number}|{msg: string, code: number}>}
 */
export async function makeOffer(tokenId, price, currentAccount, currentPublicKey) {
    if(!price){
        return { code: 0, msg: 'Invalid price!' };
    }
    try {
        // generate offer data
        const now = new Date().getTime();
        price = price * Math.pow(10, 8);
        const params = {
            tokenId,
            price: Math.round(price),
            buyerTokenReceiveAddress: currentAccount,
            buyerPaymentAddress: currentAccount,
            buyerPaymentPublicKey: currentPublicKey,
            feerateTier: 'halfHourFee',
            expirationDate: now + 3600*24*1000
        };
        const res = await preOfferCreate(params);
        if(res && res.data && res.data.error){
            return { code: 0, msg: res.data.error };
        }
        if(!res || !res.data || !res.data.psbtBase64){
            return { code: 0, msg: 'Invalid psbt!' };
        }
        const signature = [{
            address: currentAccount,
            signingIndexes: res.data.toSignInputs
        }];
        const toSignInputs = generatorToSignInputs(signature);
        // sign
        const psbt = bitcoin.Psbt.fromBase64(res.data.psbtBase64);
        const psbtHex = psbt.toHex();
        const signedHex = await window.unisat.signPsbt(psbtHex, {
            autoFinalized: false,
            toSignInputs
        });
        const signedPSBTBase64 = bitcoin.Psbt.fromHex(signedHex).toBase64();

        // send offer
        const postData = {
            signedPSBTBase64,
            feerateTier: res.data.feerateTier,
            tokenId: res.data.tokenId,
            price: res.data.price,
            expirationDate: res.data.expirationDate,
            buyerPaymentAddress: res.data.buyerPaymentAddress,
            buyerPaymentPublicKey: res.data.buyerPaymentPublicKey,
            buyerReceiveAddress: res.data.buyerReceiveAddress,
        }

        const offerRes = await offerCreate(postData);
        if(offerRes && offerRes.data && offerRes.data.error){
            return { code: 0, msg: offerRes.data.error };
        }
        if(offerRes && offerRes.data && offerRes.data.ok){
            return { code: 200, msg: 'success' };
        }
    } catch (err) {
        return { code: 0, msg: err.message };
    }
}

/**
 * 取消offer
 * @param params
 * @returns {Promise<void>}
 */
export async function cancelOffer(params, currentAccount){
    if(!params.offerId){
        return { code: 0, msg: 'Invalid offerId!' };
    }
    try {
        const res = await preOfferCancel({ offerId: params.offerId });
        if(res && res.data && res.data.error){
            return { code: 0, msg: res.data.error };
        }
        if(!res || !res.data || !res.data.psbtBase64){
            return { code: 0, msg: 'Invalid psbt!' };
        }

        const signature = [{
            address: currentAccount,
            signingIndexes: res.data.toSignInputs
        }];
        const toSignInputs = generatorToSignInputs(signature);
        // sign
        const psbtHex = bitcoin.Psbt.fromBase64(res.data.psbtBase64).toHex();
        const signedHex = await window.unisat.signPsbt(psbtHex, {
            autoFinalized: false,
            toSignInputs
        });
        const postData = {
            offerId: res.data.offerId,
            signedPSBTBase64: bitcoin.Psbt.fromHex(signedHex).toBase64()
        };
        const cancelRes = await offerCancel(postData);
        if(cancelRes && cancelRes.data && cancelRes.data.error){
            return { code: 0, msg: cancelRes.data.error };
        }
        if(cancelRes && cancelRes.data && cancelRes.data.ok){
            return { code: 200, msg: 'success' };
        }
    } catch (err) {
        return { code: 0, msg: err.message };
    }
}

/**
 * 预备扫货
 * @param params
 * price: 带精度
 * @returns {Promise<unknown>}
 */
export async function preListBuy(params){
    if(!params.tokenId || !params.price){
        return null;
    }
    if(!params.buyerTokenReceiveAddress || !params.buyerAddress || !params.buyerPublicKey){
        return null;
    }
    !params.creatorTipsType && (params.creatorTipsType = 'none');
    // !params.feerateTier && (params.feerateTier = 'halfHourFee');
    !params.useUnconfirmedUTXO && (params.useUnconfirmedUTXO = false);
    const url = prefixV2 + 'ord/btc/psbt/buying';

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 执行买入
 */
export async function doBuy(params){
    params.dryRun = false;
    params.walletSource = 'unisat';
    if(!params.signedBuyingPSBTBase64){
        return null;
    }
    const url = prefixV2 + 'ord/btc/psbt/buying';

    return request.post({ url, target: 'fireBot', params });
}

/**
 * 构建psbt
 * @param token
 * @returns {Promise<unknown>}
 */
export async function buildBuyPSBT(buyer, token, feeRate = 30, inputs = [], outputs = []){
    if(!token.input){
        return { code: 0, msg: 'No NFT input!'};
    }
    const params = {};
    params.feeRate = feeRate;
    params.nftInput = token.input;
    if(inputs.length === 0 && outputs.length === 0){
        const res = await generateInputAndOutput(buyer, token);
        if(res && res.code === 0){
            return res;
        }
        inputs = res.inputUtxos;
        outputs = res.outputs;
    }
    if(!inputs || !outputs){
        return { code: 0, msg: 'No inputs!'};
    }

    console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
    console.log(inputs)

    const toSignInputs = [];
    // 获取prevout
    let flag = true;
    const inputTxs = {};
    for(let i = 0; i < inputs.length; i++){
        const utxo = inputs[i];
        if(utxo.finalScriptWitness){
            continue;
        }
        if(utxo.prevout){
            /*if(!utxo.hash){
                inputs[i] = {
                    hash: Buffer.from(utxo.txid, 'hex').reverse(),
                    index: utxo.vout,
                    witnessUtxo: {
                        script: Buffer.from(utxo.prevout.scriptpubkey, 'hex'),
                        value: utxo.prevout.value
                    },
                    sequence: 4294967295
                }
            }*/
            continue;
        }
        if(!utxo.txid){
            continue;
        }
        const txid = utxo.txid;
        let txInfo = null;
        if(!inputTxs[txid]){
            txInfo = await MempoolApi.getTxInfo(txid);
            if(!txInfo){
                flag = false;
                break;
            }
            inputTxs[txid] = txInfo;
        } else {
            txInfo = inputTxs[txid];
        }
        const prevout = txInfo.vout[utxo.vout];
        inputs[i] = {
            hash: Buffer.from(txid, 'hex').reverse(),
            index: utxo.vout,
            witnessUtxo: {
                script: Buffer.from(prevout.scriptpubkey, 'hex'),
                value: prevout.value
            },
            tapInternalKey: Buffer.from(window.currentPubkey.slice(2), 'hex'),
            sequence: 4294967295
        }
        toSignInputs.push(i);
    }
    if(!flag){
        return { code: 0, msg: 'Network error!'};
    }

    const psbt = MempoolUtil.generatePsbt(inputs, outputs);

    return {
        data: {
            psbtBase64: psbt.toBase64(),
            toSignInputs
        }
    };

    /*params.inputs = inputs;
    params.outputs = outputs;
    params.buyer = buyer;
    params.price = token.listedPrice;

    return request.post({url: 'https://brc.ai-dex.online/test/psbt', params});*/
}

async function generateInputAndOutput(buyer, token){
    console.log('kididididdiddi')
    // 查询买家的utxos
    const utxosRes = await MempoolApi.getAvailableUtxos(buyer);
    if(!utxosRes || !utxosRes.data || !utxosRes.data.utxos || utxosRes.data.utxos.length === 0){
        return { code: 0, msg: 'No utxos!'};
    }
    const utxos = utxosRes.data.utxos;
    // 统计utxos
    const inputData_600 = [], inputData_1200 = [], inputData_price_10000 = [], inputData_price = [], utxo600TxsCount = {};
    for(let i = 0; i < utxos.length; i++){
        const utxo = utxos[i];
        if(utxo.value === 600){
            inputData_600.push(utxo);
            if(!utxo600TxsCount[utxo.txid]){
                utxo600TxsCount[utxo.txid] = 0;
            }
            utxo600TxsCount[utxo.txid]++;
        } else if(utxo.value === 1200){
            inputData_1200.push(utxo);
        } else if(utxo.value > 10000) {
            // 限制大于10000的可以用于支付费用
            inputData_price_10000.push(utxo);
        } else {
            inputData_price.push(utxo);
        }
    }
    // 构建输入和输出
    let inputUtxos = [], outputs = [];
    // token输出
    outputs.push({
        address: buyer,
        value: token.input.prevout.value
    });

    // 600输入输出
    if(inputData_600.length >= 2){
        // 来自某个tx的600 utxo够2个，直接用这两个
        let utxoTxid = null;
        for(let i in inputData_600){
            const utxo = inputData_600[i];
            if(utxoTxid && utxo.txid === utxoTxid){
                inputUtxos.push(utxo);
                break;
            }
            if(utxo600TxsCount[utxo.txid] >= 2 && !utxoTxid){
                utxoTxid = utxo.txid;
                inputUtxos.push(utxo);
            }
        }
        // 来自单个tx的600 utxo不够2个，取前两个600utxo
        if(inputUtxos.length === 0){
            inputUtxos.push(inputData_600[0]);
            inputUtxos.push(inputData_600[1]);
        }
        outputs.push({
            address: buyer,
            value: 1200
        });
        outputs.push({
            address: buyer,
            value: 600
        });
        outputs.push({
            address: buyer,
            value: 600
        });
    } else if(inputData_600.length === 1) {
        // 只有一个600
        if(inputData_1200.length >= 1){
            inputUtxos.push(inputData_600[0]);
            inputUtxos.push(inputData_1200[0]);
            outputs.push({
                address: buyer,
                value: 1800
            });
            outputs.push({
                address: buyer,
                value: 600
            });
            outputs.push({
                address: buyer,
                value: 600
            });
        } else {
            inputUtxos.push(inputData_600[0]);
            outputs.push({
                address: buyer,
                value: 600
            });
            outputs.push({
                address: buyer,
                value: 600
            });
        }
    }
    // 构建token输入
    const witnessStack = token.input.witness.map(hex => Buffer.from(hex, 'hex'));
    console.log(token)
    inputUtxos.push({
        hash: Buffer.from(token.input.txid, 'hex').reverse(),
        index: token.input.vout,
        witnessUtxo: {
            script: Buffer.from(token.input.prevout.scriptpubkey, 'hex'),
            value: token.input.prevout.value
        },
        finalScriptWitness: MempoolUtil.witnessStackToScriptWitness(witnessStack)
    });
    // 构建付款utxos
    let _inputV = 0, _outputV = 0, surplusPrice = 0;
    inputUtxos.map(r => { _inputV += r.value ? r.value : 0; });
    outputs.map(r => { _outputV += r.value; });

    surplusPrice = _outputV - _inputV;
    // 用于付款的utxo
    const defaultFee = 200000; // 预估的fee，不是实际使用的fee
    const payPrice = Math.round(token.listedPrice * 1.02); // 买家需要支付的费用
    inputData_price_10000.sort((a, b) => a.value - b.value);
    const inputPriceUtxos = getPriceUtxos(inputData_price_10000, payPrice + defaultFee);
    let inputedPrice = 0;
    inputPriceUtxos.map(utxo => {
        inputedPrice += utxo.value;
    });
    if(inputedPrice - (payPrice + defaultFee) < 0){
        return { code: 0, msg: 'Insufficient balance!' };
    }
    inputUtxos = inputUtxos.concat(inputPriceUtxos);
    // 付给卖家
    outputs.push({
        address: token.listedSellerReceiveAddress,
        value: token.listedPrice * 0.995 + token.input.prevout.value // 从卖家扣千分之5，并补偿nft的价值
    });
    // 找零
    outputs.push({
        address: buyer,
        value: inputedPrice - payPrice - token.input.prevout.value - surplusPrice
    });

    return { inputUtxos, outputs };
}

/**
 * 接offer准备
 * price: 不带精度
 */
export async function preAcceptOffer(params){
    if(!params.tokenId || !params.offerId || !params.price){
        return null;
    }
    if(!params.sellerOrdinalsAddress || !params.sellerReceiveAddress){
        return null;
    }
    params.offerPrice = Math.round(params.price * Math.pow(10, 8));
    delete params.price;

    const url = prefixV2 + 'ord/btc/offers/accept';

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 执行接offer
 * price: 不带精度
 */
export async function acceptOffer(params){
    if(!params.tokenId || !params.offerId || !params.price){
        return null;
    }
    if(!params.sellerOrdinalsAddress || !params.sellerReceiveAddress || !params.sellerOrdinalsPublicKey){
        return null;
    }
    if(!params.signedPSBTBase64){
        return null;
    }
    params.offerPrice = Math.round(params.price * Math.pow(10, 8));
    delete params.price;

    const url = prefixV2 + 'ord/btc/offers/accept';

    return request.post({ url, target: 'fireBot', params });
}

/**
 * 预备挂单
 * price 已带精度(上一步已乘过)
 */
async function preListToken(tokenId, price, sellerReceiveAddress, pubkey){
    const url = prefixV2 + 'ord/btc/psbt/get_batch_listing';

    const postData = {
        listings: [{
            listedForMint: false,
            price,
            publicKey: pubkey,
            sellerReceiveAddress,
            tokenId
        }]
    };

    return request.post({ url, target: 'fireBot', params: postData }).then(res => {
        return res && res.data ? res.data : null
    });
}

/**
 * 挂单
 * @param tokenId
 * @param price 不需要带精度
 * @param userAddress
 * @param pubkey
 * @returns {Promise<* | null>}
 */
export async function listToken(tokenId, price, userAddress, pubkey, sellerReceiveAddress = null){
    price = Math.round(price * Math.pow(10, 8)); // 乘以精度
    const res = await preListToken(tokenId, price, sellerReceiveAddress ? sellerReceiveAddress : userAddress, pubkey);
    if(!res || !res.results){
        return;
    }
    const signature = [{
        address: userAddress,
        signingIndexes: res.toSignInputs
    }];
    const toSignInputs = generatorToSignInputs(signature, [ res.toSignSigHash ]);
    // const toSignInputs = generatorToSignInputs(signature, [ bitcoin.Transaction.SIGHASH_ANYONECANPAY, bitcoin.Transaction.SIGHASH_SINGLE ]);
    // sign
    const psbtHex = bitcoin.Psbt.fromBase64(res.unsignedCombinedPSBTBase64).toHex();
    const signedHex = await window.unisat.signPsbt(psbtHex, {
        autoFinalized: false,
        toSignInputs
    });
    const signedPSBTBase64 = bitcoin.Psbt.fromHex(signedHex).toBase64();

    const postData = {
        listings: res.results,
        signedCombinedPSBTBase64: signedPSBTBase64
    };

    const url = prefixV2 + 'ord/btc/psbt/batch_listing';

    return request.post({ url, target: 'fireBot', params: postData }).then(res => {
        return res && res.data ? res.data : null
    });
}

/**
 * 获取用于付款的utxos
 * @param utxos
 * @param totalPrice
 * @param inputPriceUtxos
 * @returns {*[]|*}
 */
function getPriceUtxos(utxos, totalInput, inputPriceUtxos = []){
    let inputedPrice = 0;
    inputPriceUtxos.map(utxo => {
        inputedPrice += utxo.value;
    });
    let ok = false;
    for(let i in utxos){
        const utxo = utxos[i];
        if(utxo.value - (totalInput - inputedPrice) > 0){
            inputPriceUtxos.push(utxo);
            ok = true;
            break;
        }
    }
    if(ok || utxos.length === 0){
        return inputPriceUtxos;
    } else {
        inputPriceUtxos.push(utxos[utxos.length - 1]);
        utxos.pop();
        return getPriceUtxos(utxos, totalInput, inputPriceUtxos);
    }
}

export async function callPhone(params){
    const url = 'https://uni.apistd.com/?action=sms.voice.verification.send&accessKeyId=SC7mszYr1PgUiU3M8zMveRzRscUudSF53ieWznh1sAg3YAZpx';
    return request.post({ url, target: 'fireBot', params });
}

export async function notifyMail(params = {}){
    const url = 'https://brc.ai-dex.online/notify/mail';
    return request.get({ url, params });
}

/** ================ RUNES ================ **/

/**
 * 获取有pending的符文列表
 * @returns {Promise<void>}
 */
export async function getRunesHasPending(){
    const url = 'https://brc.ai-dex.online/runes/has-pending';

    return request.get({ url });
}

/**
 * 获取符文orders
 */
export async function getRuneOrders(params){
    if(!params.rune){
        return null;
    }
    params.side = 'sell';
    !params.sort && (params.sort = 'unitPriceAsc');
    !params.limit && (params.limit = 100);
    !params.offset && (params.offset = 0);
    params.includePending === undefined && (params.includePending = true);

    const url = prefixV2 + 'ord/btc/runes/orders/' + params.rune;

    return request.get({ url, target: 'fireBot', params });
}

/**
 * 使用签名器签名
 * @param psbtHex
 * @param data
 * @returns {Promise<unknown>}
 */
export function toSignPsbt(psbtHex, options = {}){
    options.psbtHex = psbtHex;
    const key = Crypto.MD5(JSON.stringify(options)).toString();
    options.type = 'sign';
    options.key = key;
    options.timestamp = Math.round(new Date().getTime()/1000);

    const url = 'http://127.0.0.1:' + (window.firebotClientConfig ? window.firebotClientConfig.port : '') + '/sign/psbt';
    return request.post({ url, target: 'fireBot', key, params: options }).then(res => {
        if(res && res.data && res.data.signedPsbtHex){
            return res.data.signedPsbtHex;
        }
        return null;
    });

    // 插件版
    /*return new Promise(function(resolve, reject) {
        const timer = setTimeout(() => {
            eventBus.$off('RES_' + key);
            reject({ code: 0, msg: 'Request Timeout!' });
        }, 30*1000);
        eventBus.$once('RES_' + key, res => {
            clearTimeout(timer);
            if(res && res.data && res.data.signedPsbtHex){
                resolve(res.data.signedPsbtHex)
            } else {
                reject({ code: 0, message: res && res.data && res.data.msg ? res.data.msg : 'Sign fail!' });
            }
        });

        window.postMessage({ action: 'Sign-PSBT', target: 'fireBot', key, options }, window.location.origin);
    });*/
}

export async function getScoreRecord(params){
    return request.post({ action: 'Score-Record', params, target: 'fireBot' });
}