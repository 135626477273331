<template>
  <div class="record-con">
    <div class="tab-menus">
      <div class="tab-item" :class="{'is-active': activeTab === 'tasks'}" @click="onChangeTab('tasks')">{{$t('tabs.tasks')}}</div>
      <div class="tab-item" :class="{'is-active': activeTab === 'orders'}" @click="onChangeTab('orders')">{{$t('tabs.orders')}}</div>
      <div class="tab-item" :class="{'is-active': activeTab === 'trades'}" @click="onChangeTab('trades')">{{$t('tabs.trades')}}</div>
      <div class="tab-item" :class="{'is-active': activeTab === 'account'}" @click="onChangeTab('account')">{{$t('tabs.account')}}</div>
      <div class="flex-1"></div>
      <div class="tab-right-con" v-if="currentSymbol && spotAccount">
        <div class="col">
          {{$t('balance')}}: {{spotAccount.balances[currentSymbol]
            ? parseFloat(spotAccount.balances[currentSymbol].free) + parseFloat(spotAccount.balances[currentSymbol].locked)
            : 0
          }} {{currentSymbol}}
        </div>
        <div class="col">{{$t('cost')}}: {{cost}}</div>
        <div class="col" :class="profit < 0 ? 'text-danger' : 'text-success'">{{$t('profit')}}: {{profit}}%</div>
      </div>
    </div>

    <!-- tasks -->
    <div class="tab-con trade-records" v-if="activeTab === 'tasks'">
      <el-table
          :data="tasks"
          size="small"
          height="100%"
          :highlight-current-row="false"
          :empty-text="$t('noData')"
      >
        <el-table-column
            prop="name"
            label="任务名称"
            width="150">
        </el-table-column>
        <el-table-column
            prop="price"
            label="触发价格"
            width="100">
        </el-table-column>
        <el-table-column
            prop="amount"
            label="金额"
            width="150">
          <template v-slot="scope">
            <div>{{scope.row.amount}}U({{scope.row.quantity}}{{scope.row.symbol.replace('USDT', '')}})</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="price"
            label="SIDE"
            width="100">
          <template v-slot="scope">
            <span :class="scope.row.side === 'SELL' ? 'text-red' : 'text-green'">{{scope.row.side}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="remark"
            label="任务描述">
        </el-table-column>
        <el-table-column
            label="Handle"
            width="180"
        >
          <template v-slot="scope">
            <div class="handle-btns">
              <div class="btn" @click="excuteTask(scope.row)">
                <i class="el-icon-loading" v-if="isExcuting"></i>
                <span>Excute</span>
              </div>
              <div class="btn mgl-10" @click="cancelTask(scope.row, scope.$index)">
                <i class="el-icon-loading" v-if="isCanceling"></i>
                <span>Cancel</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- orders -->
    <div class="tab-con trade-records" v-if="activeTab === 'orders'">
      <el-table
          :data="orders"
          size="small"
          height="100%"
          :highlight-current-row="false"
          :empty-text="$t('noData')"
      >
        <el-table-column
            prop="orderId"
            label="ID"
            width="100">
        </el-table-column>
        <el-table-column
            label="QTY"
            width="100">
          <template v-slot="scope">
            {{parseFloat(scope.row.origQty)}}
          </template>
        </el-table-column>
        <el-table-column
            label="Price"
            width="100">
          <template v-slot="scope">
            {{parseFloat(scope.row.price)}}
          </template>
        </el-table-column>
        <el-table-column
            label="Side"
            width="90">
          <template slot-scope="scope">
            <span :class="scope.row.side ? 'text-success' : 'text-danger'">{{scope.row.side}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Type"
            width="90">
          <template v-slot="scope">
            {{scope.row.type}}
          </template>
        </el-table-column>
        <el-table-column
            label="Date"
            width="150"
        >
          <template v-slot="scope">
            {{$func.getDate(parseInt(scope.row.updateTime/1000))}}
          </template>
        </el-table-column>
        <el-table-column
            label="Handle"
        >
          <template v-slot="scope">
            <div class="handle-btns">
              <div class="btn" @click="cancleOrder(scope.row)">
                <i class="el-icon-loading" v-if="isCanceling"></i>
                <span>Cancel</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- spot account -->
    <div class="tab-con" v-if="activeTab === 'account'">
      <div class="data-lists balances-lists" v-if="spotAccount">
        <div class="data-row thead">
          <div class="td name">Asset</div>
          <div class="td number">Free</div>
          <div class="td number">Locked</div>
        </div>
        <div class="tbody scroll-con">
          <div class="data-row" v-for="(item, idx) in spotAccount.balances" :key="idx">
            <div class="td name">{{idx}}</div>
            <div class="td number">{{parseFloat(item.free)}}</div>
            <div class="td number">{{parseFloat(item.locked)}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- trade records -->
    <div class="tab-con trade-records" v-if="activeTab === 'trades'">
      <el-table
          :data="lists"
          size="small"
          height="100%"
          :highlight-current-row="false"
          :empty-text="$t('noData')"
      >
        <el-table-column
            prop="orderId"
            label="ID"
            width="100">
        </el-table-column>
        <el-table-column
            label="QTY"
            width="100">
          <template v-slot="scope">
            {{parseFloat(scope.row.qty)}}
          </template>
        </el-table-column>
        <el-table-column
            label="Quote QTY"
            width="120">
          <template v-slot="scope">
            {{parseFloat(scope.row.quoteQty)}}
          </template>
        </el-table-column>
        <el-table-column
            label="Price"
            width="100">
          <template v-slot="scope">
            {{parseFloat(scope.row.price)}}
          </template>
        </el-table-column>
        <el-table-column
            label="Side"
            width="100">
          <template slot-scope="scope">
            <span :class="scope.row.isBuyer ? 'text-success' : 'text-danger'">{{scope.row.isBuyer ? 'BUY' : 'SELL'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Date"
            width="160"
        >
          <template v-slot="scope">
            {{$func.getDate(parseInt(scope.row.time/1000))}}
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>

</template>

<script>
import * as binanceApi from "@/api/binance";

export default {
  data() {
    return {
      sg: null,
      currentSymbol: null,
      cost: 0,
      profit: 0,
      activeTab: 'tasks',
      lists: [],
      spotAccount: null,
      orders: [],
      isCanceling: false,
      tasks: [],
      isExcuting: false
    }
  },
  created() {
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      if(!sg || !sg.symbol){
        return;
      }
      if(this.sg && sg.symbol === this.sg.symbol){
        return;
      }
      this.sg && this.$eventBus.$off('CANDLE_DATA_' + this.sg.symbol); // 取消监听旧的
      this.sg = sg;
      this.currentSymbol = sg.symbol.replace('USDT', '');
      this.listenCandle();
      this.initData();
    });
    // 刷新任务列表
    this.$eventBus.$on('REFRESH_TASKS', () => {
      this.getTasks();
    });
  },
  mounted() {
    // 更新订单列表
    this.$eventBus.$on('UPDATE_ORDERS', () => {
      this.getOpenOrders();
      this.getSpotAccount();
    });
    setTimeout(() => {
      this.initData();
    }, 3000);
  },
  methods:{
    listenCandle(){
      this.$eventBus.$on('CANDLE_DATA_' + this.sg.symbol, (candle) => {
        if(this.cost > 0){
          this.profit = parseFloat((candle.close - this.cost)/this.cost * 100).toFixed(2);
        }
      });
    },
    async initData(){
      await this.getTasks();
      await this.getTradeRecords();
      await this.getSpotAccount();
      await this.getOpenOrders();
    },
    onChangeTab(tab){
      this.activeTab = tab;
    },
    /**
     * 任务
     */
    getTasks(){
      if(!this.sg){
        return;
      }
      this.tasks = [];
      const waitSendOrders = localStorage.getItem('WAIT_SEND_ORDERS');
      const tasks = waitSendOrders ? JSON.parse(waitSendOrders) : [];
      tasks.map(item => {
        if(item.symbol === this.sg.symbol){
          this.tasks.push(item);
        }
      });
    },
    /**
     * 交易记录
     * @returns {Promise<void>}
     */
    async getTradeRecords(){
      const res = await binanceApi.tradeRecords(this.sg.symbol);
      if(!res || res.code <= 0 || !res.data){
        return;
      }
      res.data.reverse();
      this.lists = res.data;
    },
    /**
     * 账户详情
     * @returns {Promise<void>}
     */
    async getSpotAccount(){
      const res = await binanceApi.accountInfo();
      const accountInfo = res && res.data ? res.data : {};
      const balances = {};
      for(let i in accountInfo.balances){
        balances[accountInfo.balances[i].asset] = accountInfo.balances[i];
      }
      accountInfo.balances = balances;
      this.spotAccount = accountInfo;
      this.$eventBus.$emit('SPOT_ACCOUNT', accountInfo);
      this.cost = await this.cauCost(this.currentSymbol);
      const dailyStats = await binanceApi.dailyStats(this.sg.symbol);
      if(dailyStats && dailyStats.lastPrice){
        this.profit = this.cost ? parseFloat((dailyStats.lastPrice - this.cost)/this.cost * 100).toFixed(2) : 0;
      }
    },
    /**
     * 所有订单
     * @returns {Promise<void>}
     */
    async getOpenOrders(){
      const res = await binanceApi.openOrders(this.sg.symbol);
      if(!res || res.code <= 0 || !res.data){
        return;
      }
      this.orders = res.data;
      this.$eventBus.$emit('SPOT_ORDERS', this.orders);
    },
    /**
     * 取消挂单
     * @returns {Promise<void>}
     */
    async cancleOrder(row){
      if(this.isCanceling){
        return;
      }
      this.isCanceling = true;
      const orderId = row.orderId;
      console.log(orderId)
      const res = await binanceApi.cancleOrder(this.sg.symbol, orderId);
      this.isCanceling = false;
      if(res && res.data && res.data.status === 'CANCELED'){
        this.$message.success('Order has been canceled.');
        this.$eventBus.$emit('CANCEL_SPOT_ORDER', row);
        await this.getOpenOrders();
        await this.getSpotAccount();
      } else {
        this.$message.error('Operation failed.');
      }
    },
    /**
     * 计算持仓成本
     */
    async cauCost(symbol){
      const asset = this.spotAccount ? this.spotAccount.balances[symbol] : null;
      if(!asset){
        return 0;
      }
      const balance = parseFloat(asset.free) + parseFloat(asset.locked);
      if(balance == 0){
        return 0;
      }
      const res = await binanceApi.allOrders(this.sg.symbol);
      if(!res || res.code <= 0 || !res.data){
        return 0;
      }
      res.data.reverse();
      let totalBuy = 0, avePrice = 0;
      for(let i in res.data){
        const item = res.data[i];
        if(item.status !== 'FILLED'){
          continue;
        }
        if(item.side === 'BUY'){
          totalBuy += parseFloat(item.executedQty);
          avePrice = item.price;
        } else if(item.side === 'SELL') {
          totalBuy -= parseFloat(item.executedQty);
        }
        const percent = parseFloat(totalBuy/balance).toFixed(2);
        if(1 - percent < 0.5 && 1 - percent >= 0){
          break;
        }
      }
      return parseFloat(avePrice);
    },
    /**
     * 立即执行任务
     * @param row
     */
    excuteTask(row){

    },
    /**
     * 取消任务
     * @param row
     */
    cancelTask(row, idx){
      this.tasks.splice(idx, 1);
      const waitSendOrders = localStorage.getItem('WAIT_SEND_ORDERS');
      const tasks = waitSendOrders ? JSON.parse(waitSendOrders) : [];
      for(let i = 0; i < tasks.length; i++){
        const item = tasks[i];
        if(
            item.symbol === row.symbol
            && item.side === row.side
            && item.price - row.price === 0
            && item.amount - row.amount === 0
            && item.name === row.name
            && item.quantity - row.quantity === 0
        ){
          tasks.splice(idx, 1);
        }
      }
      localStorage.setItem('WAIT_SEND_ORDERS', JSON.stringify(tasks));
      this.getTasks();
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars";
@import "../../../assets/css/dark";

.record-con {
  max-width: 100%;
  height: calc(100% - @mg-height);
  overflow-x: hidden;
  background-color: @section-bg-color;
  padding: 16px;
  border-radius: 16px;
}

.tab-con{
  background-color: @content-bg-color;
  border-radius: 0 16px 16px 16px;
  height: calc(100% - 36px);
  padding: @mg;
  box-sizing: border-box;
}
.trade-records {
  width: 100%;

  /deep/.el-table__header{
    width: 100% !important;
  }
  /deep/.el-table__body{
    width: 100% !important;
  }
  /deep/.el-table__cell{
    text-align: left !important;
  }
  .handle-btns{
    display: flex;
    align-items: center;

    .btn{
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid @font-color;
      padding: 0 5px;
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-align: center;

      span{
        display: block;
        min-width: 45px;
      }
      i{
        margin-right: 2px;
      }
    }
  }
}

.balances-lists{
  height: calc(100%);
  .tbody {
    height: calc(100% - 22px);
  }
  .td{
    width: 80px;
  }
}

@media only screen and (max-width: 750px) {

}
</style>