import Vue from 'vue'
import App from './App.vue'
import router from '../router';

import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/css/reset.less";
import "@/assets/css/public.less";

Vue.use(dataV)
Vue.use(ElementUI);

Vue.config.productionTip = false;

import { eventBus } from '@/utils/eventBus.js';
Vue.prototype.$eventBus = eventBus;

import * as func from "@/utils/func";
Vue.prototype.$func = func;

import * as common from "@/utils/common";
Vue.prototype.$com = common;

import globalData from "@/globalData";
Vue.prototype.$global = globalData;

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const currentLanguage = localStorage.getItem('CURRENT_LANGUAGE');
if(!currentLanguage){
    localStorage.setItem('CURRENT_LANGUAGE', 'en');
}
const i18n = new VueI18n({
    locale: currentLanguage ? currentLanguage : 'en',
    fallbackLocale: 'en',
    messages: {
        'zh': Object.assign(require('@/language/cn'), zhLocale),
        'en': Object.assign(require('@/language/en'), enLocale),
    }
});

// init data socket
import dataSocket from '@/sockets/data.socket.js';
dataSocket.init((socket) => {
    globalData.dataSocket = socket;
});

// speak
let speakTimer = null;
Vue.prototype.$speak = (msg) => {
    return new Promise((resolve, reject) => {
        try {
            speakTimer && clearTimeout(speakTimer);
            const synth = window.speechSynthesis;
            const voices = {};
            synth.getVoices().map(row => {
                !voices[row.lang] && (voices[row.lang] = []);
                voices[row.lang].push(row);
            });
            const utterance = new SpeechSynthesisUtterance(msg);
            if(voices['zh-CN'] && voices['zh-CN'].length > 0){
                utterance.voice = voices['zh-CN'][Math.floor(Math.random() * voices['zh-CN'].length)];
            }
            utterance.onend = resolve;
            utterance.onerror = reject;
            synth.speak(utterance);
            speakTimer = setTimeout(() => {
                speakTimer && clearTimeout(speakTimer);
                reject('Speak timeout!');
            }, 30*1000);
        } catch (err) {
            console.log(err);
            reject();
        }
    });
}

const app = new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');

import message from "@/utils/message";
message(app);