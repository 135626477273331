<template>
  <div class="section-con">
    <div class="floor-price">
      <span>ME Floor: {{$com.fixed(floorPrice, 3)}} sats/{{runeInfo.symbol}}</span>
      <span>Count: {{lists.length}}</span>
    </div>
    <div class="token-lists scroll-con">
      <i class="el-icon-loading loading" v-if="lists === null || showLoading"></i>
      <div class="token-item" v-for="(item, idx) in lists" :key="idx" v-else>
        <div class="amount">{{item.formattedAmount}} <span class="mark">{{runeInfo.symbol}}</span></div>
        <div class="price mgt-16">Price: {{ item.formattedUnitPrice }} <span class="mark">sats/{{runeInfo.symbol}}</span></div>
        <div class="sub-price">≈ ${{ btcUsd ? $com.fixed(item.formattedUnitPrice/Math.pow(10, 8)*btcUsd, 6) : '-' }}</div>
        <div class="price mgt-16">Total: {{ item.price/Math.pow(10, 8) }} <span class="mark">BTC</span></div>
        <div class="sub-price">≈ ${{ btcUsd ? $com.fixed(item.price/Math.pow(10, 8)*btcUsd, 2) : '-' }}</div>
        <div class="vs-me mgt-16" :class="floorPrice - item.formattedUnitPrice > 0 ? 'text-green' : 'text-red'">
          vs ME: {{floorPrice - item.formattedUnitPrice > 0 ? '+' : ''}}{{parseFloat((floorPrice - item.formattedUnitPrice)/item.formattedUnitPrice*100).toFixed(2)}}%
        </div>
        <el-button size="mini" type="success" plain round class="fire-btn mgt-16" @click="goto(item)">狙击</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";
import * as mempoolApi from "@/api/mempool";

export default {
  props: ['rune', 'runeInfo', 'btcUsd'],
  data(){
    return {
      lists: [],
      floorPrice: null,
      showLoading: false
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData(){
      this.showLoading = true;
      while(true){
        this.floorPrice = this.runeInfo.floorUnitPrice;
        await this.loadLists();
        this.showLoading = false;
        await this.$func.sleep(10*1000);
      }
    },
    async loadLists(){
      const res = await magicApi.getRuneOrders({
        rune: this.rune,
        limit: 1000
      });
      if(res && res.data && res.data.orders){
        const tokens = [];
        for(let i in res.data.orders){
          const token = res.data.orders[i];
          if(token.isPending){
            tokens.push(token);
          }
        }
        this.lists = tokens;
      }
    },
    async goto(item){
      const getSellerSend = (tx) => {
        let sellerSend = null;
        for(let j in tx.vin){
          const vin = tx.vin[j];
          if(vin.prevout.scriptpubkey_address === item.maker){
            sellerSend = vin.prevout.value;
            break;
          }
        }
        return sellerSend;
      };
      let txid = null;
      const res = await mempoolApi.getPendingTxsByAddress(item.maker);
      let outputValue = null;
      if(res && res.length === 1){
        txid = res[0].txid;
        outputValue = getSellerSend(res[0]);
      } else if(res && res.length > 1){
        for(let i in res){
          const tx = res[i];
          let sellerReceived = 0, sellerSend = 0;
          sellerSend = getSellerSend(tx);
          for(let j in tx.vout){
            const out = tx.vout[j];
            if(out.scriptpubkey_address === item.makerReceiveAddress){
              // 卖家收款
              sellerReceived = out.value;
            }
          }
          if(sellerReceived - sellerSend - item.price === 0){
            txid = tx.txid;
            outputValue = sellerSend;
            break;
          }
        }
      }
      item.outputValue = outputValue;
      item.imageURI = this.runeInfo.imageURI;
      item.runeId = this.runeInfo.runeId;
      item.runeNumber = this.runeInfo.number;
      item.runeName = this.runeInfo.name;
      item.runeSymbol = this.runeInfo.symbol;
      item.floorUnitPrice = this.runeInfo.floorUnitPrice;


      console.log(this.runeInfo)
      console.log(item)
      console.log(res)
      console.log(txid);

      if(!txid) {
        this.$message.error('未查询到Pending交易信息，预计已出块！');
        return;
      }

      this.$router.replace('tools?action=panic-pool&txid=' + txid + '&rune=' + JSON.stringify(item));
      location.reload();
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.floor-price {
  font-size: 14px;
  color: @light-color;
  padding-bottom: 8px;
  span {
    padding-right: @mg;
  }
}
.token-lists {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin:  0 -8px;
  height: 520px;
}
.token-item {
  width: 184px;
  min-height: 220px;
  max-height: 220px;
  text-align: center;
  margin: 8px;
  background-color: #1B1B1B;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;

  .cover {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    border: none;
    overflow: hidden;
  }
  .amount {
    font-size: 15px;
    color: @font-color;
    margin-top: 10px;
  }
  .name {
    font-size: 12px;
    color: @font-color;
    margin-top: 5px;
  }
  .price {
    font-size: 13px;
    color: @light-color;
  }
  .sub-price {
    font-size: 12px;
    color: @sub-font-color;
    margin-top: 5px;
  }
  .vs-me {
    font-size: 13px;
  }
  .fire-btn {
    margin-bottom: 5px;
    width: 150px;
  }
  .el-button--mini{
    padding: 5px 10px;
  }
  .mark {
    font-size: 12px;
    color: @sub-font-color;
  }
}
</style>