import request from '@/utils/request.js';

const prefix = 'https://api.ai-dex.online/';

export async function analysisLists(params) {
    return request.get({ url: prefix + 'notify/singals-analysis', params })
}

export async function lists(params) {
    return request.get({ url: prefix + 'singals/lists', params })
}