<template>
  <div>
    <el-drawer
        title="任务中心"
        :visible.sync="showTaskDrawer"
        direction="rtl"
        class="task-drawer"
    >
      <!-- 系列offer任务 -->
      <div class="section-title">
        <div class="title">自动发系列Offer任务配置</div>
      </div>
      <div class="task-lists tasks-con scroll-con">
        <div class="task-row thead">
          <div class="name">系列</div>
          <div class="number">Floor Price</div>
          <div class="number">Top Offer</div>
          <div class="number">最小差价</div>
          <div class="number">加价幅度</div>
          <div class="number"></div>
        </div>
        <div class="task-row" v-for="(item, symbol) in collectionOffersTaskConfig" :key="symbol">
          <div class="name">{{ symbol }}</div>
          <div class="number">{{ floorPrices[symbol] ? floorPrices[symbol].me_floorPrice : '-' }}</div>
          <div class="number">{{ topCollectionsOffers[symbol] || '-' }}</div>
          <div class="number">{{ item.diffPrice }}</div>
          <div class="number">{{ item.stepPrice }}</div>
          <div class="number">
            <div class="text-red pointer fs-12" @click="cancelTask(item, symbol, 'offer')">
              <i class="el-icon-close text-red pointer"></i> <span>取消</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 挂单任务 -->
      <div class="section-title">
        <div class="title">自动挂单任务配置</div>
      </div>
      <div class="task-lists tasks-con scroll-con">
        <div class="task-row thead">
          <div class="name">系列</div>
          <div class="number">Floor Price</div>
          <div class="number">Top Offer</div>
          <div class="number">目标位置</div>
          <div class="number">最低挂单价</div>
          <div class="number"></div>
        </div>
        <div class="task-row" v-for="(item, symbol) in listTokenTaskConfig" :key="symbol">
          <div class="name">{{ symbol }}</div>
          <div class="number">{{ floorPrices[symbol] ? floorPrices[symbol].me_floorPrice : '-' }}</div>
          <div class="number">{{ topCollectionsOffers[symbol] || '-' }}</div>
          <div class="number">{{ item.targetPos }}</div>
          <div class="number">{{ item.minPrice }}</div>
          <div class="number">
            <div class="text-red pointer fs-12" @click="cancelTask(item, symbol, 'list')">
              <i class="el-icon-close text-red pointer"></i> <span>取消</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section-title">
        <div class="title">自己发出的offer</div>
        <div class="handles-con">
          <i :class="isRunningTasks ? 'el-icon-video-pause' : 'el-icon-video-play'" @click="startTasks(!isRunningTasks)"></i>
          <span class="pdl-5" v-if="isRunningTasks">Running...</span>
          <el-checkbox v-model="useSigner" class="mgl-10">使用签名器</el-checkbox>
        </div>
      </div>

      <div class="task-lists queue scroll-con">
        <div class="thead task-row">
          <div class="w-150px">系列</div>
          <div class="w-150px">价格</div>
          <div class="w-100px">数量</div>
          <div class="w-200px">用户地址</div>
          <div class="w-200px">过期时间</div>
          <div class="w-200px">更新时间</div>
        </div>
        <div
            class="task-row"
            v-for="(item, idx) in myCollectionsOffer"
            :key="idx"
        >
          <div class="w-150px">{{item.symbol}}</div>
          <div class="w-150px">{{item.price}}<span class="mark">BTC</span></div>
          <div class="w-100px">{{item.quantity}}</div>
          <div class="w-200px">{{$com.formatString(item.maker, 6, 8)}}</div>
          <div class="w-200px">{{$func.getDate(item.expireTime)}}</div>
          <div class="w-200px">{{$func.getDate(item.updateTime)}}</div>
        </div>
      </div>

      <div class="log-con">日志：{{logMsg}}</div>

    </el-drawer>

    <div class="task-drawer-btn" @click="onShowTaskDrawer">
      <i class="el-icon-odometer"></i>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";
import Crypto from "_crypto-js@4.1.1@crypto-js";
import {getScoreRecord} from "@/api/magic";

const isRunningSymbol = {}; // 是否正在运行某个系列
const lastOffersPrice = {}; // 最近一次报出的价格
const lastListedPrice = {}; // 最近一次挂单价格

export default {
  data() {
    return {
      showTaskDrawer: false,
      currentAccount: window.currentAccount,
      currentPublicKey: window.currentPubkey,
      currentBalance: null,
      // 系列offer配置
      collectionOffersTaskConfig: {},
      // 挂单配置
      listTokenTaskConfig: {},
      // 地板价
      floorPrices: {},
      topCollectionsOffers: {},
      myCollectionsOffer: {},
      isRunningTasks: false,
      useSigner: false,
      logMsg: '',
      waitDestroy: null,
      // 分数变化记录
      scoreChangeRecord: []
    };
  },
  mounted() {
    // 当前登录账号
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.currentAccount = account.address;
      this.currentPublicKey = account.pubkey;
      this.useSigner = !!window.firebotClient;
    });
    // 轮询配置
    this.loopTaskConfig();
    // 轮询余额
    this.loopAccountBalance();

    this.loopScoreRecord();
  },
  beforeDestroy() {
    this.waitDestroy = true;
  },
  methods: {
    onShowTaskDrawer(){
      this.showTaskDrawer = true;
    },
    /**
     * 轮询配置参数
     * @returns {Promise<void>}
     */
    async loopTaskConfig(){
      while(true){
        if(this.waitDestroy) break;
        if(!this.currentAccount){
          await this.$func.sleep(1000);
          continue;
        }
        // 系列offer任务配置
        let taskConfig = window.localStorage.getItem('TASK_AutoCollectionOffers_' + this.currentAccount);
        this.collectionOffersTaskConfig = taskConfig ? JSON.parse(taskConfig) : {};
        // token挂单任务配置
        taskConfig = window.localStorage.getItem('TASK_AutoListToken_' + this.currentAccount);
        this.listTokenTaskConfig = taskConfig ? JSON.parse(taskConfig) : {};
        await this.$func.sleep(30*1000);
      }
      this.waitDestroy = null;
    },
    async loopScoreRecord(){
      while(true){
        if(this.waitDestroy) break;
        magicApi.getScoreRecord().then(res => {
          this.scoreChangeRecord = res.data && res.data.length > 0 ? res.data.slice(1) : [];
        });
        await this.$func.sleep(5*1000);
      }
      this.waitDestroy = null;
    },
    /**
     * 删除任务
     * @param item
     * @param symbol
     */
    cancelTask(item, symbol, type = null){
      if(type === 'offer'){
        const taskConfigs = this.collectionOffersTaskConfig;
        delete taskConfigs[symbol];
        window.localStorage.setItem('TASK_AutoCollectionOffers_' + this.currentAccount, JSON.stringify(taskConfigs));
        this.$set(this, 'collectionOffersTaskConfig', taskConfigs);
      } else if(type === 'list') {
        const taskConfigs = this.listTokenTaskConfig;
        delete taskConfigs[symbol];
        window.localStorage.setItem('TASK_AutoListToken_' + this.currentAccount, JSON.stringify(taskConfigs));
        this.$set(this, 'listTokenTaskConfig', taskConfigs);
      }
    },
    /**
     * 启动任务
     * @param status
     * @param isForce
     * @returns {Promise<void>}
     */
    async startTasks(status){
      if(!this.currentAccount){
        this.$message.error('Wallet not connect');
        return;
      }
      if(this.isRunningTasks === status){
        return;
      }
      this.isRunningTasks = status;
      localStorage.setItem('IS_RUNNING_TASKS', status);

      if(this.isRunningTasks){


        // 轮询系列tokens并更新地板
        this.loopCollectionsTokens();
        // 轮询系列offers
        this.loopCollectionsOffers();
      }
    },

    /**
     * 加载tokens
     * @returns {Promise<void>}
     */
    async loopCollectionsTokens(){
      while(true){
        let symbols = Object.keys(this.collectionOffersTaskConfig).concat(Object.keys(this.listTokenTaskConfig));
        symbols = Array.from(new Set(symbols));

        for(let i in symbols){
          if(!this.isRunningTasks) break; // 结束
          const symbol = symbols[i];
          // tokens
          let res = null;
          try {
            res = await magicApi.getCollectionTokens({
              collectionSymbol: symbol,
              disablePendingTransactions: true, // 不显示pending的tokens
              limit: 60,
              offset: 0
            });
          } catch (err){}
          if(!res || !res.data || !res.data.tokens) continue;
          if(res.data.tokens.length === 0) continue;
          const tokens = res.data.tokens;
          this.$set(this.floorPrices, symbol, {
            me_floorPrice: tokens[0].listedPrice/Math.pow(10, 8),
            me_floorPrice_update_time: Math.round(new Date().getTime()/1000)
          });
          // 检测并挂单
          this.checkAndList(symbol, tokens);
          await this.$func.sleep(3*1000);
        }
        await this.$func.sleep(10*1000);
        if(!this.isRunningTasks) break; // 结束
      }
    },
    /**
     * 加载系列offers
     * @returns {Promise<void>}
     */
    async loopCollectionsOffers(){
      while(true) {
        for (let symbol in this.collectionOffersTaskConfig) {
          if (!this.isRunningTasks) break; // 结束
          let offers = [];
          try {
            offers = await magicApi.getCollectionOffers({ symbol });
          } catch (err) {}
          if(!offers[0]) continue;
          this.$set(this.topCollectionsOffers, symbol, offers[0].price);
          // 更新任务中我自己的系列offer列表
          const res = {
            topOffer: offers[0],
            offersPrice: []
          };
          for(let i = 0; i < offers.length; i++){
            if(offers[i].maker === this.currentAccount){
              this.$set(this.myCollectionsOffer, symbol, offers[i]);
              res.selfOffer = offers[i];
              if(res.offersPrice.length >= 10) break;
            }
            res.offersPrice.length < 10 && res.offersPrice.push(offers[i].price);
          }
          // 检测并报价
          this.checkAndOffer(res);
          await this.$func.sleep(3*1000);
        }
        await this.$func.sleep(5*1000);
        if(!this.isRunningTasks) break; // 结束
      }
    },
    /**
     * 获取余额
     * @returns {Promise<void>}
     */
    async loopAccountBalance(){
      while(true) {
        const lastBalance = await this.$func.getBalance();
        this.log('最新余额 -> ' + lastBalance);
        if(this.currentBalance !== null && lastBalance && lastBalance - this.currentBalance !== 0){
          this.currentBalance = lastBalance;
          this.$speak('余额发生变化，当前余额' + this.currentBalance);
          // 音乐
          const audio = new Audio(require('@/assets/mp3/2.mp3'));
          audio.play();
          setInterval(() => {
            audio.play();
          }, 30*1000);

          this.log('余额变化 Balance -> ' + this.currentBalance + ' -> ' + lastBalance);
        } else if(this.currentBalance === null && lastBalance) {
          this.currentBalance = lastBalance;
        }
        await this.$func.sleep(20*1000);
      }
    },

    /**
     * 检测并报价
     * @param info
     * @returns {Promise<void>}
     */
    async checkAndOffer(info){
      if(!info.topOffer){
        // 没人报价
        return;
      }
      const app = this;
      const symbol = info.topOffer.symbol;
      const offerConfig = this.collectionOffersTaskConfig[symbol];

      // 检测配置是否有效
      if(!offerConfig || !offerConfig.diffPrice || !offerConfig.stepPrice){
        // 配置无效
        app.log('系列报价: 配置无效 ' + symbol + ' -> top:' + info.topOffer.price, 'error', 1);
        return;
      }

      const now = Math.round(new Date().getTime()/1000);
      // 检测地板价是否有效
      if(
          !this.floorPrices[symbol]
          || !this.floorPrices[symbol].me_floorPrice
          || now - this.floorPrices[symbol].me_floorPrice_update_time > 120
      ){
        // 地板价失效
        app.log('系列报价: 地板价失效' + symbol + ' -> ' + (now - this.floorPrices[symbol].me_floorPrice_update_time), 'error', 1);
        return;
      }

      // 判断是否需要降低offer
      const floorPrice = this.floorPrices[symbol].me_floorPrice;
      const myDiff = info.selfOffer ? Math.round((floorPrice - info.selfOffer.price)*Math.pow(10, 8))/Math.pow(10, 8) : null;

      let targetOfferPrice = null, mode = null;

      if(!info.selfOffer) {
        // 有topOffer，但是没有自己的报价
        targetOfferPrice = parseFloat(info.topOffer.price) + parseFloat(offerConfig.stepPrice);
        targetOfferPrice = Math.round(targetOfferPrice*Math.pow(10, 8))/Math.pow(10, 8);
        mode = 'add';
      } else if(myDiff && myDiff - offerConfig.diffPrice <= 0) {
        // 差价不足，降低offer
        targetOfferPrice = floorPrice - offerConfig.diffPrice;
        targetOfferPrice = Math.round(targetOfferPrice*Math.pow(10, 8))/Math.pow(10, 8);
        // 优化价格
        let nextPrice = null;
        for(let pi in info.offersPrice){
          if(info.offersPrice[pi] - targetOfferPrice <= 0){
            nextPrice = info.offersPrice[pi];
            break;
          }
        }
        const goodPrice = nextPrice ? parseFloat(nextPrice) + parseFloat(offerConfig.stepPrice) : null;
        if(goodPrice){
          targetOfferPrice = Math.min(goodPrice, targetOfferPrice);
        }
        targetOfferPrice = Math.round(targetOfferPrice*Math.pow(10, 8))/Math.pow(10, 8);
        mode = 'update';
        app.log(symbol + ' -> 差价不足降低offer -> self:' + info.selfOffer.price + ' -> to:' + targetOfferPrice, 'warn', 1);
      } else if(info.selfOffer.maker !== info.topOffer.maker){
        // 有topOffer，有自己的报价，且自己不是top
        targetOfferPrice = parseFloat(info.topOffer.price) + parseFloat(offerConfig.stepPrice);
        targetOfferPrice = Math.round(targetOfferPrice*Math.pow(10, 8))/Math.pow(10, 8);
        mode = 'update';
        app.log(symbol + ' -> top不是自己 -> top:' + info.topOffer.price + ' -> self:' + info.selfOffer.price + ' -> to:' + targetOfferPrice, 'warn', 1);
      } else if(info.selfOffer.maker === info.topOffer.maker) {
        // 自己是topOffer
        if(info.selfOffer.price - info.offersPrice[1] - offerConfig.stepPrice > 0){
          targetOfferPrice = parseFloat(info.offersPrice[1]) + parseFloat(offerConfig.stepPrice);
          targetOfferPrice = Math.round(targetOfferPrice*Math.pow(10, 8))/Math.pow(10, 8);
          targetOfferPrice = Math.min(targetOfferPrice, info.selfOffer.price);
          if(targetOfferPrice - info.selfOffer.price >= 0){
            return;
          }
          mode = 'update';
          app.log(symbol + ' -> 自己是top，可下调 -> self:' + info.selfOffer.price + ' -> to:' + targetOfferPrice, 'warn', 1);
        }
      }
      if(!targetOfferPrice){
        return;
      }
      const maxOfferPrice = Math.round((floorPrice - offerConfig.diffPrice)*Math.pow(10, 8))/Math.pow(10, 8);
      if(maxOfferPrice - targetOfferPrice < 0){
        // 不够差价
        app.log(symbol + ' -> 不够差价 -> fp:' + floorPrice + ' -> dp:' + offerConfig.diffPrice + ' -> max:' + maxOfferPrice + ' -> to:' + targetOfferPrice, 'error', 1);
        return;
      }

      if(info.selfOffer && info.selfOffer.price - targetOfferPrice === 0){
        // 自己有相同报价
        app.log(symbol + ' -> 自己有相同报价 -> self:' + info.selfOffer.price + ' -> to:' + targetOfferPrice, 'warn', 1);
        return;
      }
      if(targetOfferPrice - floorPrice >= 0){
        // 报价绝对不能高于地板
        app.log(symbol + ' -> 报价绝对不能高于地板 -> fp:' + floorPrice + ' -> to:' + targetOfferPrice, 'warn', 1);
        return;
      }
      if(!this.currentBalance || targetOfferPrice - this.currentBalance > 0){
        // 余额不足
        app.log('系列报价余额不足: ' + symbol + ' -> ' + mode + ' -> to:' + targetOfferPrice + ' -> b:' + this.currentBalance, 'error', 1);
        return;
      }
      if(
          lastOffersPrice[symbol]
          && lastOffersPrice[symbol].targetOfferPrice - targetOfferPrice === 0
          && now - lastOffersPrice[symbol].time < 10
      ){
        // 10s内发出过相同价格
        app.log(symbol + ' -> 10s内发出过相同价格', 'warn', 1);
        return;
      }
      // 是否正在执行
      if(isRunningSymbol[symbol] && now - isRunningSymbol[symbol] < 10){
        app.log(symbol + ' -> 等待上一次报价执行完成', 'warn', 1);
        return;
      }
      isRunningSymbol[symbol] = now;
      // 执行报价
      app.log('准备执行系列报价: ' + symbol + ' -> ' + mode + ' -> ' + targetOfferPrice, 'warn', 1);
      if(parseFloat(parseFloat(floorPrice - targetOfferPrice).toFixed(8)) - offerConfig.diffPrice >= 0) {
        // 符合差价，执行报价
        lastOffersPrice[symbol] = {
          targetOfferPrice,
          time: now
        };
        const expire = 3600*2;
        try {
          let offerRes = null;
          if(mode === 'add'){
            app.log('发新offer -> ' + symbol + ' -> ' + mode + ' -> ' + targetOfferPrice);
            // 新offer
            offerRes = await magicApi.makeCollectionOffer(symbol, targetOfferPrice, this.currentAccount, this.currentPublicKey, expire, this.useSigner);
          } else if(mode === 'update') {
            app.log('更新offer -> ' + symbol + ' -> ' + mode + ' -> ' + targetOfferPrice);
            // 更新offer
            const offerId = info.selfOffer.offerId;
            offerRes = await magicApi.editCollectionOffer(symbol, targetOfferPrice, offerId, this.currentAccount, this.currentPublicKey, expire, this.useSigner);
          }
          if(offerRes && offerRes.data && offerRes.data.offerIds){
            // 报价成功
            this.myCollectionsOffer[symbol] = {
              symbol,
              price: targetOfferPrice,
              quantity: 1,
              offerId: offerRes.data.offerIds[0],
              maker: this.currentAccount,
              expireTime: now + expire,
              updateTime: now
            };
            app.log('系列报价成功 -> ' + symbol + ' -> ' + targetOfferPrice + ' -> ' + offerRes.data.offerIds[0], 'success', 1);
          } else {
            app.log('系列报价失败 -> ' + symbol + ' -> ' + targetOfferPrice + (offerRes.msg ? ' (' + offerRes.msg + ')' : ''), 'error', 1);
          }
        } catch(err) {
          console.log('系列报价发生错误');
          console.log(err);
        }
      }
      isRunningSymbol[symbol] = 0;
    },
    /**
     * 检测并挂单
     * @param symbol
     * @param tokens
     * @returns {Promise<void>}
     */
    async checkAndList(symbol, tokens){
      if(!this.listTokenTaskConfig[symbol]){
        // 没有挂单配置
        return;
      }
      const config = this.listTokenTaskConfig[symbol];
      if(!config.targetPos || !config.minPrice){
        // 无效的配置
        return;
      }
      // 当前分钟
      const now = new Date().getTime() / 1000;
      const minute = parseInt((now % 3600)/60);
      // 上一次更新
      const lastScore = this.scoreChangeRecord.length > 0 ? this.scoreChangeRecord[this.scoreChangeRecord.length - 1] : null;
      console.log(lastScore)
      const diffTime = lastScore ? parseFloat((now - lastScore.time)/60).toFixed(2) : null;
      console.log('距离上次更新间隔 ' + diffTime + 'm');
      // 获取我的tokens和对应名次
      const myTokens = [], targetPosPrice = {};
      for(let i = 0; i < tokens.length; i++){
        const row = tokens[i];
        if(row.owner === this.currentAccount){
          row.idx = i + 1; // 自己的名次
          if(diffTime && diffTime - 29 >= 0 && diffTime - 32.5 < 0) {
            // 距离上次更新的分钟数 >= 29m 且 < 32m时，且最近一次未更新，冲到最前面
            console.log('去第一档')
            if(row.idx - config.targetPos < -1){
              // 太靠前，后撤到目标位置
              row.toPos = config.targetPos;
            } else if(row.idx - config.targetPos > 1) {
              // 太靠后，前冲到目标位置
              row.toPos = config.targetPos;
            }
          } else if(diffTime && diffTime - 5 < 0) {
            console.log('撤离')
            // 距离上次更新时间小于5m，撤走
            if(row.idx - 20 < 0){
              row.toPos = 25;
            }
          } else if((diffTime && diffTime - 26 >= 0 && diffTime - 32 < 0) || (minute - 20 >= 0 && minute - 28 <= 0) || (minute - 50 >= 0 && minute - 58 <= 0)){
            // 第20-28和第50-58分钟，或者距离上次更新 >= 26m 控制在目标位置+3
            console.log('去第二档')
            if(row.idx - config.targetPos < -1){
              // 太靠前，后撤到目标位置
              row.toPos = parseInt(config.targetPos) + 0;
            } else if(row.idx - config.targetPos > 1) {
              // 太靠后，前冲到目标位置
              row.toPos = parseInt(config.targetPos) + 0;
            }
          } else {
            // 其他时间，如果在前20名，撤走
            if(row.idx - 20 < 0){
              row.toPos = 25;
            }
          }
          console.log('pos to -> ' + row.toPos)
          myTokens.push(row);
        }
        // 计算目标位置的价格
        if(i === 25 || i - config.targetPos === 0 || i - config.targetPos === 3){
          if(tokens[i].listedPrice - tokens[i-1].listedPrice > 1){
            // 当前价与上一个价格 差 大于 1sats，插到当前的上一位
            targetPosPrice['pos_' + i] = {
              prev: tokens[i-1].listedPrice,
              current: tokens[i].listedPrice,
              next: tokens[i+1].listedPrice,
              target: tokens[i].owner === this.currentAccount ? tokens[i].listedPrice : tokens[i].listedPrice - 1
            };
          } else {
            // 当前价与上一个价格 差 不超过 1sats，没位置插缝，混到当前位置
            // 如果想混到当前位置时，发现前面好几个价格都和当前相同，则插到后边
            const targetPrice = tokens[i].owner === this.currentAccount
                ? tokens[i].listedPrice
                : (tokens[i-2] && tokens[i].listedPrice - tokens[i-2].listedPrice === 0 ? parseInt(tokens[i].listedPrice) + 1 : tokens[i].listedPrice)
            targetPosPrice['pos_' + i] = {
              prev: tokens[i-1].listedPrice,
              current: tokens[i].listedPrice,
              next: tokens[i+1].listedPrice,
              target: targetPrice
            };
          }
        }
      }

      console.log('目标位置的价格信息');
      console.log(targetPosPrice);

      // 开始挂单
      for(let i in myTokens){
        const token = myTokens[i];
        const name = token.meta ? token.meta.name : token.displayName;
        if(!token.toPos){
          this.log(name + ' 没有找到可用目标位置或不在时间段！');
          continue;
        }
        if(!targetPosPrice['pos_' + token.toPos] || !targetPosPrice['pos_' + token.toPos].target){
          continue;
        }
        let targetPrice = targetPosPrice['pos_' + token.toPos].target/Math.pow(10, 8);
        if(!targetPrice){
          continue;
        }
        if(targetPrice - config.minPrice < 0){
          targetPrice = config.minPrice;
          this.log(name + ' 目标位置低于设定最低报价，按最低报价执行！');
        }
        this.log(name + ' 当前价格 ' + token.listedPrice/Math.pow(10, 8) + ' 当前位置 ' + token.idx);
        if(targetPrice - token.listedPrice/Math.pow(10, 8) === 0){
          // 与当前价格相同，不再挂单
          this.log(`${name} 目标价格${targetPrice}与当前价格相同，无需挂单`);
          continue;
        }
        this.log(`${name} 准备挂单到位置 ${token.toPos}，价格 ${targetPrice} -> 准备执行...`);
        await this.listToken(token, targetPrice, i);
      }
    },
    /**
     * 挂单
     * @param token
     */
    async listToken(token, targetPrice, i){
      if(!targetPrice){
        return;
      }

      const now = parseInt(new Date().getTime()/1000);

      if(lastListedPrice[token.id]){
        console.log(lastListedPrice)
        console.log(lastListedPrice[token.id].price - targetPrice);
        console.log(now - lastListedPrice[token.id].time)
      }

      if(
          lastListedPrice[token.id]
          && lastListedPrice[token.id].price - targetPrice === 0
          && now - lastListedPrice[token.id].time < 15
      ){
        const name = token.meta ? token.meta.name : token.displayName;
        this.log(name + ' 最近15s挂单过相同价格 -> ' + targetPrice);
        return;
      }
      lastListedPrice[token.id] = {
        price: targetPrice,
        time: now
      };

      const res = await magicApi.listToken(token.id, targetPrice, this.currentAccount, this.currentPublicKey);
      console.log(res)
      if(res && res.ok && res.listed > 0){
        this.log('挂单成功');
      } else {
        this.log('挂单失败');
      }
    },
    /**
     * 打印日志
     * @param txt
     */
    log(txt){
      this.logMsg = this.$func.getDate() + ': ' + txt;
      console.log(this.logMsg)
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.task-drawer-btn {
  position: fixed;
  right: 0;
  bottom: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: @light-color;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.task-drawer {

  /deep/.el-drawer {
    width: 60% !important;
    background-color: @section-bg-color;
    .el-drawer__header {
      span {
        color: @light-color;
      }
    }
    .el-drawer__body {
      display: flex;
      flex-direction: column;
    }
  }
}
.section-title {
  margin: 10px @mg;
  font-size: 13px;
  display: flex;
  align-items: center;
  .title {
    flex: 1;
  }
  .filter-con {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .active{
      color: @light-color;
    }
    span {
      color: @sub-font-color;
    }
    div {
      cursor: pointer;
    }
  }
}
.handles-con {
  font-size: 15px;
  color: @light-color;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 13px;
  }
}
.log-con {
  margin: @mg;
  font-size: 12px;
  color: @sub-font-color;
  background-color: @content-bg-color;
  padding: 10px 10px;
  border-radius: 10px;
}
.task-lists {
  margin: 0 @mg/2;
  font-size: 12px;

  &.tasks-con {
    max-height: 160px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &.queue {
    flex-direction: row;
    flex: 1;
  }
  .task-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @content-bg-color;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;

    .name {
      flex: 1;
      min-width: 120px;
    }
    .number {
      width: 120px;
      text-align: right;
    }
    .mark {
      font-size: 12px;
      color: @sub-font-color;
      padding-left: 3px;
    }
  }
  .task-item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    margin: 5px;
    background-color: @content-bg-color;
    width: calc(33.3% - 30px);
    height: 56px;
    border-radius: 10px;

    &.thead {
      color: @sub-font-color;
    }
    &.on {
      box-shadow: 0 0 2px @light-color;
    }
    &.active {
      box-shadow: 0 0 5px @green;
      background-color: rgba(139, 215, 120, .2);
    }
    .data-col {
      a {
        color: @font-color;
        text-decoration: none;
      }
    }
  }
}
</style>