import * as bitcoin from "bitcoinjs-lib";
import * as varuint from 'bip174/src/lib/converter/varint';
import ecc from '@bitcoinerlab/secp256k1';
import * as MempoolApi from "@/api/mempool";

bitcoin.initEccLib(ecc);

/**
 * 分析历史块
 * @param blocks
 */
export function analysisHistoryBlocks(blocks){
    if(!blocks){
        return;
    }
    let count = 0, totalFeeRate = 0, totalTxCount = 0, totalTime = 0, timeCount = 0;
    const historyBlock = {};
    let prevTime = null, lastTime = blocks[blocks.length - 1].timestamp;
    blocks.map(row => {
        if(row.extras.totalFees > 0){
            count++;
            totalFeeRate += row.extras.avgFeeRate;
            totalTxCount += row.tx_count;
            if(prevTime){
                totalTime += (row.timestamp - prevTime);
                timeCount++;
            }
            prevTime = row.timestamp;
        }
    });
    historyBlock.aveFeeRate = parseFloat(parseFloat(totalFeeRate/count).toFixed(2));
    historyBlock.aveTxCount = parseInt(totalTxCount/count);
    historyBlock.aveTime = parseInt(totalTime/timeCount);
    historyBlock.lastTime = lastTime;

    return historyBlock;
}

/**
 * 解析买家
 * @param txInfo
 * @returns {*}
 */
export function parseBuyer(txInfo, tokenValue){
    // const lastOut = txInfo.vout[txInfo.vout.length - 1];
    // const lastOutAddress = lastOut.scriptpubkey_address; // 最后一个输出地址
    // 找出买家
    for(let i in txInfo.vout){
        const r = txInfo.vout[i];
        if(r.value === tokenValue){
            txInfo.buyer = r.scriptpubkey_address;
            break;
        }
    }

    return txInfo.buyer;
}

/**
 * 解析txInfo
 * 解析tx详情，包含其中的内容
 * address：[] 不为空，表示只关心指定地址的输入输出
 */
export async function parseTxInfo(txInfo){
    // 重组输入输出
    const inputs = [], outputs = [];
    // inputs
    for(let i in txInfo.vin){
        const vin = txInfo.vin[i];
        const row = {
            hash: Buffer.from(vin.txid, 'hex').reverse(),
            index: vin.vout,
            witnessUtxo: {
                script: Buffer.from(vin.prevout.scriptpubkey, 'hex'),
                value: vin.prevout.value
            },
            sequence: 4294967295
        };
        inputs.push(row);
    }
    // outputs
    for(let i = 0; i < txInfo.vout.length; i++) {
        const out = txInfo.vout[i];
        if(out.scriptpubkey_address){
            outputs.push({
                address: out.scriptpubkey_address,
                value: out.value,
            });
        } else if(out.scriptpubkey_type === 'op_return'){
            const data = Buffer.from('', 'hex')
            const embed = bitcoin.payments.embed({data: [data]});
            outputs.push({
                script: embed.output,
                value: 0 // OP_RETURN 输出的价值为 0
            });
        }
    }

    const psbt = generatePsbt(inputs, outputs);
    const psbtRes = await MempoolApi.decodePsbt(psbt.toHex());

    return psbtRes;
}

/**
 * 解析token的输入
 */
export function parseTokenInput(txInfo, txParseInfo, token){
    if(!txParseInfo){
        return { vin: null, vout: null };
    }
    // 过滤
    let ins = [], outs = [];
    for(let i = 0; i < txParseInfo.outputInfos.length; i++){
        const output = txParseInfo.outputInfos[i];
        output.out = txInfo.vout[i];
        if(output.address !== token.makerReceiveAddress){
            // 卖家不对
            continue;
        }
        outs.push(output);
    }
    for(let i = 0; i < txParseInfo.inputInfos.length; i++){
        // rune: 卖家相同，数量相同，价格相同
        const input = txParseInfo.inputInfos[i];
        input.in = txInfo.vin[i];
        if(input.address !== token.maker){
            // 卖家不对
            continue;
        }
        if(token.rune && input.runes && input.runes[0]){
            const amount = input.runes[0].amount/Math.pow(10, input.runes[0].divisibility);
            if(amount - token.formattedAmount !== 0){
                // 数量不对
                continue;
            }
        }
        ins.push(input);
    }
    // 找出有效输入输出
    let vin = null, vout = null;
    if(ins.length === 1 && outs.length === 1){
        if(outs[0].value - ins[0].value - token.price === 0){
            vin = ins[0];
            vout = outs[0];
        }
    } else {
        let flag = false;
        for(let i in ins){
            const inValue = ins[i].value;
            for(let j in outs){
                if(outs[j].value - inValue - token.price === 0){
                    vin = ins[i];
                    vout = outs[j];
                    flag = true;
                    break;
                }
            }
            if(flag) break;
        }
    }

    return { vin, vout };
}

/**
 * 生成psbt
 */
export function generatePsbt(inputs, outputs){
    const psbt = new bitcoin.Psbt({ network: bitcoin.networks.bitcoin });
    psbt.setVersion(2); // 设置交易版本
    psbt.setLocktime(0); // 设置锁定时间

    psbt.addInputs(inputs);
    psbt.addOutputs(outputs);

    return psbt;
}

/**
 * 生成psbt
 * @param buyer
 * @param tokenInput
 * @param payAmount
 * @param minFee
 * @returns {Promise<{msg: string, code: number}|null>}
 */
export async function buildPsbt(buyer, tokenInput, payAmount, minFee){
    if(!buyer || !tokenInput || !tokenInput.vin || !payAmount){
        return null;
    }
    // 查询买家的utxos
    const utxosRes = await MempoolApi.getAvailableUtxos(buyer);
    if(!utxosRes || !utxosRes.data || !utxosRes.data.utxos || utxosRes.data.utxos.length === 0){
        return { code: 0, msg: 'No utxos!'};
    }
    const utxos = utxosRes.data.utxos;
    utxos.sort((a, b) => a.value - b.value);
    // 获取用于付款的utxos
    const payUtxos = getPayUtxos(utxos, parseInt(payAmount) + parseInt(minFee));
    // 获取prevout
    let flag = true;
    const inputTxs = {};
    for(let i = 0; i < payUtxos.length; i++){
        const utxo = payUtxos[i];
        if(utxo.prevout) continue;
        let txInfo = null;
        if(!inputTxs[utxo.txid]){
            txInfo = await MempoolApi.getTxInfo(utxo.txid);
            if(!txInfo){
                flag = false;
                break;
            }
            inputTxs[txInfo.txid] = txInfo;
        } else {
            txInfo = inputTxs[utxo.txid];
        }
        payUtxos[i].prevout = txInfo.vout[utxo.vout];
    }
    // 构建psbt
    let psbt = null;
    if(tokenInput.vin.runes && tokenInput.vin.runes.length > 0){
        // 符文
        psbt = buildRunePsbt(buyer, tokenInput, payAmount, minFee, payUtxos);
    }

    return psbt;
}

/**
 * 构建符文输入输出
 * @param buyer
 * @param tokenInput
 * @param payAmount
 * @param minFee
 * @param payUtxos
 */
function buildRunePsbt(buyer, tokenInput, payAmount, minFee, payUtxos){
    const inputs = [], outputs = [];
    let totalInputValue = 0;
    // first pay input
    const vin = payUtxos[0];
    inputs.push({
        hash: Buffer.from(vin.txid, 'hex').reverse(),
        index: vin.vout,
        witnessUtxo: {
            script: Buffer.from(vin.prevout.scriptpubkey, 'hex'),
            value: vin.prevout.value
        },
        sequence: 4294967295
    });
    totalInputValue += vin.prevout.value;
    // token input
    const tokenVin = tokenInput.vin.in;
    const witnessStack = tokenVin.witness.map(hex => Buffer.from(hex, 'hex'));
    inputs.push({
        hash: Buffer.from(tokenVin.txid, 'hex').reverse(),
        index: tokenVin.vout,
        witnessUtxo: {
            script: Buffer.from(tokenVin.prevout.scriptpubkey, 'hex'),
            value: tokenVin.prevout.value
        },
        finalScriptWitness: witnessStackToScriptWitness(witnessStack), // taproot
        sequence: 4294967295
    });
    // more pay input
    if(payUtxos.length > 1){
        for(let i = 1; i < payUtxos.length; i++){
            const vin = payUtxos[i];
            inputs.push({
                hash: Buffer.from(vin.txid, 'hex').reverse(),
                index: vin.vout,
                witnessUtxo: {
                    script: Buffer.from(vin.prevout.scriptpubkey, 'hex'),
                    value: vin.prevout.value
                },
                sequence: 4294967295
            });
            totalInputValue += vin.prevout.value;
        }
    }

    /** 输出 **/
    const tokenVout = tokenInput.vout.out;
    console.log(tokenInput)
    // 收token
    outputs.push({
        address: buyer,
        value: tokenVin.prevout.value
    });
    // 给卖家
    outputs.push({
        address: tokenVout.scriptpubkey_address,
        value: tokenVout.value
    });
    // 找零
    // 计算找零：总输入 - token总价
    const changeAmount = totalInputValue - payAmount - tokenVin.prevout.value - minFee;
    outputs.push({
        address: buyer,
        value: changeAmount
    });

    const psbt = generatePsbt(inputs, outputs);

    return psbt;
}

/**
 * 构建witnessStack
 * @param witness
 * @returns {Buffer}
 */
export function witnessStackToScriptWitness(witness) {
    let buffer = Buffer.allocUnsafe(0);

    function writeSlice(slice) {
        buffer = Buffer.concat([buffer, Buffer.from(slice)]);
    }

    function writeVarInt(i) {
        const currentLen = buffer.length;
        const varintLen = varuint.encodingLength(i);

        buffer = Buffer.concat([buffer, Buffer.allocUnsafe(varintLen)]);
        varuint.encode(i, buffer, currentLen);
    }

    function writeVarSlice(slice) {
        writeVarInt(slice.length);
        writeSlice(slice);
    }

    function writeVector(vector) {
        writeVarInt(vector.length);
        vector.forEach(writeVarSlice);
    }

    writeVector(witness);

    return buffer;
}

/**
 * 找到数组中与指定值最接近的值的索引
 * @param arr
 * @param target
 * @returns {*}
 */
function findClosest(arr, target) {
    if (arr.length === 0) {
        throw new Error('数组不能为空');
    }

    // 初始化最接近的值和最小的差值
    let smallestDifference = Math.abs(arr[0] - target);

    let closestIdx = 0;
    // 遍历数组找到最接近的正数值
    for (let i = 1; i < arr.length; i++) {
        let currentDifference = Math.abs(arr[i] - target);
        if (currentDifference < smallestDifference) {
            smallestDifference = currentDifference;
            closestIdx = i;
        }
    }

    return closestIdx;
}

/**
 * 获取用于付款的utxos
 * @param utxos
 * @param totalPrice
 * @param inputPriceUtxos
 * @returns {*[]|*}
 */
export function getPayUtxos(utxos, payAmount, payUtxos = []){
    if(utxos.length === 0){
        return payUtxos;
    }
    let prevIdx = null, okIdx = null;
    let utxoVaues = utxos.map((u, i) => {
        if(prevIdx !== null && u.value - payAmount < 0 && utxos[prevIdx].value - payAmount >= 0){
            okIdx = prevIdx;
        }
        prevIdx = i;
        return u.value;
    });
    if(okIdx){
        payUtxos.push(utxos[okIdx]);
        return payUtxos;
    }
    const closestIdx = findClosest(utxoVaues, payAmount);

    payUtxos.push(utxos[closestIdx]);
    if(utxoVaues[closestIdx] - payAmount > 0){
        return payUtxos;
    }

    utxos.splice(closestIdx, 1);
    return getPayUtxos(utxos, payAmount - utxoVaues[closestIdx], payUtxos);
}

/**
 * 估算虚拟大小
 * @param psbt
 * @returns {number}
 */
export function estimateVirtualSize(psbt) {
    const tx = psbt.__CACHE.__TX;

    // 获取非见证数据的大小
    const nonWitnessSize = tx.byteLength(false);

    // 估算见证数据的大小
    const numInputs = tx.ins.length;
    const numOutputs = tx.outs.length;
    const witnessSize = numInputs * 107; // 估算每个输入的见证数据大小

    // 计算交易的权重和虚拟大小
    const weight = (nonWitnessSize * 3) + (nonWitnessSize + witnessSize);
    const vSize = Math.ceil(weight / 4);

    return vSize;
}