<template>
  <div class="panel-container">
    <div class="tab-header-right" :class="{on: !isShowAll}" @click="onShowAll">
      <svg t="1700767143859" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5780" width="200" height="200"><path d="M741.248 79.68l-234.112 350.08v551.488l55.296 24.704v-555.776l249.152-372.544c8.064-32.96-10.496-59.712-41.152-59.712h-709.248c-30.464 0-49.28 26.752-41.344 59.712l265.728 372.544v432.256l55.36 24.704v-478.592l-248.896-348.864h649.216z m-68.032 339.648c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.32-27.264-14.016-27.264-30.848z m0 185.216c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.256-27.264-14.016-27.264-30.848z m0 185.28c0-16.832 12.096-30.592 27.264-30.848h277.888c15.232 0 27.712 13.824 27.712 30.848s-12.416 30.848-27.712 30.848h-277.888c-15.168-0.32-27.264-13.952-27.264-30.848z" p-id="5781"></path></svg>
    </div>
    <el-tabs v-model="activeTab" type="border-card" class="scroll-con articles-con">
      <!-- search -->
      <div class="search-input">
        <el-input v-model="filterKeyword" size="mini" @input="filterLists">
          <template slot="append">
            <i class="el-icon-search"></i>
          </template>
        </el-input>
      </div>
      <!-- news -->
      <el-tab-pane :label="$t('tabs.news')" :name="$t('tabs.news')">
        <div class="news-item" :class="{flash: hasNewNews && item.isNew}" v-for="(item, idx) in showNewsLists" :key="idx">
          <div class="top-con">
            <el-image :src="item.image" fit="cover" lazy class="cover"></el-image>
            <div class="top-desp">
              <div class="name" v-if="item.source || item.name || item.title">
<!--                <i class="icon-mark"></i>-->
                {{item.source ? item.source + ':' : ''}}{{item.name}}{{item.title}}
              </div>
              <div class="time-con">
                <div class="time">
                  <span>{{$func.getDate(item.timestamp)}}</span>
                  <span class="new-tag" v-if="currentTime - item.timestamp < 60">NEW</span>
                </div>
                <div class="star" v-if="item.isBig">
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                </div>
                <div class="ai-btn" @click="aiAnalysis(item)">{{$t('articles.aiDecode')}}</div>
              </div>
            </div>
          </div>
          <div class="news-brief">
            <div class="sub-title" v-html="$i18n.locale == 'zh' ? (item.bodyCn || item.body) : item.body"></div>
            <div class="brief" v-if="item.suggestions.length > 0">
              <div class="symbols">
                <span
                    v-for="(sg, sn) in item.suggestions"
                    :key="sn"
                    :title="sg.exchange"
                    @click="onChangeSymbol(sg, item)"
                >
                  {{sg.symbol}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="loading-con" v-if="newsLists.length == 0">
          <i class="el-icon-loading"></i>
        </div>
      </el-tab-pane>
      <!--    <el-tab-pane label="Anomalies">链上异动</el-tab-pane>-->
      <!-- singals -->
      <el-tab-pane :label="$t('tabs.singals')" :name="$t('tabs.singals')">
        <div class="singals-item" :class="{flash: hasNewSingal && item.isNew}" v-for="(item, idx) in showSingalsLists" :key="idx" @click="onChangeSymbol({symbol: item.pairName}, item)">
          <div class="top">
            <span class="name">{{item.pairName}}</span>
            <div class="date">
              {{item.date}}
              <span class="new-tag" v-if="currentTime - item.Timestamp < 60">NEW</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label>MCAP:</label>
              <span>{{$com.formatNumber(item['流通量'])}}</span>
            </div>
            <div class="col">
              <label>MValue:</label>
              <span>${{$com.formatNumber(item['市值'])}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col green">
              <label>Price:</label>
              <span>${{parseFloat(item['Price'])}}</span>
            </div>
            <div class="col">
              <label>B2AP:</label>
              <span>${{parseFloat(item['B2AP']).toFixed(item.precision)}}</span>
            </div>
          </div>
        </div>
        <div class="loading-con" v-if="singalsLists.length == 0">
          <i class="el-icon-loading"></i>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="$t('articles.aiAnalysis')" :visible.sync="aiAnalysisDialog" class="ai-analysis-dialog">
      <div class="news-item" v-if="aiAnalysisResult">
        <div class="top-con">
          <el-image :src="aiAnalysisResult.image" fit="cover" lazy class="cover"></el-image>
          <div class="top-desp">
            <div class="name" v-if="aiAnalysisResult.source || aiAnalysisResult.name || aiAnalysisResult.title">
              {{aiAnalysisResult.source ? aiAnalysisResult.source + ':' : ''}}{{aiAnalysisResult.name}}{{aiAnalysisResult.title}}
            </div>
            <div class="time-con">
              <div class="time">
                <span>{{$func.getDate(aiAnalysisResult.timestamp)}}</span>
                <span class="new-tag" v-if="currentTime - aiAnalysisResult.timestamp < 60">NEW</span>
              </div>
              <div class="star" v-if="aiAnalysisResult.isBig">
                <i class="el-icon-star-on"></i>
                <i class="el-icon-star-on"></i>
                <i class="el-icon-star-on"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="news-brief">
          <div class="sub-title" v-html="$i18n.locale === 'zh' ? (aiAnalysisResult.bodyCn || aiAnalysisResult.body) : aiAnalysisResult.body"></div>
          <div class="brief" v-if="aiAnalysisResult.suggestions.length > 0">
            <div class="symbols">
                <span
                    v-for="(sg, sn) in aiAnalysisResult.suggestions"
                    :key="sn"
                    :title="sg.exchange"
                    @click="onChangeSymbol(sg, aiAnalysisResult)"
                >
                  {{sg.symbol}}
                </span>
            </div>
          </div>
        </div>
      </div>
      <div class="analysis-result scroll-con">
        <div class="content" v-html="aiAnalysisResult.aiResult.content" v-if="aiAnalysisResult && aiAnalysisResult.aiResult"></div>
        <div class="loading-data" v-else><i></i></div>
      </div>
      <div class="ai-analysis-remark">
        <div class="remark text-danger fs-14">{{$t('articles.aiWarning')}}</div>
        <span class="flex-1"></span>
        <span class="text-warning no-wrap">{{$t('pointsBalance')}}:10</span>
        <span class="text-success pdl-5 no-wrap">({{$t('articles.aiDecode') + ': '}}2 {{$t('priceUnit')}})</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as newsApi from "@/api/news";
import * as singalsApi from "@/api/singals";
import {eventBus} from "@/utils/eventBus";
import {analysis} from "@/api/news";

export default {
  data(){
    return {
      currentSymbol: null,
      activeTab: 0,
      newsLists: [],
      singalsLists: [],
      showNewsLists: [],
      showSingalsLists: [],
      isShowAll: true,
      hasNewNews: false,
      hasNewSingal: false,
      currentTime: new Date().getTime()/1000,
      filterKeyword: null,
      aiAnalysisDialog: false,
      aiAnalysisResult: null
    }
  },
  created() {
    this.activeTab = this.$t('tabs.news');
    this.$eventBus.$on('CHANGE_ARTICLES_TAB', (tabName) => {
      this.activeTab = tabName;
    });
    this.$eventBus.$on('LASTEST_NEWS', (row) => {
      row = this.parseLink(row);
      row.isNew = true;
      this.newsLists.unshift(row);
      this.filterLists('news');
      this.$eventBus.$emit('LAST_NEWS', this.newsLists);
      this.hasNewNews = true;
      setTimeout(() => {
        this.hasNewNews = false;
      }, 1500);
    });
    this.$eventBus.$on('LASTEST_SINGAL', (row) => {
      this.loadNewSingals();
    });
    this.$eventBus.$on('MARKER_CLICK', (row) => {
      for(let i in this.newsLists){
        const item = this.newsLists[i];
        if(item.timestamp != row.time){
          continue;
        }
        const symbols = item.suggestions.map(r => r.symbol);
        if(symbols.indexOf(row.symbol) == -1){
          continue;
        }
        row = item;
        break;
      }
      if(row.body || row.title){
        console.log(row)
      }
    });
  },
  mounted() {
    this.loadLastNews();
    setTimeout(() => {
      this.loadSingals();
    }, 1500);
  },
  methods: {
    onShowAll(){
      this.isShowAll = !this.isShowAll;
      this.filterLists('all');
    },
    filterLists(){
      let type = null;
      if(this.activeTab == 0){
        type = 'news';
      } else if(this.activeTab == 1) {
        type = 'singals';
      }
      let sg = localStorage.getItem('CURRENT_SYMBOL');
      sg = sg ? JSON.parse(sg) : null;
      if(!this.isShowAll && sg && sg.symbol){
        this.showNewsLists = this.newsLists.filter(r => {
          const symbols = r.suggestions.map(s => s.symbol);
          return symbols.indexOf(sg.symbol) > -1;
        });
        this.showSingalsLists = this.singalsLists.filter(r => r.pairName == sg.symbol);
      } else {
        const filterSymbol = this.filterKeyword ? this.filterKeyword.toUpperCase() : null;
        if(filterSymbol){
          this.showNewsLists = this.newsLists.filter(r => {
            const symbols = r.suggestions.map(s => s.symbol);
            return symbols.join('').indexOf(filterSymbol) > -1;
          });
          this.showSingalsLists = this.singalsLists.filter(r => r.pairName.indexOf(filterSymbol) > -1);
        } else {
          this.showNewsLists = this.newsLists;
          this.showSingalsLists = this.singalsLists;
        }
      }
    },
    async loadLastNews(){
      const params = {
        page: 1,
        size: 500,
        sort: 'desc',
        cursor: null,
        // isBig: true
      }
      const res = await newsApi.lastNews(params);
      if(res && res.data && res.data.lists){
        for(let i in res.data.lists){
          const item = res.data.lists[i];
          this.newsLists.push(this.parseLink(item));
        }
        this.filterLists('news');
        this.$eventBus.$emit('LAST_NEWS', this.newsLists);
      }
    },
    parseLink(item){
      const linkRegex = /(https?:\/\/\S+)/g;

      item.body = item.body ? item.body.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;
      item.title = item.title ? item.title.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;

      item.bodyCn = item.bodyCn ? item.bodyCn.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;
      item.titleCn = item.titleCn ? item.titleCn.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>]</a>') : null;

      return item;
    },
    /**
     * 加载历史信号
     * @returns {Promise<void>}
     */
    async loadSingals(){
      const res = await singalsApi.lists();
      if(res && res.data){
        this.singalsLists = res.data;
        this.filterLists('singals');
        this.$eventBus.$emit('LAST_SINGALS', this.singalsLists);
      }
    },
    async loadNewSingals(){
      const res = await singalsApi.lists({ size: 1 });
      if(res && res.data && res.data.length > 0){
        res.data[0].isNew = true;
        this.singalsLists.unshift(res.data[0]);
        this.filterLists('singals');
        this.$eventBus.$emit('LAST_SINGALS', this.singalsLists);
        this.hasNewSingal = true;
        setTimeout(() => {
          this.hasNewSingal = false;
        }, 1500);
      }
    },
    /**
     * 切换交易对
     * @param sg
     */
    onChangeSymbol(sg){
      if(this.currentSymbol === sg.symbol){
        return;
      }
      this.currentSymbol = sg.symbol;
      sg.exchange = !sg.exchange || sg.exchange === '-' ? 'binance' : sg.exchange;
      localStorage.setItem('CURRENT_SYMBOL', JSON.stringify(sg));
      this.$eventBus.$emit('CHANGE_SYMBOL', sg);
      // update route
      const { symbol } = this.$route.query;
      symbol !== sg.symbol && this.$router.replace({
        path: '/trade',
        query: { symbol: sg.symbol }
      });
    },
    /**
     * Ai解读
     */
    async aiAnalysis(item){
      this.aiAnalysisResult = item;
      this.aiAnalysisDialog = true;

      const res = await newsApi.analysis({ id: item._id, lang: this.$i18n.locale });
      if(res && res.data && res.data.content){
        res.data.content = res.data.content.replace(/\n/g, '<br>');
        this.$set(this.aiAnalysisResult, 'aiResult', res.data);
      } else if(res && res.data && res.data.msg) {
        if(res.data.msg == 'isNotSupported'){
          res.data.content = this.$t('articles.isNotSupported');
          this.$set(this.aiAnalysisResult, 'aiResult', res.data);
        } else {
          this.$message.error(res.data.msg);
        }
      } else {
        this.$message.error(this.$t('articles.aiDecodeFailed'));
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.panel-container{
  position: relative;

  .tab-header-right{
    position: absolute;
    right: 10px;
    top: 16px;
    z-index: 1;
    cursor: pointer;

    &.on{
      svg{
        opacity: 1;
      }
    }
    svg{
      width: 16px;
      height: 16px;
      fill: @font-color;
      opacity: .3;
    }
  }
}
.articles-con{
  /deep/.el-tabs__content{
    height: calc(100vh - 75px - 32px - 32px - 48px - 30px);
    position: relative;

    .search-input{
      position: sticky;
      top: 0;
      margin-left: -@mg;
      padding: @mg 10px 10px @mg;
      width: calc(100% + @mg*2);
      box-sizing: border-box;
      z-index: 1;
      background-color: @content-bg-color;

      &:before{
        content: ' ';
        display: block;
        width: calc(100% + @mg*2 - 6px);
        height: @mg;
        margin-left: -14px;
        background-color: @content-bg-color;
        z-index: 2;
        margin-top: -32px;
        border-radius: @mg @mg 0 0;
      }
    }
  }
}
@keyframes flash {
  0%,
  25% {
    background-color: rgba(246, 124, 52, .5);
  }
  50% {
    background-color: rgba(246, 124, 52, .2);
  }
  100% {
    background-color: transparent;
  }
}
.loading-con{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.singals-item {
  padding: @mg;
  background-color: @input-bg-color;
  margin-bottom: @mg;
  border-radius: @mg;
  &.flash{
    animation: flash 0.5s linear;
  }
  .top{
    display: flex;
    align-items: center;
    font-size: 13px;
    .name{
      flex: 1;
      font-weight: bold;
      color: @light-color;
      cursor: pointer;
    }
    .date{
      font-size: 12px;
      color: @sub-font-color;
      display: flex;
      align-items: center;

      .new-tag{
        display: block;
        padding: 1px 3px;
        background-color: #ff4d51;
        color: #fff;
        font-size: 12px;
        border-radius: 3px;
        text-align: center;
        transform: scale(0.76, 0.76);
      }
    }
  }
  .row{
    display: flex;
    align-items: center;
    .col{
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding-top: 8px;
      &:first-child{
        margin-right: 8px;
      }
      &:last-child{
        margin-left: 8px;
      }
      &.green{
        color: @green;
      }
      &.red{
        color: @red;
      }
      label{
        flex: 1;
      }
    }
  }
}

.ai-analysis-dialog {
  .news-brief{
    .sub-title{
      opacity: .8;
    }
  }
}
.analysis-result{
  padding: @mg;
  background-color: @content-bg-color;
  min-height: 100px;
  border-radius: @mg;
  overflow-x: hidden;
  max-height: 260px;

  .content {
    color: @font-color;
    font-size: 13px;
    line-height: 22px;
  }
  .loading-data{
    position: relative;
    left: calc(50% - 5px);
    top: 45px;
    opacity: .6;

    &:before{
      display: block;
      content: ' ';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @light-color;
      position: absolute;
      left: -15px;
      top: 0;
      animation: blink 0.4s infinite alternate; /* Blink animation */
    }
    i{
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @light-color;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.2;
      animation: blink 0.4s infinite alternate;
      animation-delay: 0.2s;
    }
    &:after{
      display: block;
      content: ' ';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @light-color;
      position: absolute;
      left: 15px;
      top: 0;
      opacity: 0.5;
      animation: blink 0.4s infinite alternate;
      animation-delay: 0.4s;
    }
  }
}
.ai-analysis-remark{
  padding-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;

  .remark {
    padding-right: 20px;
  }
}
@keyframes blink {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 750px) {
  .ai-analysis-dialog{
    width: 90vw;
    margin: 0 auto;
    overflow: hidden;

    /deep/.el-dialog{
      width: 100%;
    }
    .analysis-result{
      max-height: 200px;
    }
    .ai-analysis-remark {
      flex-direction: column;
      font-size: 12px !important;

      .remark {
        font-size: 12px !important;
        padding-right: 0;
      }
    }
  }
}
</style>
