<template>
  <div class="content-con">
    <div class="left-panel">
      <div class="section-con">
        <div class="info-con" v-if="symbolInfo && symbolInfo.baseInfo">
          <div class="base-section">
            <el-avatar size="medium" :src="symbolInfo.baseInfo.brief ? symbolInfo.baseInfo.brief.logo : null"></el-avatar>
            <div class="name-con">
              <div class="symbol">{{symbolInfo.baseInfo.symbol}}</div>
              <div class="name">{{symbolInfo.baseInfo.name}}</div>
            </div>
            <div class="price">${{parseFloat(symbolInfo.price)}}</div>
          </div>
          <div class="brief-section">
            <div class="data-row">
              <div class="label">Circulating supply</div>
              <div class="value">{{parseInt(symbolInfo.baseInfo.circulatingSupply)}}</div>
            </div>
            <div class="data-row">
              <div class="label">Total supply</div>
              <div class="value">{{parseInt(symbolInfo.baseInfo.totalSupply)}}</div>
            </div>
            <div class="data-row">
              <div class="label">Market cap</div>
              <div class="value">${{parseInt(symbolInfo.marketCap)}}</div>
            </div>
          </div>
          <div class="address-section" v-if="symbolInfo.baseInfo.brief">
            <div class="section-title">Contracts</div>
            <div class="data-row" :class="{'more-address': ['Ethereum', 'BNB Smart Chain (BEP20)'].indexOf(name) == -1}" v-for="(address, name) in symbolInfo.baseInfo.brief.contractAddress">
              <div class="label">{{name}}</div>
              <div class="value">{{$com.formatString(address, 6, 8)}}<i class="el-icon-copy-document" @click="$com.copyText(address)"></i></div>
            </div>
          </div>
          <div class="urls-section" v-if="symbolInfo.baseInfo.brief">
            <a v-for="(links, name) in symbolInfo.baseInfo.brief.urls" :href="links[0]" target="_blank" v-if="['website', 'twitter', 'facebook'].indexOf(name) > -1">
              {{name}}
            </a>
          </div>
        </div>
        <!-- news -->
        <el-tabs v-model="activeTab" type="border-card" @tab-click="onChangeTab" class="articles-con scroll-con">
          <el-tab-pane label="Related News">
            <div class="news-lists">
              <div class="news-item" v-for="(item, idx) in newsLists">
                <div class="top-con">
                  <div class="top-desp">
                    <div class="name">
                      <i class="icon-mark" v-if="item.source || item.name"></i>
                      {{item.source ? item.source + ':' : ''}}{{item.name}}
                    </div>
                    <div class="time-con">
                      <div class="time">
                        <i class="icon-mark" v-if="!item.source && !item.name"></i>
                        <span>{{$func.getDate(item.timestamp)}}</span>
                        <span class="new-tag" v-if="currentTime - item.timestamp < 60">NEW</span>
                      </div>
                      <span class="star" v-if="item.isBig">
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                </span>
                    </div>
                  </div>
                </div>

                <div class="news-brief">
                  <div class="sub-title" v-html="$i18n.locale == 'zh' ? (item.bodyCn || item.titleCn || item.body || item.title) : (item.body || item.title)"></div>
                  <div class="brief" v-if="item.suggestions.length > 0">
                    <div class="symbols">
                      <span v-for="(sg, sn) in item.suggestions" :key="sn" :title="sg.exchange" @click="onChangeSymbol(sg, item)">{{sg.symbol}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="no-data" style="height: 100px;" v-if="newsLists.length == 0">
                {{$t('noData')}}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="main-panel">
      <div class="filter-con">
        <div class="filter-form">
          <span class="label">Start time:</span>
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="mini"
              placeholder="Start Time"
              v-if="startTime"
          >
          </el-date-picker>
          <div style="width: 10px;"></div>
          <el-button size="mini" round class="animate-handle-btn" @click="startOrPauseAnimate">
            <i class="el-icon-video-play" v-if="!animateStatus"></i>
            <i class="el-icon-video-pause" v-else></i>
          </el-button>
        </div>
        <el-button size="mini" round class="reset-btn" @click="resetData">Reset</el-button>
      </div>

      <div class="section-con-box">
        <div class="tab-menus">
          <div class="tab-item" :class="{active: sectionTabActive == 'marketTrends'}" @click="sectionTabActive = 'marketTrends'">Market trends</div>
          <div class="tab-item" :class="{active: sectionTabActive == 'tradeRecords'}" @click="sectionTabActive = 'tradeRecords'">Trade records</div>
          <div class="flex-1"></div>
          <div class="tab-right-con">
            <div class="block-con" v-if="sectionTabActive == 'marketTrends'">
              <span class="text-blue">⬇ Txns Anomalies</span>
              <span class="text-yellow">⬆ News Event</span>
            </div>
          </div>
        </div>

        <div class="kline-con" :class="{'show-section': sectionTabActive == 'marketTrends'}">
          <kline :symbol-info="symbolInfo" :news="newsLists" :key="'Kline-' + currentSymbol" :start-time="startTime" v-if="symbolInfo"></kline>
        </div>

        <div class="logs-con" :class="{'show-section': sectionTabActive == 'tradeRecords'}">
          <div style="display: flex;justify-content: space-between;">
            <div class="running-logs">
              <div class="log-item" v-for="(item, idx) in runningLogs" :key="idx">
                <div class="time">{{$func.getDate(item.time)}}</div>
                <div class="fs-12">{{item.msg}}</div>
              </div>
            </div>
            <div class="trade-logs">
              <div class="log-item" :class="{'text-danger': item.side == 'sell'}" v-for="(item, idx) in tradeRecords" :key="idx">
                <div class="time">{{$func.getDate(item.time)}}</div>
                <div class="side">{{item.side}}</div>
                <div class="price">${{parseFloat(item.price).toFixed(8)}}</div>
                <div class="amount">{{item.amount}}U</div>
                <div class="amount">{{item.side == 'sell' ? item.rate + '%' : '-'}}</div>
                <div class="amount">{{item.type ? item.type : ''}}</div>
                <div class="amount">{{item.msg ? item.msg : ''}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as coinsApi from "@/api/coins";
import * as newsApi from "@/api/news";
import Kline from "@/pages/components/simulate/Kline";
import * as predictApi from "@/api/predict";
import {hourDataSource} from "@/api/predict";

export default {
  components: {
    Kline
  },
  data(){
    return {
      activeTab: 0,
      sectionTabActive: 'marketTrends',
      startTime: null,
      currentTime: parseInt(new Date().getTime()/1000),
      newsLists: [],
      currentNews: null,
      currentSymbol: null,
      symbolInfo: null,
      contractAddress: {},
      circulatingSupply: 0,
      hourDataStatistic: null,
      animateStatus: false,
      tradeRecords: [],
      runningLogs: []
    }
  },
  async mounted() {
    let { news, symbol } = this.$route.query;
    this.startTime = this.$func.getDate(new Date().getTime()/1000 - 3*24*3600);
    // 监听统计数据
    this.$eventBus.$on('HOUR_DATA_STATISTIC', res => {
      this.hourDataStatistic = res;
    });
    if(news){
      this.currentNews = JSON.parse(news);
      this.startTime = this.$func.getDate(this.currentNews.timestamp - 3*24*3600);
      this.newsLists.unshift(this.currentNews);
      this.currentSymbol = symbol;
    } else if(symbol) {
      this.currentSymbol = symbol;
      this.newsLists = await this.getNews(symbol);
      this.currentNews = this.newsLists.length > 0 ? this.newsLists[0] : null;
    }
    if(this.currentSymbol){
      await this.getCoinInfo(this.currentSymbol.replace('USDT', ''));

      // 按小时分组的异动
      /*if(this.contractAddress['Ethereum']){
        await this.getHourData(this.contractAddress['Ethereum'], this.startTime);
      }*/
    }
    // 交易记录
    this.$eventBus.$on('TRADE-RECORDS', records => {
      this.tradeRecords = records;
    });
    // 运行日志
    this.$eventBus.$on('RUNNING-LOGS', logs => {
      this.runningLogs = logs;
    });
  },
  methods: {
    onChangeTab(idx){
      this.activeTab = idx;
    },
    /**
     * symbol info
     * @returns {Promise<void>}
     */
    async getCoinInfo(coin){
      const res = await coinsApi.info({coin});
      this.symbolInfo = res ? res.data : {};
      if(this.symbolInfo && this.symbolInfo.baseInfo){
        this.circulatingSupply = this.symbolInfo.baseInfo.circulatingSupply;
        this.contractAddress = this.symbolInfo.baseInfo.brief.contractAddress;
      }
    },
    /**
     * related news
     * @param symbol
     * @returns {Promise<*>}
     */
    async getNews(symbol){
      const res = await newsApi.searchNews({ symbol });
      const linkRegex = /(https?:\/\/\S+)/g;
      for(let i in res.data){
        const item = res.data[i];
        const body = item.body;
        res.data[i].body = body ? body.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>LINK]</a>') : null;
      }

      return res.data.reverse();
    },
    /**
     * 获取按小时分组的数据
     * @param address
     * @param startTime
     * @returns {Promise<void>}
     */
    async getHourData(address, startTime){
      const res = await predictApi.hourDataSource({ address, startTime });
      if(res && res.data && res.data.hourData && res.data.hourData.length > 0){
        this.$eventBus.$emit('HOUR_DATA', res.data.hourData);
      }
    },
    /**
     * reset data
     */
    resetData(){
      this.$eventBus.$emit('RESET_INFO_DATA', this.startTime);
    },
    /**
     * 开始动画/暂停动画
     */
    startOrPauseAnimate(){
      const action = this.animateStatus ? 'pause' : 'start';
      this.$eventBus.$emit('HANDLE_ANIMATE', this.animateStatus ? 'pause' : 'start');
      this.animateStatus = action == 'start' ? true : false;
    },
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.content-con {
  display: flex;

  .main-panel {
    flex: 1;
    margin: 0 @mg 0 0;
    position: relative;
  }

  .left-panel{
    .section-con{
      display: flex;
      flex-direction: column;
    }
  }
}

.info-con {
  margin-bottom: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .section-title{
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
  }
  .base-section {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @sub-font-color;
    padding-bottom: 10px;

    .price {
      font-size: 16px;
    }
    .name-con{
      margin-left: 10px;
      flex: 1;

      .symbol{
        font-size: 15px;
        font-weight: bold;
      }
      .name{
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }

  .data-row{
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 10px;

    .label{
      flex: 1;
    }
    .value{
      i {
        margin-left: 5px;
      }
    }
  }
  .brief-section{
    margin-top: 10px;
  }
  .address-section {
    .more-address {
      display: none;
    }
  }
  .urls-section {
    display: flex;
    margin-top: 10px;
    a{
      font-size: 13px;
      color: @sub-font-color;
      background-color: @input-bg-color;
      margin-right: 10px;
      display: block;
      padding: 5px 10px;
      text-decoration: none;
      cursor: pointer;
      border-radius: 12px;
    }
  }
}

.articles-con{
  flex: 1;
}
.news-lists {
  margin-bottom: @mg;
}

.show-section {
  opacity: 1 !important;
  position: unset !important;
  top: unset !important;
}

.filter-con {
  margin: @mg 0;
  display: flex;
  align-items: center;
  background-color: @section-bg-color;
  padding: @mg;
  border-radius: @mg;

  .filter-form {
    flex: 1;
    display: flex;
    align-items: center;

    .label{
      padding-right: 10px;
      font-size: 14px;
    }
  }
  .animate-handle-btn{
    background-color: transparent;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font-size: 28px;
    color: @font-color;
  }
  .reset-btn{
    background-color: transparent;
    color: @font-color;
    border-color: @font-color;
  }
}

.statistic-con {
  margin: 15px;
  padding: 15px 0;

  /deep/.title {
    font-weight: bold;
    font-size: 14px;
  }
  .grid-value {
    font-size: 13px;
  }
}

.section-con-box{
  background-color: @section-bg-color;
  padding: @mg;
  border-radius: @mg;

  .kline-con {
    width: 100%;
    padding: @mg;
    opacity: 0;
    position: absolute;
    top: -10000000px;
    background-color: @content-bg-color;
    box-sizing: border-box;
    border-radius: 0 @mg @mg @mg;
  }

  .txns-con {
    width: 100%;
    box-sizing: border-box;
    padding: @mg;
    opacity: 0;
    position: absolute;
    top: -10000000px;
    background-color: @content-bg-color;
    border-radius: 0 @mg @mg @mg;
  }
}

.logs-con{
  width: 100%;
  margin: 0 0 15px 0;
  opacity: 0;
  position: absolute;
  top: -10000000px;

  .trade-logs{
    font-size: 12px;
    flex: 1;

    .log-item{
      display: flex;
      align-items: center;

      .side {
        padding-right: 10px;
      }
      .time {
        padding-right: 10px;
      }
      .price {
        padding-right: 10px;
      }
      .amount {
        padding-right: 10px;
      }
    }
  }
  .running-logs {
    font-size: 12px;
    flex: 1;

    .log-item {
      display: flex;
      align-items: center;

      .time {
        padding-right: 10px;
      }
    }
  }
}

</style>