module.exports = {
    header: {
        Trade: 'Trade',
        News: 'History News',
        Analysis: 'Analysis',
        Singals: 'Singals',
        Strategy: 'Strategy',
        Simulate: 'Simulate',
        NFT: 'NFT',
        Backback: 'Backback',
        search:{
            placeholder: 'Please enter the trading pair',
            resultTitle: 'Search results',
            placeholderNft: 'Please enter the collection symbol'
        },
        accountModal: {
            title: 'Account',
            connect: 'Connect',
            disconnect: 'Disconnect',
            address: 'Address',
            balance: 'Balance'
        }
    },
    trade: {
        form: {
            pricePlaceholder: 'Select or enter price',
            priceLimit: 'Price limit',
            amountPlaceholder: 'Please enter the amount',
            amount: 'Amount',
            numberPlaceholder: 'Please enter quantity',
            number: 'Quantity',
            stopPlaceholder: 'Please enter the percentage',
            stop: 'Stop Loss Percent',
            free: 'Free',
            expectedStopPrice: 'Expected stop loss price'
        }
    },
    tabs: {
        news: 'News',
        singals: 'Singals',
        ordinary: 'Ordinary',
        advanced: 'Advanced',
        config: 'Config',
        tasks: 'Tasks',
        orders: 'Orders',
        trades: 'Trades',
        account: 'Account',
        buy: 'Buy',
        sell: 'Sell'
    },
    kline: {
        open: 'Open',
        close: 'Close',
        high: 'High',
        low: 'Low',
        change: 'Change',
        amplitude: 'Amplitude',
        volume: 'Volume'
    },
    articles: {
        aiAnalysis: 'AI Interpretation',
        aiDecode: 'AI Decode',
        aiWarning: 'The above results are provided by ChatGPT and do not represent the platform\'s viewpoint. Please evaluate on your own!',
        isNotSupported: 'The system currently does not support interpretation in your language environment.',
        aiDecodeFailed: 'AI interpretation failed.'
    },
    strategies: {
        'SPOT_GRID': 'SPOT_GRID'
    },
    free: 'Free',
    pointsBalance: 'remaining points',
    priceUnit: 'points per deduction',
    volume: 'Volume',
    change: 'Change',
    high: 'High',
    low: 'Low',
    price: 'Price',
    balance: 'Balance',
    cost: 'Cost',
    profit: 'Profit',
    buy: 'Buy',
    sell: 'Sell',
    marketPrice: 'Market',
    limitPrice: 'Limit',
    noData: 'No data',
    related: 'Related',
    loadMore: 'Load More'
}