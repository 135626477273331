import request from '@/utils/request.js';
import nacl from 'tweetnacl';
import { encodeBase64 } from 'tweetnacl-util';

let config = localStorage.getItem('PACKBACK_CONFIG');
config = config ? JSON.parse(config) : {};
const apiKey = config.apiKey;
const apiSecret = config.apiSecret;

const baseURL = 'https://api.backpack.exchange/api/v1/';
const headers = {
    'X-API-KEY': apiKey,
    'X-WINDOW': 60000
};

function sign(data){
    if(!apiKey || !apiSecret){
        return null;
    }
    data.window = headers['X-WINDOW'];
    // 将对象转换为键/值对数组，并按键名进行排序
    const sortedParams = Object.entries(data).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
    // 将键/值对数组转换为字符串格式
    const message = sortedParams.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    const privateKey = new Uint8Array(Buffer.concat([Buffer.from(apiSecret, "base64"), Buffer.from(apiKey, "base64")]))
    const signatureBytes = nacl.sign.detached(Buffer.from(message), privateKey);

    return encodeBase64(signatureBytes);
}

export async function getAssets(params){
    return request.get({ url: baseURL + 'assets', params, target: 'fireBot' });
}

export async function getKlines(params){
    return request.get({ url: 'https://api.ai-dex.online/backpack/klines', params });
}

export async function getLastPrice(symbol){
    const res = await getKlines({
        "symbol": symbol,
        "interval": "1m"
    });
    if(res && res.data){
        const lastCandle = res.data[res.data.length - 1];
        return lastCandle.close;
    }
    return null;
}

export async function getBalance(params){
    const timestamp = new Date().getTime();
    headers['X-Timestamp'] = timestamp;
    headers['X-Signature'] = sign(Object.assign({}, params, {
        instruction: 'balanceQuery',
        timestamp
    }));

    return request.get({ url: 'https://api.ai-dex.online/backpack/balance', headers, params });
}

export async function makeOrder(params){
    const timestamp = new Date().getTime();
    headers['X-Timestamp'] = timestamp;
    headers['X-Signature'] = sign(Object.assign({}, params, {
        instruction: 'orderExecute',
        timestamp
    }));
    headers['Content-Type'] = 'application/json';

    return request.post({ url: 'https://api.ai-dex.online/backpack/order', headers, params });
}

export async function cancelOrder(params){
    const timestamp = new Date().getTime();
    params = {
        "orderId":"111935998675386368",
        "symbol":"SOL_USDC"
    }
    headers['X-Timestamp'] = timestamp;
    headers['X-Signature'] = sign(Object.assign({}, params, {
        instruction: 'orderCancel',
        timestamp
    }));
    headers['Content-Type'] = 'application/json';

    return request.post({ url: baseURL + 'order', method: 'DELETE', headers, params, target: 'fireBot' });
}