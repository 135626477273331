<template>
  <div class="right-panel">
    <token-info></token-info>

    <el-tabs v-model="activeTabTrade" type="border-card" class="trade-panel">
      <el-tab-pane :label="$t('tabs.ordinary')">
        <basic-buy></basic-buy>
      </el-tab-pane>

      <el-tab-pane :label="$t('tabs.advanced')">
        <advance-buy></advance-buy>
      </el-tab-pane>

      <el-tab-pane :label="$t('tabs.config')">
        <el-radio-group v-model="configForm.defaultMarketType" size="small" class="rpc-type">
          <el-radio-button label="Binance" ></el-radio-button>
          <el-radio-button label="Okex"></el-radio-button>
          <el-radio-button label="Coinbase"></el-radio-button>
        </el-radio-group>
        <div class="h-10"></div>
        <el-input size="small" v-model="configForm.binance.apiKey" placeholder="请输入API Key"></el-input>
        <div class="h-10"></div>
        <el-input size="small" v-model="configForm.binance.secretKey" placeholder="请输入Secret Key"></el-input>
        <div class="h-10"></div>
        <div class="warning-msg">您的ApiKey将存储在您本地电脑的浏览器缓存中，服务端不记录您的ApiKey！</div>
        <div class="execute-btns flex mgt-10">
          <el-button size="small" type="info" class="w-100" @click="clearConfig">删除缓存</el-button>
          <el-button size="small" type="success" class="w-100" @click="saveConfig">保存配置</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import TokenInfo from "./TokenInfo";
import BasicBuy from "./Handles/BasicBuy";
import AdvanceBuy from "./Handles/AdvanceBuy";
import tradeLibs from "@/libs/trade";
import * as func from "@/utils/func";

export default {
  components: {
    TokenInfo,
    BasicBuy,
    AdvanceBuy
  },
  data(){
    return {
      activeTabTrade: '',
      configForm: {
        defaultMarketType: 'Binance',
        binance: {},
        okex: {}
      },
      autoForm: {
        maxBuyPrice: null,
        autoMaxPriceBuyEnable: false,
        minSellPrice: null,
        autoMinPriceSellEnable: false
      },
    }
  },
  created() {
    // 监听切换交易对
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      if (!sg || !sg.symbol) {
        return;
      }
      const exchangeInfo = await this.getExchangeInfo(sg.symbol);
      this.$eventBus.$emit('CHANGE_ExchangeInfo', exchangeInfo);
    });
  },
  methods: {
    /**
     * 获取exchangeInfo
     * @param symbol
     * @returns {Promise<*>}
     */
    async getExchangeInfo(symbol){
      const exchangeInfo = await tradeLibs.exchangeInfo(symbol);
      return exchangeInfo;
    },
    /**
     * 保存配置项
     */
    async saveConfig(){
      if(this.configForm.defaultMarketType == 'Binance'){
        if(!this.configForm.binance.apiKey){
          this.$message.error('apiKey不可以为空');
          return;
        } else if(!this.configForm.binance.secretKey){
          this.$message.error('secretKey不可以为空');
          return;
        }
        const res = await tradeLibs.check(
            this.configForm.binance.apiKey,
            this.configForm.binance.secretKey
        );
        if(!res || !res.permissions || res.permissions.indexOf('SPOT') == -1){
          this.$message.error('此ApiKey无效或者未开启现货权限！');
          return;
        }
        localStorage.setItem('ACCOUNT_CONFIG', JSON.stringify(this.configForm));
        this.$message.success('保存成功');
      } else {
        this.$message.error('目前仅支持Binance Api，其他Api正在开发中！');
        return;
      }
    },
    clearConfig(){
      localStorage.removeItem('ACCOUNT_CONFIG');
      this.$message.success('缓存已删除');
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.right-panel{
  width: 320px;
  margin: @mg;
  border-radius: 25px;
  background-color: @section-bg-color;

  .trade-panel{
    margin: @mg;
  }

  .rpc-type {
    width: 100%;
    display: flex;

    .el-radio-button{
      flex: 1;

      &.is-active {

        /deep/.el-radio-button__inner{
          background-color: #67C23A !important;
          color: #fff;
        }
      }
    }
    /deep/.el-radio-button__inner{
      width: 100%;
    }
  }

  .auto-task {
    border: 1px solid #ccc;
    padding: 10px 10px;
    margin-top: 10px;

    .title {
      font-size: 13px;
      text-align: left;
    }
    .row {
      display: flex;
      align-items: center;
      margin-top: 5px;

      /deep/.el-input-group__prepend, /deep/.el-input-group__append{
        padding: 0 8px;
      }

      .el-switch {
        margin-left: 5px;
      }
    }
  }

  .warning-msg{
    font-size: 12px;
    color: @light-color;
  }
}

@media only screen and (max-width: 750px) {
  .right-panel{
    width: calc(100vw - 32px);
    margin-top: 0;
  }
}
</style>
