<template>
  <div class="section-container">
    <div class="form-con">
      <div class="flex mgt-5">
        <el-input v-model="form.slug" size="small" class="flex-1" placeholder="系列名称"></el-input>
        <div class="w-16"></div>
        <el-button @click="runTask" :type="isRunning ? 'success' : 'warning'" size="small">{{isRunning ? '监控中...' : '开始监控'}}</el-button>
      </div>
    </div>

    <div class="info-con" v-if="eventInfo">
      <el-image :src="eventInfo.exampleUrl" class="cover"></el-image>
      <div class="brief-con">
        <div class="name">{{eventInfo.name}}</div>
        <div class="desc">{{eventInfo.desc}}</div>
        <div class="tags">
          <div class="tag-item">总量：{{eventInfo.totalAmount}}</div>
          <div class="tag-item">已售：{{eventInfo.sellAmount}}</div>
          <div class="tag-item">Pending：{{eventInfo.pendingAmount}}</div>
          <div class="tag-item">剩余：{{eventInfo.inventory}}</div>
          <div class="flex-1"></div>
          <div class="tag-item" :class="checkAuth ? 'text-green' : 'text-red'">{{checkAuth ? '连接成功' : '连接失败'}}</div>
        </div>
      </div>
      <div
          class="event-item"
          :class="{on: form.event && form.event.id === item.id}"
          v-for="(item, idx) in eventInfo.stage"
          :key="idx"
          @click="form.event = item"
      >
        <div class="name">{{item.name}}</div>
        <div class="time">开始：{{$func.getDate(item.startTime/1000)}}</div>
        <div class="time">结束：{{$func.getDate(item.endTime/1000)}}</div>
        <div class="limit">数量：{{item.userLimit}}个/地址</div>
        <div class="status" v-if="eventStatus(item) === 'toBegin'">
          <div>即将开始：</div>
          <el-statistic :value="item.startTime" time-indices class="countdown"></el-statistic>
        </div>
        <div class="status" v-else>{{eventStatus(item)}}</div>
      </div>
    </div>

    <div class="form-con" v-if="eventInfo">
      <div class="flex mgt-5">
        <el-date-picker
            v-model="form.startDateTime"
            type="datetime"
            size="small"
            placeholder="启动时间"
        >
        </el-date-picker>
        <div class="w-16"></div>
        <div class="gas-fees">
          <span class="label">GAS</span>
          <el-tag
              size="mini"
              effect="plain"
              type="warning"
              v-for="(item, key) in gasFees"
              :key="key"
              :class="{active: form.fee === key}"
              @click="form.fee = key"
          >
            {{key}}: {{item}}
          </el-tag>
          <el-input v-model="form.gas" size="small" class="flex-1" placeholder="自定义"></el-input>
        </div>
        <div class="w-16"></div>
        <el-input v-model="form.timeStep" size="small" min="10" class="w-200px" placeholder="轮询间隔时长">
          <template slot="append">ms</template>
        </el-input>
        <div class="w-16"></div>
        <el-button @click="runPanic" :type="isRunningPanic ? 'success' : 'primary'" size="small">
          {{isRunningPanic ? '抢单中...' : '定时开抢'}}
        </el-button>
      </div>
    </div>

    <div class="logs-con scroll-con">
      <div>日志：</div>
      <div class="item" v-for="(item, idx) in logs" :key="idx">
        {{item.time}}: {{item.msg}}
      </div>
    </div>
  </div>
</template>

<script>
import * as GeniiApi from "@/api/geniidata";
import * as MempoolApi from "@/api/mempool";

export default {
  data(){
    return {
      eventInfo: null,
      checkAuth: null,
      gasFees: {},
      form: {
        event: null,
        startDateTime: null,
        fee: 'halfHourFee',
        timeStep: 1000
      },
      isRunning: false,
      isRunningPanic: false,
      isRunningPanicOk: false,
      isSuccess: false,
      logs: []
    }
  },
  methods: {
    /**
     * 启动监控
     */
    async runTask(){
      this.isRunning = !this.isRunning;
      let isFirst = true;
      while(true) {
        if(!this.isRunning){
          this.log('停止数据监控');
          break;
        }
        if(isFirst){
          this.log('启动数据监控');
        }
        const now = parseInt(new Date().getTime()/1000);
        try {
          if(isFirst || now % 5 === 0){
            this.log('数据监控中...');
            const res = await GeniiApi.getEventInfo('feeling-good');
            if(res && res.data){
              this.$set(this, 'eventInfo', res.data);
            }
          }
          if(isFirst || now % 10 === 0){
            const gasRes = await MempoolApi.getGas();
            if(gasRes && gasRes.data){
              this.$set(this, 'gasFees', gasRes.data);
            }
          }
          if(isFirst || now % 15 === 0){
            GeniiApi.checkAuth().then(checkRes => {
              this.checkAuth = checkRes && checkRes.data === 'success' ? true : false;
            });
          }
        } catch (err) {
          console.log(err.message);
        }

        isFirst = false;
        await this.$func.sleep(1000);
      }
    },
    eventStatus(row){
      const now = new Date().getTime();
      if(row.unlock){
        if(now - row.startTime < 0){
          return 'toBegin';
        }
      } else {
        if(now - row.endTime > 0){
          return 'end';
        } else {
          return 'close';
        }
      }
    },
    /**
     * 启动抢单
     */
    async runPanic(){
      if(!this.form.timeStep || this.form.timeStep - 10 < 0){
        this.$message.error('轮询时间间隔不能小于10ms');
        return;
      }
      if(!this.form.event) {
        this.$message.error('请选择活动');
        return;
      }
      if(!this.form.startDateTime){
        this.$message.error('请选择启动时间');
        return;
      }
      this.log('抢单监控中...');
      const startTime = new Date(this.form.startDateTime).getTime();
      this.isRunningPanic = !this.isRunningPanic;
      let n = 0;
      while(true){
        if(!this.isRunningPanic){
          this.log('停止抢单监控');
          break;
        }
        const now = new Date().getTime();
        if(now - startTime < 0){
          await this.$func.sleep(500);
          continue;
        }
        GeniiApi.sendOrder(this.form.event.id, this.gasFees[this.form.fee]).then(res => {
          if(res && res.data && res.data.id){
            this.log('抢单成功，等待支付！ -> ID:' + res.data.id);
            this.isSuccess = true;
          } else if(n % 5 === 0){
            this.log(JSON.stringify(res));
          }
        });
        if(this.isSuccess){
          break;
        }
        if(n % 10 === 0){
          this.log('已发送' + n + '次');
        }
        n++;
        await this.$func.sleep(parseInt(this.form.timeStep) + 2);
      }
    },
    /**
     * 打印日志
     * @param msg
     */
    log(msg){
      const time = this.$func.getDate();
      this.logs.unshift({
        time,
        msg
      });
      this.logs = this.logs.slice(0, 100);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.form-con {
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .gas-fees {
    display: flex;
    align-items: center;
    background-color: @input-bg-color;
    border-radius: 6px;

    .label{
      font-size: 13px;
      color: @sub-font-color;
      margin-left: @mg;
      padding-right: 6px;
    }
    .el-tag--plain {
      background-color: transparent;
      margin-left: 3px;
      border-color: @content-bg-color;
      cursor: pointer;
      color: @sub-font-color;

      &.active {
        color: @light-color;
        border-color: @light-color;
      }
    }
  }
}
.w-300 {
  width: 300px;
}
.w-30p {
  width: 30%;
}

.info-con {
  display: flex;
  .cover {
    width: 120px;
    height: 120px;
    border-radius: 8px;
  }
  .brief-con {
    flex: 1;
    margin-left: @mg;

    .name {
      color: @light-color;
    }
    .desc {
      color: @sub-font-color;
      font-size: 13px;
      margin-top: 10px;
      line-height: 1.5em;
    }
    .tags {
      display: flex;
      .tag-item {
        background-color: @input-bg-color;
        font-size: 12px;
        margin: 10px 10px 10px 0;
        padding: 8px 10px;
        border-radius: 5px;
      }
    }
  }
  .event-item {
    background-color: @input-bg-color;
    width: 180px;
    border-radius: 10px;
    padding: 10px;
    margin-left: @mg;
    cursor: pointer;
    &.on {
      box-shadow: 0 0 3px @light-color;
    }
    .name {
      font-size: 13px;
      color: @font-color;
    }
    .time {
      font-size: 12px;
      color: @sub-font-color;
      margin-top: 5px;
    }
    .limit {
      font-size: 12px;
      color: @sub-font-color;
      margin-top: 5px;
    }
    .status {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: @sub-font-color;
      margin-top: 5px;
      text-align: right;
      /deep/.countdown {
        width: auto;
        .con {
          display: flex;
          justify-content: flex-end;
          .number {
            text-align: right;
            color: @green;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.monitor-con{
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
  display: flex;

  .block-item {
    height: 100px;
    width: 180px;
    background-color: @input-bg-color;
    border-radius: @mg;
    padding: @mg;
  }
}

.logs-con {
  font-size: 13px;
  line-height: 24px;
  color: @sub-font-color;
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
  height: calc(100vh - 75px - @mg*3 - 500px);
}
</style>