<template>
  <div class="left-panel" :class="{show: isShow}">
    <div class="section-con">
      <articles></articles>
    </div>
    <div class="change-show-bth">
      <i class="el-icon-close" @click="isShow = false" v-if="isShow"></i>
      <div class="footer-menu" v-else>
        <span @click="onClickTab($t('tabs.news'))">NEWS</span>
        <span @click="onClickTab($t('tabs.singals'))">SINGALS</span>
      </div>
    </div>
  </div>
</template>

<script>
import Articles from "./Articles";
import Users from "./Users";
export default {
  components: {
    Articles,
    Users
  },
  data(){
    return {
      isShow: false
    }
  },
  methods: {
    onClickTab(idx){
      this.isShow = true;
      this.$eventBus.$emit('CHANGE_ARTICLES_TAB', idx);
    }
  }
}
</script>
