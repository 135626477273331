<template>
  <div>
    <div class="buy-and-sell">
      <div class="buy-or-sell-radio" :class="form.buyOrSellRadio">
        <el-radio v-model="form.buyOrSellRadio" label="buy">{{$t('tabs.buy')}}</el-radio>
        <el-radio v-model="form.buyOrSellRadio" label="sell">{{$t('tabs.sell')}}</el-radio>
      </div>
    </div>

    <div class="selector mgt-10">
      <label>策略类型</label>
      <el-select v-model="form.strategy" size="mini" placeholder="请选择策略">
        <el-option
            v-for="item in strategies"
            :key="item.name"
            :label="$t('strategies.' + item.name)"
            :value="item.name"
        >
          <span style="float: left">{{ $t('strategies.' + item.name) }}</span>
          <span class="text-success fs-12" style="float:right;">{{ item.price || $t('free') }}</span>
        </el-option>
      </el-select>
    </div>
    <div class="number-input">
      <el-input type="number" min="1" step="any" size="mini" v-model="form.startPrice" @focus="onFocusInput('startPrice')" @input="onInputAmount">
        <template slot="prepend">触发价格<span class="from-price">从</span></template>
        <template slot="append">USDT</template>
      </el-input>
      <div class="h-10"></div>
      <el-input type="number" min="1" step="any" size="mini" v-model="form.endPrice" @focus="onFocusInput('endPrice')" @input="onInputAmount">
        <template slot="prepend"><span style="opacity: 0;">触发价格</span><span class="to-price">到</span></template>
        <template slot="append">USDT</template>
      </el-input>
    </div>
    <div class="number-input">
      <el-input type="number" min="1" step="any" size="mini" v-model="form.orderNumber" @input="onInputNumber">
        <template slot="prepend">挂单数量</template>
        <template slot="append">单(2~35)</template>
      </el-input>
      <div class="h-10"></div>
      <el-input type="number" min="1" step="any" size="mini" v-model="form.amount" @input="onInputAmount">
        <template slot="prepend">挂单总额</template>
        <template slot="append">USDT</template>
      </el-input>
    </div>
    <div class="h-10"></div>
    <div class="radio-group">
      <div class="label">执行方式</div>
      <el-radio-group v-model="form.executeMode">
        <el-radio :label="0">平均</el-radio>
        <el-radio :label="1">递增</el-radio>
      </el-radio-group>
    </div>

    <div class="execute-btns">
      <el-button
          :type="form.buyOrSellRadio == 'buy' ? 'success' : 'danger'"
          class="excute-btn"
          size="mini"
          @click="doExecute"
          :loading="isDoExecute"
      >
        执行策略
      </el-button>
    </div>


<!--    <div class="auto-task">-->
<!--      <div class="title">自动交易配置</div>-->
<!--      <div class="row">-->
<!--        <el-input v-model="autoForm.maxBuyPrice" size="mini">-->
<!--          <template slot="prepend">当价格低于</template>-->
<!--          <template slot="append">自动买入</template>-->
<!--        </el-input>-->
<!--        <el-switch-->
<!--            v-model="autoForm.autoMaxPriceBuyEnable"-->
<!--            active-color="#13ce66"-->
<!--        >-->
<!--        </el-switch>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <el-input v-model="autoForm.minSellPrice" size="mini">-->
<!--          <template slot="prepend">当价格高于</template>-->
<!--          <template slot="append">自动卖出</template>-->
<!--        </el-input>-->
<!--        <el-switch-->
<!--            v-model="autoForm.autoMinPriceSellEnable"-->
<!--            active-color="#13ce66"-->
<!--        >-->
<!--        </el-switch>-->
<!--      </div>-->
<!--      <div class="row fs-12 text-danger">30分钟内未找到机会则自动结束任务</div>-->
<!--    </div>-->
<!--    <div class="execute-btns">-->
<!--      <el-button size="medium" class="excute-btn">-->
<!--        启动自动交易-->
<!--      </el-button>-->
<!--    </div>-->
  </div>
</template>

<script>
import {filterParams} from "@/utils/func";

export default {
  data(){
    return {
      strategies: [{
        name: 'SPOT_GRID',
        price: 0
      }],
      sg: null,
      currentSymbol: null,
      exchangeInfo: null,
      form: {
        buyOrSellRadio: 'buy',
        strategy: 'SPOT_GRID',
        startPrice: null,
        endPrice: null,
        orderNumber: null,
        amount: null,
        executeMode: 0,
        focusInput: null
      },
      priceLines: [],
      isDoExecute: false
    }
  },
  created() {
    // 监听切换交易对
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      if (!sg || !sg.symbol) {
        return;
      }
      // reset symbol
      this.sg = sg;
      this.currentSymbol = this.sg.symbol.replace('USDT', '');
    });
    // 监听切换exchangeInfo
    this.$eventBus.$on('CHANGE_ExchangeInfo', exchangeInfo => {
      this.exchangeInfo = exchangeInfo;
    });
  },
  mounted() {
    // 监听拾取价格
    this.$eventBus.$on('PICK_PRICE', (res) => {
      this.form.focusInput && (this.form[this.form.focusInput] = res.price);
      this.drawPriceLines();
    });
  },
  methods:{
    onFocusInput(key){
      this.form.focusInput = key;
    },
    onInputAmount(){
      if(this.form.amount && /^\d+$/.test(this.form.amount)){
        this.drawPriceLines();
      }
    },
    onInputNumber(){
      if(this.form.orderNumber && /^\d+$/.test(this.form.orderNumber) && this.form.orderNumber - 35 <= 0 && this.form.orderNumber - 2 >= 0){
        this.drawPriceLines();
      }
    },
    drawPriceLines(){
      if(this.form.startPrice && this.form.endPrice){
        this.$eventBus.$emit('CLEAR_PRICE_LINE', { type: 'SPOT-GRID' });
        let stepAmount = null;
        if(this.form.orderNumber && this.form.orderNumber - 2 > 0 && this.form.amount && /^\d+$/.test(this.form.amount)){
          stepAmount = parseInt(this.form.amount/this.form.orderNumber);
        } else if(this.form.amount && /^\d+$/.test(this.form.amount)) {
          stepAmount = this.form.amount/2;
        }
        const stepAmountStr = (stepAmount ? '(' + stepAmount + 'U)' : '');
        const color = 'rgba(73,203,243,0.5)';
        const lines = [{
          type: 'SPOT-GRID',
          price: this.form.startPrice,
          amount: stepAmount,
          color,
          label: (this.form.startPrice - this.form.endPrice > 0 ? '网格开始' : '网格结束') + stepAmountStr
        }, {
          type: 'SPOT-GRID',
          price: this.form.endPrice,
          amount: stepAmount,
          color,
          label: (this.form.startPrice - this.form.endPrice > 0 ? '网格结束' : '网格开始') + stepAmountStr
        }];
        let decimals = this.form.startPrice.toString().split('.')[1];
        decimals = decimals ? decimals.length : 0;
        if(this.form.orderNumber && this.form.orderNumber - 2 > 0){
          if(this.form.executeMode === 0){
            // 平均
            const step = Math.abs(this.form.startPrice - this.form.endPrice)/(this.form.orderNumber - 1);
            const maxPrice = Math.max(this.form.startPrice, this.form.endPrice);
            for(let i = 0; i < this.form.orderNumber - 2; i++){
              lines.push({
                type: 'SPOT-GRID',
                price: parseFloat(maxPrice - (i + 1) * step).toFixed(decimals),
                amount: stepAmount,
                color,
                label: '第' + (i + 2) + '单' + stepAmountStr
              });
            }
          }
        }
        this.priceLines = lines;
        this.$eventBus.$emit('DRAW_PRICE_LINE', lines);
        setTimeout(() => {
          this.$eventBus.$emit('CLEAR_PRICE_LINE', { type: 'PICK' });
        }, 200);
      }
    },
    /**
     * 执行策略
     */
    doExecute(){
      if(!this.form.startPrice || !this.form.endPrice){
        this.$message.error('请设置开始和结束价格');
        return;
      }
      if(!this.form.orderNumber){
        this.$message.error('请设置网格数量');
        return;
      }
      if(!this.form.amount){
        this.$message.error('请设置挂单总额');
        return;
      }
      if(!this.sg || !this.exchangeInfo){
        return;
      }
      const pairName = this.sg.symbol;

      let cacheSendOrders = localStorage.getItem('WAIT_SEND_ORDERS');
      cacheSendOrders = cacheSendOrders ? JSON.parse(cacheSendOrders) : [];
      // 删除旧的网格
      const waitSendOrders = [];
      for(let i = 0; i < cacheSendOrders.length; i++){
        const item = cacheSendOrders[i];
        if(item.symbol === pairName && item.type === 'SPOT_GRID_LIMIT'){
          continue;
        }
        waitSendOrders.push(item);
      }

      for(let i = 0; i < this.priceLines.length; i++){
        const item = this.priceLines[i];
        const orderData = this.$func.filterParams({
          price: item.price,
          quantity: item.amount/item.price,
        }, this.exchangeInfo.filters);
        waitSendOrders.push({
          symbol: pairName,
          price: orderData.price,
          quantity: orderData.quantity,
          amount: item.amount,
          side: 'BUY',
          type: 'SPOT_GRID_LIMIT',
          name: '现货网格挂单',
          remark: `价格低于${orderData.price}U时，限价买入${orderData.quantity + ' ' + pairName.replace('USDT', '')}`
        });
      }
      localStorage.setItem('WAIT_SEND_ORDERS', JSON.stringify(waitSendOrders));
      this.$eventBus.$emit('REFRESH_TASKS');
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../../assets/css/trade.less";
</style>