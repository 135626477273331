import { fetchData } from "./common.js";
import Crypto from 'crypto-js';
import { eventBus } from './eventBus.js';

let baseURL = 'http://49.232.25.158:56718/';
const headers = {
    'Content-Type': 'application/json'
}

export default {
    get: async (options) => {
        return new Promise((resolve, reject) => {
            const queryString = options.params ? (new URLSearchParams(options.params).toString()) : '';
            if(options.url && options.url.indexOf('http') === -1){
                options.url = baseURL + options.url + (options.url.indexOf('?') > -1 ? '&' : (queryString ? '?' : '')) + queryString;
            } else {
                options.url = options.url + (options.url.indexOf('?') > -1 ? '&' : (queryString ? '?' : '')) + queryString;
            }
            options.params = {
                headers: options.headers || headers
            };
            options.params.method = 'GET';
            if(options.target && options.target === 'fireBot') {
                if(window.firebotClient){
                    if(options.url.indexOf('http://127.0.0.1') === -1){
                        options.url = 'http://127.0.0.1:' + window.firebotClientConfig.port + '?url=' + btoa(options.url);
                    }
                    fetchData(options, null, null, resolve, reject, options.proxyIP);
                } else {
                    const action = options.action || 'request';
                    delete options.action;
                    const key = Crypto.MD5(JSON.stringify(options)).toString();
                    const timer = setTimeout(() => {
                        eventBus.$off('RES_' + key);
                        reject({ code: 0, msg: 'Request Timeout ' + options.url });
                    }, (options.timeout ? options.timeout : 30) * 1000);
                    eventBus.$once('RES_' + key, res => {
                        clearTimeout(timer);
                        resolve(res);
                    });
                    window.postMessage({ action, target: 'fireBot', key, options }, window.location.origin);
                }
            } else {
                fetchData(options, null, null, resolve, reject, options.proxyIP);
            }
        });
    },
    post: async (options) => {
        return new Promise((resolve, reject) => {
            const data = options.params;
            const body = typeof data === 'string' || data instanceof String ? data : JSON.stringify(data);
            if(options.url && options.url.indexOf('http') === -1){
                options.url = baseURL + options.url;
            }

            options.params = {
                headers: options.headers || headers
            };
            options.params.method = options.method || 'POST';
            options.params.body = body;

            if(options.target && options.target === 'fireBot'){
                if(window.firebotClient){
                    if(options.url.indexOf('http://127.0.0.1') === -1){
                        options.url = 'http://127.0.0.1:' + window.firebotClientConfig.port + '?url=' + btoa(options.url);
                    }
                    fetchData(options, null, null, resolve, reject, options.proxyIP);
                } else {
                    const action = options.action || 'request';
                    delete options.action;
                    const key = Crypto.MD5(JSON.stringify(options)).toString();
                    const timer = setTimeout(() => {
                        eventBus.$off('RES_' + key);
                        reject({ code: 0, msg: 'Request Timeout!' });
                    }, 30*1000);
                    eventBus.$once('RES_' + key, res => {
                        clearTimeout(timer);
                        resolve(res);
                    });
                    window.postMessage({ action, target: 'fireBot', key, options }, window.location.origin);
                }
            } else {
                // encodeURIComponent(body);
                fetchData(options, null, null, resolve, reject, options.proxyIP)
            }
        });
    }
}