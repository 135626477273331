<template>
  <div class="container">
    模板页面
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
