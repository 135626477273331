<template>
  <div class="section-container">
    <div class="form-con">
      <div class="flex mgt-5">
        <el-input v-model="form.txid" size="small" class="flex-1">
          <template slot="prepend">Tx ID</template>
        </el-input>
        <div class="w-16"></div>
        <el-button @click="queryTxInfo" type="success" size="small" v-if="!isLoading">查询</el-button>
      </div>
    </div>

    <div class="info-con" v-if="info">
      <div class="inputs">
        <div class="section-name">Inputs</div>
        <div class="row" v-for="(row, idx) in info.inputInfos" :key="idx">
          <div class="flex">
            <div class="flex-1">{{$com.formatString(row.address, 15, 10)}}</div>
            <div class="value">{{parseFloat(row.value/Math.pow(10, 8)).toFixed(8)}}</div>
          </div>
          <div class="contents">
            <!-- nft -->
            <a :href="'https://magiceden.io/ordinals/item-details/' + r.inscriptionId" target="_blank" class="tag" v-for="(r, i) in row.inscriptions" :key="i">
              <img :src="inscriptions[r.inscriptionId].image" class="cover" v-if="inscriptions[r.inscriptionId].contentType.indexOf('image') > -1 && inscriptions[r.inscriptionId].image" />
              <iframe :src="inscriptions[r.inscriptionId].preview" scrolling="no" class="cover" v-else></iframe>
              <div>
                <div>{{inscriptions[r.inscriptionId].name}} #{{r.inscriptionNumber}}</div>
                <div class="text-red">Sell {{inscriptions[r.inscriptionId].price/Math.pow(10, 8)}}</div>
              </div>
            </a>
            <!-- runes -->
            <a target="_blank" class="tag" v-for="(r, i) in row.runes" :key="i">
              <img :src="rune.imageURI" class="cover" v-if="rune && rune.rune === r.rune" />
              <div>
                <div>{{r.spacedRune}}</div>
                <div>{{r.amount/Math.pow(10, r.divisibility)}}</div>
                <div class="text-red" v-if="rune && row.address === rune.maker && r.amount/Math.pow(10, r.divisibility) - rune.formattedAmount === 0">
                  Sell {{rune.formattedUnitPrice}} sats/{{r.symbol}}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="outputs">
        <div class="section-name">Outputs</div>
        <div class="row" v-for="(row, idx) in info.outputInfos" :key="idx">
          <div class="flex">
            <div class="flex-1" v-if="row.address">{{$com.formatString(row.address, 15, 10)}}</div>
            <div class="flex-1" v-else-if="row.scriptpubkey_type === 'op_return'">OP_RETURN</div>
            <div class="col" v-if="addressTags[row.address]">({{addressTags[row.address]}})</div>
            <div class="col" v-if="rune && row.address === rune.makerReceiveAddress">(卖家)</div>
            <div class="col" v-if="(row.runes && row.runes.length > 0) || (row.inscriptions && row.inscriptions.length > 0)">(买家)</div>
            <div class="value">{{parseFloat(row.value/Math.pow(10, 8)).toFixed(8)}}</div>
          </div>
          <div class="contents">
            <!-- nft -->
            <a :href="'https://magiceden.io/ordinals/item-details/' + r.inscriptionId" target="_blank" class="tag" v-for="(r, i) in row.inscriptions" :key="i">
              <img :src="inscriptions[r.inscriptionId].image" class="cover" v-if="inscriptions[r.inscriptionId].contentType.indexOf('image') > -1 && inscriptions[r.inscriptionId].image" />
              <iframe :src="inscriptions[r.inscriptionId].preview" scrolling="no" class="cover" v-else></iframe>
              <div>
                <div>{{inscriptions[r.inscriptionId].name}} #{{r.inscriptionNumber}}</div>
                <div class="text-green">Receive</div>
              </div>
            </a>
            <!-- runes -->
            <a target="_blank" class="tag" v-for="(r, i) in row.runes" :key="i">
              <img :src="rune.imageURI" class="cover" v-if="rune && rune.rune === r.rune" />
              <div>
                <div>{{r.symbol}} {{r.spacedRune}}</div>
                <div>{{r.amount/Math.pow(10, r.divisibility)}}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as MempoolApi from "@/api/mempool";
import * as MagicApi from "@/api/magic";
import * as bitcoin from "bitcoinjs-lib";

export default {
  data(){
    return {
      addressTags: {},
      form: {
        txid: null
      },
      rune: null,
      txInfo: null,
      info: null,
      inscriptions: {},
      replacesTxs: [],
      isLoading: false
    }
  },
  mounted() {
    const { txid, rune } = this.$route.query;
    if(txid){
      this.form.txid = txid;
      this.rune = rune ? JSON.parse(rune) : null;
      setTimeout(() => {
        this.queryTxInfo();
      }, 300);
    } else {
      this.form.txid = localStorage.getItem('PanicPool_txid');
    }
    // 地址标记
    this.addressTags = {
      'bc1qcq2uv5nk6hec6kvag3wyevp6574qmsm9scjxc2': '手续费',
      '3P4WqXDbSLRhzo2H6MT6YFbvBKBDPLbVtQ': '手续费',
      'bc1pew6s05q67m9vryme0yjnh6xhafw2sldjq35zynxjer405lruz2gsnhapew': '手续费',
      '3Ke21osfhEbEryUeqdwAuAY8VKxm5B9uB2': '手续费'
    };
  },
  methods: {
    /**
     * 查询tx详情
     * @returns {Promise<void>}
     */
    async queryTxInfo(){
      if(!this.form.txid){
        this.$message.error('请输入Tx ID');
        return;
      }
      localStorage.setItem('PanicPool_txid', this.form.txid);
      this.txInfo = await this.loadTxInfo(this.form.txid);
    },
    /**
     * 获取tx详情
     */
    async loadTxInfo(txid){
      try {
        this.info = null;
        // 从第一条购买tx构建nftInput

        const txInfo = await MempoolApi.getTxInfo(txid);

        console.log(txInfo)

        console.log(this.rune)

        const res = await MempoolApi.generatePsbt({ vin: txInfo.vin, vout: txInfo.vout });

        const psbtRes = await MempoolApi.decodePsbt(res.data.psbtHex);
        console.log(psbtRes)
        if(psbtRes && psbtRes.fee){
          // 解析铭文
          const inscriptions = psbtRes.inscriptions;
          for(let inscriptionId in inscriptions){
            const tokenInfo = await MagicApi.getTokenInfo({ tokenId: inscriptionId });
            if(tokenInfo && tokenInfo.data){
              inscriptions[inscriptionId] = Object.assign({}, inscriptions[inscriptionId], tokenInfo.data)
            }
          }
          this.inscriptions = this.parseInscriptions(inscriptions);

          // 解析符文
        }
        this.info = psbtRes;

        return txInfo;
      } catch (err) {
        console.log('Get Tx Info -> ' + err.message);
      }
      return null;
    },
    /**
     * 解析数据
     * @param inscriptions
     * @returns {*}
     */
    parseInscriptions(inscriptions){
      console.log(inscriptions)
      for(let inscriptionId in inscriptions){
        const item = inscriptions[inscriptionId];
        const row = {
          inscriptionId,
          name: item.collection.name,
          symbol: item.collection.symbol,
          inscriptionNumber: item.inscriptionNumber,
          price: item.listedPrice,
          owner: item.address,
          preview: item.preview,
          image: item.image,
          contentType: item.contentType
        };
        inscriptions[inscriptionId] = row;
      }
      return inscriptions;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.form-con {
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
}
.info-con {
  display: flex;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .inputs {
    margin-right: @mg;
  }
  .outputs {
    margin-left: @mg;
  }
  .inputs, .outputs {
    flex: 1;
    .section-name {
      font-size: 13px;
      color: @font-color;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .row {
      line-height: 24px;
      font-size: 13px;
      color: @font-color;
      background-color: @input-bg-color;
      padding: 4px 8px;
      margin-top: 4px;
      border-radius: 5px;
      .col {
        font-size: 12px;
        margin: 0 3px;
        color: @sub-font-color;
      }
      .tag {
        font-size: 12px;
        color: @sub-font-color;
        display: flex;
        align-items: center;
        line-height: 20px;

        .cover {
          width: 60px;
          height: 60px;
          border-radius: 3px;
          border: none;
          overflow: hidden;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>