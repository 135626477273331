import request from "@/utils/request";

function prefixUrl(){
    return 'http://127.0.0.1:' + (window.firebotClientConfig ? window.firebotClientConfig.port : '') + '/';
}

export async function syncAccountByClient(params = {}){
    const options = { url: prefixUrl() + 'account/sync', params };
    options.target = 'fireBot';
    options.timeout = 60;

    return request.get(options).then(res => res && res.data ? res.data : res);
}

export async function saveAccountByClient(params = {}){
    const options = { url: prefixUrl() + 'account/sync', params };
    options.target = 'fireBot';

    return request.post(options).then(res => res && res.data ? res.data : res);
}