import request from '@/utils/request.js';

const prefix = 'https://api.ai-dex.online/';

export async function dataLists(params) {
    return request.get({ url: prefix + 'predict/lists', params })
}

export async function statistic(params){
    return request.get({ url: prefix + 'predict/result-lists', params })
}

export async function analysis(params){
    return request.get({ url: prefix + 'predict/analysis', params })
}

export async function transfers(params){
    return request.get({ url: prefix + 'predict/transfers', params })
}

export async function hourData(params){
    return request.get({ url: prefix + 'predict/hour-data-marked', params })
}

export async function hourDataSource(params){
    return request.get({ url: prefix + 'predict/hour-data-source', params })
}

export async function pickPoints(params) {
    return request.get({ url: prefix + 'predict/pick', params })
}

export async function getGoodChange(params) {
    const url = 'http://35.175.182.226:9098/test/good-change';
    return request.get({ url, params })
}