<template>
  <div class="main-con">
    <div class="nft-con scroll-con">
      <i class="el-icon-loading loading" v-if="collectionLists === null || showLoading"></i>

      <div class="nft-item" v-for="(item, idx) in collectionLists" :key="idx" v-if="isShowItem(item)">
        <div class="img-con">
          <el-image :src="item.image" fit="cover" lazy class="cover"></el-image>
        </div>
        <div class="data-col name-col">
          <div class="title">
            <a :href="'https://magiceden.io/ordinals/marketplace/' + item.collectionSymbol" target="_blank">{{item.name}}</a>
          </div>
          <div class="sub-title mgt-10">{{item.collectionSymbol}}</div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Floor:</span> {{item.fp}} <span class="currency">{{item.currency}}</span>
          </div>
          <div class="mgt-10">
            <span class="label">MCAP:</span> {{$com.fixed(item.marketCap, 2)}} <span class="currency">{{item.currency}}</span>
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Supply:</span> {{item.totalSupply}}
          </div>
          <div class="mgt-10">
            <span class="label">Owner:</span> {{item.ownerCount}}
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Volume:</span> {{$com.fixed(item.totalVol, 2)}} <span class="currency">{{item.currency}}</span>
          </div>
          <div class="mgt-10">
            <span class="label">Listed:</span> {{item.listedCount}}
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Sales:</span> {{item.txns}}
          </div>
          <div class="mgt-10">
            <span class="label">Pending:</span> {{item.pending}} ...
          </div>
        </div>
        <div class="flex-1"></div>
        <div class="data-col handle-col">
          <i
              class="like-btn"
              :class="likes.indexOf(item.collectionSymbol) > -1 ? 'el-icon-star-on on' : 'el-icon-star-off'"
              @click="excuteLikeCollection(item)"
          ></i>
          <router-link :to="'nft-info?symbol=' + item.collectionSymbol">
            <i class="el-icon-view view-btn"></i>
          </router-link>
          <i
              class="el-icon-setting setting-btn"
              :class="{on: nftTaskConfig[item.collectionSymbol]}"
              @click="onShowSettingDialog(item)"
          ></i>
          <div class="text-bth" @click="onShowOfferDialog(item)">发Offer</div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
          :page-size="pageSize"
          :current-page="currentPage"
          layout="prev, next"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @current-change="onChangePage"
      ></el-pagination>
    </div>

    <!-- setting dialog -->
    <el-dialog
        :title="'规则设置: ' + handleItem.collectionSymbol"
        class="setting-dialog"
        :visible.sync="showSettingDialog"
        v-if="handleItem"
    >
      <el-form ref="form" :model="settingForm" label-position="top">
        <el-form-item label="策略">
          <el-select v-model="settingForm.strategy" size="small" class="w-100" placeholder="请选择策略">
            <el-option label="抢TopOffer" value="topOffer"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认报价（相对地板价的百分比）">
          <el-input v-model="settingForm.defaultPriceRate" size="small" class="w-100"></el-input>
        </el-form-item>
        <el-form-item label="与系列地板最小差价">
          <el-input v-model="settingForm.minDiffPrice" size="small" class="w-100"></el-input>
        </el-form-item>
        <el-form-item label="强制撤单差价">
          <el-input v-model="settingForm.forceCancelDiffPrice" size="small" class="w-100"></el-input>
        </el-form-item>
        <el-form-item label="每次加价">
          <el-input v-model="settingForm.stepPrice" size="small" class="w-100"></el-input>
        </el-form-item>

        <el-form-item class="text-right">
          <el-button type="success" size="small" @click="onSaveConfig">保存配置</el-button>
          <el-button size="small" @click="showSettingDialog = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- offer dialog -->
    <el-dialog
        :title="'系列Offer: ' + handleItem.collectionSymbol"
        class="setting-dialog"
        :visible.sync="showOfferDialog"
        v-if="handleItem"
    >
      <div class="offers scroll-con" v-if="handleItem.offers">
        <div class="data-row" v-for="(item, idx) in handleItem.offers" :key="idx">
          <div class="col w-100px">{{item.price}}</div>
          <div class="col w-150px">{{$com.formatString(item.maker, 4, 4)}}</div>
          <div class="col w-50px">{{item.quantity}}</div>
          <div class="col text-right flex-1">{{$func.getDate(item.expireTime)}}</div>
        </div>
      </div>

      <el-form ref="form" :model="offerForm" label-position="top">
        <el-form-item label="报价(BTC)">
          <el-input v-model="offerForm.price" size="small" class="w-100"></el-input>
        </el-form-item>
        <el-form-item label="有效时长(s)">
          <el-input v-model="offerForm.expire" size="small" class="w-100"></el-input>
        </el-form-item>

        <el-form-item>
          <div class="flex">
            <div class="flex-1 text-warning">Floor: {{handleItem.fp}} {{handleItem.currency}}</div>
            <el-button type="success" size="small" @click="sendCollectionOffer">发布</el-button>
            <el-button size="small" @click="showOfferDialog = false">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  props: ['showMode'],
  data(){
    return {
      currentAccount: window.currentAccount,
      currentPublicKey: window.currentPubkey,
      collectionLists: null,
      pageOffset: 0,
      currentPage: 1,
      total: 2000,
      pageSize: 20,
      showLoading: false,
      likes: [],
      showSettingDialog: false,
      handleItem: null,
      settingForm: {
        strategy: 'TopOffer',
        defaultPriceRate: 80,
        minDiffPrice: 0.005,
        forceCancelDiffPrice: 0.003,
        stepPrice: 0.00000001
      },
      nftTaskConfig: {},
      showOfferDialog: false,
      offerForm: {
        price: null,
        expire: 60
      },
      waitDestroy: null
    }
  },
  created() {
    this.$eventBus.$on('ACCOUNT_CONNECTED', async (account) => {
      this.currentAccount = account.address;
      this.currentPublicKey = account.pubkey;
      this.loadTaskConfig();
      await this.loadLikeCollections();
      if(this.showMode === 'like'){
        this.loadCollections();
      }
    });
    // 监听搜索
    this.$eventBus.$on('ON_SEARCH_COLLECTION', (searchKey) => {
      this.searchCollection(searchKey);
    });
  },
  watch: {
    showMode(newV, oldV){
      if(newV === 'like'){
        this.total = 0;
        this.pageSize = 100;
        this.pageOffset = 0;
        this.loadCollections();
      } else if(newV === 'all'){
        this.total = 2000;
        this.pageSize = 20;
        this.pageOffset = 0;
        this.loadCollections();
      }
    }
  },
  async mounted() {
    await this.$func.sleep(500);
    this.initData();
  },
  beforeDestroy() {
    this.waitDestroy = true;
  },
  methods: {
    async initData(){
      this.loadTaskConfig();
      this.loadLikeCollections();

      while(true){
        if(this.waitDestroy) break;
        await this.loadCollections();
        await this.$func.sleep(15 * 1000);
      }
      this.waitDestroy = null;
    },
    /**
     * 列表中是否显示
     * @param item
     * @returns {boolean}
     */
    isShowItem(item){
      if(this.showMode === 'all'){
        return true;
      } else if(this.showMode === 'like'){
        if(this.likes.indexOf(item.collectionSymbol) > -1){
          return true;
        }
      }
      return false;
    },
    /**
     * 搜索系列
     * @param symbol
     * @returns {Promise<void>}
     */
    async searchCollection(symbol, isInsert = false){
      const info = await magicApi.loadInfo({ symbol });
      if(info){
        if(isInsert){
          return info;
        } else {
          this.collectionLists = [info]
        }
      }
    },
    /**
     * 切换页码
     * @param p
     */
    onChangePage(p){
      this.currentPage = p;
      this.pageOffset = (p - 1)*this.pageSize;
      this.showLoading = true;
      this.loadCollections();
    },
    /**
     * 加载全部系列
     * @returns {Promise<void>}
     */
    async loadCollections(){
      const res = await magicApi.getTopCollections({
        offset: this.pageOffset,
        limit: this.pageSize
      });
      let lists = res && res.data ? res.data : [];
      if(res && res.data && res.data.length < this.pageSize){
        this.total = this.currentPage * this.pageSize;
      }
      this.showLoading = false;
      if(this.showMode === 'like'){
        lists = await this.fillCollections(lists);
      }
      this.collectionLists = lists;
    },
    /**
     * 补充未显示全的系列
     */
    async fillCollections(lists){
      const symbols = lists.map(r => r.collectionSymbol);
      for(let i =0; i < this.likes.length; i++){
        const symbol = this.likes[i];
        if(symbols.indexOf(symbol) === -1){
          const info = await this.searchCollection(symbol, true);
          lists.push(info);
        }
      }
      return lists;
    },
    /**
     * 关注的系列
     * @returns {Promise<void>}
     */
    async loadLikeCollections(){
      if(!this.currentAccount){
        return null;
      }
      /*const res = await magicApi.likeCollections({ address: this.currentAccount });
      if(res && res.data){
        this.likes = res.data;
      }*/

      const res = localStorage.getItem('LIKES_COLLECTIONS_' + this.currentAccount);
      const likes = res ? JSON.parse(res) : [];
      this.likes = likes;
    },
    /**
     * 关注/取关 系列
     * @param item
     * @returns {Promise<void>}
     */
    async excuteLikeCollection(item){
      if(!this.currentAccount){
        this.$message.error('Wallet not connected!');
        return;
      }
      // backend version
      /*const res = await magicApi.excuteLike({
        symbol: item.collectionSymbol,
        userAddress: this.currentAccount
      });
      if(!res || !res.data){
        this.$message.error('操作失败');
        return;
      }
      if(res.data.mode == 1) {
        this.likes.push(item.collectionSymbol);
      } else if(res.data.mode == -1) {
        const pos = this.likes.indexOf(item.collectionSymbol);
        // remove
        this.likes.splice(pos, 1);
      }*/

      // frontend version
      const pos = this.likes.indexOf(item.collectionSymbol);
      if(pos > -1){
        // remove
        this.likes.splice(pos, 1);
        // 删除配置
        delete this.nftTaskConfig[item.collectionSymbol];
        localStorage.setItem('NFT_TASK_CONFIG_' + this.currentAccount, JSON.stringify(this.nftTaskConfig));
      } else {
        // add
        this.likes.push(item.collectionSymbol);
      }
      localStorage.setItem('LIKES_COLLECTIONS_' + this.currentAccount, JSON.stringify(this.likes));
    },
    /**
     * 加载配置信息
     * @returns {Promise<void>}
     */
    async loadTaskConfig(){
      if(!this.currentAccount){
        return;
      }
      // 加载nft任务配置
      /*const nftTaskConfig = localStorage.getItem('NFT_TASK_CONFIG_' + this.currentAccount);
      this.nftTaskConfig = nftTaskConfig ? JSON.parse(nftTaskConfig) : {};*/
      magicApi.getConfig({ address: this.currentAccount }).then(res => {
        if(res && res.data){
          this.$set(this, 'nftTaskConfig', res.data);
        }
      });
    },
    /**
     * 策略配置
     * @param item
     */
    onShowSettingDialog(item){
      this.$set(this, 'handleItem', item);
      this.showSettingDialog = true;
      if(this.nftTaskConfig[item.collectionSymbol]){
        this.$set(this, 'settingForm', this.nftTaskConfig[item.collectionSymbol]);
      }
    },
    /**
     * 保存系列配置
     */
    async onSaveConfig(){
      if(!this.currentAccount){
        this.$message.error('Wallet not connected!');
        return;
      }
      if(this.settingForm.strategy === 'TopOffer'){
        if(!parseFloat(this.settingForm.minDiffPrice)){
          this.$message.error('请设置offer价格与系列地板最小差价');
          return;
        }
        if(!parseFloat(this.settingForm.stepPrice)){
          this.$message.error('请设置offer每次加价');
          return;
        }

        const config = this.settingForm;
        config.name = 'Top Offer Grabbing';
        config.symbol = this.handleItem.collectionSymbol;
        config.defaultPriceRate = parseFloat(config.defaultPriceRate);
        config.minDiffPrice = parseFloat(config.minDiffPrice);
        config.forceCancelDiffPrice = parseFloat(config.forceCancelDiffPrice);
        //config.stepPrice = parseFloat(config.stepPrice);

        /*let allConfigs = localStorage.getItem('NFT_TASK_CONFIG_' + this.currentAccount);
        allConfigs = allConfigs ? JSON.parse(allConfigs) : {};
        allConfigs[config.symbol] = config;
        localStorage.setItem('NFT_TASK_CONFIG_' + this.currentAccount, JSON.stringify(allConfigs));*/

        this.nftTaskConfig[config.symbol] = config;
        const res = await magicApi.saveConfig({
          userAddress: this.currentAccount,
          configParams: this.nftTaskConfig
        });
        if(res && res.data){
          this.$message.success('配置保存成功');
        } else {
          this.$message.error('配置保存失败');
        }

        this.showSettingDialog = false;
        this.handleItem = {};
      }
    },
    /**
     * 发系列offer弹出
     * @param item
     */
    onShowOfferDialog(item){
      this.$set(this, 'handleItem', item);
      this.showOfferDialog = true;
      magicApi.getCollectionOffers({ symbol: item.collectionSymbol }).then(offers => {
        this.$set(this.handleItem, 'offers', offers);
        if(offers.length > 0){
          this.offerForm.price = offers[0].price;
        }
      });
    },
    /**
     * 发布系列offer
     * @returns {Promise<void>}
     */
    async sendCollectionOffer(){
      if(!this.handleItem){
        return;
      }
      if(!this.handleItem.collectionSymbol){
        return;
      }
      if(!this.handleItem.fp){
        return;
      }
      if(!this.currentAccount){
        this.$message.error('未连接钱包');
        return;
      }
      if(!this.offerForm.price){
        this.$message.error('请输入价格');
        return;
      }
      if(this.offerForm.price - this.handleItem.fp >= 0){
        const confirm = await this.$confirm('Offer出价高出地板价，是否继续发送？', '注意', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => {});
        if(confirm !== 'confirm'){
          return;
        }
      }
      const res = await magicApi.makeCollectionOffer(
          this.handleItem.collectionSymbol,
          this.offerForm.price,
          this.currentAccount,
          this.currentPublicKey,
          this.offerForm.expire
      );

      if(res.code === 200 && res.data && res.data.offerIds){
        this.showOfferDialog = false;
        this.$message.success('发送成功');
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.main-con {
  position: relative;
  flex: 1;
}

.nft-con{
  margin: @mg auto;
  padding: 0 @mg @mg @mg;
  border-radius: 25px;
  position: relative;
  flex: 1;
  background-color: @section-bg-color;
  height: calc(100vh - 75px - @mg*3 - 5px - 32px);
}
.nft-item {
  display: flex;
  align-items: center;
  margin-top: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .img-con{
    width: 40px;
    height: 40px;
    margin-right: 15px;

    .cover{
      width: 40px;
      height: 40px;
      border-radius: 50%;

      /deep/.el-image__error, /deep/.el-image__placeholder{
        background-color: @content-bg-color;
        background-image: url("../../../assets/imgs/logo-icon.png");
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center;
        filter: grayscale(1);
        opacity: 0.15;
        font-size: 12px;
      }
    }
  }

  .data-col {
    min-width: 200px;
    font-size: 13px;

    &.name-col {
      width: 240px;

      a{
        text-decoration: none;
        color: @light-color;
      }
    }
    &.handle-col {
      padding: 0 15px;
      min-width: auto;
      width: 200px;
      display: flex;
      justify-content: right;
      align-items: center;

      i {
        font-size: 18px;
        cursor: pointer;
        color: @font-color;
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
      }
      .view-btn{
        margin-left: 15px;
      }
      .like-btn {
        font-size: 18px;
        &.on{
          color: @light-color;
          font-size: 20px;
        }
      }
      .setting-btn {
        margin-left: 15px;
        font-size: 17px;

        &.on{
          color: @light-color;
        }
      }
      .text-bth {
        margin-left: 15px;
        font-size: 12px;
        cursor: pointer;
        border: 1px solid @font-color;
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
    .title {
      color: @light-color;
    }
    .sub-title {
      color: @font-color;
    }
    .label {
      color: @sub-font-color;
    }
    .currency {
      font-size: 12px;
      color: @sub-font-color;
    }
  }
}

.setting-dialog {
  /deep/.el-dialog{
    width: 500px;
    .el-dialog__title {
      color: @light-color;
    }
  }
  /deep/.el-form {
    .el-form-item {
      margin-bottom: 5px;
    }
    .el-form-item__label {
      line-height: 20px;
      padding-bottom: 5px;
      color: @font-color;
    }
  }

  .offers {
    color: @font-color;
    margin-bottom: @mg;
    max-height: 150px;
    background-color: @content-bg-color;
    padding: @mg @mg 5px @mg;
    border-radius: @mg;

    .data-row {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      font-size: 12px;
    }
  }
}
</style>