import { render, staticRenderFns } from "./LeftPanel.vue?vue&type=template&id=37e47445"
import script from "./LeftPanel.vue?vue&type=script&lang=js"
export * from "./LeftPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports