<template>
  <div>
    <div class="buy-and-sell">
      <div class="buy-or-sell-radio" :class="form.buyOrSellRadio">
        <el-radio v-model="form.buyOrSellRadio" label="buy">{{$t('tabs.buy')}}</el-radio>
        <el-radio v-model="form.buyOrSellRadio" label="sell">{{$t('tabs.sell')}}</el-radio>
      </div>
    </div>

    <div class="number-input">
      <el-input type="number" min="0" step="any" :placeholder="$t('trade.form.pricePlaceholder')" v-model="form.excutePrice" size="medium">
        <template slot="prepend">{{$t('trade.form.priceLimit')}}</template>
        <template slot="append">USDT</template>
      </el-input>
      <div class="h-10"></div>
      <el-input type="number" min="0" step="any" :placeholder="$t('trade.form.amountPlaceholder')" v-model="form.amount" size="medium" @input="onInputAmount">
        <template slot="prepend">{{$t('trade.form.amount')}}</template>
        <template slot="append">USDT</template>
      </el-input>
      <div class="h-10"></div>
      <el-input type="number" min="0" step="any" :placeholder="$t('trade.form.numberPlaceholder')" v-model="form.quantity" size="medium" @input="onInputQuantity">
        <template slot="prepend">{{$t('trade.form.number')}}</template>
        <template slot="append">{{currentSymbol}}</template>
      </el-input>
      <div class="h-10" v-if="form.buyOrSellRadio == 'buy'"></div>
      <el-input
          :placeholder="$t('trade.form.stopPlaceholder')"
          type="number"
          min="0"
          step="any"
          v-model="form.stopPercent"
          size="medium"
          class="stop-percent"
          @input="onInputStopPercent"
          v-if="form.buyOrSellRadio == 'buy'"
      >
        <template slot="prepend">
          {{$t('trade.form.stop')}}
          <span class="" style="margin-right: -35px;margin-left: 25px;">-</span>
        </template>
        <template slot="append">%</template>
      </el-input>
      <div class="text-right text-danger fs-12 pdt-5" v-if="form.stopPrice">
        {{$t('trade.form.expectedStopPrice')}}: {{form.stopPrice}}
      </div>
      <div class="h-10"></div>
      <el-radio-group
          v-model="buyOrSellPercent"
          size="mini"
          fill="rgba(0, 0, 0, 0)"
          class="sell-percent"
          @change="setBuyOrSellPercent"
      >
        <el-radio-button label="25%"></el-radio-button>
        <el-radio-button label="50%"></el-radio-button>
        <el-radio-button label="75%"></el-radio-button>
        <el-radio-button label="100%"></el-radio-button>
      </el-radio-group>
    </div>

    <div class="trade-data-row" v-if="balances">
      <label>{{$t('trade.form.free')}}:</label>
      <span v-if="form.buyOrSellRadio == 'buy'">{{balances['USDT'] ? parseFloat(balances['USDT'].free) : 0}} USDT</span>
      <span v-else>{{balances[currentSymbol] ? parseFloat(balances[currentSymbol].free) : 0}} {{currentSymbol}}</span>
    </div>

    <div class="execute-btns">
      <el-button
          :type="form.buyOrSellRadio == 'buy' ? 'success' : 'danger'"
          class="excute-btn"
          size="medium"
          @click="doExecute('limit')"
          :loading="isDoExecute"
      >
        {{$t('limitPrice')}} {{ form.buyOrSellRadio == 'buy' ? $t('buy') : $t('sell')}}
      </el-button>
      <el-button size="medium" @click="doExecute('market')">
        {{$t('marketPrice')}} {{ form.buyOrSellRadio == 'buy' ? $t('buy') : $t('sell')}}
      </el-button>
    </div>
  </div>
</template>

<script>
import * as func from "@/utils/func";
import * as binanceApi from "@/api/binance";
import tradeLibs from "@/libs/trade";

export default {
  data(){
    return {
      spotAccount: null,
      exchangeInfo: null,
      balances: null,
      sg: null,
      currentSymbol: null,
      lastPrice: null,
      form: {
        buyOrSellRadio: 'buy',
        amount: 0,
        quantity: 0,
        excutePrice: null, // 限价
        stopPercent: null, // 止损%
        stopPrice: null
      },
      buyOrSellPercent: null,
      inputTime: 0,
      inputTimer: null,
      isDoExecute: false,
    }
  },
  created() {
    // 监听最新价发生变化
    this.$eventBus.$on('SYMBOL_DAILY_STATS', (dailyStats) => {
      this.lastPrice = dailyStats.lastPrice;
    });
    // 监听现货账户信息更新
    this.$eventBus.$on('SPOT_ACCOUNT', (accountInfo) => {
      this.spotAccount = accountInfo;
      this.balances = accountInfo.balances;
    });
    // 监听拾取价格
    this.$eventBus.$on('PICK_PRICE', (res) => {
      this.form.excutePrice = res.price;
    });
    // 监听切换交易对
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      if(!sg || !sg.symbol){
        return;
      }
      // reset form
      this.$set(this, 'form', Object.assign(this.form, {
        amount: 0,
        quantity: 0,
        excutePrice: null,
        stopPercent: null,
        stopPrice: null
      }));
      // reset symbol
      this.sg = sg;
      this.currentSymbol = this.sg.symbol.replace('USDT', '');
      this.listenCandle();
    });
    // 监听切换exchangeInfo
    this.$eventBus.$on('CHANGE_ExchangeInfo', exchangeInfo => {
      this.exchangeInfo = exchangeInfo;
    });
  },
  methods:{
    /**
     * 监听最新价格
     */
    listenCandle(){
      this.$eventBus.$on('CANDLE_DATA_' + this.sg.symbol, (candle) => {
        this.lastPrice = candle.close;
        this.cauAmountQuantity();
      });
    },
    /**
     * 切换比例
     */
    setBuyOrSellPercent(v){
      v = v.replace('%', '');
      if(!this.exchangeInfo || !this.balances || !this.currentSymbol){
        console.log('network error');
        return;
      }
      if(this.form.buyOrSellRadio == 'buy') {
        this.form.amount = v * this.balances['USDT'].free / 100;
      } else {
        const balance = this.balances[this.currentSymbol] ? this.balances[this.currentSymbol].free : 0;
        this.form.quantity = v * balance / 100;
      }
      this.cauAmountQuantity();
    },
    onInputAmount(v){
      v = Math.abs(parseFloat(v));
      if(!v){
        return;
      }
      if(!this.exchangeInfo || !this.balances || !this.currentSymbol){
        console.log('network error');
        return;
      }

      this.cauAmountQuantity();
    },
    onInputStopPercent(v){
      if(this.form.buyOrSellRadio != 'buy'){
        return;
      }
      v = Math.abs(parseFloat(v));
      if(!v){
        return;
      }
      this.form.stopPercent = v;
      const price = this.form.excutePrice ? this.form.excutePrice : this.lastPrice;
      if(!price){
        return;
      }
      if(!this.form.priceDecimals){
        const priceArr = price.toString().split('.');
        this.form.priceDecimals = priceArr[1] ? priceArr[1].length : 2;
      }
      this.form.stopPrice = parseFloat(price * (100 - this.form.stopPercent)/100).toFixed(this.form.priceDecimals);
    },
    onInputQuantity(v){
      v = Math.abs(parseFloat(v));
      if(!v){
        return;
      }
      if(!this.exchangeInfo || !this.balances || !this.currentSymbol){
        console.log('network error');
        return;
      }
      this.cauAmountQuantity();
    },
    cauAmountQuantity(){
      if(!this.exchangeInfo){
        return;
      }
      // 正在输入
      const now = new Date().getTime();
      if(now - this.inputTime < 600){
        this.inputTimer && clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(() => {
          this.cauAmountQuantity();
        }, 600);
        return;
      }
      this.inputTime = now;

      const filters = this.exchangeInfo.filters;
      if(this.form.buyOrSellRadio === 'buy'){
        if(!this.form.amount){
          return;
        }
        const price = this.form.excutePrice ? this.form.excutePrice : this.lastPrice;
        this.form.quantity = this.form.amount / price;
        this.form.price = price;
        this.form = func.filterParams(this.form, filters);
      } else {
        if(!this.form.quantity){
          return;
        }
        const price = this.form.excutePrice ? this.form.excutePrice : this.lastPrice;
        this.form.amount = this.form.quantity * price;
        this.form.price = price;
        this.form = func.filterParams(this.form, filters);
      }
      this.form.amount = parseFloat(parseFloat(this.form.amount).toFixed(this.form.priceDecimals));
    },
    /**
     * 立即执行买卖操作
     */
    async doExecute(mode){
      if(this.isDoExecute){
        return;
      }
      const pairName = this.sg.symbol;
      if(!this.form.quantity){
        this.$message.error('请填写数量');
        return;
      }
      if(mode === 'limit'){
        this.form.excutePrice = parseFloat(this.form.excutePrice);
        if(!this.form.excutePrice){
          this.$message.error('请填写限价');
          return;
        }
        const params = {
          symbol: pairName,
          price: this.form.excutePrice,
          quantity: this.form.quantity
        };
        this.isDoExecute = true;
        let waitSendOrders = localStorage.getItem('WAIT_SEND_ORDERS');
        waitSendOrders = waitSendOrders ? JSON.parse(waitSendOrders) : [];
        if(this.form.buyOrSellRadio === 'buy'){
          waitSendOrders.push({
            symbol: pairName,
            price: params.price,
            quantity: params.quantity,
            amount: this.form.amount,
            side: 'BUY',
            name: '限价委托(买入)',
            type: 'STOP_LIMIT',
            remark: `当价格低于${params.price}U时执行买入${this.form.amount}U(${params.quantity}${pairName.replace('USDT', '')})`
          });
          // 预创建止损订单
          if(this.form.stopPrice != null){
            waitSendOrders.push({
              symbol: pairName,
              price: this.form.stopPrice,
              quantity: params.quantity,
              amount: this.form.amount,
              side: 'SELL',
              type: 'STOP_LOSS_LIMIT',
              name: '限价止损',
              remark: `委托单成交后，当价格下跌接近${this.form.stopPrice}U时，创建价格${this.form.stopPrice}U的止损订单`
            });
          }
        } else if(this.form.buyOrSellRadio === 'sell'){
          waitSendOrders.push({
            symbol: pairName,
            price: params.price,
            quantity: params.quantity,
            amount: this.form.amount,
            side: 'SELL',
            name: '限价委托(卖出)',
            type: 'STOP_LIMIT',
            remark: `当价格高于${params.price}U时执行卖出${this.form.amount}U(${params.quantity}${pairName.replace('USDT', '')})`
          });
        }
        localStorage.setItem('WAIT_SEND_ORDERS', JSON.stringify(waitSendOrders));
        this.$eventBus.$emit('REFRESH_TASKS');
        this.isDoExecute = false;
        this.$message.success('挂单成功');
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../../assets/css/trade.less";
</style>