<template>
  <div class="container">
    <div class="left-panel left-side">
      <div class="menu-item" @click="onChangeMenu('all')">
        <router-link to="/nft">
          <span>全部系列</span>
          <i class="el-icon-arrow-right"></i>
        </router-link>
      </div>
      <div class="menu-item" @click="onChangeMenu('like')">
        <span>关注的系列</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" @click="onChangeMenu('my')">
        <span>持有的系列</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <!-- 主内容区 -->
    <div class="nft-con" v-if="info">
      <!-- 系列基本信息 -->
      <div class="nft-item">
        <div class="img-con">
          <el-image :src="info.image" fit="cover" lazy class="cover"></el-image>
        </div>
        <div class="data-col name-col">
          <div class="title">{{info.name}}</div>
          <div class="sub-title symbol-name mgt-10">
            <a :href="'https://magiceden.io/ordinals/marketplace/' + info.collectionSymbol" target="_blank">{{info.collectionSymbol}}</a>
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Floor:</span> {{info.floorPrice}} <span class="currency">{{info.currency}}</span>
          </div>
          <div class="mgt-10">
            <span class="label">MCAP:</span> {{$com.fixed(info.marketCap, 2)}} <span class="currency">{{info.currency}}</span>
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Supply:</span> {{info.totalSupply}}
          </div>
          <div class="mgt-10">
            <span class="label">Listed:</span> {{info.listedCount}}
          </div>
        </div>
        <div class="data-col">
          <div>
            <span class="label">Volume:</span> {{$com.fixed(info.totalVol, 2)}} <span class="currency">{{info.currency}}</span>
          </div>
          <div class="mgt-10">
            <span class="label">Pending:</span> {{info.pending}} ...
          </div>
        </div>
      </div>

      <div class="tab-menus">
        <div class="tab-item" :class="{active: moduleActive === 'token-listed'}" @click="moduleActive='token-listed'">最新挂单</div>
        <div class="tab-item" :class="{active: moduleActive === 'collection-offers'}" @click="moduleActive='collection-offers'">系列offer</div>
        <div class="flex-1"></div>
        <el-button size="mini" type="warning" @click="onShowOfferDialog">发offer</el-button>
        <el-button size="mini" type="warning" @click="showOfferSettingDialog = true">自动Offer配置</el-button>
        <el-button size="mini" type="warning" @click="showListSettingDialog = true">自动List配置</el-button>
      </div>
      <token-listed :info="info" :key="info.collectionSymbol" v-if="info && moduleActive === 'token-listed'" ></token-listed>
      <collection-offers :info="info" :key="info.collectionSymbol" v-else-if="info && moduleActive === 'collection-offers'"></collection-offers>

    </div>

    <!-- offer dialog -->
    <el-dialog
        :title="(offerForm.offerId ? '修改' : '') + '系列Offer: ' + info.collectionSymbol"
        class="offer-dialog"
        :visible.sync="showOfferDialog"
        v-if="info"
    >
      <div class="offers scroll-con" v-if="info.offers">
        <div class="data-row" v-for="(item, idx) in info.offers" :key="idx">
          <div class="col w-100px">{{item.price}}</div>
          <div class="col w-150px">{{$com.formatString(item.maker, 4, 4)}}</div>
          <div class="col w-50px">{{item.quantity}}</div>
          <div class="col text-right flex-1">{{$func.getDate(item.expireTime)}}</div>
        </div>
      </div>

      <el-form ref="form" :model="offerForm" label-position="left">
        <el-form-item>
          <el-input v-model="offerForm.price" size="small" class="w-100">
            <template slot="prepend">Offer出价</template>
            <template slot="append">BTC</template>
          </el-input>
        </el-form-item>
        <div class="warn-msg" v-if="offerForm.price && offerForm.price - info.fp >= 0">警告：offer价格超出地板价</div>
        <el-form-item>
          <el-input v-model="offerForm.expire" size="small" class="w-100">
            <template slot="prepend">有效时长</template>
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <label class="text-normal pdr-10">是否使用签名器</label>
          <el-checkbox v-model="offerForm.useSigner">是</el-checkbox>
        </el-form-item>

        <el-form-item>
          <div class="flex">
            <div class="flex-1 text-warning">Floor: {{info.fp}} {{info.currency}}</div>
            <el-button type="success" size="small" @click="sendCollectionOffer">发布</el-button>
            <el-button size="small" @click="showOfferDialog = false">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- offer setting dialog -->
    <el-dialog
        :title="'自动发送系列Offer配置: ' + info.collectionSymbol"
        class="offer-dialog"
        :visible.sync="showOfferSettingDialog"
        v-if="info"
    >
      <el-form ref="form" :model="offerSettingForm" label-position="top">
        <el-form-item>
          <el-input v-model="offerSettingForm.diffPrice" size="small" class="w-100">
            <template slot="prepend">与地板价差价</template>
            <template slot="append">BTC</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="offerSettingForm.stepPrice" size="small" class="w-100">
            <template slot="prepend">每次加价幅度</template>
            <template slot="append">BTC</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex">
            <div class="flex-1 text-warning">Floor: {{info.fp}} {{info.currency}}</div>
            <el-button type="success" size="small" @click="saveOfferConfig">保存配置</el-button>
            <el-button size="small" @click="showOfferSettingDialog = false">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- list setting dialog -->
    <el-dialog
        :title="'自动挂单配置: ' + info.collectionSymbol"
        class="offer-dialog"
        :visible.sync="showListSettingDialog"
        v-if="info"
    >
      <el-input v-model="listSettingForm.targetPos" size="small" class="w-100">
        <template slot="prepend">目标挂单位置</template>
      </el-input>
      <el-input v-model="listSettingForm.minPrice" size="small" class="w-100 mgt-10">
        <template slot="prepend">最小挂单价</template>
        <template slot="append">BTC</template>
      </el-input>
      <div class="flex mgt-16">
        <div class="flex-1 text-warning">Floor: {{info.fp}} {{info.currency}}</div>
        <el-button type="success" size="small" @click="saveListConfig">保存配置</el-button>
        <el-button size="small" @click="showListSettingDialog = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import * as magicApi from "@/api/magic";
import IconBtc from "@/components/icons/btc";
import TokenListed from "./components/Nft/TokenListed";
import CollectionOffers from "@/pages/components/Nft/CollectionOffers";

export default {
  components:{ IconBtc, TokenListed, CollectionOffers },
  data(){
    return {
      currentAccount: window.currentAccount,
      currentPublicKey: window.currentPubkey,
      menuActive: null,
      moduleActive: 'token-listed',
      symbol: null,
      info: null,
      // 发系列地板
      showOfferDialog: false,
      offerForm: {
        price: null,
        expire: 3600,
        useSigner: window.firebotClient ? true : false
      },
      // 自动发offer配置
      showOfferSettingDialog: false,
      offerSettingForm: {
        diffPrice: null,
        stepPrice: '0.00000001'
      },
      // 自动挂单配置
      showListSettingDialog: false,
      listSettingForm: {
        minPrice: null, // 最小挂单价
        targetPos: null // 目标挂单位置
      }
    }
  },
  async mounted() {
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.currentAccount = account.address;
      this.currentPublicKey = account.pubkey;
      if(window.firebotClient){
        this.offerForm.useSigner = true;
      }
      this.loadCollectionOfferConfig();
      this.loadTokensListConfig();
    });
    this.$eventBus.$on('EDIT_COLLECTION_OFFER', offer => {
      this.onShowOfferDialog(offer);
    });

    const { symbol } = this.$route.query;
    this.symbol = symbol;
    await this.$func.sleep(300);

    // load info
    await this.loadInfo();

    // 加载配置
    this.loadCollectionOfferConfig();
    this.loadTokensListConfig();
  },
  methods: {
    onChangeMenu(name){
      this.menuActive = name;
    },
    /**
     * 加载系列详情
     * @returns {Promise<void>}
     */
    async loadInfo(){
      try {
        const info = await magicApi.loadInfo({ symbol: this.symbol });
        const floorRes = await magicApi.getCollectionFloorPrice({ symbol: this.symbol });
        info.floorPrice = floorRes ? floorRes.floorPrice : info.fp;
        this.info = info;
        this.totalListed = parseInt(info.listedCount);
        // 定时更新基础信息
        setInterval(async () => {
          const info = await magicApi.loadInfo({ symbol: this.symbol });
          this.totalListed = parseInt(info.listedCount);
          this.$set(this, 'info', Object.assign({}, this.info, info));
        }, 60*1000);
        // 定时更新地板价
        setInterval(async ()  => {
          const floorRes = await magicApi.getCollectionFloorPrice({ symbol: this.symbol });
          this.$set(this, 'info', Object.assign({}, this.info, floorRes));
        }, 15 * 1000);
      } catch (err) {
        console.log('loadInfo -> ' + err.message);
      }
    },
    /**
     * 加载系列配置
     */
    loadCollectionOfferConfig(){
      if(!this.currentAccount){
        return;
      }
      let configs = window.localStorage.getItem('TASK_AutoCollectionOffers_' + this.currentAccount);
      configs = configs ? JSON.parse(configs) : {};
      if(this.info && configs[this.info.collectionSymbol]){
        this.offerSettingForm = configs[this.info.collectionSymbol];
      }
    },
    loadTokensListConfig(){
      if(!this.currentAccount){
        return;
      }
      let configs = window.localStorage.getItem('TASK_AutoListToken_' + this.currentAccount);
      configs = configs ? JSON.parse(configs) : {};
      if(this.info && configs[this.info.collectionSymbol]){
        this.listSettingForm = configs[this.info.collectionSymbol];
      }
    },
    /**
     * 发系列offer弹出
     */
    onShowOfferDialog(offer){
      this.showOfferDialog = true;
      magicApi.getCollectionOffers({ symbol: this.info.collectionSymbol }).then(offers => {
        this.$set(this.info, 'offers', offers);
        if(offer && offer.price){
          this.offerForm.price = offer.price;
          this.offerForm.offerId = offer.offerId;
        } else if(offers.length > 0){
          this.offerForm.price = Math.round((parseFloat(offers[0].price) + 0.00000001)*Math.pow(10, 8))/Math.pow(10, 8);
        }
      });
    },
    /**
     * 发布系列offer
     * @returns {Promise<void>}
     */
    async sendCollectionOffer(){
      if(!this.info){
        return;
      }
      if(!this.info.collectionSymbol){
        return;
      }
      if(!this.info.fp){
        return;
      }
      if(!this.currentAccount){
        this.$message.error('未连接钱包');
        return;
      }
      if(!this.offerForm.price){
        this.$message.error('请输入价格');
        return;
      }
      if(this.offerForm.price - this.info.fp >= 0){
        const confirm = await this.$confirm('Offer出价高出地板价，是否继续发送？', '注意', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => {});
        if(confirm !== 'confirm'){
          return;
        }
      }
      let res = null;
      if(this.offerForm.offerId){
        // edit
        res = await magicApi.editCollectionOffer(
            this.info.collectionSymbol,
            this.offerForm.price,
            this.offerForm.offerId,
            this.currentAccount,
            this.currentPublicKey,
            this.offerForm.expire,
            this.offerForm.useSigner
        );
      } else {
        // add
        res = await magicApi.makeCollectionOffer(
            this.info.collectionSymbol,
            this.offerForm.price,
            this.currentAccount,
            this.currentPublicKey,
            this.offerForm.expire,
            this.offerForm.useSigner
        );
      }
      console.log(res)
      if(res.code === 200 && res.data && res.data.offerIds){
        this.showOfferDialog = false;
        this.$eventBus.$emit('UPDATE_COLLECTION_OFFERS');
        this.$message.success('系列Offer发送成功');
      } else {
        this.$message.error(res.msg);
      }
    },
    /**
     * 保存自动系列offer配置
     */
    saveOfferConfig(){
      if(!this.currentAccount){
        this.$message.error('未连接钱包');
        return;
      }
      if(!this.info){
        return;
      }
      if(!this.info.collectionSymbol){
        return;
      }
      if(!this.offerSettingForm.diffPrice){
        this.$message.error('与地板价差价');
        return;
      }
      if(!this.offerSettingForm.diffPrice){
        this.$message.error('每次加价幅度');
        return;
      }
      let configs = window.localStorage.getItem('TASK_AutoCollectionOffers_' + this.currentAccount);
      configs = configs ? JSON.parse(configs) : {};
      configs[this.info.collectionSymbol] = this.offerSettingForm;
      window.localStorage.setItem('TASK_AutoCollectionOffers_' + this.currentAccount, JSON.stringify(configs));
      this.showOfferSettingDialog = false;
      this.$message.success('保存成功');
    },
    /**
     * 保存自动挂单配置
     */
    saveListConfig(){
      if(!this.currentAccount){
        this.$message.error('未连接钱包');
        return;
      }
      if(!this.info){
        return;
      }
      if(!this.info.collectionSymbol){
        return;
      }
      if(!this.listSettingForm.targetPos){
        this.$message.error('请设置目标挂单位置');
        return;
      }
      if(!this.listSettingForm.minPrice){
        this.$message.error('请设置最小挂单价');
        return;
      }
      let configs = window.localStorage.getItem('TASK_AutoListToken_' + this.currentAccount);
      configs = configs ? JSON.parse(configs) : {};
      configs[this.info.collectionSymbol] = this.listSettingForm;
      window.localStorage.setItem('TASK_AutoListToken_' + this.currentAccount, JSON.stringify(configs));
      this.showListSettingDialog = false;
      this.$message.success('保存成功');
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.container {
  display: flex;
  justify-content: space-between;
}

.left-side{
  width: 200px;
  background-color: @section-bg-color;
}

.nft-con{
  margin: 0 auto 0 auto;
  position: relative;
  flex: 1;
}

.nft-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: @section-bg-color;
  padding: @mg;
  border-radius: 25px;

  .img-con{
    width: 50px;
    height: 50px;
    margin-right: 15px;

    .cover{
      width: 50px;
      height: 50px;
      border-radius: 25px;

      /deep/.el-image__error, /deep/.el-image__placeholder{
        background-color: @content-bg-color;
        background-image: url("../assets/imgs/logo-icon.png");
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center;
        filter: grayscale(1);
        opacity: 0.15;
        font-size: 12px;
      }
    }
  }

  .data-col {
    min-width: 200px;
    font-size: 15px;

    &.name-col {
      width: 240px;

      .symbol-name {
        a {
          text-decoration: none;
          color: @sub-font-color;
        }
      }
    }
    &.handle-col {
      padding: 0 15px;
      min-width: auto;

      i {
        font-size: 18px;
        cursor: pointer;
        color: @font-color;
      }
      .view-btn{
        margin-left: 15px;
      }
    }
    .title {
      color: @light-color;
    }
    .sub-title {
      color: @font-color;
    }
    .label {
      color: @sub-font-color;
    }
    .currency {
      font-size: 12px;
      color: @sub-font-color;
    }
  }
}
.tab-menus {
  margin-top: @mg;
  .tab-item {
    &.active {
      background-color: @section-bg-color;
    }
  }
}

.offer-dialog {
  /deep/.el-dialog{
    width: 500px;
    .el-dialog__title {
      color: @light-color;
    }
  }
  /deep/.el-form {
    .el-form-item {
      margin-bottom: 5px;
    }
    .el-form-item__label {
      line-height: 20px;
      padding-bottom: 5px;
      color: @font-color;
    }
  }
  .warn-msg {
    color: @red;
    font-size: 13px;
    text-align: right;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .offers {
    color: @font-color;
    margin-bottom: @mg;
    max-height: 150px;
    background-color: @content-bg-color;
    padding: @mg @mg 5px @mg;
    border-radius: @mg;

    .data-row {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      font-size: 12px;
    }
  }
}
</style>