<template>
  <el-tabs v-model="activeTab" @tab-click="onChangeTab">
    <el-tab-pane label="活跃">活跃</el-tab-pane>
    <el-tab-pane label="巨鲸">

    </el-tab-pane>
    <el-tab-pane label="热点">热点地址</el-tab-pane>
    <el-tab-pane label="关注">关注</el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data(){
    return {
      activeTab: 0
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    onChangeTab(idx){
      console.log(idx)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
