<template>
  <div class="chart-con">
    <i class="el-icon-loading loading" v-if="isLoadingKlineData && candleData.length == 0"></i>
    <div id="kline-chart-con"></div>
    <div class="interval-selector">
      <el-tag size="mini" effect="plain" :type="intervalType === '1s' ? 'warning' : 'info'" @click="onChangeKlineInterval('1s')">1S</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '1m' ? 'warning' : 'info'" @click="onChangeKlineInterval('1m')">1M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '3m' ? 'warning' : 'info'" @click="onChangeKlineInterval('3m')">3M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '5m' ? 'warning' : 'info'" @click="onChangeKlineInterval('5m')">5M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '15m' ? 'warning' : 'info'" @click="onChangeKlineInterval('15m')">15M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '1h' ? 'warning' : 'info'" @click="onChangeKlineInterval('1h')">1H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '4h' ? 'warning' : 'info'" @click="onChangeKlineInterval('4h')">4H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType === '1d' ? 'warning' : 'info'" @click="onChangeKlineInterval('1d')">1D</el-tag>
      <div class="flex-1"></div>
      <el-tag size="mini" effect="plain" :type="showStablePrice ? 'warning' : 'info'" @click="onShowStablePrice">PI</el-tag>
    </div>
    <div id="realtime-values" class="realtime-values"></div>
  </div>
</template>

<script>
import * as func from "@/utils/func";
import tradeLibs from "@/libs/trade";
import * as binanceListener from "@/listener/binance";
import * as coinsApi from "@/api/coins";

const exchange = {
  'binance': 'BINANCE',
  'bybit-perps': 'BYBIT'
};
const exchangeNames = {};
for(let k in exchange){
  exchangeNames[exchange[k]] = k;
}
const intervalSecond = {
  '1s': 1,
  '1m': 60,
  '3m': 180,
  '5m': 300,
  '15m': 900,
  '1h': 3600,
  '4h': 4*3600,
  '1d': 24*3600
};
let klineChart, candleSeries;

export default {
  data(){
    return {
      currentSymbol: 'BTCUSDT',
      currentExchange: 'BINANCE',
      intervalType: '3m',
      showStablePrice: false,
      currentPrecision: 8,
      klineChartVisibleRange: null,
      isLoadingKlineData: false,
      candleData: [],
      markersData: [],
      priceLines: {},
      nextKlineStartTime: null,
      singalsLists: [],
      newsLists: [],
      stablePrice: {}
    }
  },
  created() {
    this.$eventBus.$on('LAST_SINGALS', (singalsLists) => {
      this.singalsLists = singalsLists;
      this.drawMakerData();
    });
    this.$eventBus.$on('LAST_NEWS', (newsLists) => {
      this.newsLists = newsLists;
      this.drawMakerData();
    });
    // 画价格线
    this.$eventBus.$on('DRAW_PRICE_LINE', params => {
      if(params instanceof Array){
        for(let i in params){
          const item = params[i];
          this.createPriceLine(this.currentSymbol, item.price, item.type, item.color, item.style, item.label);
        }
      } else {
        this.createPriceLine(this.currentSymbol, params.price, params.type, params.color, params.style, params.label);
      }
    });
    // 清除价格线
    this.$eventBus.$on('CLEAR_PRICE_LINE', params => {
      if(params instanceof Array){
        for(let i in params){
          const item = params[i];
          this.clearPriceLine(this.currentSymbol, item.price, item.type);
        }
      } else {
        this.clearPriceLine(this.currentSymbol, params.price, params.type);
      }
    });
    // 新的委托
    this.$eventBus.$on('SPOT_ORDERS', (orders) => {
      for(let i in orders){
        const item = orders[i];
        const color = item.side === 'BUY' ? '#8BD778' : '#FF5E3A';
        const label = item.type === 'LIMIT' ? '委托中(' + item.side + ')' : null;
        this.clearPriceLine(item.symbol, item.price, 'PICK');
        this.createPriceLine(item.symbol, item.price, 'ORDER', color, 'Dashed', label);
      }
    });
    // 取消委托
    this.$eventBus.$on('CANCEL_SPOT_ORDER', (order) => {
      this.clearPriceLine(order.symbol, order.price, 'ORDER');
    });
  },
  mounted() {
    klineChart = null;
    candleSeries = null;
    // 初始化默认symbol
    const { symbol } = this.$route.query;
    let sg;
    if(symbol){
      this.currentSymbol = symbol;
      sg = {
        symbol,
        exchange: 'binance'
      };
      localStorage.setItem('CURRENT_SYMBOL', JSON.stringify(sg));
    } else {
      sg = localStorage.getItem('CURRENT_SYMBOL');
      sg = sg ? JSON.parse(sg) : null;
      if(sg && sg.symbol){
        this.currentExchange = exchange[sg.exchange] ? exchange[sg.exchange] : exchange['binance'];
        this.currentSymbol = sg.symbol;
      }
    }
    sg = {
      symbol: this.currentSymbol,
      exchange: exchangeNames[this.currentExchange]
    }
    this.$eventBus.$emit('CHANGE_SYMBOL', sg);
    // 初始化kline chart
    this.initKlineChart(async () => {
      this.resetKlineChart();
      await this.loadCandleData(this.currentSymbol, this.getCandleStartTime(), this.intervalType, 'next', this.currentExchange);
      this.drawCandleData();
      this.drawMakerData();
    });
    // 监听更新，重新初始化
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      if(!sg || !sg.symbol){
        return;
      }
      // 取消旧的监听
      this.cancelListenCandleData(this.currentSymbol);
      this.currentExchange = exchange[sg.exchange] ? exchange[sg.exchange] : exchange['binance'];
      this.currentSymbol = sg.symbol;
      // 打开新的监听
      this.listenCandleData();
      this.runListener();
      this.resetKlineChart();
      await this.loadCandleData(this.currentSymbol, this.getCandleStartTime(), this.intervalType, 'next', this.currentExchange);
      this.drawCandleData();
      this.resetKlineChartPricePrecision();
      this.drawMakerData();
    });
    this.listenCandleData();
    // run ws
    this.runListener();
    // 窗口大小发生变化
    let windowSize = {}, timerReset = null;
    window.addEventListener('resize', function() {
      clearTimeout(timerReset);
      timerReset = setTimeout(() => {
        if(window.innerWidth - windowSize.width === 0 && window.innerHeight - windowSize.height === 0){
          window.location.reload();
        }
      }, 500);
      windowSize = {
        width: window.innerWidth,
        height: window.innerHeight
      };
    });
  },
  methods: {
    listenCandleData(){
      this.$eventBus.$on('CANDLE_DATA_' + this.currentSymbol, (candle) => {
        candle.change = parseFloat((candle.close - candle.open)/candle.open * 100).toFixed(2);
        const lastCandle = this.candleData.length > 0 ? this.candleData[this.candleData.length - 1] : null;
        if(!lastCandle){
          return;
        }
        if(lastCandle.time == candle.time){
          this.candleData[this.candleData.length - 1] = candle;
          candleSeries && candleSeries.update(candle);
        } else if(candle.time - lastCandle.time > 0) {
          this.candleData.push(candle);
          candleSeries && candleSeries.update(candle);
        }
      });
    },
    cancelListenCandleData(pairName){
      this.$eventBus.$off('CANDLE_DATA_' + pairName);
    },
    /**
     * 初始化k线图
     * @param callback
     * @returns {Promise<void>}
     */
    async initKlineChart(callback){
      if(!klineChart){
        await func.sleep(100);
        const domElement = document.getElementById('kline-chart-con');
        const options = {
          layout: {
            background: {
              color: '#242424',
            },
            textColor: '#B0A492',
          },
          crosshair: {
            mode: 0,
          },
          priceScale: {
            mode: LightweightCharts.PriceScaleMode.Normal
          },
          grid: {
            horzLines: { color: '#353945' },
            vertLines: { color: '#353945' },
          },
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: 20
          },
        };
        if(this.$i18n.locale === 'zh'){
          options.timeScale.timezone = 'Asia/Shanghai';
          options.localization = {
            timeFormatter: (businessDayOrTimestamp) => {
              return func.getDate(businessDayOrTimestamp);
            },
          }
        } else {
          options.timeScale.timezone = 'America/New_York';
          options.localization = {
            timeFormatter: (businessDayOrTimestamp) => {
              return new Date(businessDayOrTimestamp*1000).toLocaleString('en-US', { timeZone: 'America/New_York' });
            },
          }
        }
        klineChart = LightweightCharts.createChart(domElement, options);
        // toolTip
        this.drawToolTip();
        // scroll event
        klineChart.timeScale().subscribeVisibleTimeRangeChange((visibleRange, _) => {
          this.klineChartVisibleRange = visibleRange ? [visibleRange.from, visibleRange.to] : null;
        });
        domElement.addEventListener("mouseup", async (e) => {
          if(!this.klineChartVisibleRange){
            return;
          }
          const currentRange = [this.candleData[0].time, this.candleData[this.candleData.length - 1].time];
          if(this.klineChartVisibleRange[0] > currentRange[0] && this.klineChartVisibleRange[1] < currentRange[1]){
            // 可见范围在已加载数据范围里
            return;
          }

          let startTime = this.klineChartVisibleRange[1] - 1000 * intervalSecond[this.intervalType];
          let loadDirect = null;
          if(this.klineChartVisibleRange[1] >= currentRange[1]) {
            startTime = currentRange[1];
            loadDirect = 'next';
          } else if(startTime < currentRange[0]){
            startTime = currentRange[0] - 1000 * intervalSecond[this.intervalType];
            loadDirect = 'prev';
          }
          if(loadDirect){
            await this.loadCandleData(this.currentSymbol, startTime, this.intervalType, loadDirect, this.currentExchange);
            this.drawCandleData();
            this.drawMakerData();
          }
        });
        // click event
        klineChart.subscribeClick((param) => {
          this.onClickChart(param);
        });
      }
      callback();
    },
    /**
     * 显示参考价
     */
    onShowStablePrice(){
      this.showStablePrice = !this.showStablePrice;
      if(this.showStablePrice){
        this.generateStablePrice(this.currentSymbol, this.candleData[this.candleData.length - 1].time);
      } else {
        this.removeStablePrice();
      }
    },
    /**
     * 绘制参考线
     * @param symbol
     * @param time
     * @returns {Promise<void>}
     */
    removeStablePrice(){
      if(!this.stablePrice){
        return;
      }
      const stablePrice = this.stablePrice;
      stablePrice.day1 && this.clearPriceLine(null, null, 'STABLEPRICE');
      stablePrice.day3 && this.clearPriceLine(null, null, 'STABLEPRICE');
      stablePrice.day7 && this.clearPriceLine(null,null, 'STABLEPRICE');
      stablePrice.day1 && this.clearPriceLine(null,null, 'STABLEPRICE');
      this.stablePrice = {};
    },
    async generateStablePrice(symbol, time){
      const res = await coinsApi.stablePrice({ symbol, startTime: time });
      if(res && res.data){
        this.stablePrice = res.data;
        const stablePrice = res.data;
        stablePrice.day1 && this.createPriceLine(symbol, parseFloat(stablePrice.day1).toFixed(this.currentPrecision), 'STABLEPRICE', 'rgba(73,203,243,.4)', 'dotted', '参考1');
        stablePrice.day3 && this.createPriceLine(symbol, parseFloat(stablePrice.day3).toFixed(this.currentPrecision), 'STABLEPRICE', 'rgba(255,55,115,.4)', 'dotted', '参考2');
        stablePrice.day7 && this.createPriceLine(symbol, parseFloat(stablePrice.day7).toFixed(this.currentPrecision), 'STABLEPRICE', 'rgba(255,238,0,.4)', 'dotted', '参考3');
        stablePrice.day1 && this.createPriceLine(symbol, parseFloat(stablePrice.day1*1.015).toFixed(this.currentPrecision), 'STABLEPRICE', 'rgba(255,238,0,.6)', 'dotted', '参考');
      }
    },
    /**
     * chart 点击事件
     * @param param
     */
    onClickChart(param){
      const { x, y } = param.point;
      const time = param.time;
      // 点击到marker
      const markerHits = this.markersData.filter((marker) => {
        return Math.abs(marker.time - time) < intervalSecond[this.intervalType];
      });
      if(markerHits.length > 0){
        this.$eventBus.$emit('MARKER_CLICK', { symbol: this.currentSymbol, time: markerHits[0].time });
      }
      // 画价格线
      const price = parseFloat(candleSeries.coordinateToPrice(y)).toFixed(this.currentPrecision);
      // v1
      this.clearPriceLine(this.currentSymbol, null, 'PICK');
      this.$eventBus.$emit('PICK_PRICE', { price });
      // 生成价格线
      this.createPriceLine(this.currentSymbol, price, 'PICK', '#a4a002');
      // v2
      /*const existsPrice = this.existsPriceLine(this.currentSymbol, price, 'PICK');
      if(existsPrice){
        this.clearPriceLine(this.currentSymbol, existsPrice, 'PICK');
      } else {
        this.$eventBus.$emit('PICK_PRICE', { price });
        // 生成价格线
        this.createPriceLine(this.currentSymbol, price, 'PICK', '#a4a002');
      }*/

      // 参考价
      if(this.showStablePrice){
        this.removeStablePrice();
        this.generateStablePrice(this.currentSymbol, time);
      }
    },
    /**
     * 绘制实时数据
     */
    drawToolTip(){
      const toolTip = document.getElementById('realtime-values');
      klineChart.subscribeCrosshairMove(param => {
        if (!param.time) {
          toolTip.style.display = 'none';
        } else {
          toolTip.style.display = 'flex';
          const data = param.seriesData.get(candleSeries);
          if(!data){
            return;
          }
          const changeValue = parseFloat((data.close - data.open)/data.open*100).toFixed(2);
          const amplitude = parseFloat((data.high - data.low)/data.low*100).toFixed(2);
          const price = data.value !== undefined ? data.value : data.close;

          const priceStr = `
<span>${this.$t('kline.open')}:${data.open}</span>
<span>${this.$t('kline.close')}:${data.close}</span>
<span>${this.$t('kline.high')}:${data.high}</span>
<span>${this.$t('kline.low')}:${data.low}</span>
            `;
          const changeStr = `
<span>${this.$t('kline.change')}: ${changeValue}</span>
<span>${this.$t('kline.amplitude')}: ${amplitude}</span>
          `;

          toolTip.innerHTML = `
<div class="price-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${priceStr}</div>
<div class="change-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${changeStr}</div>
`;
          const coordinate = candleSeries.priceToCoordinate(price);
          if (coordinate === null) {
            return;
          }
        }
      });
    },
    /**
     * 切换k线时间类型
     * @param iv
     * @returns {Promise<void>}
     */
    async onChangeKlineInterval(iv){
      if(this.intervalType == iv){
        return;
      }
      this.intervalType = iv;

      this.runListener();
      this.resetKlineChart();
      await this.loadCandleData(this.currentSymbol, this.getCandleStartTime(), this.intervalType, 'next', this.currentExchange);
      this.drawCandleData();
      this.drawMakerData();
    },
    /**
     * 重置chart
     */
    resetKlineChart(){
      this.candleData = [];
      this.klineChartVisibleRange = null;
      this.nextKlineStartTime = null;
      if (candleSeries){
        candleSeries.setData([]);
        // clear price line
        this.clearPriceLine();
      }
    },
    /**
     * 绘制蜡烛数据
     */
    drawCandleData(){
      if(!candleSeries){
        candleSeries = klineChart.addCandlestickSeries();
        candleSeries.priceScale().applyOptions({
          autoScale: true
        });

        this.resetKlineChartPricePrecision();
      }
      if(this.candleData.length > 0){
        candleSeries.setData(this.candleData);
      }
    },
    /**
     * 绘制maker数据
     */
    drawMakerData(){
      if(this.candleData.length == 0){
        return;
      }
      const makers = [];
      // singals
      for(let i in this.singalsLists){
        const item = this.singalsLists[i];
        if(item.pairName != this.currentSymbol){
          continue;
        }
        if(item.Timestamp - this.candleData[0].time < 0){
          continue;
        }
        if(item.Timestamp - this.candleData[this.candleData.length - 1].time > 0){
          continue;
        }
        makers.push({
          time: parseInt(item.Timestamp - item.Timestamp % intervalSecond[this.intervalType]),
          position: 'belowBar',
          color: 'rgb(255,238,0)',
          shape: 'arrowUp',
          text: 'S',
          size: 1
        });
      }
      // news
      for(let i in this.newsLists) {
        const item = this.newsLists[i];
        // 对应pair
        let flag = false;
        if(item.suggestions && item.suggestions.length > 0){
          for(let s in item.suggestions){
            if(item.suggestions[s].symbol == this.currentSymbol){
              flag = true;
              break;
            }
          }
        }
        if(!flag){
          continue;
        }
        if(item.timestamp - this.candleData[0].time < 0){
          continue;
        }
        if(item.timestamp - this.candleData[this.candleData.length - 1].time > 0){
          continue;
        }
        makers.push({
          time: parseInt(item.timestamp),
          position: 'belowBar',
          color: 'rgb(255,182,0)',
          shape: 'arrowUp',
          text: (item.isBig ? '⭐' : '') + 'N',
          size: 1,
          type: 'NEWS'
        });
      }

      // test
      let lastGroup = [];
      for(let i = 5; i < this.candleData.length; i++){
        let totalChange = 0;
        for(let n = 0; n < 5; n++){
          totalChange += parseFloat(Math.abs(this.candleData[i - n].change) - 0.3 < 0 ? 0 : this.candleData[i - n].change);
        }
        const c = parseFloat(totalChange/5).toFixed(2);
        lastGroup.push({
          time: parseInt(this.candleData[i].time),
          change: c
        });
        lastGroup = lastGroup.slice(-2);
        const v = lastGroup[0].change > 0 ? parseFloat(lastGroup[lastGroup.length - 1].change/lastGroup[0].change).toFixed(1) : 0;
        let flag = true;
        /*for(let n = 1; n < lastGroup.length; n++){
          if(lastGroup[n].change - lastGroup[n - 1].change < 0){
            flag = false;
            break;
          }
        }*/
        if(c > 0 && v && v - 2 > 0 && this.candleData[i].change - 0.8 > 0 && this.candleData[i - 1].change - 0.5 > 0){
          makers.push({
            time: parseInt(this.candleData[i].time),
            position: 'belowBar',
            color: 'rgb(255,55,115)',
            shape: 'circle',
            text: '',
            size: 1
          });
        }
      }
      makers.sort((a, b) => a.time - b.time);
      this.markersData = makers;
      candleSeries && candleSeries.setMarkers(makers);
    },
    resetKlineChartPricePrecision(){
      const firstCandle = this.candleData.length > 0 ? this.candleData[0] : null;
      let precision = 8, minMove = 0.00000001;
      if(firstCandle){
        const priceArr = [
          firstCandle.open.toString(),
          firstCandle.close.toString(),
          firstCandle.high.toString(),
          firstCandle.low.toString()
        ];
        let len = 0;
        for(let i in priceArr){
          const _arr = priceArr[i].split('.');
          if(_arr[1] && _arr[1].length - len > 0){
            len = _arr[1].length;
          }
        }
        precision = len ? len : 2;
        minMove = parseFloat(Math.pow(10, -precision)).toFixed(precision);
      }
      candleSeries.applyOptions({
        priceFormat: {
          type: 'price',
          precision,
          minMove
        },
      });

      this.currentPrecision = precision;
    },
    /**
     * 获取k线开始时间
     * @returns {number}
     */
    getCandleStartTime() {
      const now = parseInt(new Date().getTime()/1000);
      const startTime = now - intervalSecond[this.intervalType] * 1000;
      return startTime;
    },
    async loadCandleData(pairName, startTime, interval, scrollDirect, exchangeName){
      if(this.isLoadingKlineData){
        return;
      }
      this.isLoadingKlineData = true;
      let res = [];
      try {
        res = await tradeLibs.candles(pairName, interval, startTime, 1000);
      } catch (err) {}
      if(res.length == 0){
        this.isLoadingKlineData = false;
        return;
      }
      this.nextKlineStartTime = res[res.length - 1].openTime/1000;
      let lists = res.map(item => ({
        time: parseInt(item.openTime/1000),
        open: parseFloat(item.open),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        close: parseFloat(item.close),
        volume: parseFloat(item.volume),
        change: parseFloat((item.close - item.open)/item.open*100).toFixed(2)
      }));

      if(scrollDirect == 'prev'){
        lists = lists.filter(item => item.time < this.candleData[0].time);
      } else if (this.candleData.length > 0) {
        lists = lists.filter(item => item.time > this.candleData[this.candleData.length - 1].time);
      }
      this.candleData = scrollDirect == 'prev' ? lists.concat(this.candleData) : this.candleData.concat(lists);
      this.isLoadingKlineData = false;
    },
    /**
     * 监听websocket
     */
    runListener(){
      let pairs = localStorage.getItem('LISTENING_PAIRS');
      pairs = pairs ? pairs.split(',') : [];
      if(pairs.indexOf(this.currentSymbol) == -1){
        pairs.push(this.currentSymbol);
        pairs = pairs.slice(-10);
        localStorage.setItem('LISTENING_PAIRS', pairs.join(','));
      }
      const pairsStr = pairs.map(r => r == this.currentSymbol ? r.toLowerCase() + '@kline_' + this.intervalType : r.toLowerCase() + '@kline_1s');
      binanceListener.run(pairsStr.join('/'));
    },
    /**
     * 价格线处理
     * @param symbol
     * @param price
     * @param color
     * @param style
     * @param label
     */
    createPriceLine(symbol, price, type, color, style, label){
      if(!candleSeries){
        return;
      }
      style = !style ? 'Dotted' : style; // Dotted Dashed
      price = parseFloat(price);
      const params = {
        price,
        color: color,
        lineWidth: 1,
        lineStyle: LightweightCharts.LineStyle[style],
        axisLabelVisible: true
      };
      if(label){
        params.title = label;
      }
      const priceLine = candleSeries.createPriceLine(params);
      this.priceLines[symbol + '_' + price + '_' + type] = priceLine;
    },
    /**
     * 清除价格线
     * @param symbol
     * @param price
     * @param type
     */
    clearPriceLine(symbol, price, type){
      if(!candleSeries){
        return;
      }
      price = price ? parseFloat(price) : null;
      for(let key in this.priceLines){
        const keyArr = key.split('_');
        if(symbol && keyArr[0] !== symbol){
          continue;
        }
        if(price && keyArr[1] - price !== 0){
          continue;
        }
        if(type && keyArr[2] !== type){
          continue;
        }
        candleSeries.removePriceLine(this.priceLines[key]);
        delete this.priceLines[key];
      }
    },
    existsPriceLine(symbol, price, type){
      price = parseFloat(price);
      let flag = null;
      for(let key in this.priceLines){
        const keyArr = key.split('_');
        if(symbol && keyArr[0] != symbol){
          continue;
        }
        if(price && Math.abs((keyArr[1] - price)/price * 100) > 0.1){
          continue;
        }
        if(type && keyArr[2] != type){
          continue;
        }
        flag = keyArr[1];
        break;
      }
      return flag;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/dark.less";

.chart-con {
  height: 100%;
  overflow-y: hidden;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.loading{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 30px;
  z-index: 9999;
  color: @sub-font-color;
}

#kline-chart-con{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.interval-selector {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 999;

  /deep/.el-tag{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &.el-tag--plain.el-tag--info{
      color: @font-color;
    }
    &.el-tag--plain.el-tag--warning{
      color: @light-color;
    }
  }
}

.realtime-values {
  position: absolute;
  left: 246px;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  height: 20px;

  /deep/.price-str{
    span {
      padding-right: 10px;
    }
  }
  /deep/.change-str{
    span {
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .realtime-values{
    left: 20px;
    top: 30px;
    display: block !important;

    div{
      clear: both;
    }
  }
}

</style>