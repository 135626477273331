import request from '@/utils/request.js';

const prefix = 'https://api.ai-dex.online/';

/**
 * 历史新闻
 * @param params
 * @returns {Promise<unknown>}
 */
export async function historyNews(params) {
    // const url = 'http://35.175.182.226:9098/test/good-news';
    return request.get({ url: prefix + 'news/history', params })
}

/**
 * 最近的新闻
 * @param params
 * @returns {Promise<unknown>}
 */
export async function lastNews(params) {
    return request.get({ url: prefix + 'news/last', params })
}

export async function searchNews(params){
    return request.get({ url: prefix + 'news/search', params })
    return null;
}

export async function analysis(params){
    return request.get({ url: prefix + 'news/analysis', params })
    return null;
}