<template>
  <div class="main-con">
    <div class="ststistic-con scroll-con">
      <div class="flex">
        <el-input size="small" v-model="tokenId"></el-input>
        <el-button size="small" @click="searchToken" class="mgl-10">查询</el-button>
        <el-button size="small" @click="checkSymbol" class="mgl-10">异常成交</el-button>
      </div>

      <el-table
          :data="lists"
          :height="windowHeight - 100"
          class="data-lists"
      >
        <el-table-column
            label="KIND"
            width="65"
        >
          <template slot-scope="scope">
            <div :class="scope.row.kind === 'accepted' ? 'text-green' : (scope.row.kind === 'list' ? 'text-red' : '')">{{scope.row.kind}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="USER"
            width="100"
        >
          <template slot-scope="scope">
            <div>{{$com.formatString(scope.row.kind === 'list' ? scope.row.sellerOrdinalsAddress : scope.row.buyerPaymentAddress, 6, 5)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="Price"
            width="100"
        >
          <template slot-scope="scope">
            <div>{{scope.row.price}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="Create Time"
            width="100"
        >
          <template slot-scope="scope">
            <div :class="scope.row.kind === 'offer_cancelled' ? 'text-red' : (scope.row.kind === 'offer' ? 'text-green' : '')">
              {{scope.row.createTime ? $func.getDate(scope.row.createTime) : '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Expire Time"
            width="100"
        >
          <template slot-scope="scope">
            <div>{{scope.row.expireTime ? $func.getDate(scope.row.expireTime) : '-'}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="Status"
            width="100"
        >
          <template slot-scope="scope">
            <div>{{scope.row.expireTime && scope.row.createTime ? scope.row.expireTime - scope.row.createTime : '-'}}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="loading fs-14" v-if="this.isLoading"><i class="el-icon-loading"></i></div>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  data(){
    return {
      windowHeight: window.innerHeight,
      tokenId: null,
      offset: 0,
      lists: [],
      isLoading: false
    }
  },
  methods: {
    /**
     * 查询token
     * @returns {Promise<void>}
     */
    async searchToken(){
      if(!this.tokenId){
        return;
      }
      if(this.isLoading){
        return;
      }
      this.isLoading = true;

      const timeRange = await this.getTimeRange(this.tokenId);
      const activities = await this.getActivites(this.tokenId, timeRange);
      const offers = await this.getOffers(this.tokenId);

      // 重组offers
      const offersObj = {};
      offers.map(item => {
        item.key = item.kind + '_' + item.buyerPaymentAddress + '_' + item.price;
        offersObj[item.key] = item;
      });
      // 遍历activities重组数据
      const lists = [];
      for(let i in activities){
        const item = activities[i];
        item.kind = item.kind === 'offer_placed' ? 'offer' : item.kind;
        item.kind = item.kind === 'offer_accepted_broadcasted' ? 'accepted' : item.kind;
        const key = item.kind + '_' + item.buyerPaymentAddress + '_' + item.price;
        if(item.kind === 'offer' && offersObj[key]){
          offersObj[key].createTime = item.createTime;
          lists.push(offersObj[key]);
        } else {
          lists.push(item);
        }
      }

      // 排序
      lists.sort((a, b) => b.createTime - a.createTime);
      // 去重
      const _lists = [];
      for(let i in lists){
        if(i == 0) continue;
        if(lists[i].key === lists[i - 1].key){
          continue;
        }
        _lists.push(lists[i]);
      }

      this.lists = _lists;

      this.isLoading = false;
    },
    /**
     * 检测异常成交
     * @returns {Promise<void>}
     */
    async checkSymbol(){
      for(let i = 0; i < 100; i++){
        const res = await magicApi.getActivities({
          collectionSymbol: this.tokenId,
          limit: 100,
          offset: i * 100,
          kind: ['buying_broadcasted']
        });
        if(!res || !res.data || !res.data.activities || res.data.activities.length === 0) break;

        console.log(res.data.activities.length)

        let prevSales = [], prevLists = [];
        for(let k in res.data.activities){
          const item = res.data.activities[k];
          const price = item.listedPrice/Math.pow(10, 8);
          if(price - 1 > 0){
            continue;
          }
          // 近期平均成交价
          if(prevSales.length >= 3){
            let sales = [], t = 0, c = 0;
            sales = sales.concat(prevSales);
            sales.sort((a, b) => a - b);
            sales = sales.slice(1, sales.length - 1);
            sales.map(v => {
              t += v;
              c++;
            });
            const aveSalePrice = t/c;

            const change = (price - aveSalePrice)/aveSalePrice * 100;
            if(change - 50 > 0){
              continue;
            }
            if(change < -30){
              console.log(item);
              console.log(this.$func.getDate(parseInt(new Date(item.createdAt).getTime()/1000)) + ' -> ' + price);
              console.log(prevSales);
            }
          }

          if(item.kind === 'buying_broadcasted'){
            prevSales.push(price);
            prevSales = prevSales.slice(-5);
          } else if(item.kind === 'list'){
            prevLists.push(price);
            prevLists = prevLists.slice(-5);
          }
        }
        await this.$func.sleep(1000);
      }
    },
    /**
     * 获取历史offers
     * @param tokenId
     */
    async getOffers(tokenId){
      let offers = [];
      for(let i = 0; i < 10; i++){
        // all
        const res = await magicApi.getOffers({
          token_id: tokenId,
          limit: 100,
          offset: i*100,
          status: 'all'
        });
        if(!res || !res.data || !res.data.offers || res.data.offers.length === 0){
          break;
        }
        offers = offers.concat(res.data.offers);
        await this.$func.sleep(500);
      }

      return offers.map(item => {
        return {
          kind: 'offer',
          offerId: item.id,
          chain: item.token.chain,
          symbol: item.token.collectionSymbol,
          tokenId: item.tokenId,
          price: item.price/Math.pow(10, 8),
          buyerReceiveAddress: item.buyerReceiveAddress,
          buyerPaymentAddress: item.buyerPaymentAddress,
          sellerReceiveAddress: item.sellerReceiveAddress,
          sellerOrdinalsAddress: item.sellerOrdinalsAddress,
          expireTime: parseInt(item.expirationDate/1000),
          isValid: item.isValid
        }
      });
    },
    /**
     * 获取历史activites
     * @param tokenId
     * @param timeRange
     * @returns {Promise<*[]>}
     */
    async getActivites(tokenId, timeRange){
      // 计算offset
      let offset = 0, stepOffset = 1000;
      for(let i = 0; i < 10; i++){
        const res = await magicApi.getActivities({
          tokenId: tokenId,
          limit: 60,
          offset,
          status: 'all',
          kind: ['offer_placed', 'list', 'offer_accepted_broadcasted']
        });
        console.log(res)
        if(!res || !res.data || !res.data.activities) break;
        const dataArr = res.data.activities;
        if(dataArr.length > 0){
          const time1 = parseInt(Date.parse(dataArr[0].createdAt)/1000);
          const time2 = parseInt(Date.parse(dataArr[dataArr.length - 1].createdAt)/1000);
          if(time1 - timeRange.startTime >= 0 && time2 - timeRange.startTime <= 0){
            for(let k in dataArr){
              const prevTime = k > 0 ? parseInt(Date.parse(dataArr[k - 1].createdAt)/1000) : null;
              const time = parseInt(Date.parse(dataArr[k].createdAt)/1000);
              offset++;
              if((prevTime === null || prevTime - timeRange.startTime >= 0) && time - timeRange.startTime <= 0){
                offset = offset - offset%20;
                this.offset = offset;
                break;
              }
            }
            break;
          }
          if(time1 - timeRange.startTime < 0){
            offset -= stepOffset < 60 ? 60 : stepOffset;
            stepOffset = stepOffset/2;
          } else if(time2 - timeRange.startTime > 0) {
            offset += stepOffset < 60 ? 60 : stepOffset;
            stepOffset = stepOffset/2;
          }
        } else {
          offset -= stepOffset < 60 ? 60 : stepOffset;
          stepOffset = stepOffset/2;
        }
        offset = offset - offset%20;

        await this.$func.sleep(500);
      }
      // 查询activities
      const activities = [];
      for(let i = 0; i < 10; i++){
        const res = await magicApi.getActivities({
          tokenId: tokenId,
          limit: 100,
          offset,
          status: 'all',
          kind: ['offer_placed', 'offer_cancelled', 'list', 'offer_accepted_broadcasted']
        });
        if(!res || !res.data || !res.data.activities || res.data.activities.length === 0) break;
        let isEnd = false;
        for(let idx in res.data.activities) {
          const item = res.data.activities[idx];
          const createTime = parseInt(Date.parse(item.createdAt)/1000);
          const price = item.listedPrice/Math.pow(10, 8);
          activities.push({
            kind: item.kind,
            chain: item.chain,
            symbol: item.collectionSymbol,
            tokenId: item.tokenId,
            price,
            buyerReceiveAddress: item.kind === 'list' ? null : item.newOwner,
            buyerPaymentAddress: item.buyerPaymentAddress,
            sellerReceiveAddress: item.sellerPaymentReceiverAddress,
            sellerOrdinalsAddress: item.kind === 'list' ? item.newOwner : item.oldOwner,
            createTime,
            key: item.kind + '_' + item.buyerPaymentAddress + '_' + price
          });
          if(createTime - timeRange.endTime <= 0){
            isEnd = true;
          }
        }
        if(isEnd) break;
        offset += 100;
      }

      return activities;
    },
    // 获取时间范围
    async getTimeRange(tokenId){
      const res = await magicApi.getActivities({
        tokenId: tokenId,
        limit: 100,
        status: 'valid',
        kind: ['list', 'offer_accepted_broadcasted']
      });
      const timeRange = {};
      if(res && res.data && res.data.activities){
        for(let idx in res.data.activities) {
          const item = res.data.activities[idx];
          if(item.kind === 'offer_accepted_broadcasted'){
            timeRange.startTime = parseInt(Date.parse(item.createdAt)/1000);
          }
          if(timeRange.startTime && item.kind === 'list'){
            timeRange.endTime = parseInt(Date.parse(item.createdAt)/1000);
            break;
          }
        }
      }

      return timeRange;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.main-con {
  position: relative;
  flex: 1;
}
.ststistic-con {
  margin-top: @mg;
  margin-bottom: @mg;
  padding: 0 @mg @mg @mg;
  display: flex;
  flex-direction: column;
  background-color: @section-bg-color;
  border-radius: @mg;
  height: calc(100vh - 75px - 20px - @mg*2 - 5px - 32px);

  .statistic-item {
    display: flex;

    &.light {
      color: @light-color;
      a {
        color: @light-color;
      }
    }
    a {
      color: @font-color;
      text-decoration: none;
    }
  }
}
</style>