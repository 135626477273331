<template>
  <div class="section-container">
    <div class="form-con">
      <div class="flex mgt-5">
        <el-input v-model="form.txid" size="small" class="flex-1" placeholder="准备狙击的目标Tx">
          <template slot="prepend">Tx ID</template>
        </el-input>
        <div class="w-16"></div>
        <el-button @click="runTask" type="success" size="small" v-if="!isRunning">开始监控</el-button>
      </div>
    </div>

    <div class="monitor-con">
      <div class="info-con" v-if="rune">
        <img :src="rune.imageURI" class="cover" />
        <div class="brief-con">
          <div class="name">
            <a :href="'https://magiceden.io/runes/' + rune.runeName" target="_blank">{{rune.runeName}}</a>
          </div>
          <div class="desc">
            {{rune.side === 'sell' ? 'Seller' : '-'}}: {{$com.formatString(rune.maker, 4, 6)}}
            <span class="pdl-16 text-red" v-if="isPending !== null && isPending !== false">Pending... <i class="el-icon-loading"></i></span>
          </div>
          <div class="tags">
            <div class="tag-item">Floor：{{$com.fixed(rune.floorUnitPrice, 3)}} sats/{{rune.runeSymbol}}</div>
            <div class="tag-item">Taker Fee(1%)：{{Math.round(rune.price*0.01)/Math.pow(10, 8)}} BTC</div>
            <div class="flex-1"></div>
          </div>
          <div class="price">
            <span class="floor">Total: {{rune.price/Math.pow(10, 8)}} BTC</span>
            <span class="floor">Price: {{rune.formattedUnitPrice}} sats/{{rune.runeSymbol}}</span>
            <span class="fs-13" :class="rune.floorUnitPrice - rune.formattedUnitPrice > 0 ? 'text-green' : 'text-red'">
              vs ME: {{rune.floorUnitPrice - rune.formattedUnitPrice > 0 ? '+' : ''}}{{ parseFloat((rune.floorUnitPrice - rune.formattedUnitPrice)/rune.formattedUnitPrice*100).toFixed(2) }}%
            </span>
          </div>
        </div>
      </div>

      <div class="event-item block-ok" v-if="historyBlock">
        <div class="name">已出块</div>
        <div class="time">平均费率：{{historyBlock.aveFeeRate}} sat/vB</div>
        <div class="time">平均txs：{{historyBlock.aveTxCount}}</div>
        <div class="limit">平均时长：{{parseFloat(historyBlock.aveTime/60).toFixed(1)}}min</div>
      </div>

      <div class="event-item block-wait" v-for="(item, idx) in memBlocks" :key="idx" v-if="historyBlock">
        <div class="name">即将出块</div>
        <div class="time">{{parseFloat(item.feeRange[0]).toFixed(2)}}~{{parseFloat(item.feeRange[item.feeRange.length - 1]).toFixed(2)}} sat/vB</div>
        <div class="time">txs：{{item.nTx}}</div>
        <div class="limit" v-if="idx === 0">{{parseFloat((historyBlock.aveTime - (new Date().getTime()/1000 - historyBlock.lastTime))/60).toFixed(1)}}min</div>
      </div>
    </div>

    <!-- form -->
    <div class="form-con" v-if="rune">
      <div class="flex mgt-5">
        <div class="gas-fees">
          <span class="label">GAS</span>
          <el-tag
              size="mini"
              effect="plain"
              type="warning"
              v-for="(item, key) in gasFees"
              :key="key"
              :class="{active: form.feeType === key && !form.feeRate}"
              @click="form.feeType = key"
              v-if="key !== 'economyFee'"
          >
            {{$t('feeRate.' + key)}}: {{item}} sat/vB
          </el-tag>
          <el-input v-model="form.feeRate" size="small" class="w-200px fee-rate" placeholder="自定义费率">
            <template slot="append">sat/vB</template>
          </el-input>
        </div>
        <el-checkbox v-model="form.optimizationFee" class="mgl-10" fill="#01D243" text-color="#01D243">优化费率</el-checkbox>

        <div class="flex-1"></div>

        <div class="fs-12 mgr-10" v-if="minFeeRate">狙击最低费率：{{minFeeRate}} sats/vB（低于此费率可能失败）</div>
        <el-button size="small" type="warning" @click="signBuy" v-if="isPending && !isBad">预签名</el-button>
        <el-button @click="runPanic" :type="isRunningPanic ? 'success' : 'primary'" size="small" v-if="isPending && !isBad">
          {{isRunningPanic ? '抢单中...' : '抢单'}}
        </el-button>
        <el-tag type="success" size="medium" v-if="!isPending">已确认</el-tag>
        <el-tag type="danger" size="medium" v-if="isBad">此订单不支持狙击</el-tag>
      </div>
    </div>

    <!-- pending txs -->
    <div class="txs-lists scroll-con">
      <div class="title">Pending记录：</div>
      <div class="tx-item thead">
        <div class="w-200px">Txid</div>
        <div class="w-200px">Buyer</div>
        <div class="w-200px">Date</div>
        <div class="w-150px">Fee Rate</div>
        <div class="w-150px">Fee</div>
        <div class="w-100px">Status</div>
      </div>
      <div
          class="tx-item"
          :class="{ replaced: item.status.replaced }"
          v-for="(item, idx) in txs"
          :key="idx"
          @click="form.txId = item.txid"
      >
        <div class="w-200px">
          <a :href="'https://mempool.space/tx/' + item.txid" target="_blank">{{$com.formatString(item.txid, 8, 10)}}</a>
        </div>
        <div class="w-200px">
          {{item.buyer ? $com.formatString(item.buyer, 8, 8) : '-'}}
          <span class="fs-12 text-success" v-if="item.buyer && item.buyer === currentAccount">(自己)</span>
        </div>
        <div class="w-200px">{{$func.getDate(item.time)}}</div>
        <div class="w-150px">{{item.feeRate}} sat/vB</div>
        <div class="w-150px">{{item.fee}} sat</div>
        <div class="w-100px">{{ item.status.replaced ? 'Replaced' : (item.status.confirmed ? '已确认' : 'Pending...') }}</div>
        <div class="btns flex-1">
          <span v-if="item.buyer && item.buyer === currentAccount" @click="replaceSelfTx(item)">替换</span>
        </div>
      </div>
    </div>

    <!-- logs -->
    <div class="logs-con scroll-con" v-if="rune">
      <div>日志：</div>
      <div class="item" v-for="(item, idx) in logs" :key="idx">
        {{item.time}}: {{item.msg}}
      </div>
    </div>

  </div>
</template>

<script>
import * as MempoolApi from "@/api/mempool";
import * as MempoolUtil from "@/utils/mempool";

let ws = null;

export default {
  data(){
    return {
      currentAccount: window.currentAccount,
      currentPublicKey: window.currentPubkey,
      gasFees: {},
      minFeeRate: null,
      historyBlock: null,
      memBlocks: [],
      form: {
        txid: null,
        feeType: 'halfHourFee',
        feeRate: null,
        optimizationFee: false,
        tokenInput: null
      },
      rune: null,
      txInfo: null,
      txsInfo: {},
      replacesTxs: [],
      txs: [],
      isPending: true,
      isBad: false,
      isRunning: false,
      isRunningPanic: false,
      logs: []
    }
  },
  mounted() {
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.currentAccount = account.address;
      this.currentPublicKey = account.pubkey;
    });

    const { txid, rune } = this.$route.query;
    if(txid){
      this.form.txid = txid;
      this.rune = rune ? JSON.parse(rune) : null;
      setTimeout(() => {
        this.runTask();
      }, 300);
    } else {
      this.form.txid = localStorage.getItem('PanicPool_txid');
    }
  },
  destroyed() {
    ws && ws.close();
  },
  methods: {
    async runTask(){
      if(!this.form.txid){
        return;
      }
      localStorage.setItem('PanicPool_txid', this.form.txid);
      if(ws === null){
        // 启动监听
        this.connectWebSocket();
      }
      this.isRunning = true;
      // 获取要狙击的tx详情
      this.txInfo = await this.loadTxInfo({ txid: this.form.txid });
      while(true){
        // 获取替换txs
        this.replacesTxs = await this.getReplacesTxs(this.form.txid);
        this.getPendingTxs();

        if(this.txs.length > 0){
          if(!this.firstTxInfo){
            // 从替换列表中查询最早一笔交易的详情
            this.firstTxInfo = await this.loadTxInfo(this.txs[this.txs.length - 1], this.txs.length - 1);
            // 解析第一笔tx详情
            const parseRes = await MempoolUtil.parseTxInfo(this.firstTxInfo);
            // 解析卖家输入
            this.form.tokenInput = MempoolUtil.parseTokenInput(this.firstTxInfo, parseRes, this.rune);
            if(!this.form.tokenInput.vin){
              this.$message.error('此订单不可以狙击！');
              this.isBad = true;
              break;
            }
          }

          // 查询所有pending记录的详情
          this.isPending = this.txIsPending(this.txs[0]);
          if(!this.isPending){
            // 已确认
            break;
          }
          for(let i in this.txs){
            await this.loadTxInfo(this.txs[i], i);
          }
        }
        await this.$func.sleep(10*1000);
      }
    },
    /**
     * websocket
     */
    connectWebSocket() {
      ws = new WebSocket('wss://mempool.space/api/v1/ws');
      ws.onopen = function () {
        ws.send('{"action":"init"}');
        ws.send('{"action":"want","data":["blocks","stats","mempool-blocks"]}');
      };
      ws.onclose = function (event) {
        setTimeout(this.connectWebSocket, 3000); // 3秒后重新连接
      };
      ws.onerror = function (error) {
        setTimeout(this.connectWebSocket, 3000); // 3秒后重新连接
      };
      const self = this;
      ws.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if(data.blocks){
          const historyBlock = MempoolUtil.analysisHistoryBlocks(data.blocks);
          self.$set(self, 'historyBlock', historyBlock);
        }
        data['mempool-blocks'] && self.nextBlocks(data['mempool-blocks'].slice(0, 1));
        data.fees && self.$set(self, 'gasFees', data.fees);
      };
    },
    nextBlocks(blocks){
      this.$set(this, 'memBlocks', blocks);
    },
    /**
     * 获取tx详情
     */
    async loadTxInfo(txInfo, idx){
      try {
        if(!this.txs[idx] || !this.txs[idx].vout){
          if(!this.txsInfo[txInfo.txid]){
            const res = await MempoolApi.getTxInfo(txInfo.txid, txInfo.status ? txInfo.status.replaced : false);
            if(txInfo.status){
              res.status.replaced = txInfo.status.replaced;
            }
            txInfo = res;
          } else {
            if(txInfo.status){
              this.txsInfo[txInfo.txid].status.replaced = txInfo.status.replaced;
            }
            txInfo = this.txsInfo[txInfo.txid];
          }
          if(!txInfo){
            return;
          }
          txInfo.buyer = MempoolUtil.parseBuyer(txInfo, this.rune.outputValue);
          this.txsInfo[txInfo.txid] = txInfo;
        }
        if(idx !== undefined){
          this.txs[idx] = this.txs[idx] ? Object.assign(this.txs[idx], txInfo) : txInfo;
        }
        return txInfo;
      } catch (err) {
        console.log('Get Tx Info -> ' + err.message);
      }
      return null;
    },
    /**
     * 获取替换的txs
     * @param txId
     * @returns {Promise<void>}
     */
    async getReplacesTxs(txId){
      try {
        const res = await MempoolApi.getReplacesTxs(txId);
        return res;
      } catch (err) {
        this.log('Get Replaces Txs -> ' + err.message);
      }
      return [];
    },
    /**
     * 获取pending的txs
     * @returns {Promise<void>}
     */
    getPendingTxs(){
      try {
        if(this.replacesTxs.length === 0){
          // 没有被替换
          const txs = [];
          txs.push(this.txInfo);
          this.txs = txs;
        } else {
          // 有被替换
          this.txs = this.replacesTxs;
        }
      } catch (err) {
        this.log('Get Pending Txs -> ' + err.message);
      }
    },
    /**
     * 判断tx正在pending
     * @param tx
     * @returns {boolean}
     */
    txIsPending(tx){
      if(tx && !tx.status.replaced && !tx.status.confirmed) {
        // 有tx且没有被替换，且正在pending，加载pending交易的详情
        return true;
      }
      return false;
    },
    /**
     * 计算feeRate
     * @returns {Promise<null>}
     */
    async cauFeeRate(){
      // 获取feeRate
      let feeRate = this.gasFees[this.form.feeType];
      let flag = true;
      if(this.form.feeRate){
        const maxFeeRate = Math.max(this.txs[0] ? this.txs[0].feeRate : 0, this.gasFees ? this.gasFees.fastestFee : 0);
        if((maxFeeRate > 0 && this.form.feeRate/maxFeeRate > 2) || (maxFeeRate === 0 && this.form.feeRate > 200)){
          flag = false;
          const confirm = await this.$confirm('自定义费率偏高，是否继续发送？', '注意', {
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => {});
          if(confirm === 'confirm'){
            flag = true;
          }
        }
        feeRate = this.form.feeRate;
      }
      if(!flag){
        this.log('取消预签名');
        return null;
      }
      return feeRate;
    },
    /**
     * 预签名
     */
    async signBuy(){
      if(!this.form.tokenInput || !this.form.tokenInput.vin){
        this.$message.error('没有识别到有效的输入！');
        return;
      }
      if(!this.currentAccount || !this.currentPublicKey){
        this.$message.error('请重新连接钱包');
        return;
      }
      try {
        // 费率
        const feeRate = await this.cauFeeRate();
        if(feeRate === null){
          return;
        }
        // 最小fee
        const minFee = this.txs[0].fee;
        // 构建psbt
        const psbt = await MempoolUtil.buildPsbt(this.currentAccount, this.form.tokenInput, this.rune.price, minFee);
        const psbtRes = await MempoolApi.decodePsbt(psbt.toHex());
        // 计算fee
        this.minFeeRate = psbtRes.feeRate;
        const fee = Math.round(feeRate * psbtRes.vsize);
        const lastOutputIdx = psbt.data.globalMap.unsignedTx.tx.outs.length - 1;
        const lastOutPut = psbt.data.globalMap.unsignedTx.tx.outs[lastOutputIdx];
        psbt.data.globalMap.unsignedTx.tx.outs[lastOutputIdx].value = lastOutPut.value - (fee - minFee);
        // sign psbt
        const signedHex = await window.unisat.signPsbt(psbt.toHex(), {
          autoFinalized: true
        });

        console.log(signedHex)
      } catch (err) {
        console.log('signBuy -> ' + err.message);
      }
    },
    runPanic(){

    },
    replaceSelfTx(){

    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.form-con {
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .gas-fees {
    display: flex;
    align-items: center;
    background-color: @input-bg-color;
    border-radius: 6px;

    .label{
      font-size: 13px;
      color: @sub-font-color;
      margin-left: @mg;
      padding-right: 6px;
    }
    .el-tag--plain {
      background-color: transparent;
      margin-left: 3px;
      border-color: @content-bg-color;
      cursor: pointer;
      color: @sub-font-color;

      &.active {
        color: @light-color;
        border-color: @light-color;
      }
    }
    .fee-rate {
      /deep/.el-input__inner {
        color: @light-color !important;
      }
    }
  }
}
.w-300 {
  width: 300px;
}
.w-30p {
  width: 30%;
}

.monitor-con{
  margin: @mg 0;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
  display: flex;

  .event-item {
    background-color: @input-bg-color;
    width: 150px;
    border-radius: 10px;
    padding: 10px;
    margin-left: @mg;
    cursor: pointer;
    &.on {
      box-shadow: 0 0 3px @light-color;
    }
    .name {
      font-size: 13px;
      color: @font-color;
    }
    .time {
      font-size: 12px;
      margin-top: 5px;
    }
    .limit {
      font-size: 12px;
      margin-top: 5px;
    }
    .status {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: @sub-font-color;
      margin-top: 5px;
      text-align: right;
      /deep/.countdown {
        width: auto;
        .con {
          display: flex;
          justify-content: flex-end;
          .number {
            text-align: right;
            color: @green;
            font-size: 12px;
          }
        }
      }
    }
    &.block-ok {
      background: repeating-linear-gradient( #2d3348, #2d3348 0.16592500000000143%, #9339f4 0.16592500000000143%, #105fb0 100% );
    }
    &.block-wait {
      background: repeating-linear-gradient(to right, rgb(85, 75, 69), rgb(85, 75, 69) 0.20075%, rgb(170, 125, 15) 0.20075%, rgb(170, 125, 15) 17.1666%, rgb(170, 125, 15) 16.834%, rgb(170, 125, 15) 33.1345%, rgb(170, 125, 15) 33.4672%, rgb(170, 125, 15) 50.1004%, rgb(170, 125, 15) 50.1004%, rgb(170, 125, 15) 67.0662%, rgb(170, 125, 15) 66.7336%, rgb(178, 125, 16) 83.0341%, rgb(178, 125, 16) 83.3668%, rgb(187, 125, 17) 100%);
      color: #fff;
    }
  }
}

.info-con {
  display: flex;
  flex: 1;

  .cover {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: none;
    overflow: hidden;
    background-color: @content-bg-color;
  }
  .brief-con {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: @mg;

    .name {
      color: @light-color;
      a{
        text-decoration: none;
        color: @light-color;
      }
    }
    .desc {
      color: @sub-font-color;
      font-size: 13px;
      margin-top: 10px;
      line-height: 1.5em;
    }
    .tags {
      display: flex;
      align-items: center;
      .tag-item {
        background-color: @input-bg-color;
        font-size: 12px;
        margin: 10px 10px 10px 0;
        padding: 4px 6px;
        border-radius: 5px;
      }
    }
    .price {
      color: @light-color;
      font-size: 16px;
      padding-right: @mg;
      display: flex;
      align-items: center;

      span.floor {
        color: @font-color;
        font-size: 13px;
        padding-right: @mg;
      }
    }
  }
}

.txs-lists {
  margin-top: @mg;
  margin-bottom: @mg;
  padding: @mg;
  border-radius: @mg;
  background-color: @content-bg-color;
  height: 268px;
  .title {
    color: @sub-font-color;
    font-size: 13px;
    padding-bottom: 10px;
  }
  .tx-item {
    display: flex;
    align-items: center;
    background-color: @input-bg-color;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;

    &.replaced {
      color: @sub-font-color;
      a {
        color: @sub-font-color;
      }
    }
    &.thead {
      background-color: transparent;
      padding-bottom: 0;
    }

    a{
      text-decoration: none;
      color: @font-color;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        cursor: pointer;
        display: block;
        background-color: @light-color;
        color: #fff;
        padding: 2px 5px;
        border-radius: 3px;
      }
    }
  }
}

.logs-con {
  font-size: 12px;
  line-height: 24px;
  color: @sub-font-color;
  margin-bottom: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;
  height: calc(100vh - 75px - @mg*3 - 668px);
}
</style>
