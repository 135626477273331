import * as indicatorUtil from '@/utils/indicator';
import * as func from '@/utils/func';

const cacheDataTlp = {
    maxPrice_2h: 0,
    colors_24h: [],
    maxChanges_1h: []
};
const cacheDataObj = {};
export function checkPoint(pairName, klineData) {
    if (klineData.length < 480) {
        // 6小时的k线
        return null;
    }
    if(!cacheDataObj[pairName]){
        // 初始化缓存数据
        cacheDataObj[pairName] = Object.assign({}, cacheDataTlp);
    }
    // 初始化数据
    const lastCandle = klineData[klineData.length - 1];
    const prevCandle = klineData[klineData.length - 2];
    const now = lastCandle.time;
    const res = {
        time: now,
        price: lastCandle.close,
        low: lastCandle.low,
        change: parseFloat(parseFloat((lastCandle.close - lastCandle.open)/lastCandle.open * 100).toFixed(2)),
        ampChange: parseFloat(parseFloat((lastCandle.high - lastCandle.low)/lastCandle.low * 100).toFixed(2)), // 振幅
        prevChange: parseFloat(parseFloat((prevCandle.close - prevCandle.open)/prevCandle.open * 100).toFixed(2))
    };

    res.stablePrice_4h = indicatorUtil.cauStablePrice(klineData.slice(-80));
    res.stablePrice_6h = indicatorUtil.cauStablePrice(klineData.slice(-120));
    res.stablePrice_day1 = indicatorUtil.cauStablePrice(klineData.slice(-480));
    res.stableChange_6h = indicatorUtil.cauStableChange(klineData.slice(-120));
    res.realChange = parseFloat(parseFloat((lastCandle.open - res.stablePrice_day1)/res.stablePrice_day1 * 100).toFixed(2));
    res.realChange_6h = parseFloat(parseFloat((lastCandle.open - res.stablePrice_6h)/res.stablePrice_6h * 100).toFixed(2));
    res.avePrice_1h = indicatorUtil.cauAvePrice(klineData.slice(-20));
    res.avePrice_4h = indicatorUtil.cauAvePrice(klineData.slice(-80));

    if(res.time % 3600 === 0){
        const candles_1h = klineData.slice(-20);
        const avePrices = candles_1h.map(r => {
            return (parseFloat(r.open) + parseFloat(r.close))/2;
        });
        // 6h orp
        res.priceORP_6h = outRangePercent({
            max: res.stablePrice_6h * (1 + 0.015),
            min: res.stablePrice_6h * (1 - 0.015)
        }, avePrices);

        // 4h orp
        res.priceORP_4h = outRangePercent({
            max: res.stablePrice_4h * (1 + 0.015),
            min: res.stablePrice_4h * (1 - 0.015)
        }, avePrices);

        // 6h change orp
        const changes = candles_1h.map(r => r.change);
        res.changeORP = outRangePercent({
            max: res.stableChange_6h + 0.5,
            min: res.stableChange_6h - 0.5
        }, changes);

        res.isQRise = isQuickRise(klineData.slice(-80));

        // 记录近1h的最大change
        for(let j in candles_1h){
            const row = candles_1h[j];
            const c = parseFloat(parseFloat((row.high - res.stablePrice_6h)/res.stablePrice_6h*100).toFixed(2));
            if(!res.maxChange_1h || c - res.maxChange_1h > 0){
                res.maxChange_1h = c;
            }
        }
        cacheDataObj[pairName].maxChanges_1h.push(res.maxChange_1h);
        cacheDataObj[pairName].maxChanges_1h = cacheDataObj[pairName].maxChanges_1h.slice(-24);

        // 分析颜色
        const { markColor, color } = checkColor(res, cacheDataObj[pairName]);
        res.markColor = markColor;
        res.color = color;
        res.hourMark = true;

        // 记录最近24小时color
        cacheDataObj[pairName].colors_24h.push(color);
        cacheDataObj[pairName].colors_24h = cacheDataObj[pairName].colors_24h.slice(-24);

        cacheDataObj[pairName].prevColor = color;
        cacheDataObj[pairName].prevPriceORP_6h = res.priceORP_6h;
        cacheDataObj[pairName].prevAvePrice_1h = res.avePrice_1h;
    }

    // 分析信号点
    const timeCond = !cacheDataObj[pairName].prevSingalTime
        || now - cacheDataObj[pairName].prevSingalTime > 60*60
        || now - cacheDataObj[pairName].prevSingalTime < 0
    // 一个小时内的信号，必须比上一个价格低
    const timePriceCond = cacheDataObj[pairName].prevSingalTime
        && now - cacheDataObj[pairName].prevSingalTime <= 60*60
        && cacheDataObj[pairName].prevSingalPrice
        && lastCandle.close - cacheDataObj[pairName].prevSingalPrice < 0
    if(
        (timeCond || timePriceCond) && res.change - 0.3 > 0
        && ['red', 'darkRed'].indexOf(cacheDataObj[pairName].prevColor) === -1
        && res.realChange - 15 < 0
        && res.realChange - (-3) > 0
    ){
        // 6h orp
        res.priceORP_6h = outRangePercent({
            max: res.stablePrice_6h * (1 + 0.015),
            min: res.stablePrice_6h * (1 - 0.015)
        }, klineData.slice(-20).map(r => {
            return (r.open + r.close)/2;
        }));
        // 4h orp
        res.priceORP_4h = outRangePercent({
            max: res.stablePrice_4h * (1 + 0.015),
            min: res.stablePrice_4h * (1 - 0.015)
        }, klineData.slice(-20).map(r => {
            return (r.open + r.close)/2;
        }));
        // 6h change orp
        res.changeORP = outRangePercent({
            max: res.stableChange_6h + 0.5,
            min: res.stableChange_6h - 0.5
        }, klineData.slice(-20).map(r => r.change));

        const { markColor, color } = checkColor(res, cacheDataObj[pairName]);
        res.color = color;
        if(
            (['blue', 'darkBlue'].indexOf(color) > -1
            && ['blue', 'darkBlue'].indexOf(cacheDataObj[pairName].prevColor) > -1)
            || color === 'blue'
        ){
            res.singal = false;
        } else {
            res.singalColor = markColor;

            const isBad = res.priceORP_6h === 100 && res.priceORP_4h === 100;

            const last5TotalChange = cauLastTotalChange(klineData.slice(-5));
            if(!isBad && last5TotalChange - 1.5 > 0){
                res.singal = true;
                cacheDataObj[pairName].prevSingalPrice = lastCandle.close;
            }
        }
    }

    const _timeCond = !cacheDataObj[pairName].prevSingalTime
        || now - cacheDataObj[pairName].prevSingalTime > 30*60
        || now - cacheDataObj[pairName].prevSingalTime < 0
    if(_timeCond && get18Point(res, cacheDataObj[pairName].colors_24h.slice(-12))){
        res.singal = true;
        res.singalColor = "#ffb600";
        cacheDataObj[pairName].prevSingalPrice = lastCandle.close;
    }

    if(res.singal){
        res.singalColor = res.singalColor ? res.singalColor : '#ff5900';
        cacheDataObj[pairName].prevSingalTime = now;
    }

    res.cache = cacheDataObj[pairName];

    if(func.getDate(res.time) === '2023-07-07 23:48:00'){
        console.log(res)
    }

    return res;
}

/**
 * 1.8的标记点
 * @param res
 * @param colors_12h
 * @returns {boolean}
 */
function get18Point(res, colors_12h){
    if((parseFloat(res.change) + parseFloat(res.ampChange))/2 < 1.8){
        return false;
    }
    if(res.realChange_6h - 5 > 0){
        return false;
    }
    let greenNum = 0, redNum = 0;
    colors_12h.map(v => {
        if(['green', 'darkGreen'].indexOf(v) > -1){
            greenNum++;
        } else if(['red', 'darkRed'].indexOf(v) > -1){
            redNum++;
        }
    });
    if(greenNum >= 9 && redNum <= 1){
        return true;
    }
    return false;
}

function checkColor (res, cache){
    const returnData = {};
    if((cache.prevPriceORP_6h === 0 && res.priceORP_6h <= 10) || res.priceORP_6h === 0){
        returnData.markColor = 'rgba(0,255,80,1)';
        returnData.color = 'green';
    }  else if(res.changeORP + res.priceORP_4h <= 30 && cache.prevColor !== 'red') {
        returnData.markColor = 'rgba(0,255,80,0.2)';
        returnData.color = 'darkGreen';
    } else if(res.realChange - 8 < 0 || (['blue', 'darkBlue'].indexOf(cache.prevColor) > -1 && !res.isQRise)) {
        const c = (res.price - res.stablePrice_6h)/res.stablePrice_6h * 100;
        if(res.changeORP - 30 >= 0){
            returnData.markColor = c < -2 ? '#ff547e' : '#49cbf3';
            returnData.color = c < -2 ? 'pink' : 'blue';
        } else {
            returnData.markColor = c < -2 ? 'rgba(255,84,126,0.5)' : 'rgba(73,203,243,0.5)';
            returnData.color = c < -2 ? 'darkPink' : 'darkBlue';
        }
    } else if(['blue', 'darkRed'].indexOf(cache.prevColor) > -1 && res.realChange - 12 < 0) {
        returnData.markColor = 'rgba(252,20,89,0.2)';
        returnData.color = 'darkRed';
    } else {
        returnData.markColor = '#fc1459';
        returnData.color = 'red';
    }

    return returnData;
}

/**
 * 分析是否出现异常波动
 */
function isWave(candles, options){
    const { stablePrice_6h, stablePrice_day1 } = options;
}

function outRangePercent(range, arr){
    let count = 0;
    arr.map(v => {
        if(v > range.max || v < range.min){
            count++;
        }
    });
    return parseFloat(parseFloat(count/arr.length*100).toFixed(2));
}

/**
 * 是否快速拉盘
 */
function isQuickRise(candles){
    let maxPrice = {};
    candles.map((r, i) => {
        if(!maxPrice.price || r.high - maxPrice.price > 0){
            maxPrice.price = r.high;
            maxPrice.idx = r.idx;
        }
    });
    const prev = candles[maxPrice.idx >= 20 ? maxPrice.idx - 20 : 0];
    const pprev = candles[maxPrice.idx >= 25 ? maxPrice.idx - 25 : 0];
    const c1 = (maxPrice.price - prev.open)/prev.open*100;
    const c2 = (maxPrice.price - pprev.open)/pprev.open*100;
    if((c1 + c2)/2 > 15){
        return true;
    }
    return false;
}

/**
 * 计算累计change
 * @param candles
 * @returns {number}
 */
function cauLastTotalChange(candles){
    let lastTotalChange = 0;
    const last5Changes = candles.map(r => {
        const c = (r.close - r.open)/r.open * 100;
        return c;
    });
    for(let j in last5Changes){
        if(last5Changes[j] < -0.1){
            lastTotalChange = 0;
            continue;
        }
        lastTotalChange += last5Changes[j];
    }
    return lastTotalChange;
}

function isV(candles, singal){
    let flag = false;
    if(
        candles[0].change - (-0.2) < 0
        && candles[1].change - (-0.2) < 0
        && candles[2].change - (-0.2) < 0
        && candles[3].change - 1 > 0
    ){
        flag = true;
    }
    if(flag && (singal.open - singal.stablePrice.day1 < 0)){

    }
}

/**
 * 分析趋势状态
 * @param trendStatusArr
 */
function isVeryStable(candles, singal){
    let waveCount = 0;
    candles.map(r => {
        if(
            Math.max(r.open, r.close) - singal.stablePrice.day1*1.015 > 0
            || Math.min(r.open, r.close) - singal.stablePrice.day1*(1-0.01) < 0
        ){
            waveCount++;
        }
    });
    const p = parseFloat((waveCount/candles.length)*100).toFixed(2);

    return p;
}
function checkTrendStatus(singal, trendStatusArr){
    const maxFallChange = (singal.maxPrice_2h - singal.price)/singal.price * 100;
    const badIdx = trendStatusArr.findIndex(v => v >= 6);

    if(func.getDate(singal.time) === '2024-02-27 10:21:00'){
        console.log(func.getDate(singal.time));
        console.log(trendStatusArr);
        console.log(badIdx);
    }
    if(
        trendStatusArr.slice(-12).findIndex(v => v > 2) === -1
        && Math.abs(singal.stablePrice.day1 - singal.stablePrice.day3)/singal.stablePrice.day3*100 - 2 <= 0
        && Math.min(singal.open, singal.close) - singal.stablePrice.day3 > 0
        && Math.max(singal.open, singal.close) - singal.stablePrice.day1*(1+0.015) < 0
    ){
        return 'very-stable';
    }

    if(badIdx > -1){
        let greenCount = 0;
        for(let i = badIdx; i < trendStatusArr.length; i++){
            if(trendStatusArr[i] <= 2){
                greenCount++;
            }
        }
        if(func.getDate(singal.time) === '2024-03-09 15:51:00'){
            console.log(func.getDate(singal.time));
            console.log(greenCount);
        }
        if(greenCount < 2){
            return 'fall';
        }
    }
    let stableCount = 0;
    trendStatusArr.slice(-6).map(v => {
        if(v <= 2){
            stableCount++;
        }
    });
    if(stableCount >= 6){
        return 'stable';
    }

    const startIdx = trendStatusArr.findIndex(v => (v === 1 || v === 2));
    const start = [], middle = [], end = [];
    for(let i = startIdx; i < trendStatusArr.length; i++){
        const v = trendStatusArr[i];
        if(v <= 2){
            middle.length === 0 ? start.push(v) : end.push(v);
        } else if(v > 2 && v <= 5) {
            end.length === 0 && middle.push(v);
        }
    }

    if(start.length >= 6 && middle.length >= 2 && end.length >= 2){
        return true;
    }

    return true;
}