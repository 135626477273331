import request from '@/utils/request.js';

const prefix = 'https://api.ai-dex.online/';

export async function lists(params) {
    return request.get({ url: prefix + 'coins/lists', params })
}

export async function stablePrice(params) {
    return request.get({ url: prefix + 'coins/stable-price', params })
}

export async function info(params) {
    return request.get({ url: prefix + 'coins/info', params })
}

/**
 * 统计分析
 * @param params
 * @returns {Promise<unknown>}
 */
export async function statistic(params) {
    return request.get({ url: prefix + 'coins/max-change', params })
}