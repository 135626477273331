/**
 * 智能策略-v1
 */

const dataTpl =  {
    historyKline: [], // 保留最后5个k
    trendDataSource: [],
    trendHistory: [],
    trendData: {},
    news: [],
    hourTxns: [],
    tradeRecords: [],
    afterBuyMaxPrice: 0,
    waitBuy: null,
    lastBuyPoint: null,
    waitSell: null,
    logs: []
};

const runningData = {};

export function execute(action, newKline, singals, options, pairName){
    if(!runningData[pairName]){
        runningData[pairName] = dataTpl;
    }
    if(action == 'InitTrendDataSource'){
        runningData[pairName].trendDataSource = options.trendDataSource;
        analysisTrend(newKline, pairName);
        return runningData[pairName].trendData;
    } else if(action == 'InitKline'){
        // 初始化最近5个k
        runningData[pairName].historyKline = options.historyKline;
        return;
    } else if(action == 'NewsNotify'){
        runningData[pairName].news.push(singals.news);
        return;
    } else if(action == 'HourTxnsNotify'){
        runningData[pairName].hourTxns.push(singals.hourTxns);
        return;
    } else if(action == 'UpdateTradeRecords'){
        runningData[pairName].tradeRecords = options.tradeRecords;
        return;
    } else if(action == 'NewKline'){
        const rData = runningData[pairName];
        // 更新buy后的最大价格
        if(
            rData.tradeRecords.length > 0
            && rData.tradeRecords[rData.tradeRecords.length - 1].side == 'buy'
            && newKline.close - rData.afterBuyMaxPrice > 0
        ){
            runningData[pairName].afterBuyMaxPrice = newKline.close;
        }
        const prevKlineData = rData.historyKline.slice(-5);
        // 更新记录的最后一个k
        const lastKline = rData.historyKline[rData.historyKline.length - 1];
console.log(rData.historyKline)
        if(lastKline.time == newKline.time){
            runningData[pairName].historyKline[rData.historyKline.length - 1] = newKline;
        } else {
            runningData[pairName].historyKline.push(newKline);
            runningData[pairName].historyKline = runningData[pairName].historyKline.slice(-5);
        }

        // 分析趋势
        analysisTrend(newKline, pairName);
        // 获取趋势状态
        const trendRes = getTrendStatus(rData.trendData, newKline);
        const trendDirect = trendRes.trendDirect;
        runningData[pairName].trendDirect = trendDirect;
        runningData[pairName].trendMsg = trendRes.msg;
        runningData[pairName].trendHistory.push(trendDirect);
        runningData[pairName].trendHistory = rData.trendHistory.slice(-10);

        const returnData = rData;
        // 检测买点
        const buyPoint = checkBuyPoints(newKline, prevKlineData, trendDirect, pairName);
        if(buyPoint){
            runningData[pairName].lastBuyPoint = buyPoint;
            returnData.newBuyPoint = buyPoint;
        } else {
            returnData.newBuyPoint = null;
        }
        // 检测卖点
        const sellPoint = checkSellPoints(newKline, prevKlineData, trendDirect, pairName);
        if(sellPoint){
            runningData[pairName].lastSellPoint = sellPoint;
            returnData.newSellPoint = sellPoint;
        } else {
            returnData.newSellPoint = sellPoint;
        }

        return returnData;
    }
}

/**
 * 找买点
 * @param newKlineData
 * @param prevKlineData
 * @param trendDirect
 * @param pairName
 * @returns {{level: (number), price, change: number, time}}
 */
function checkBuyPoints(newKlineData, prevKlineData, trendDirect, pairName){
    const rData = runningData[pairName];
    if(!rData.trendData){
        return;
    }
    if(prevKlineData.length < 5){
        return;
    }
    // 趋势
    if(trendDirect == -1){
        // 趋势向下，锁定
        return;
    }
    let goodTrendCount = 0;
    for(let i = 0; i < rData.trendHistory.length; i++){
        if(rData.trendHistory[i] >= 0){
            goodTrendCount++;
        }
    }
    if(goodTrendCount < 6){
        // return;
    }

    const lastOrder = rData.tradeRecords.length > 0 ? rData.tradeRecords[rData.tradeRecords.length - 1] : null;
    if(!lastOrder || lastOrder.side == 'sell'){
        // 最后一个单子没有卖掉
        // return false;
    }
    const change = (newKlineData.close - newKlineData.open)/newKlineData.open * 100;
    let level = trendDirect == 1 ? 1 : 0;
    if(rData.waitBuy){
        if(newKlineData.high - rData.waitBuy.maxPrice < 0){
            return;
        }
        const newMinPrice = Math.min(newKlineData.open, newKlineData.close, newKlineData.low);
        if(newMinPrice - rData.waitBuy.maxPrice > 0){
            return;
        }
        runningData[pairName].waitBuy = null;
        // log('接针触发成功');
    } else {
        if(change < 1){
            return;
        }
        let goodCount = 0, prevCV = 0, goodStatus = 0;
        for(let i in prevKlineData){
            const cv = (prevKlineData[i].high - prevKlineData[i].open)/prevKlineData[i].open * 100;
            if(cv > 0){
                goodCount++;
            }
            if(cv - prevCV > 0){
                goodStatus++;
            }
            prevCV = cv;
        }

        if(change > 3){
            // 瞬间涨幅太大，等待接针
            // log('瞬间涨幅太大，等待接针');
            runningData[pairName].waitBuy = {
                maxPrice: newKlineData.close,
                time: newKlineData.timestamp,
                pairName
            };
            return;
        }
    }
    if(
        rData.lastBuyPoint && newKlineData.time - rData.lastBuyPoint.time < 3600
        && newKlineData.close - rData.lastBuyPoint.price > 0
    ){
        // 近一个小时提醒过，且新提醒价格更高，不再提醒
        return;
    }
    const lastNews = rData.news.length > 0 ? rData.news[rData.news.length - 1] : null;
    if(
        lastNews
        && newKlineData.timestamp - lastNews.timestamp < 7*3600*24
        && newKlineData.timestamp - lastNews.timestamp > 0
    ){
        level += 2;
        log('收到新闻', newKlineData.timestamp, pairName);
    }
    const lastHourMark = rData.hourTxns.length > 0 ? rData.hourTxns[rData.hourTxns.length - 1] : null;
    if(
        lastHourMark
        && newKlineData.timestamp - lastHourMark.time < 7*3600*24
        && newKlineData.timestamp - lastHourMark.time > 0
    ){
        level += 3;
        log('发现异动', newKlineData.timestamp, pairName);
    }
    if(level == 0){
        return;
    }

    const lastBuyPoint = {
        time: newKlineData.timestamp,
        price: newKlineData.close,
        openPrice: newKlineData.open,
        change,
        level
    };

    if(rData.waitSell && rData.waitSell.type == 'timeoutForceSell'){
        runningData[pairName].waitSell = null;
        log('发现新买点，解除强卖', newKlineData.timestamp, pairName);
    }

    return lastBuyPoint;
}

/**
 * 检测卖点
 * @param newKlineData
 * @param prevKlineData 前5根线
 * @param trendDirect
 * 1、涨幅小于等于3% 回调1%
 * 2、涨幅大于3%且小于等于5% 回调1.5%
 * 3、涨幅大于5%且小于等于10% 回调2%
 * 4、涨幅大于10% 回调3%
 * 5、最大强制止损5%
 * 6、动态止损3%
 * 7、距离最后一次信号超过1h未上涨，找时机卖出
 * 8、跌1.5%，止损; 跌>=3%，等待回调2%，重新买入
 * 9、跌超5%，止损并锁定，回调3%，买入
 */
function checkSellPoints(newKlineData, prevKlineData, pairName){
    const rData = runningData[pairName];
    if(!rData.trendData){
        return;
    }
    if(rData.tradeRecords.length == 0){
        return;
    }
    const lastOrder = rData.tradeRecords[rData.tradeRecords.length - 1];
    if(lastOrder.side != 'buy'){
        return;
    }
    // 记录点
    const point = {
        price: newKlineData.close,
        time: newKlineData.time + 1.5*60
    };
    // profit
    const profit = parseFloat((newKlineData.close - lastOrder.price)/lastOrder.price * 100).toFixed(2);
    const change = parseFloat((newKlineData.close - newKlineData.open)/newKlineData.open * 100).toFixed(2);
    newKlineData = Object.assign({}, newKlineData, { change });
    // 回调率
    let backRate = null;
    if(profit > 1.5 && profit <= 3){
        backRate = 1;
    } else if(profit > 3 && profit <= 5) {
        backRate = 1.5;
    } else if(profit > 5 && profit <= 10) {
        backRate = 2;
    } else if(profit > 10) {
        backRate = 3;
    }
    // 记录买单后的最大价格
    if(!rData.afterMaxPrice){
        rData.afterMaxPrice = lastOrder.price;
        runningData[pairName].afterMaxPrice = lastOrder.price;
    }
    if(newKlineData.close - rData.afterMaxPrice > 0){
        rData.afterMaxPrice = newKlineData.close;
        runningData[pairName].afterMaxPrice = newKlineData.close;
    }
    const maxProfit = (rData.afterMaxPrice - lastOrder.price)/lastOrder.price * 100;
    // 待卖出任务
    if(rData.waitSell){
        const waitTimeDiff = newKlineData.time - rData.waitSell.time;
        if(backRate && rData.waitSell.type == 'profitBack' && waitTimeDiff > 5){
            log('价格回调检测 -> change:' + change + ' -> ' + (newKlineData.close - rData.waitSell.price), newKlineData.time, pairName);
            if(
                maxProfit - profit > backRate
                && newKlineData.close - rData.waitSell.price < 0.5
                && change < 0
            ){
                // 价格回调
                point.type = 'B';
                point.msg = '触发价格回调 -> back:' + (maxProfit - profit);
                runningData[pairName].waitSell = null;
                log('触发价格回调', newKlineData.time, pairName);
                return point;
            } else {
                //runningData[pairName].waitSell = null;
                //log('价格回调预警解除', newKlineData.time, pairName);
                // return;
            }
        }
        if(rData.waitSell.type == 'priceFall' && waitTimeDiff > 5){
            if(newKlineData.close - rData.waitSell.price < 0){
                // 极速下跌5s后还继续跌
                point.type = 'F';
                point.msg = '价格急速下跌';
                runningData[pairName].waitSell = null;
                log('触发价格急速下跌', newKlineData.time, pairName);
                return point;
            } else {
                runningData[pairName].waitSell = null;
                log('价格急速下跌预警解除', newKlineData.time, pairName);
                return;
            }
        }
        if(rData.waitSell.type == 'profitLoss' && waitTimeDiff > 5){
            if(change < 0 && profit < -1.5){
                point.type = 'F';
                point.msg = '触发止损:' + profit;
                runningData[pairName].waitSell = null;
                log('触发止损', newKlineData.time, pairName);
                return point;
            } else {
                runningData[pairName].waitSell = null;
                log('止损预警解除', newKlineData.time, pairName);
                return;
            }
        }
        if(rData.waitSell.type == 'timeoutForceSell'){
            // log('尝试超时强卖 profit:' + profit + ' waitTimeDiff ' + waitTimeDiff, newKlineData.time, pairName);
            if(waitTimeDiff > 24*3600 && profit < -1 && profit > -3){
                point.type = 'M4';
                point.msg = '超过24小时打高止损';
                runningData[pairName].waitSell = null;
                return point;
            } else if(waitTimeDiff > 12*3600 && profit > -1){
                point.type = 'M3';
                point.msg = '超过12小时打低止损';
                runningData[pairName].waitSell = null;
                return point;
            } else if(waitTimeDiff > 8*3600 && profit > 0){
                point.type = 'M2';
                point.msg = '超过8小时';
                runningData[pairName].waitSell = null;
                return point;
            } else if(profit - 1 > 0){
                point.type = 'M1';
                point.msg = '超过4小时';
                runningData[pairName].waitSell = null;
                return point;
            }
        }
    }
    // 检测触发回调
    if(backRate && maxProfit - profit > backRate && change < 0){
        log('触发回调 profit: + ' + profit + ' backRate: ' + backRate, newKlineData.time, pairName);
        runningData[pairName].waitSell = {
            time: newKlineData.timestamp,
            price: newKlineData.close,
            type: 'profitBack'
        };
        return;
    }
    // 检测超时；距离最后一次信号超过4h未上涨，找时机卖出
    const now = newKlineData.timestamp;
    const lastBuyPoint = rData.lastBuyPoint;
    if(
        (!rData.waitSell || rData.waitSell.type != 'timeoutForceSell')
        && now - lastBuyPoint.time > 4*3600
        && newKlineData.close - lastBuyPoint.price < 0.5
    ){
        log('持仓太久，准备强卖', newKlineData.timestamp, pairName);
        runningData[pairName].waitSell = {
            time: newKlineData.timestamp,
            price: newKlineData.close,
            type: 'timeoutForceSell'
        };
        return;
    }
    // 检测价格急速下跌
    if(
        change < 0
        && (newKlineData.high - newKlineData.close)/newKlineData.close * 100 > 3
        && (!rData.waitSell || rData.waitSell.type != 'priceFall')
    ){
        log('价格急速下跌', newKlineData.timestamp, pairName);
        runningData[pairName].waitSell = {
            time: newKlineData.timestamp,
            price: newKlineData.close,
            type: 'priceFall'
        };
        return;
    }
    // 止损
    if(change < 0 && profit < -1.8){
        log('准备止损', newKlineData.timestamp, pairName);
        runningData[pairName].waitSell = {
            time: newKlineData.timestamp,
            price: newKlineData.close,
            type: 'profitLoss'
        };
        return;
    }

    // 超过24小时未卖出，找一个点尽快卖出
    /*if(diffTime > 12*3600 && !rData.waitSell && now - lastBuyPoint.time > 2*3600){
        // 超过6个小时持仓，近两个小时没有好的买点，启动强卖策略
        log('超过12个小时持仓，启动强卖策略', newKlineData.timestamp, pairName);
        runningData[pairName].waitSell = {
            time: newKlineData.timestamp,
            price: newKlineData.close,
            type: 'timeoutForceSell'
        };
        return;
    }
    if(rData.waitSell && rData.waitSell.type == 'timeoutForceSell'){
        // 超时强卖
        if(now - lastBuyPoint.time < 2*3600){
            const backProfit = (rData.afterBuyMaxPrice - newKlineData.close)/newKlineData.close * 100;
            if(backProfit - 1 > 0 && profit - 3 > 0){
                point.type = 'M4';
                point.msg = '超过2小时但最近出现新买点有收益就卖';
                log('强卖:超过2小时但最近出现新买点有收益就卖', newKlineData.timestamp, pairName);
                return point;
            }
            return;
        }
        if(diffTime > 24*3600 && profit < -1 && profit > -3){
            point.type = 'M4';
            point.msg = '超过24小时打高止损';
            return point;
        } else if(diffTime > 12*3600 && profit > -1){
            point.type = 'M3';
            point.msg = '超过12小时打低止损';
            return point;
        } else if(diffTime > 6*3600 && profit > 0){
            point.type = 'M2';
            point.msg = '超过6小时';
            return point;
        } else if(profit - 0.5 > 0){
            point.type = 'M1';
            point.msg = '超过2小时';
            return point;
        }
    }*/

    // 统计
    let goodCount = 0, badCount = 0, prevCV = 0, badStatus = 0;
    for(let i in prevKlineData){
        const cv = (prevKlineData[i].close - prevKlineData[i].open)/prevKlineData[i].open * 100;
        if(cv > 1){
            goodCount++;
        } else if(cv < -0.5) {
            badCount++;
        }
        if(cv - prevCV <= 0){
            badStatus++;
        }
        prevCV = cv;
    }

    return;
}

/**
 * 分析趋势
 * @param newData
 */
function analysisTrend(newData, pairName){
    const rData = runningData[pairName];
    if(newData){
        const newTime = newData.time;
        const hourKey = newTime - newTime%3600;
        const min15Key = newTime - newTime%(15*60);
        const min3Key = newTime - newTime%(3*60);
        // week
        if(rData.trendDataSource.day7Kline[rData.trendDataSource.day7Kline.length - 1].time == hourKey){
            // 覆盖
            const lastWeekRow = rData.trendDataSource.day7Kline[rData.trendDataSource.day7Kline.length - 1];
            lastWeekRow.high = newData.high - lastWeekRow.high > 0 ? newData.high : lastWeekRow.high;
            lastWeekRow.low = newData.low - lastWeekRow.low < 0 ? newData.low : lastWeekRow.low;
            lastWeekRow.close = newData.close;
            lastWeekRow.volume = parseFloat(newData.volume) + parseFloat(lastWeekRow.volume);
            runningData[pairName].trendDataSource.day7Kline[rData.trendDataSource.day7Kline.length - 1] = lastWeekRow;
        } else {
            // 追加
            runningData[pairName].trendDataSource.day7Kline.push(newData);
            runningData[pairName].trendDataSource.day7Kline = rData.trendDataSource.day7Kline.slice(-7*24);
        }
        // day
        if(rData.trendDataSource.day1Kline[rData.trendDataSource.day1Kline.length - 1].time == min15Key){
            // 覆盖
            const lastDayRow = rData.trendDataSource.day1Kline[rData.trendDataSource.day1Kline.length - 1];
            lastDayRow.high = newData.high - lastDayRow.high > 0 ? newData.high : lastDayRow.high;
            lastDayRow.low = newData.low - lastDayRow.low < 0 ? newData.low : lastDayRow.low;
            lastDayRow.close = newData.close;
            lastDayRow.volume = parseFloat(newData.volume) + parseFloat(lastDayRow.volume);
            runningData[pairName].trendDataSource.day1Kline[rData.trendDataSource.day1Kline.length - 1] = lastDayRow;
        } else {
            // 追加
            runningData[pairName].trendDataSource.day1Kline.push(newData);
            runningData[pairName].trendDataSource.day1Kline = rData.trendDataSource.day1Kline.slice(-60/15*24);
        }
        // hour12
        if(rData.trendDataSource.hour12Kline[rData.trendDataSource.hour12Kline.length - 1].time == min15Key){
            // 覆盖
            const lastDayRow = rData.trendDataSource.hour12Kline[rData.trendDataSource.hour12Kline.length - 1];
            lastDayRow.high = newData.high - lastDayRow.high > 0 ? newData.high : lastDayRow.high;
            lastDayRow.low = newData.low - lastDayRow.low < 0 ? newData.low : lastDayRow.low;
            lastDayRow.close = newData.close;
            lastDayRow.volume = parseFloat(newData.volume) + parseFloat(lastDayRow.volume);
            runningData[pairName].trendDataSource.hour12Kline[rData.trendDataSource.hour12Kline.length - 1] = lastDayRow;
        } else {
            // 追加
            runningData[pairName].trendDataSource.hour12Kline.push(newData);
            runningData[pairName].trendDataSource.hour12Kline = rData.trendDataSource.hour12Kline.slice(-60/15*12);
        }
        // hour
        if(rData.trendDataSource.hour1Kline[rData.trendDataSource.hour1Kline.length - 1].time == min3Key){
            // 覆盖
            const lastHourRow = rData.trendDataSource.hour1Kline[rData.trendDataSource.hour1Kline.length - 1];
            lastHourRow.high = newData.high - lastHourRow.high > 0 ? newData.high : lastHourRow.high;
            lastHourRow.low = newData.low - lastHourRow.low < 0 ? newData.low : lastHourRow.low;
            lastHourRow.close = newData.close;
            lastHourRow.volume = parseFloat(newData.volume) + parseFloat(lastHourRow.volume);
            runningData[pairName].trendDataSource.hour1Kline[rData.trendDataSource.hour1Kline.length - 1] = lastHourRow;
        } else {
            // 追加
            runningData[pairName].trendDataSource.hour1Kline.push(newData);
            runningData[pairName].trendDataSource.hour1Kline = rData.trendDataSource.hour1Kline.slice(-20);
        }
    }

    const resDay7 = checkTrend(rData.trendDataSource.day7Kline, 3);
    const resDay1 = checkTrend(rData.trendDataSource.day1Kline, 1.5);
    const resHour12 = checkTrend(rData.trendDataSource.hour12Kline, 1.5);
    const resHour1 = checkTrend(rData.trendDataSource.hour1Kline, 1);

    runningData[pairName].trendData = {
        day7: resDay7,
        day1: resDay1,
        hour1: resHour1,
        hour12: resHour12
    };
    return runningData[pairName].trendData;
}

/**
 * 趋势检测
 * @param klineData
 * @param threshold
 * @returns {{reliableChange: string, min: {price: number, time: null}, max: {price: number, time: null}, direct: (number), maxChange: string, zeroChange: {count: number, deadline: null, percent: string}, isChange: boolean, avePrice: string}}
 */
function checkTrend(klineData, threshold){
    // 根据变化率找到未涨前的均价
    const max = {
        price: 0,
        time: null
    };
    const min = {
        price: 999999999,
        time: null
    };
    const changeRates = [];
    let lastTwoChanges = [], limitValue = threshold;
    let zeroCount = 0, totalPrice = 0, deadline = null;
    for(let i in klineData){
        const item = klineData[i];
        if(item.low - min.price < 0){
            min.price = item.low;
            min.time = parseInt(item.openTime/1000);
        }
        if(item.high - max.price > 0){
            max.price = item.high;
            max.time = parseInt(item.openTime/1000);
        }
        let change = parseFloat((parseFloat(item.close) - parseFloat(item.open))/item.open*100).toFixed(2);
        if(
            lastTwoChanges.length == 2
            && Math.abs(lastTwoChanges[0]) - limitValue < 0
            && Math.abs(change) - limitValue < 0
            && changeRates[i - 1].value < limitValue
        ){
            changeRates[i - 1].value = 0;
        }
        if(Math.abs(change) - limitValue < 0){
            change = 0;
        }

        const time = parseInt(item.openTime/1000);
        changeRates.push({
            time,
            value: change
        });
        if(change >= limitValue && !deadline){
            deadline = time;
        }
        if(change == 0){
            zeroCount++;
        }
        totalPrice += parseFloat(item.close);
    }

    const avePrice = totalPrice/changeRates.length;
    const zeroChangePercent = parseFloat(zeroCount/changeRates.length*100).toFixed(2);

    // 计算理想的最大收益
    const direct = max.time > min.time ? 1 : -1;// 1:先跌后涨 -1:先涨后跌
    let maxChange = 0;
    if(direct == 1){
        maxChange = parseFloat((max.price - min.price)/min.price*100).toFixed(2);
    } else {
        // 重新找最高点前的最低点
        min.price = max.price;
        min.time = max.time;
        for(let i in klineData){
            const item = klineData[i];
            if(item.closeTime/1000 > max.time){
                break;
            }
            if(item.low - min.price < 0){
                min.price = item.low;
                min.time = item.closeTime/1000;
            }
        }
        maxChange = parseFloat((max.price - min.price)/min.price*100).toFixed(2);
    }
    // 最可靠的收益（相对容易达成的）
    const reliableChange = parseFloat((max.price - avePrice)/avePrice*100).toFixed(2);

    const res = {
        min,
        max,
        maxChange,
        reliableChange,
        isChange: reliableChange > 10,
        direct,
        avePrice: parseFloat(avePrice).toFixed(8),
        zeroChange: {
            percent: zeroChangePercent,
            count: zeroCount,
            deadline
        }
    }

    return res;
}

function getTrendStatus(trendData, newKline){
    const day1AvePrice = trendData.day1.avePrice,
        day7AvePrice = trendData.day7.avePrice,
        hour1AvePrice = trendData.hour1.avePrice,
        hour12AvePrice = trendData.hour12.avePrice;
    const pDay7Day1 = (day1AvePrice - day7AvePrice)/day7AvePrice*100;
    const pDay7Hour1 = (hour1AvePrice - day7AvePrice)/day7AvePrice*100;
    const pDay1Hour1 = (hour1AvePrice - day1AvePrice)/day1AvePrice*100;
    const pHour12Hour1 = (hour1AvePrice - hour12AvePrice)/hour12AvePrice*100;
    const pHour12Close = (newKline.close - hour12AvePrice)/hour12AvePrice*100;
    let trendDirect = 0, msg = '平稳';
    if(pHour12Hour1 < 1 && hour1AvePrice - day1AvePrice > 0){
        trendDirect = 1;
        msg = '趋势向上';
    } else if(pDay1Hour1 < 2 && newKline.close - hour1AvePrice < 0) {
        // 小时均接近日均，小时均线低于日均,实时价在小时均线下
        trendDirect = -2;
        msg = '从高点价格回调';
    } else if(pDay7Hour1 > 3 && pDay1Hour1 < -pDay7Day1/2) {
        // 小时均在周均3%以上
        trendDirect = -3;
        msg = '价格处于高位';
    }
    if(pHour12Close > 5){
        // 价格已经涨起来
        trendDirect = -4;
        msg = '价格已拉高';
    }

    return { trendDirect, msg };
}

function log(msg, time, pairName){
    runningData[pairName].logs.push({
        time: time ? time : parseInt(new Date().getTime()/1000),
        msg
    });
}

export default execute;