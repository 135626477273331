module.exports = {
    header: {
        Trade: '交易',
        News: '历史新闻',
        Analysis: '分析',
        Singals: '信号',
        Strategy: '策略',
        Simulate: '模拟',
        NFT: 'NFT',
        Tools: '小工具',
        Backback: 'BP刷单',
        search:{
            placeholder: '请输入交易对名称',
            resultTitle: '搜索结果',
            placeholderNft: '请输入系列symbol'
        },
        accountModal: {
            title: '账户',
            connect: '连接',
            disconnect: '断开连接',
            address: '地址',
            balance: '余额'
        }
    },
    trade: {
        form: {
            pricePlaceholder: '选择或者输入价格',
            priceLimit: '限价',
            amountPlaceholder: '请输入金额',
            amount: '金额',
            numberPlaceholder: '请输入数量',
            number: '数量',
            stopPlaceholder: '请输入百分比',
            stop: '止损',
            free: '可用',
            expectedStopPrice: '预计止损价格'
        }
    },
    tabs: {
        news: '新闻',
        singals: '信号',
        ordinary: '常规',
        advanced: '高级',
        config: '配置',
        tasks: '任务',
        orders: '挂单',
        trades: '成交',
        account: '账户',
        buy: '买 入',
        sell: '卖 出'
    },
    kline: {
        open: '开',
        close: '收',
        high: '高',
        low: '低',
        change: '涨跌',
        amplitude: '振幅',
        volume: '交易量'
    },
    articles: {
        aiAnalysis: 'AI解读',
        aiDecode: 'AI解读',
        aiWarning: '以上结果由ChatGPT提供，不代表平台观点，请自行评估！',
        isNotSupported: '系统暂时不支持您当前语言环境的解读！',
        aiDecodeFailed: 'AI解读失败！'
    },
    strategies: {
        'SPOT_GRID': '网格挂单'
    },
    free: '免费',
    pointsBalance: '剩余积分',
    priceUnit: '积分/次',
    volume: '交易量',
    change: '涨跌幅',
    high: '最高价',
    low: '最低价',
    price: '价格',
    balance: '余额',
    cost: '成本',
    profit: '盈亏',
    buy: '买入',
    sell: '卖出',
    marketPrice: '市价',
    limitPrice: '限价',
    noData: '暂无数据',
    related: '相关',
    loadMore: '加载更多',
    feeRate: {
        fastestFee: '快',
        halfHourFee: '中',
        hourFee: '慢',
        minimumFee: '很慢'
    }
}