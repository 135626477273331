<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
    <div class="config-container">
      <div class="config-panel buy-config">
        <div class="title">买入配置</div>
        <el-form-item label="关键词" prop="buy.keywords">
          <el-input type="textarea" v-model="ruleForm.buy.keywords" placeholder="多个关键词请用’,‘号隔开，当接收到新闻并识别到这些关键词的任何一个时，自动买入。"></el-input>
        </el-form-item>
        <el-form-item label="买入金额" prop="buy.amount">
          <el-input v-model="ruleForm.buy.amount">
            <template slot="append">USDT</template>
          </el-input>
        </el-form-item>
      </div>
      <div class="config-panel sell-config">
        <div class="title">卖出配置</div>
        <el-form-item label="卖出点" prop="sell.profit">
          <el-input v-model="ruleForm.sell.profit" placeholder="达到此收益率自动卖出">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="止损点（填负数）">
          <el-input v-model="ruleForm.sell.loss" placeholder="超过此亏损率自动卖出">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    configKey: {
      type: String
    },
    config: {
      type: Object
    }
  },
  data(){
    return {
      ruleKey: null,
      rules: {
        buy: {
          keywords: [
            { required: true, message: '请输入关键词', trigger: 'blur' }
          ],
          amount: [
            { required: true, message: '请输入买入金额', trigger: 'blur' }
          ],
        },
        sell: {
          profit: [
            { required: true, message: '请输入卖出点', trigger: 'blur' }
          ]
        }
      },
      ruleForm: {
        buy: {},
        sell: {}
      }
    }
  },
  mounted() {
    const self = this;
    if(this.config){
      this.ruleForm = this.config;
    }
    // 监听保存策略
    this.$eventBus.$on('SAVE_STRTEGY', info => {
      self.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return;
        }
        const now = new Date().getTime();
        let myStrtegy = localStorage.getItem('MY_STRATEGY');
        myStrtegy = myStrtegy ? JSON.parse(myStrtegy) : {};
        const key = this.configKey ? this.configKey : info.slug + '_' + now;
        myStrtegy[key] = {
          info,
          ruleForm: self.ruleForm,
          updateTime: parseInt(now/1000)
        };
        localStorage.setItem('MY_STRATEGY', JSON.stringify(myStrtegy));
        self.$message.success('保存成功');
        this.$eventBus.$emit('SAVE_STRTEGY_SUCCESS', self.ruleForm);
      });
    });
  }
}
</script>

<style lang="less" scoped>
.config-container{
  display: flex;
  justify-content: space-between;

  .buy-config{
    width: 50%;
    padding-right: 10px;
  }
  .sell-config{
    width: 50%;
    padding-left: 10px;
  }
  .config-panel {

    .title{
      font-size: 13px;
      font-weight: bold;
    }
    .el-form-item{
      margin-top: 10px;
      /deep/.el-form-item__label{
        line-height: 30px;
        padding-bottom: 0 !important;
      }
    }
  }
}
</style>