<template>
  <div class="container">
    Home页面
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
