import * as indicatorUtil from '@/utils/indicator';
import * as func from '@/utils/func';

const cacheDataTlp = {
    prevSingalTime: 0,
    last24_avePrice: [],
    last24_aveChange: [],
    // 每隔1h记录一个
    last4_avePrice_1h: [],
    last4_avePrice_2h: [],
    last4_avePrice_4h: [],
    last_maxChange_2h: 1,
    isPriceStable_12h: false,
    isChangeStable_12h: false,
    basicAveChange: 0.3,
    basicAvePrice: null,
    startAvePice: null,
    // 记录最后10个
    last10_avePrice_1h: [],
    last10_avePrice_2h: [],
    last10_avePrice_4h: [],
    mark: 0,
    prevSingal: {
        time: 0,
        price: 0
    }
};
const cacheDataObj = {};
export function checkBuy(klineData, pairName) {
    if (klineData.length < 120) {
        // 6小时的k线
        return null;
    }
    if(!cacheDataObj[pairName]){
        cacheDataObj[pairName] = Object.assign({}, cacheDataTlp);
    }
    const cacheData = cacheDataObj[pairName];

    const lastCandle = klineData[klineData.length - 1];
    const now = lastCandle.time;
    const res = {
        time: now,
        price: lastCandle.close
    };

    const avePrice_1h = indicatorUtil.cauAvePrice(klineData.slice(-20));
    const avePrice_2h = indicatorUtil.cauAvePrice(klineData.slice(-40));
    const avePrice_4h = indicatorUtil.cauAvePrice(klineData.slice(-80));

    res.avePrice_1h = avePrice_1h;
    cacheData.last10_avePrice_1h.push(avePrice_1h);
    cacheData.last10_avePrice_1h = cacheData.last10_avePrice_1h.slice(-10);

    res.avePrice_2h = avePrice_2h;
    cacheData.last10_avePrice_2h.push(avePrice_2h);
    cacheData.last10_avePrice_2h = cacheData.last10_avePrice_2h.slice(-10);

    res.avePrice_4h = avePrice_4h;
    cacheData.last10_avePrice_4h.push(avePrice_4h);
    cacheData.last10_avePrice_4h = cacheData.last10_avePrice_4h.slice(-10);

    if(now % 1800 === 0){
        // 记录最后4个1h均价
        cacheData.last4_avePrice_1h.push(avePrice_1h);
        cacheData.last4_avePrice_1h = cacheData.last4_avePrice_1h.slice(-4);
        // 记录最后4个2h均价
        cacheData.last4_avePrice_2h.push(avePrice_2h);
        cacheData.last4_avePrice_2h = cacheData.last4_avePrice_2h.slice(-4);
        // 记录最后4个4h均价
        cacheData.last4_avePrice_4h.push(avePrice_4h);
        cacheData.last4_avePrice_4h = cacheData.last4_avePrice_4h.slice(-4);
        // 记录最后24个1小时均价
        cacheData.last24_avePrice.push(avePrice_1h);
        cacheData.last24_avePrice = cacheData.last24_avePrice.slice(-24);

        cacheData.isPriceStable_6h = checkIsPriceStable(klineData.slice(-120).map(r => r.close), 2, 120);
        // 最近12小时价格是否稳定
        cacheData.isPriceStable_12h = checkIsPriceStable(cacheData.last24_avePrice, 1, 24);
        // 均价是否稳定
        const isPriceStable = checkIsPriceStable(cacheData.last4_avePrice_1h, 0.5, 4);
        cacheData.isPriceStable = isPriceStable;
        // 如果稳定，记为基准均价
        if(isPriceStable){
            cacheData.basicAveChange = indicatorUtil.cauAveChange(klineData.slice(-40), true) * 1.5;
            cacheData.basicAvePrice = indicatorUtil.cauAvePrice(klineData.slice(-40));
        }

        // 1小时的平均change
        let aveChange_1h = indicatorUtil.cauAveChange(klineData.slice(-20));
        // 记录最近24个1小时平均change
        cacheData.last24_aveChange.push(aveChange_1h);
        cacheData.last24_aveChange = cacheData.last24_aveChange.slice(-24);
        // 近12小时change是否稳定
        cacheData.isChangeStable_12h = checkIsChangeStable(cacheData.last24_aveChange, cacheData.basicAveChange, 24);
        aveChange_1h = aveChange_1h - cacheData.basicAveChange < 0 ? 0 : aveChange_1h;

        res.aveChange_1h = parseFloat(aveChange_1h).toFixed(2);
    }
    res.maxChange_2h = cacheData.last_maxChange_2h;
    res.basicAveChange = parseFloat(cacheData.basicAveChange).toFixed(2);

    if(now - cacheData.prevSingalTime < 5*60 && now - cacheData.prevSingalTime > 0){
        return res;
    }

    // 过滤条形码行情
    if(checkBarCode(klineData.slice(-20))){
        return res;
    }

    res.isPriceStable_6h = cacheData.isPriceStable_6h;

    let markPoint = false, remark = null;
    const direct = lastCandle.close - lastCandle.open >= 0 ? 1 : -1;
    const zeroChangeRate = indicatorUtil.cauZeroChangeRate(klineData.slice(-40));
    res.zeroChangeRate = zeroChangeRate;
    const last6h_candles = klineData.slice(-120);
    res.zeroChangeRate_6h = indicatorUtil.cauZeroChangeRate(last6h_candles);
    if(res.zeroChangeRate_6h === 0 && res.isPriceStable_6h){
        cacheData.startAvePice = indicatorUtil.cauAvePrice(last6h_candles);
    }
    if(!cacheData.startAvePice){
        return res;
    }
    const diffStartChange = (lastCandle.close - cacheData.startAvePice)/cacheData.startAvePice * 100;
    if(diffStartChange - 2 < 0 || diffStartChange - 15 > 0){
        return res;
    }
    res.diffStartChange = parseFloat(diffStartChange).toFixed(2);

    // 两小时持续横盘，涨幅大于1.2，等待下一个candle
    const closeChange = (lastCandle.close - lastCandle.open)/lastCandle.open * 100;
    res.trend1 = checkTrend(cacheData.last10_avePrice_1h, 10, 0.007, 0.7);
    res.trend2 = checkTrend(cacheData.last10_avePrice_2h, 10, 0.005, 0.7);
    res.trend4 = checkTrend(cacheData.last10_avePrice_4h, 10, 0.005, 0.7);

    if(zeroChangeRate - 10 < 0 && closeChange - 1.2 > 0){
        markPoint = true;
        remark = res.zeroChangeRate_6h === 0 && res.trend1 === 0 ? 'A0' : 'A';
    }
    // 涨幅大于1.8
    if(closeChange - 1.8 > 0){
        markPoint = true;
        remark = 'B';
    }
    // 最后5个change累计大于1.8
    const last5TotalChange = cauLast5TotalChange(klineData.slice(-5));
    const lastCandle_2 = klineData[klineData.length - 2];
    if(last5TotalChange - 1.5 > 0 && closeChange - 0.5 > 0 && lastCandle_2.close - lastCandle_2.open > 0){
        markPoint = true;
        remark = res.zeroChangeRate_6h === 0 && res.trend1 === 0 ? 'C0' : 'C';
    }

    if(markPoint === true){
        const realChange = cacheData.basicAvePrice ? (lastCandle.open - cacheData.basicAvePrice)/cacheData.basicAvePrice * 100 : 0;
        // 真实涨幅在-3 ~ 5 区间内
        if(realChange - (-3) > 0 && realChange - 5 < 0){
            res.singal = markPoint;
            res.remark = remark;
            res.realChange = parseFloat(closeChange).toFixed(2);
            res.prevSingalTime = cacheData.prevSingalTime;
            cacheData.prevSingalTime = now;
            // 如果是稳定的，检测前面是否有异动、或者等下一个candle
            const _aveChange_1h_all = indicatorUtil.cauAveChange(klineData.slice(-20), true);
            res.isChangeStable_12h = cacheData.isChangeStable_12h && _aveChange_1h_all - cacheData.basicAveChange <= 0 ? true : false;
            const _avePrice_1h_all = indicatorUtil.cauAvePrice(klineData.slice(-20), true);
            res.isPriceStable_12h = cacheData.isPriceStable_12h && _avePrice_1h_all - cacheData.basicAvePrice <= 0 ? true : false;
            // 1h均价趋势
            const _avePrice_1h = indicatorUtil.cauAvePrice(klineData.slice(-20));
            res.trend_1h = checkTrend(cacheData.last4_avePrice_1h.concat([_avePrice_1h]), 5);
            // 2h均价趋势
            const _avePrice_2h = indicatorUtil.cauAvePrice(klineData.slice(-40));
            res.trend_2h = checkTrend(cacheData.last4_avePrice_2h.concat([_avePrice_2h]), 5);
            // 4h均价趋势
            const _avePrice_4h = indicatorUtil.cauAvePrice(klineData.slice(-80));
            res.trend_4h = checkTrend(cacheData.last4_avePrice_4h.concat([_avePrice_4h]), 5);
        } else {
            res.badRealChange = true;
        }
    }

    // 连续6个小时平稳的情况下，等待下一个candle进行检测
    if(res.singal && ['A0', 'C0'].indexOf(remark) > -1){
        cacheData.mark = now;
    }
    if(now - cacheData.mark > 0 && now - cacheData.mark <= 6*60){
        res.singal = true;
        remark = 'D';
        res.remark = remark;
    }
    if(res.singal){
        const change_2 = (lastCandle.close - lastCandle_2.open)/lastCandle_2.open * 100; // 上一个change
        if(res.isPriceStable_6h && res.zeroChangeRate_6h - 10 < 0 && res.diffStartChange - 2 > 0) {
            res.goodSingal = true;
        } else if(res.zeroChangeRate - 60 > 0) {
            res.goodSingal = false;
        } else if(['A0', 'C0'].indexOf(remark) > -1 && closeChange - 1.6 < 0){
            res.goodSingal = false;
        } else if(remark === 'D') {
            // 信号类型是D，上一个change大于1.5，当前change大于0.5
            if(change_2 - 1.8 >= 0 && closeChange - 0.5 > 0){
                res.goodSingal = true;
            }
        } else {
            const cond1 = now - cacheData.prevSingal.time > 20*60 || lastCandle.close - cacheData.prevSingal.close < 0;
            const cond2 = res.trend1 + res.trend2 + res.trend4 >= 2;
            if(cond1 && cond2){
                res.goodSingal = true;
            }
        }
        cacheData.prevSingal = {
            time: now,
            close: lastCandle.close
        };
    }

    return res;
}

function cauLast5TotalChange(candles){
    let lastTotalChange = 0;
    const last5Changes = candles.map(r => {
        const c = (r.close - r.open)/r.open * 100;
        return c;
    });
    for(let j in last5Changes){
        if(last5Changes[j] < -0.1){
            break;
        }
        lastTotalChange += last5Changes[j];
    }
    return lastTotalChange;
}

/**
 * 检测是否价格稳定点
 * @param arr
 * @param value
 */
function checkIsPriceStable(arr, thresholdChange = 1, count = 4){
    if(arr.length - count < 0){
        return false;
    }
    const change = (arr[arr.length - 1] - arr[arr.length - count])/arr[arr.length - count] * 100;
    if(change - thresholdChange > 0){
        return false;
    }
    let flag = true;
    for(let i in arr){
        if(i == 0) continue;
        const c = (arr[i] - arr[i - 1])/arr[i - 1] * 100;
        if(Math.abs(c) - thresholdChange > 0){
            flag = false;
            break;
        }
    }

    return flag;
}

function checkIsChangeStable(arr, thresholdChange = 1, count = 4){
    if(arr.length - count < 0){
        return false;
    }
    let flag = true;
    for(let i in arr){
        if(i == 0) continue;
        if(Math.abs(arr[i]) - thresholdChange > 0){
            flag = false;
            break;
        }
    }

    return flag;
}

/**
 * arr值的趋势
 * @param arr
 * @param count
 * @returns {number}
 */
function checkTrend(arr, count = 4, limit = 0.02, percent = 1){
    if(arr.length - count < 0){
        return 0;
    }
    let v1 = 0, v2 = 0;
    for(let i in arr){
        if(i == 0) continue;
        const c = (arr[i] - arr[i - 1])/arr[i - 1] * 100;
        if(c - limit > 0){
            v1++;
        } else if(c - (-limit) < 0){
            v2++;
        }
    }
    if(v1 >= (arr.length - 1) * percent){
        return 1;
    }
    if(v2 >= (arr.length - 1) * percent){
        return -1;
    }
    return 0;
}

/**
 * 是否有交易量
 * @param candles
 * @returns {boolean}
 */
function checkBarCode(candles){
    const lastCandle = candles[candles.length - 1];

    if(func.getDate(lastCandle.time) !== '2024-01-26 09:27:00'){
        // return true
    }

    const last10Price = candles.slice(-10).map(r => (parseFloat(r.open) + parseFloat(r.close))/2);
    let n = 0, prevMax = 0;
    for(let i in last10Price){
        if(i == 0) {
            prevMax = last10Price[i];
            continue;
        }
        if((last10Price[i] - prevMax)/prevMax*100 > 0.01) {
            n++;
            prevMax = last10Price[i];
        }
    }
    if(n >= 4){
        return false;
    }

    const highChanges = [], threshold = 0.02;
    for(let i in candles){
        const r = candles[i];
        const currentValue = parseFloat(parseFloat((r.high - r.low)/r.low * 100).toFixed(2));

        highChanges.push(currentValue);
        if(i == 0){
            continue;
        }
        const previousValue = highChanges[i - 1];
        // 计算相邻元素之间的差异的百分比
        const differencePercentage1 = Math.abs((currentValue - previousValue) / previousValue);
        // 如果差异小于阈值，则将当前值设置为与前一个值相同
        if (differencePercentage1 <= threshold) {
            highChanges[i - 1] = currentValue;
            highChanges[i] = currentValue;
            continue;
        }
        // 当前值的一半和上一个值差不多
        const differencePercentage2 = Math.abs((currentValue/2 - previousValue) / previousValue);
        if (differencePercentage2 <= threshold) {
            highChanges[i - 1] = currentValue/2;
            highChanges[i] = currentValue/2;
            continue;
        }
        // 当前值的2倍和上一个差不多
        const differencePercentage3 = Math.abs((currentValue*2 - previousValue) / previousValue);
        if (differencePercentage3 <= threshold) {
            highChanges[i] = currentValue;
            highChanges[i - 1] = currentValue;
        }
    }
    // 统计
    const counts = {};
    highChanges.map(v => {
        if(!counts['v_' + v]) counts['v_' + v] = 0;
        counts['v_' + v]++;
    });
    const arr = Object.entries(counts);
    arr.sort((a, b) => b[1] - a[1]);

    let v1 = parseFloat(arr[0][0].split('_')[1]);
    let count = arr[0][1];
    if(arr[1]){
        const v2 = parseFloat(arr[1][0].split('_')[1]);
        if(Math.abs(v2 - v1) / v1 <= threshold){
            count += arr[1][1];
            v1 = (v1 + v2)/2;
        }
    }
    if(arr[2]){
        const v3 = parseFloat(arr[2][0].split('_')[1]);
        if(Math.abs(v3 - v1) / v1 <= threshold){
            count += arr[2][1];
            v1 = (v1 + v3)/2;
        }
    }
    //
    // console.log(highChanges)
    // console.log(arr)
    // console.log(v1)
    // console.log(count)

    if(lastCandle.change - v1 * 3.2 > 0){
        return false;
    }

    const cond1 = count / candles.length * 100 - 68 > 0;
    //const cond2 = lastCandle.change - 0.01 < 0;*/

    return cond1;
}

/**
 * 计算初始价格
 * @param candles
 * @returns {number}
 */
function cauStartPrice(candles){
    let totalPrice = 0, count = 0;
    for(let i = 0; i < candles.length; i++){
        if(i < 5){
            continue;
        }
        const prevRow = candles[i - 5];
        const row = candles[i];
        if(Math.abs(row.change) - 0.3 > 0){
            continue;
        }
        const preAvePrice = (parseFloat(prevRow.open) + parseFloat(prevRow.close))/2;
        const atr = (row.close - preAvePrice)/preAvePrice * 100;
        if(Math.abs(atr) - 0.5 > 0){
            continue;
        }

        const avePrice = (parseFloat(row.open) + parseFloat(row.close))/2;

        totalPrice += avePrice;
        count++;
    }

    return totalPrice/count;
}