<template>
  <div class="container">
    <left-panel></left-panel>
    <div class="middle-panel">
      <div class="symbols-tabs">
        <symbol-tabs></symbol-tabs>
      </div>
      <div class="k-line-panel">
        <kline-chart></kline-chart>
      </div>
      <div class="trade-record-panel">
        <trade-records></trade-records>
      </div>
    </div>
    <right-panel></right-panel>
  </div>
</template>

<script>
import LeftPanel from "./components/Trade/LeftPanel";
import RightPanel from "./components/Trade/RightPanel";
import TradeRecords from "./components/Trade/TradeRecords";
import KlineChart from "./components/Trade/KlineChart";
import SymbolTabs from "./components/Trade/SymbolTabs";

export default {
  components:{
    LeftPanel,
    RightPanel,
    SymbolTabs,
    KlineChart,
    TradeRecords
  },
  data(){
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";

.container{
  display: flex;
}

.middle-panel{
  flex: 1;
  width: calc(100vw - 360px - 320px - 32px - 20px);
  height: calc(100vh - @header-height - @mg-height);
  padding-top: @mg;

  .symbols-tabs {
    height: 24px;
  }
  .k-line-panel{
    height: calc(@kline-chart-height - @mg - 24px);
    margin-bottom: @mg;
  }
  .trade-record-panel{
    width: 100%;
    height: calc(100vh - @header-height - @mg-height - @kline-chart-height);
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 750px) {
  .container{
    flex-direction: column;
  }
  .middle-panel{
    width: calc(100vw - 32px);
    margin: 0 auto;

    .trade-record-panel{
      display: none;
    }
  }
}
</style>
