import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter)

import Home from './pages/Home.vue';
import Trade from './pages/Trade.vue';
import HistoryNews from './pages/HistoryNews.vue';
import SymbolInfo from "./pages/SymbolInfo.vue";
import Analysis from './pages/Statistic.vue';
import DataPanel from './pages/DataPanel.vue';
import Simulate from "./pages/Simulate.vue";
import Strategy from "./pages/Strategy.vue";
import StrategyInfo from "@/pages/StrategyInfo";
import Singals from "@/pages/Singals";
import Nft from "@/pages/Nft";
import NftInfo from "@/pages/NftInfo";
import Tools from './pages/Tools.vue';
import KlineCanvas from './pages/KlineCanvas.vue';
import HotCoins from './pages/HotCoins.vue';
import Temp from './pages/Temp.vue';

const routes = [
  {
    path: '/',
    name: 'Trade',
    component: Trade,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/trade',
    name: 'Trade',
    component: Trade,
  },
  {
    path: '/history-news',
    name: 'HistoryNews',
    component: HistoryNews,
  },
  {
    path: '/symbol-info',
    name: 'SymbolInfo',
    component: SymbolInfo
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: Analysis,
  },
  {
    path: '/singals',
    name: 'Singals',
    component: Singals,
  },
  {
    path: '/statistic',
    name: 'DataPanel',
    component: DataPanel,
  },
  {
    path: '/simulate',
    name: 'Simulate',
    component: Simulate
  },
  {
    path: '/strategy',
    name: 'Strategy',
    component: Strategy
  },
  {
    path: '/strategy-info',
    name: 'StrategyInfo',
    component: StrategyInfo
  },
  {
    path: '/nft',
    name: 'Nft',
    component: Nft
  },
  {
    path: '/nft-info',
    name: 'NftInfo',
    component: NftInfo
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/kline-canvas',
    name: 'KlineCanvas',
    component: KlineCanvas
  },
  {
    path: '/hot-coins',
    name: 'HotCoins',
    component: HotCoins
  },
  {
    path: '/temp',
    name: 'Temp',
    component: Temp
  }
];

const router = new VueRouter({
  mode: 'hash', // history, hash
  routes
})

export default router;
