<template>
  <div>
    <div class="token-lists scroll-con">
      <i class="el-icon-loading loading" v-if="tokenLists === null"></i>
      <div
          class="token-item"
          v-for="(item, idx) in tokenLists"
          :key="idx"
          v-else
      >
        <div class="number-sn">{{item.posIndex || ''}}<i class="el-icon-loading text-warning" v-if="item.mempoolTxId"></i></div>
        <el-image :src="item.contentPreviewURI.replace('ord-mirror', 'ord-mirror-staging').replace('preview', 'content')" fit="cover" lazy class="cover"></el-image>
        <div class="name-con">
          <div class="name">
            <a :href="'https://magiceden.io/ordinals/item-details/' + item.id" target="_blank">
              {{ item.meta ? item.meta.name : item.displayName }}
            </a>
          </div>
          <div class="number sub-title mgt-5">
            <span class="pdl-0">Listed: {{item.listedPrice/Math.pow(10, 8)}}</span>
            <i class="pdl-5">({{ parseFloat((new Date().getTime() - new Date(item.listedAt).getTime())/1000/60).toFixed(1) }}m)</i>
          </div>
        </div>
        <div class="name-con">
          <div class="name">Seller: {{$com.formatString(item.owner, 5, 8)}}<span class="text-green pdl-5">{{item.owner === currentAccount ? '自己' : ''}}</span></div>
        </div>
        <div class="price-con">
          <div class="price" :class="{ light: topOffers[item.id].price - info.floorPrice > 0 }" v-if="topOffers[item.id]">
              <span :class="{my: currentAccount && topOffers[item.id].userAddress === currentAccount}">
                TopOffer:{{topOffers[item.id].price}}
              </span>
          </div>
          <div class="mgt-5 text-right" v-if="topOffers[item.id]">
              <span :class="{my: currentAccount && topOffers[item.id].userAddress === currentAccount}">
                {{ $com.formatString(topOffers[item.id].userAddress, 5, 3) }}
              </span>
          </div>
          <div class="price mgt-5" v-if="item.toOfferPrice && item.toOfferPrice > 0">
            <icon-btc></icon-btc>
            <span class="make-offer-price">Offer:{{item.toOfferPrice}}</span>
          </div>
        </div>

        <div class="handle-action">
          <el-button size="mini" type="danger" v-if="item.owner === currentAccount" @click="toPrevPos(item, idx)">上移</el-button>
          <el-button size="mini" type="success" v-if="item.owner === currentAccount" @click="toNextPos(item, idx)">下移</el-button>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
          :page-size="pageSize"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="totalListed"
          @current-change="onChangeTokensPage"
      ></el-pagination>
    </div>

    <!-- 挂单dialog -->
    <el-dialog title="挂单" :visible.sync="showListDialog" class="list-dialog">
      <div class="handle-token" v-if="handleToken">
        <el-image :src="handleToken.contentPreviewURI.replace('ord-mirror', 'ord-mirror-staging').replace('preview', 'content')" fit="cover" lazy class="cover"></el-image>
        <div class="name-con">
          <div class="name">{{handleToken.meta ? handleToken.meta.name : handleToken.displayName}}</div>
          <div class="price">Listed: {{handleToken.listedPrice/Math.pow(10, 8)}}</div>
        </div>
      </div>
      <el-input v-model="targetListPrice" size="small" @change="onChangeTargetListPrice">
        <template slot="prepend">挂单价</template>
        <template slot="append">BTC</template>
      </el-input>
      <el-input v-model="sellerReceiveAddress" size="small" class="mgt-10">
        <template slot="prepend">收款地址</template>
      </el-input>
      <div class="mgt-5 text-danger fs-13 text-right" v-if="targetListPrice && targetListPrice - info.floorPrice < 0">警告：挂单价已小于地板价！</div>
      <div class="target-pos" v-if="targetListPos">
        预计挂单位置：{{targetListPos}}
      </div>
      <div class="mgt-16 text-right">
        <el-button size="mini" type="success" @click="listToken(handleToken)">挂单</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";
const symbolName = {
  'bitcoin-puppets': '小猴子',
  'nodemonkes': '大猴子'
};

export default {
  props: [ 'info' ],
  data(){
    return {
      currentAccount: window.currentAccount || null,
      currentPublicKey: window.currentPubkey,
      // 列表
      tokenLists: null,
      prevFloorPrice: null,
      currentPage: 1,
      totalListed: 0,
      pageSize: 60,
      topOffers: {},
      // 挂单
      showListDialog: false,
      handleToken: null,
      targetListPrice: null,
      targetListPos: null,
      sellerReceiveAddress: window.currentAccount,
      waitDestroy: null
    }
  },
  created() {
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.currentAccount = account.address;
      this.currentPublicKey = account.pubkey;
      this.sellerReceiveAddress = this.currentAccount;
    });
  },
  mounted() {
    // load tokens
    this.loopTokensLists();
  },
  beforeDestroy() {
    this.waitDestroy = true;
  },
  methods: {
    async loopTokensLists(){
      while(true){
        if(this.waitDestroy) break;
        await this.loadTokens();
        await this.$func.sleep(15*1000);
      }
      this.waitDestroy = null;
    },
    /**
     * 切换分页
     * @param page
     */
    onChangeTokensPage(page){
      this.currentPage = page;
      localStorage.setItem('RUN_PAGE_' + this.info.collectionSymbol, this.currentPage);
      this.excuteOfferIndex = -1;
      this.loadTokens();
    },
    /**
     * 加载挂单的token列表
     * @returns {Promise<void>}
     */
    async loadTokens() {
      try {
        const res = await magicApi.getCollectionTokens({
          collectionSymbol: this.info.collectionSymbol,
          disablePendingTransactions: false, // 显示pending的tokens
          limit: this.pageSize,
          offset: (this.currentPage - 1) * this.pageSize
        });

        if(res.data && res.data.tokens){
          let i = 0, firstOwner = null;
          res.data.tokens.map((row, idx) => {
            // 播报地板
            if(idx === 0){
              const floorPrice = row.listedPrice/Math.pow(10, 8);
              if(!this.prevFloorPrice || floorPrice - this.prevFloorPrice !== 0){
                this.prevFloorPrice = floorPrice;
                this.$speak((symbolName[row.collectionSymbol] || row.collection.name) + ' 当前地板价' + floorPrice);
              }
            }
            // 播报自己的位置
            if(!firstOwner && row.owner === this.currentAccount){
              firstOwner = i + 1;
              this.$speak('自己的' + (symbolName[row.collectionSymbol] || row.collection.name) + '最小排名第' + firstOwner);
            }

            if(!row.mempoolTxId){
              i++;
              row.posIndex = i;
            }
            return row;
          });
          this.tokenLists = res.data.tokens;
        }
      } catch (err) {
        console.log('loadTokens -> ' + err.message);
      }
    },
    toPrevPos(token, index){
      // 找prevToken
      let prevToken = null;
      for(let i = index; i >= 0; i--){
        if(!this.tokenLists[i - 1]){
          break;
        }
        if(this.tokenLists[i - 1].mempoolTxId){
          continue;
        }
        prevToken = this.tokenLists[i - 1];
        break;
      }
      if(!prevToken){
        this.$message.error('不能再上移');
        return;
      }
      this.handleToken = token;
      this.targetListPrice = (prevToken.listedPrice - 1)/Math.pow(10, 8);
      this.targetListPos = this.cauTargetPos(this.targetListPrice);
      this.showListDialog = true;
    },
    toNextPos(token, index){
      // 找nextToken
      let nextToken = null;
      for(let i = index; i < this.tokenLists.length; i++){
        if(!this.tokenLists[i + 2]){
          break;
        }
        if(this.tokenLists[i + 2].mempoolTxId){
          continue;
        }
        nextToken = this.tokenLists[i + 2];
        break;
      }
      if(!nextToken){
        this.$message.error('不能再下移');
        return;
      }
      this.handleToken = token;
      this.targetListPrice = (nextToken.listedPrice - 1)/Math.pow(10, 8);
      this.targetListPos = this.cauTargetPos(this.targetListPrice) - 1;
      this.showListDialog = true;
    },
    onChangeTargetListPrice(){
      this.targetListPos = this.cauTargetPos(this.targetListPrice);
    },
    cauTargetPos(price){
      let pos = 0;
      for(let i in this.tokenLists){
        const token = this.tokenLists[i];
        if(token.mempoolTxId){
          continue;
        }
        pos++;
        if(price - token.listedPrice/Math.pow(10, 8) < 0){
          break;
        }
      }
      return pos;
    },
    /**
     * 挂单
     * @param token
     */
    async listToken(token){
      if(!this.targetListPrice){
        this.$message.error('挂单价不能为空');
        return;
      }
      const whiteAddress = [
          '392jU3MPLUcdv7BDAr86Ma5MLerdyYXYgq',
          '3GUMKaK3TroRTMnrRnC1Mw1TbF5iTe5FNk',
          '3G196rUA6ysQXwASL86TABWPpBBxLmdsMn',
          '35s1VKTBcR3RXizMoiGBkgWJHm6JLkGbbQ',
          '3F4CQFJbvtu5RtR8rp7ctzzGoGUn9RMVL5',
          '3PXnqSTpcypkTqdKJWFxfxCJqagn4efvx5',
          '3E1ijWsQndakgMBCKG6Jgxj6ZbqW3X8dCb',
          '3AjJutwprtujimuaq5dktthMxhH44CxNAY',
          '3PFb5i1zTucdocN4CmxBgqy3B6Huj4sqb4',
          '3FrMWyYdXqjJ36mq22Tkkm9Qnt4g5y9ZwV',
          '3BznGiGj3KzwSCTxR5zY1vSB6ssv1UBgwU',
          '3DfnTHgXeFoN7CBk9bozirjJgJiBFGPefd',
          '3Ed9rHzv834xTfbB8hfiCSiKJmcM9dAyVh',
          '3GkQotofno5RnebQL8YowpvoWorZRSYdKJ',
          '33oK15o1XpqCdJAUhSAm4sYG8SvGzwzcDq',
          '3LmQaLwziqLF181gm7u4U14CEpu3Rrae72',
          '3DCGy3tkWT2sUMvTLs5xNziMhcBykKbz59',
          '3B4zPQNz98RMkDK2NHUhZKwa9RZLogghvF',
          '3Fn3ivqThXGrJUWkC1Q6vr7WqDzypw3A5R',
          '347M84ZgSmGAwDeo7VALDC8RedHh7kj94b',
          'bc1p6dw8vhyjcl5dkc48vnhzl70uhlshy9k0fsq3l3pwa8sl9t7zuvssn0l58q'
      ];
      if(whiteAddress.indexOf(this.sellerReceiveAddress) === -1){
        this.$message.error('收款地址不在白名单中');
        return;
      }
      const res = await magicApi.listToken(
          token.id,
          this.targetListPrice,
          this.currentAccount,
          this.currentPublicKey,
          this.sellerReceiveAddress
      );
      if(res && res.ok){
        this.$message.success('挂单成功');
        setTimeout(() => {
          this.loadTokens();
        }, 5000);
      } else {
        this.$message.error('挂单失败');
      }
      this.showListDialog = false;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.token-lists {
  margin-bottom: @mg;
  padding: 8px;
  background-color: @section-bg-color;
  border-radius: @mg;
  height: calc(100vh - 75px - 82px - 20px - @mg*3 - 36px - 5px - 32px);
}

.token-item {
  padding: @mg + 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 8px;
    right: 8px;
    background-color: @content-bg-color;
    border-radius: 16px;
  }
  &.on {
    &:before {
      box-shadow: 0 0 2px @light-color;
    }
  }
  &.run {
    &:before {
      box-shadow: 0 0 5px @green;
    }
    .make-offer-price {
      color: @green;
    }
  }
  div {
    z-index: 1;
  }
  .number-sn {
    font-size: 16px;
    width: 30px;
    font-style: italic;
  }
  .cover {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .name-con {
    flex: 1;

    .name {
      color: @sub-font-color;

      a{
        text-decoration: none;
        color: @font-color;
      }
    }
    .number {
      display: flex;
      align-items: center;
      font-size: 12px;
      span{
        padding-left: 5px;
      }
    }
  }
  .sub-title {
    color: @sub-font-color;
  }
  .price-con {
    .my {
      color: @green;
    }
  }
  .price {
    display: flex;
    align-items: center;
    span {
      padding-left: 5px;
    }
    &.light {
      color: @light-color;
    }
  }
  .handle-action {
    flex: 1;
  }
}
.list-dialog {
  /deep/.el-dialog {
    width: 600px;
  }
  .handle-token {
    display: flex;
    align-items: center;
    margin-bottom: @mg;
    .cover {
      width: 36px;
      height: 36px;
      margin-right: 10px;
      border-radius: 5px;
    }
    .name-con {
      .name {
        font-size: 13px;
        color: @sub-font-color;
      }
      .price {
        font-size: 13px;
        color: @font-color;
        margin-top: 5px;
      }
    }
  }
  .target-pos {
    margin-top: 10px;
    font-size: 12px;
    color: @sub-font-color;
  }
}
</style>