import { eventBus } from '@/utils/eventBus.js';
let binanceWs = null;
export async function run(pairsStr){
    if(binanceWs){
        console.log('强制断开连接');
        await closeConnect(binanceWs);
    }
    const url = `wss://data-stream.binance.vision/ws/${pairsStr}`;
    binanceWs = new WebSocket(url);
    console.log('连接 -> ' + url);

    binanceWs.onmessage = (event) => {
        const message = event.data;
        // 解析
        const bufferData = Buffer.from(message);
        const jsonStr = bufferData.toString('utf8');
        const json = JSON.parse(jsonStr);
        const candle = candleTransform(json.k);
        // 发送到事件
        const row = {
            symbol: candle.symbol,
            time: parseInt(candle.startTime/1000),
            timestamp: parseInt(json.E/1000),
            openTime: parseInt(candle.startTime/1000),
            open: parseFloat(candle.open),
            high: parseFloat(candle.high),
            low: parseFloat(candle.low),
            close: parseFloat(candle.close),
            volume: candle.volume,
            closeTime: parseInt(candle.closeTime/1000),
            quoteVolume: candle.quoteVolume,
            trades: candle.trades
        }

        eventBus.$emit('CANDLE_DATA_' + row.symbol, row);
    };
    binanceWs.onclose = () => {
        console.log('连接断开 -> ' + url);
    };
}

/**
 * 断开重连
 * @param ws
 * @param callback
 */
async function closeConnect(ws){
    return new Promise((resolve, reject) => {
        let closeOk = false;
        let timer = setTimeout(() => {
            if(!closeOk){
                reject();
            }
        }, 20*1000);
        ws.close();
        ws.onclose = () => {
            console.log('连接断开')
            closeOk = true;
            timer && clearTimeout(timer);
            resolve();
        };
    });
}

/**
 * 解析candle
 */
function candleTransform(m) {
    return {
        symbol: m.s,
        startTime: m.t,
        closeTime: m.T,
        firstTradeId: m.f,
        lastTradeId: m.L,
        open: m.o,
        high: m.h,
        low: m.l,
        close: m.c,
        volume: m.v,
        trades: m.n,
        interval: m.i,
        isFinal: m.x,
        quoteVolume: m.q,
        buyVolume: m.V,
        quoteBuyVolume: m.Q
    };
};