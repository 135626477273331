<template>
  <div v-if="sg">
    <div class="token-con">
      <el-avatar :size="44" src="https://empty">
        <img :src="icon"/>
      </el-avatar>
      <div class="token-prief">
        <div class="name">{{sg.symbol}}</div>
        <div class="address" v-if="dailyStats">{{$t('price')}}: {{dailyStats.lastPrice}}</div>
      </div>
      <el-tooltip effect="light" content="收藏" placement="left">
        <i class="el-icon-star-off fs-24"></i>
      </el-tooltip>
    </div>

    <el-row :gutter="20" class="statistic-token mg-10" v-if="dailyStats">
      <el-col :span="6">
        <el-statistic :title="$t('volume')">
          <template slot="formatter">
            {{$com.formatNumber(dailyStats.volume)}}
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="6">
        <el-statistic :title="$t('change')">
          <template slot="formatter">
            {{dailyStats.priceChangePercent}}%
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="6">
        <el-statistic :title="$t('high')">
          <template slot="formatter">
            {{$com.formatDecimal(dailyStats.highPrice)}}
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="6">
        <el-statistic :title="$t('low')">
          <template slot="formatter">
            {{$com.formatDecimal(dailyStats.lowPrice)}}
          </template>
        </el-statistic>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import tradeLibs from "@/libs/trade";

const exchangeIcon = {
  'binance': require('@/assets/imgs/exchange/binance.png')
};
export default {
  data(){
    return {
      sg: null,
      icon: null,
      dailyStats: null
    }
  },
  created() {
    this.$eventBus.$on('CHANGE_SYMBOL', async (sg) => {
      this.sg && this.$eventBus.$off('CANDLE_DATA_' + this.sg.symbol); // 取消监听旧的
      this.sg = sg;
      this.icon = exchangeIcon[sg.exchange];

      const dailyStats = await tradeLibs.dailyStats({ symbol: sg.symbol });
      if(!dailyStats.lastPrice){
        this.$message.error(`(${sg.symbol}) ` + dailyStats.msg);
        // 去除无效的pair
        let pairs = localStorage.getItem('LISTENING_PAIRS');
        pairs = pairs ? pairs.split(',') : [];
        pairs.splice(pairs.indexOf(sg.symbol), 1);
        pairs = pairs.slice(-10);
        localStorage.setItem('LISTENING_PAIRS', pairs.join(','));
        return;
      }

      dailyStats.lastPrice = parseFloat(dailyStats.lastPrice);
      this.dailyStats = dailyStats;

      this.$eventBus.$emit('SYMBOL_DAILY_STATS', dailyStats);

      this.listenCandle();
    });
  },
  methods: {
    listenCandle(){
      this.$eventBus.$on('CANDLE_DATA_' + this.sg.symbol, (candle) => {
        this.$set(this.dailyStats, 'lastPrice', candle.close);
      });
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/dark.less";

.token-con {
  display: flex;
  align-items: center;
  margin: 16px;

  .token-prief {
    flex: 1;
    text-align: left;
    margin-left: 15px;

    .name{
      font-size: 14px;
      font-weight: bold;
    }
    .address{
      font-size: 13px;
      margin-top: 10px;
    }
  }
}

.statistic-token {
  background-color: @content-bg-color;
  margin: 16px !important;
  padding: 16px 0;
  border-radius: 16px;

  /deep/.el-statistic {
    .head {
      font-size: 12px;
      color: @font-color;
    }
    .number{
      font-size: 13px !important;
      color: @sub-font-color;
      margin-top: 6px;
    }
  }
}

.safe-tags {
  display: flex;
  justify-content: space-between;
}

.statistic-volume {
  margin: 10px 0;

  .row {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .label {
      display: flex;
      align-items: center;
      width: 60px;
    }
    /deep/.el-progress {
      flex: 1;
    }
  }
}
</style>