import request from '@/utils/request.js';
import * as func from '@/utils/func.js';

const prefix = 'https://www.geniidata.com/api/';

export async function checkAuth(){
    const options = { action: 'Geniidata-Check', url: 'check', target: 'fireBot' };

    return request.get(options);
}

export async function getEventInfo(slug){
    const params = {
        thirdId: slug
    };
    const options = { url: prefix + 'trade/event', params, target: 'fireBot' };

    return request.get(options).then(res => { res.data.code = res.data.code === 0 ? 200 : res.data.code; return res.data; });
}

export async function sendOrder(eventId, feeRate){
    const params = {
        eventId,
        feeRate,
        token: 'ca967b4762634cdd39d79c0473b73748ed0ae288'
    };
    const options = { url: prefix + 'trade/v4/order', params, target: 'fireBot', cookie: 'geniidata.com', token: '54319BBE832A3AC3A2AB19DD6103D641' };

    return request.post(options).then(res => { res.data.code = res.data.code === 0 ? 200 : res.data.code; return res.data; });
}
