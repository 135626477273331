<template>
  <div class="transfer-con">
    <div id="transfer-chart" class="chart-con" :style="{width: chartWidth}"></div>
  </div>
</template>

<script>
import * as predictApi from "@/api/predict";
import * as func from "@/utils/func";
import * as echarts from 'echarts';

const option = {
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      const date = params[0].axisValue;
      const inValue = params[0].data.in;
      const outValue = params[1].data.out;
      const percentIn = params[0].data.percent;
      const percentOut = params[1].data.percent;

      return `Date: ${date}<br>Withdrawals: ${outValue}<br>Deposit: ${inValue}<br>PercentIn: ${percentIn}<br>PercentOut: ${percentOut}`;
    },
  },
  grid: {
    left: 100,
    right: 100
  },
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: [{
    type: 'value',
    name: 'Amount',
  }, {
    type: 'value',
    name: 'Change',
    min: -5,
    max: 5
  }],
  barWidth: 3,
  dataZoom: [
    {
      type: 'slider', // 使用滑块型数据区域缩放组件
      xAxisIndex: [0], // 关联X轴索引
      startValue: 0, // 设置滚动窗口的起始值
      minValueSpan: 20
    }
  ]
};
let myBarChart = null;
const minVisibleCount = 500;

export default {
  props: {
    symbolInfo: {
      type: Object
    },
    news: {
      type: Array
    }
  },
  data(){
    return {
      symbol: null,
      contractAddress: {},
      chartWidth: '100%',
      viewAddress: null,
      chartTransferTerm: null,
      deadline: null,
      dataSource: [],
      hourDataSource: [],
      hourDataStatistic: {},
      outGroupHourly: [],
      countSource: []
    }
  },
  async mounted() {
    this.symbol = this.symbolInfo.baseInfo.symbol;
    this.contractAddress = this.symbolInfo.baseInfo.brief ? this.symbolInfo.baseInfo.brief.contractAddress : null;

    if(this.contractAddress && this.contractAddress['Ethereum']){
      const startTime = this.getStartTime();
      await this.getHourData(this.contractAddress['Ethereum'], startTime);
      this.onChangeTransferChart('ALL', this.viewAddress);
    }
    // 重置截止时间
    this.$eventBus.$on('RESET_INFO_DATA', (deadline) => {
      deadline = parseInt(new Date(deadline).getTime()/1000);
      if(deadline == this.deadline){
        return;
      }
      this.deadline = deadline;
      this.resetTransferChart();
    });
  },
  methods:{
    /**
     * 获取数据开始时间
     * @returns {number}
     */
    getStartTime() {
      let startTime;
      if(this.news && this.news.length > 0){
        startTime = this.news[0].timestamp - 30*24*3600;
      } else {
        startTime = new Date().getTime()/1000 - 30*24*3600;
      }
      return startTime;
    },
    resetTransferChart(){
      this.parseHourData();
      this.onChangeTransferChart('ALL', this.viewAddress);
    },
    /**
     * 获取按小时分组的数据
     * @param address
     * @param startTime
     * @returns {Promise<void>}
     */
    async getHourData(address, startTime){
      const res = await predictApi.hourData({ address, startTime });
      if(res && res.data && res.data.length > 0){
        this.hourDataSource = res.data;
        this.parseHourData();
      }
    },
    parseHourData(){
      const startTime = this.hourDataSource[0].hourTime;
      const endTime = this.hourDataSource[this.hourDataSource.length - 1].hourTime;
      const obj = {};
      for(let i in this.hourDataSource){
        obj[this.hourDataSource[i].hourTime] = this.hourDataSource[i];
      }
      const now = new Date().getTime()/1000;
      const weekStartTime = now - 7*24*3600, dayStartTime = now -24*3600, hourStartTime = now -3600;
      const lists = [], weekStatistic = {}, dayStatistic = {}, hourStatistic = {};
      for(let t = startTime; t <= endTime; t += 3600){
        let row = obj[t];
        if(!row){
          row = {
            "out": 0,
            "in": 0,
            "inner": 0,
            "outCount": 0,
            "inCount": 0,
            "innerCount": 0,
            "timestamp": t,
            "hourTime": t
          }
        }
        lists.push(row);
        // 统计
        if(row.hourTime >= weekStartTime){
          !weekStatistic.totalAmount && (weekStatistic.totalAmount = 0);
          !weekStatistic.maxAmount && (weekStatistic.maxAmount = 0);
          weekStatistic.totalAmount += parseInt(parseFloat(row.out) + parseFloat(row.in));
          if(row.out - weekStatistic.maxAmount > 0){
            weekStatistic.maxAmount = parseInt(row.out);
          }
        }
        if(row.hourTime >= dayStartTime){
          !dayStatistic.totalAmount && (dayStatistic.totalAmount = 0);
          !dayStatistic.maxAmount && (dayStatistic.maxAmount = 0);
          dayStatistic.totalAmount += parseInt(parseFloat(row.out) + parseFloat(row.in));
          if(row.out - dayStatistic.maxAmount > 0){
            dayStatistic.maxAmount = parseInt(row.out);
          }
        }
        if(row.hourTime >= hourStartTime){
          !hourStatistic.totalAmount && (hourStatistic.totalAmount = 0);
          !hourStatistic.maxAmount && (hourStatistic.maxAmount = 0);
          hourStatistic.totalAmount += parseInt(parseFloat(row.out) + parseFloat(row.in));
          if(row.out - hourStatistic.maxAmount > 0){
            hourStatistic.maxAmount = parseInt(row.out);
          }
        }
        if(this.deadline && row.hourTime - this.deadline >= 0){
          break;
        }
      }
      this.hourDataSource = lists;
      this.hourDataStatistic = {
        weekStatistic,
        dayStatistic,
        hourStatistic
      };
      this.$eventBus.$emit('HOUR_DATA_STATISTIC', this.hourDataStatistic);
      this.$eventBus.$emit('HOUR_DATA', this.hourDataSource);
    },
    /**
     * token transfers
     * @returns {Promise<void>}
     */
    async getTransfers(address, startTime){
      const res = await predictApi.analysis({ address, startTime });
      if(res && res.data){
        if(res.data.hot){
          const hots = [];
          for(let address in res.data.hot){
            hots.push({
              address,
              count: res.data.hot[address]
            });
          }
          this.hot = hots;
        }
        res.data.original_data.reverse();
        this.dataSource = res.data.original_data;
        this.outGroupHourly = res.data.out_group_hourly;
        this.countSource = res.data.count_lists.filter(r => r.is_mark == 1);
      }
    },
    /**
     * change term
     * @param term
     */
    onChangeTransferChart(term, address, forceUpdate){
      /*address = address || null;
      if(!forceUpdate && this.chartTransferTerm == term && this.viewAddress == address){
        return;
      }
      if(this.dataSource.length == 0){
        return;
      }
      const endTime = this.dataSource[this.dataSource.length - 1].timestamp;
      term && (this.chartTransferTerm = term);
      this.viewAddress = address;
      const termsTime = {
        '24H': 24*3600,
        '3D': 3*24*3600,
        '7D': 7*24*3600,
        'MORE': null,
        'ALL': null
      }

      const step = term == 'ALL' ? 3600 : 3600;
      const groupData = this.groupByHour(this.dataSource, termsTime[this.chartTransferTerm], address, step, endTime);
      if(term == 'ALL'){
        option.barWidth = 2;
        // this.chartWidth = (groupData.length * 6) + 'px';
        option.xAxis.scale = true;
      } else {
        option.barWidth = 5;
        this.chartWidth = '100%';
        option.xAxis.scale = false;
      }*/

      this.drawBarChart('transfer-chart', this.hourDataSource, 'timestamp');
    },
    /**
     * 绘制柱状图
     * @param dataLisst
     */
    drawBarChart(domId, dataLists, xKey, type, noMark){
      const circulatingSupply = this.symbolInfo.baseInfo.circulatingSupply;

      noMark = noMark ? noMark : false;
      type = type ? type : 'bar';
      let xAxisData = [], seriesInData = [], seriesOutData = [], seriesRateData = [];
      let yMin = null, yMax = null;
      for(let i in dataLists){
        const item = dataLists[i];
        noMark && (item.is_mark = 0)
        const dateKey = func.getDate(item[xKey]);
        xAxisData.push(dateKey);

        // 取最大最小值
        !yMin && (yMin = item['out'] * -1);
        item['out'] * -1 < yMin && (yMin = item['out'] * -1);
        !yMax && (yMax = item['in']);
        item['in'] - yMax > 0 && (yMax = item['in']);

        seriesInData.push({
          value: parseInt(item['in']),
          percent: parseFloat(item['in']/circulatingSupply*100).toFixed(4),
          in: parseInt(item['in']),
          out: parseInt(item['out']),
          timestamp: item.timestamp,
          itemStyle: {
            color: item.is_mark == 1 ? '#409EFF' : '#67C23A'
          }
        });
        seriesOutData.push({
          value: parseInt(item['out']) * -1,
          percent: parseFloat(item['out']/circulatingSupply*100).toFixed(4),
          in: parseInt(item['in']),
          out: parseInt(item['out']),
          timestamp: item.timestamp,
          itemStyle: {
            color: item.is_mark == 1 ? '#409EFF' : '#F56C6C'
          }
        });
      }

      const dom = document.getElementById(domId);
      myBarChart = !myBarChart ? echarts.init(dom) : myBarChart;
      option.xAxis.data = xAxisData;
      option.yAxis[0].min = yMin;
      option.yAxis[0].max = yMax;
      option.series = [{
        name: 'Deposit',
        type,
        data: seriesInData
      }, {
        name: 'Withdrawals',
        type,
        data: seriesOutData
      }];
      option.dataZoom[0].startValue = xAxisData.length - minVisibleCount;

      myBarChart.setOption(option);
    },
    /**
     * groupByHour
     * @param dataLists
     * @returns {{}}
     */
    groupByHour(dataLists, term, address, step, endTime){
      if(dataLists.length == 0){
        return [];
      }
      let groupData = {};
      const now = new Date().getTime()/1000;
      const startTime = term ? now - term : dataLists[0].timestamp;
      for(let i in dataLists){
        const item = dataLists[i];
        if(item.timestamp - startTime < 0){
          break;
        }
        if(address && item.from != address && item.recipient != address){
          continue;
        }
        if(['in', 'out'].indexOf(item.side) == -1){
          continue;
        }
        const hourTime = item.timestamp - item.timestamp % step;
        const hourKey = func.getDate(hourTime);
        if(!groupData[hourKey]){
          groupData[hourKey] = {
            out: 0,
            in: 0,
            timestamp: hourTime
          };
        }
        groupData[hourKey][item.side] += item.sendAmount;
      }
      for(let time = startTime; time < (endTime ? endTime : now); time += step){
        const hourTime = time - time % step;
        const hourKey = func.getDate(hourTime);
        if(!groupData[hourKey]){
          groupData[hourKey] = {
            out: 0,
            in: 0,
            timestamp: hourTime
          };
        }
      }
      groupData = func.sortObject(groupData, 'timestamp').reverse();

      return groupData;
    },
  }
}
</script>

<style lang="less" scoped>
.transfer-con {
  margin-top: 15px;
  width: 100%;
  height: 420px;
  position: relative;

  &.enable-scroll{
    overflow-x: scroll;
  }
  .chart-con{
    height: 400px;
    width: 100%;
  }
  .right-btns {
    position: absolute;
    right: 100px;
    top: 20px;
    z-index: 1;
  }
}
</style>