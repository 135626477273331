<template>
  <div class="section-container">
    <div class="section-title">内存池中的UTXOS</div>
    <div class="data-lists">
      <!-- 内存池中的utxos -->
      <div class="utxo-item" v-for="(item, idx) in memUtxos" :key="idx">
        <div class="cover">
            <img src="../../assets/imgs/BTC.png" />
        </div>
        <div class="brief">
          <div class="amount">
            <div>
              <a :href="'https://mempool.space/tx/' + item.txid + '#vout=' + item.vout" target="_blank">
                {{item.value/Math.pow(10, 8)}}
              </a>
              <span class="pdl-3">BTC</span>
            </div>
            <div class="flex">
              <div class="value flex-1">{{item.value}} sats</div>
              <i class="text-success">接收</i>
            </div>
          </div>
          <div class="dolor">
            <div class="flex-1">≈ ${{ btcPrice*item.value/Math.pow(10, 8)}}</div>
          </div>
        </div>
      </div>
      <!-- 内存池中正在花费的utxo -->
      <div class="utxo-item" :class="{on:!!handleTransferUtxos[item.key]}" v-for="(item, idx) in memVinUtxos" :key="'_' + idx">
        <div class="cover">
            <iframe :src="'https://ord.ordinalswallet.com/content/' + item.id" frameborder="none" scrolling="no"></iframe>
        </div>
        <div class="brief">
          <div class="amount">
            <div>
              <a :href="'https://mempool.space/tx/' + item.txid + '#vout=' + item.vout" target="_blank">
                {{item.value/Math.pow(10, 8)}}
              </a>
              <span class="pdl-3">BTC</span>
            </div>
            <div class="flex">
              <div class="value flex-1">{{item.value}} sats</div>
              <i class="text-red">花费</i>
            </div>
          </div>
          <div class="dolor">
            <div class="flex-1">≈ ${{ btcPrice*item.value/Math.pow(10, 8)}}</div>
            <i class="selected" :class="{'el-icon-check': !!handleTransferUtxos[item.key]}" @click="selectedUtxo(item)"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="section-title">当前可用的UTXOS</div>
    <div class="data-lists">
      <!-- 出块的普通utxo -->
      <div class="utxo-item" :class="{on:!!handleTransferUtxos[item.key]}" v-for="(item, idx) in confirmedUtxos" :key="idx">
        <div class="cover">
          <img src="../../assets/imgs/BTC.png" />
        </div>
        <div class="brief">
          <div class="amount">
            <div>
              <a :href="'https://mempool.space/tx/' + item.txid + '#vout=' + item.vout" target="_blank">{{item.value/Math.pow(10, 8)}}</a>
              <span class="pdl-3">BTC</span>
            </div>
            <div><span>{{item.value}} sats</span></div>
          </div>
          <div class="dolor">
            <div class="flex-1">≈ ${{ btcPrice*item.value/Math.pow(10, 8)}}</div>
            <i class="selected" :class="{'el-icon-check': !!handleTransferUtxos[item.key]}" @click="selectedUtxo(item)"></i>
          </div>
        </div>
      </div>
      <!-- 出块的的带铭文utxo -->
      <div class="utxo-item" :class="{on:!!handleTransferUtxos[item.key]}" v-for="(item, idx) in inscriptionsUtxos" :key="'_' + idx">
        <div class="cover">
          <iframe :src="'https://ord.ordinalswallet.com/content/' + item.id" frameborder="none" scrolling="no" v-if="item.content_type.indexOf('text') > -1"></iframe>
          <img :src="'https://ord.ordinalswallet.com/content/' + item.id" v-else/>
        </div>
        <div class="brief">
          <div class="amount">
            <div>Inscription #{{item.num}}</div>
            <div><span>{{item.value}} sats</span></div>
          </div>
          <div class="dolor">
            <div class="flex-1">≈ ${{ btcPrice*item.value/Math.pow(10, 8)}}</div>
            <i class="selected" :class="{'el-icon-check': !!handleTransferUtxos[item.key]}" @click="selectedUtxo(item)"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="handle-bar">
      <div class="remark">已选中 {{handleUtxosStat.count}} 个UTXO，总价值 {{handleUtxosStat.amount}} sats，包含 {{handleUtxosStat.insCount}} 个铭文/NFT</div>
      <el-button size="small" type="warning" @click="showTransferUtxos">转移</el-button>
      <el-button size="small" type="warning">拆分</el-button>
      <el-button size="small" type="warning">合并</el-button>
    </div>

    <!-- transfer dialog -->
    <el-dialog
        title="转移Utxos:"
        class="handle-dialog"
        :visible.sync="showTransferUtxosDialog"
    >
      <div>
        <el-input v-model="transferForm.toAddress" size="small">
          <template slot="prepend">目标地址</template>
        </el-input>
        <div class="gas-fees">
          <span class="label">GAS</span>
          <el-tag
              size="mini"
              effect="plain"
              type="warning"
              v-for="(item, key) in gasFees"
              :key="key"
              :class="{active: transferForm.feeType === key && !transferForm.feeRate}"
              @click="transferForm.feeType = key"
              v-if="key !== 'economyFee'"
          >
            {{$t('feeRate.' + key)}}: {{item}} sat/vB
          </el-tag>
          <el-input v-model="transferForm.feeRate" size="small" class="w-200px fee-rate" placeholder="自定义费率">
            <template slot="append">sat/vB</template>
          </el-input>
        </div>

        <div class="fs-13 mgt-16" v-if="psbtInfo">请核对交易详情</div>
        <div class="tx-info-con mgt-10" v-if="psbtInfo">
          <div class="inputs">
            <div class="section-name">Inputs</div>
            <div class="row" v-for="(row, idx) in psbtInfo.inputInfos" :key="idx">
              <div class="flex">
                <div class="flex-1">{{$com.formatString(row.address, 6, 6)}}</div>
                <div class="value">{{parseFloat(row.value/Math.pow(10, 8)).toFixed(8)}}</div>
              </div>
              <div class="contents">
                <!-- nft -->
                <a
                    :href="'https://magiceden.io/ordinals/item-details/' + r.inscriptionId"
                    target="_blank" class="tag" v-for="(r, i) in row.inscriptions"
                    :key="i"
                    v-if="inscriptions[r.inscriptionId]"
                >
                  <img :src="inscriptions[r.inscriptionId].image" class="cover" v-if="inscriptions[r.inscriptionId].contentType.indexOf('image') > -1 && inscriptions[r.inscriptionId].image" />
                  <iframe :src="inscriptions[r.inscriptionId].preview" scrolling="no" class="cover" v-else></iframe>
                  <div>
                    <div>{{inscriptions[r.inscriptionId].name}} #{{r.inscriptionNumber}}</div>
                    <div class="text-red">Sell {{inscriptions[r.inscriptionId].price/Math.pow(10, 8)}}</div>
                  </div>
                </a>
                <!-- runes -->
                <a target="_blank" class="tag" v-for="(r, i) in row.runes" :key="i">
                  <img :src="rune.imageURI" class="cover" v-if="rune && rune.rune === r.rune" />
                  <div>
                    <div>{{r.spacedRune}}</div>
                    <div>{{r.amount/Math.pow(10, r.divisibility)}}</div>
                    <div class="text-red" v-if="rune && row.address === rune.maker && r.amount/Math.pow(10, r.divisibility) - rune.formattedAmount === 0">
                      Sell {{rune.formattedUnitPrice}} sats/{{r.symbol}}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="outputs">
            <div class="section-name">Outputs</div>
            <div class="row" v-for="(row, idx) in psbtInfo.outputInfos" :key="idx">
              <div class="flex">
                <div class="flex-1" v-if="row.address">{{$com.formatString(row.address, 6, 6)}}</div>
                <div class="flex-1" v-else-if="row.scriptpubkey_type === 'op_return'">OP_RETURN</div>
                <div class="col" v-if="addressTags[row.address]">({{addressTags[row.address]}})</div>
                <div class="col" v-if="rune && row.address === rune.makerReceiveAddress">(卖家)</div>
                <div class="col" v-if="(row.runes && row.runes.length > 0) || (row.inscriptions && row.inscriptions.length > 0)">(买家)</div>
                <div class="value">{{parseFloat(row.value/Math.pow(10, 8)).toFixed(8)}}</div>
              </div>
              <div class="contents">
                <!-- nft -->
                <a
                    :href="'https://magiceden.io/ordinals/item-details/' + r.inscriptionId"
                    target="_blank" class="tag" v-for="(r, i) in row.inscriptions"
                    :key="i"
                    v-if="inscriptions[r.inscriptionId]"
                >
                  <img :src="inscriptions[r.inscriptionId].image" class="cover" v-if="inscriptions[r.inscriptionId].contentType.indexOf('image') > -1 && inscriptions[r.inscriptionId].image" />
                  <iframe :src="inscriptions[r.inscriptionId].preview" scrolling="no" class="cover" v-else></iframe>
                  <div>
                    <div>{{inscriptions[r.inscriptionId].name}} #{{r.inscriptionNumber}}</div>
                    <div class="text-green">Receive</div>
                  </div>
                </a>
                <!-- runes -->
                <a target="_blank" class="tag" v-for="(r, i) in row.runes" :key="i">
                  <img :src="rune.imageURI" class="cover" v-if="rune && rune.rune === r.rune" />
                  <div>
                    <div>{{r.symbol}} {{r.spacedRune}}</div>
                    <div>{{r.amount/Math.pow(10, r.divisibility)}}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="flex mgt-16">
          <div class="flex-1">
            <span v-if="excuteFeeRate">FeeRate: {{excuteFeeRate}} sats/vB</span>
            <span class="pdl-20" v-if="transferForm.fee">Fee: {{transferForm.fee}} sats</span>
          </div>
          <el-button type="success" size="small" @click="executeTransfer" v-if="psbtInfo">核对无误并提交</el-button>
          <el-button type="success" size="small" @click="checkTransferUtxos" v-else>预览</el-button>
          <el-button size="small" @click="showTransferUtxosDialog = false">取消</el-button>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as bitcoin from "bitcoinjs-lib";
import * as MempoolApi from "@/api/mempool";
import * as MagicApi from "@/api/magic";
import * as MempoolUtil from "@/utils/mempool";
import {getAllUtxos, parseUtxoInfoByPosition} from "@/api/mempool";

let ws = null;

export default {
  data(){
    return {
      currentAccount: window.currentAccount || null,
      currentPubkey: window.currentPubkey,
      btcPrice: 0,
      // 地址标记
      addressTags: {
        'bc1qcq2uv5nk6hec6kvag3wyevp6574qmsm9scjxc2': '手续费',
        '3P4WqXDbSLRhzo2H6MT6YFbvBKBDPLbVtQ': '手续费',
        'bc1pew6s05q67m9vryme0yjnh6xhafw2sldjq35zynxjer405lruz2gsnhapew': '手续费',
        '3Ke21osfhEbEryUeqdwAuAY8VKxm5B9uB2': '手续费'
      },
      confirmedUtxos: {},
      memUtxos: {},
      memVinUtxos: [],

      inscriptionsUtxos: [],

      selectedUtxos: [],
      utxosPrevout: {},

      // 准备转移的utxos
      showTransferUtxosDialog: false,
      handleTransferUtxos: {},
      handleUtxosStat: {
        count: 0,
        amount: 0,
        insCount: 0
      },
      gasFees: {},
      transferForm: {
        toAddress: window.currentAccount || null,
        feeRate: null,
        feeType: 'halfHourFee',
        fee: null,
        vSize: null
      },
      // 预览交易
      psbtInfo: null,
      inscriptions: {},
      rune: null,
      excuteFeeRate: null,
      // 准备执行的psbt
      transferPsbt: null,
    }
  },
  mounted() {
    this.$eventBus.$on('ACCOUNT_CONNECTED', (account) => {
      this.currentAccount = account.address;
      this.currentPubkey = account.pubkey;
      this.getBtcPrice();
      this.loadUtxos();
      this.transferForm.toAddress = this.currentAccount;
    });

    this.getBtcPrice();
    this.loadUtxos();

    if(ws === null){
      // 启动监听
      this.connectMemWebSocket();
    }
  },
  destroyed() {
    ws && ws.close();
  },
  methods: {
    /**
     * websocket
     */
    connectMemWebSocket() {
      ws = new WebSocket('wss://mempool.space/api/v1/ws');
      ws.onopen = function () {
        ws.send('{"action":"init"}');
        ws.send('{"action":"want","data":["blocks","stats","mempool-blocks"]}');
      };
      ws.onclose = function (event) {
        setTimeout(this.connectWebSocket, 3000); // 3秒后重新连接
      };
      ws.onerror = function (error) {
        setTimeout(this.connectWebSocket, 3000); // 3秒后重新连接
      };
      const self = this;
      ws.onmessage = function (event) {
        const data = JSON.parse(event.data);
        data.fees && self.$set(self, 'gasFees', data.fees);
      };
    },
    /**
     * 解析output
     * @param hex
     * @returns {string}
     */
    decodeOutput(hex) {
      // 检查输入长度是否为偶数
      if (hex.length % 2 !== 0) {
        throw new Error('Invalid hex string');
      }
      // 逐字节反转
      let reverseHex = '';
      for (let i = 0; i < hex.length; i += 2) {
        reverseHex = hex.substring(i, i + 2) + reverseHex;
      }

      const txid = reverseHex.slice(8);
      const vout = parseInt(reverseHex.slice(0, 8), 16);

      return { txid, vout };
    },
    /**
     * 加载我的utxos
     * @returns {Promise<void>}
     */
    async loadUtxos(){
      if(!this.currentAccount){
        return;
      }

      // 我的全部utxos
      const confirmUtxos = {}; // 已确认的
      const memReceiveUtxos = {}; // 内存池正在接收的
      const resUtxos = await MempoolApi.getAllUtxos(this.currentAccount);
      for(let i in resUtxos.data){
        const utxo = resUtxos.data[i];
        utxo.key = utxo.txid + ':' + utxo.vout;
        utxo.utxoType = 'btc';
        if(utxo.status.confirmed) {
          confirmUtxos[utxo.key] = utxo;
        } else {
          memReceiveUtxos[utxo.key] = utxo;
        }
      }
      this.memUtxos = memReceiveUtxos;
      this.confirmedUtxos = confirmUtxos;

      // 通过正在pending的交易解析正在花费的utxos
      MempoolApi.getPendingTxsByAddress(this.currentAccount).then(async txs => {
        // 解析出自己正在花费的utxos
        const vinUtxos = [];
        for(let i in txs) {
          for(let j in txs[i].vin){
            const item = txs[i].vin[j];
            if(item.prevout.scriptpubkey_address === this.currentAccount) {
              const info = await MempoolApi.parseUtxoInfoByPosition(item.txid + ':' + item.vout);
              if(info){
                item.id = info.inscriptionId;
                item.value = item.prevout.value;
                item.utxoType = 'inscription';
                vinUtxos.push(Object.assign({}, item, info));
              } else {
                item.value = item.prevout.value;
                item.utxoType = 'btc';
                item.key = item.txid + ':' + item.vout;
                vinUtxos.push(item);
              }
            }
          }
        }
        // 内存池中正在花费的utxos
        this.memVinUtxos = vinUtxos;
      });

      /** 检测utxo是不是带有铭文 **/
      // 铭文utxos
      const resIns = await MempoolApi.getInscriptionsUtxos(this.currentAccount);
      console.log(resIns)
      if(resIns && resIns.data && resIns.data.inscriptions){
        this.inscriptionsUtxos = resIns.data.inscriptions.map(row => {
          const { txid, vout } = this.decodeOutput(row.outpoint.outpoint);
          const key = txid + ':' + vout;
          this.$set(this.confirmedUtxos[key], 'utxoType', 'inscription');
          this.$set(this.confirmedUtxos[key], 'id', row.id);
          this.$set(this.memUtxos[key], 'utxoType', 'inscription');
          this.$set(this.memUtxos[key], 'id', row.id);
          return row;
        });
      }

      // 遍历解析
      /*const allUtxos = Object.assign({}, this.confirmedUtxos, this.memUtxos);
      const allUtxosArr = this.$func.sortObject(allUtxos, 'value', 'asc');
      for(let i in allUtxosArr){
        const utxo = allUtxosArr[i];
        const info = await MempoolApi.parseUtxoInfoByPosition(utxo.txid + ':' + utxo.vout);
        if(info){
          console.log('------')
          console.log(utxo.value)
          console.log(utxo.status.confirmed)
          if(utxo.status.confirmed){
            this.$set(this.confirmedUtxos[utxo.key], 'id', info.inscriptionId);
            this.$set(this.confirmedUtxos[utxo.key], 'utxoType', 'inscription');
          } else {
            this.$set(this.memUtxos[utxo.key], 'id', info.inscriptionId);
            this.$set(this.memUtxos[utxo.key], 'utxoType', 'inscription');
          }
        }
      }*/

      // 可用的普通utxos
      const res = await MempoolApi.getAvailableUtxos(this.currentAccount);
      if(res && res.data && res.data.utxos){
        // 已确认的
        /*this.utxos = res.data.utxos.map(row => {
          row.utxoType = 'btc';
          row.key = row.txid + ':' + row.vout;
          return row;
        });*/
        // 内存池中正在接收的utxos
        /*this.memUtxos = res.data.mempool.map(row => {
          row.utxoType = 'btc';
          row.key = row.txid + ':' + row.vout;
          return row;
        });*/
      }


    },
    /**
     * 勾选utxo
     */
    selectedUtxo(utxo){
      if(!utxo.prevout){
        MempoolApi.getTxInfo(utxo.txid, false).then(txInfo => {
          this.utxosPrevout[utxo.key] = txInfo.vout[utxo.vout];
        });
      } else {
        this.utxosPrevout[utxo.key] = utxo.prevout;
      }
      if(this.handleTransferUtxos[utxo.key]){
        this.$set(this.handleTransferUtxos, utxo.key, null);
        delete this.handleTransferUtxos[utxo.key];
      } else {
        this.$set(this.handleTransferUtxos, utxo.key, utxo);
      }
      let count = 0, insCount = 0, amount = 0;
      for(let key in this.handleTransferUtxos){
        const utxo = this.handleTransferUtxos[key];
        count++;
        amount += utxo.value;
        if(utxo.utxoType === 'inscription'){
          insCount++;
        }
      }
      this.$set(this, 'handleUtxosStat', {
        count,
        insCount,
        amount
      });
    },
    /**
     * 获取当前btc价格
     * @returns {Promise<void>}
     */
    async getBtcPrice(){
      const res = await MempoolApi.getPrices();
      if(res && res.data){
        this.btcPrice = res.data.USD;
      }
    },
    /**
     * 计算feeRate
     * @returns {Promise<null>}
     */
    async cauFeeRate(){
      let feeRate = this.gasFees[this.transferForm.feeType];
      let flag = true;
      if(this.transferForm.feeRate){
        const maxFeeRate = this.gasFees.fastestFee;
        if((maxFeeRate > 0 && this.transferForm.feeRate/maxFeeRate > 2) || (maxFeeRate === 0 && this.transferForm.feeRate > 200)){
          flag = false;
          const confirm = await this.$confirm('自定义费率偏高，是否继续发送？', '注意', {
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => {});
          if(confirm === 'confirm'){
            flag = true;
          }
        }
        feeRate = this.transferForm.feeRate;
      }
      return feeRate;
    },
    /**
     * 显示转移utxo窗口
     */
    showTransferUtxos(){
      this.showTransferUtxosDialog = true;
    },
    /**
     * 校验tx信息
     * @returns {Promise<void>}
     */
    async checkTransferUtxos(){
      if(!this.transferForm.toAddress){
        this.$message.error('请填写转移的目标地址');
        return;
      }
      if(!Object.keys(this.handleTransferUtxos).length === 0){
        this.$message.error('请至少选择一个要转移的utxo');
        return;
      }

      // 创建psbt
      let psbt = new bitcoin.Psbt({ network: bitcoin.networks.bitcoin });
      // 添加输入
      const tapInternalKey = Buffer.from(this.currentPubkey.slice(2), 'hex');
      let inputUtxoKeys = [], inputBtcUtxoValue = 0;
      for(let i in this.handleTransferUtxos){
        const utxo = this.handleTransferUtxos[i];
        const prevout = utxo.prevout ? utxo.prevout : this.utxosPrevout[utxo.key];
        psbt.addInput({
          hash: utxo.txid,
          index: utxo.vout,
          witnessUtxo: {
            script: Buffer.from(prevout.scriptpubkey, 'hex'),
            value: prevout.value,
          },
          tapInternalKey,
          sequence: 0xffffffff
        });
        inputUtxoKeys.push(utxo.key);
        // 如果不是普通utxo，直接添加输出
        if(utxo.utxoType !== 'btc'){
          psbt.addOutput({
            address: this.transferForm.toAddress,
            value: prevout.value,
          });
        } else {
          // 记录全部普通btc输入总价值
          inputBtcUtxoValue += prevout.value;
        }
      }
      // 剩余可用的普通utxos
      const otherUtxos = [];
      for(let j in this.confirmedUtxos){
        const utxo = this.confirmedUtxos[j];
        if(inputUtxoKeys.indexOf(utxo.key) === -1){
          otherUtxos.push(utxo);
        }
      }

      console.log('lllllllllllllllllll')

      const feeRate = await this.cauFeeRate();
      // 预估fee
      let vsize = MempoolUtil.estimateVirtualSize(psbt);
      let vFee = vsize * feeRate;
      if(inputBtcUtxoValue - vFee < 0){
        // 需要补gas UTXO
        vsize += 90;
        vFee = Math.round(vsize * feeRate);
      }

      console.log('inputBtcUtxoValue -> ' + inputBtcUtxoValue);
      console.log('粗略预估gas');
      console.log('vsize ----> ' + vsize);
      console.log('vFee ----> ' + vFee);

      // 拷贝一个用于预估的psbt
      const _vPsbt = bitcoin.Psbt.fromBase64(psbt.toBase64());

      // 用预估的fee构建psbt并解析feeRate
      if(inputBtcUtxoValue - vFee > 0){
        // 如果普通btc输入总值大于fee，多余部分找零
        _vPsbt.addOutput({
          address: this.transferForm.toAddress,
          value: Math.round(inputBtcUtxoValue - vFee)
        });
      } else if(inputBtcUtxoValue - vFee < 0){
        let payUtxos = [], payTotalValue = inputBtcUtxoValue;
        // 普通输入不够支付gas
        const needValue = vFee - inputBtcUtxoValue; // 差下的gas
        console.log('vNeedValue -> ' + needValue)
        // 从剩余的普通utxos中获取用于gas支付的部分
        payUtxos = MempoolUtil.getPayUtxos([...otherUtxos], needValue);
        for(let i in payUtxos){
          const utxo = payUtxos[i];
          if(!this.utxosPrevout[utxo.key]){
            const txInfo = await MempoolApi.getTxInfo(utxo.txid, false);
            this.utxosPrevout[utxo.key] = txInfo.vout[utxo.vout];
          }
        }
        payUtxos.map(utxo => {
          const prevout = utxo.prevout ? utxo.prevout : this.utxosPrevout[utxo.key];
          _vPsbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            witnessUtxo: {
              script: Buffer.from(prevout.scriptpubkey, 'hex'),
              value: prevout.value,
            },
            tapInternalKey,
            sequence: 0xffffffff
          });
          payTotalValue += prevout.value;
        });
        if(payTotalValue - vFee > 0){
          // 找零
          _vPsbt.addOutput({
            address: this.transferForm.toAddress,
            value: Math.round(payTotalValue - vFee)
          });
        }
      }

      // 解析psbt详情
      let psbtRes = await MempoolApi.decodePsbt(_vPsbt.toHex());
      if(!psbtRes || !psbtRes.inputInfos){
        this.$message.error('解析交易失败');
        return;
      }
      let fee = Math.round(psbtRes.vsize * feeRate);

      console.log('精准计算gas');
      console.log('vsize ===> ' + psbtRes.vsize)
      console.log('fee -> ' + fee)

      // 用精准计算的fee重新构建psbt
      if(inputBtcUtxoValue - fee > 0){
        // 如果普通btc输入总值大于fee，多余部分找零
        psbt.addOutput({
          address: this.transferForm.toAddress,
          value: Math.round(inputBtcUtxoValue - fee)
        });
      } else if(inputBtcUtxoValue - fee < 0) {
        let payUtxos = [];
        // 普通输入不够支付gas
        const needValue = fee - inputBtcUtxoValue; // 差下的gas
        console.log('needValue -> ' + needValue)

        // 从剩余的普通utxos中获取用于gas支付的部分
        payUtxos = MempoolUtil.getPayUtxos([...otherUtxos], needValue);
        for(let i in payUtxos){
          const utxo = payUtxos[i];
          if(!this.utxosPrevout[utxo.key]){
            const txInfo = await MempoolApi.getTxInfo(utxo.txid, false);
            this.utxosPrevout[utxo.key] = txInfo.vout[utxo.vout];
          }
        }
        payUtxos.map(utxo => {
          const prevout = utxo.prevout ? utxo.prevout : this.utxosPrevout[utxo.key];
          psbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            witnessUtxo: {
              script: Buffer.from(prevout.scriptpubkey, 'hex'),
              value: prevout.value,
            },
            tapInternalKey,
            sequence: 0xffffffff
          });
          inputUtxoKeys.push(utxo.key);
          inputBtcUtxoValue += prevout.value;
        });

        if(inputBtcUtxoValue - fee > 0){
          // 找零
          psbt.addOutput({
            address: this.transferForm.toAddress,
            value: Math.round(inputBtcUtxoValue - fee)
          });
        }
      }

      psbtRes = await MempoolApi.decodePsbt(psbt.toHex());
      if(!psbtRes || !psbtRes.inputInfos){
        this.$message.error('解析交易失败');
        return;
      }

      fee = Math.round(psbtRes.vsize * feeRate);
      this.$set(this.transferForm, 'vSize', psbtRes.vsize);
      this.$set(this.transferForm, 'fee', fee);


      console.log('最终计算gas');
      console.log('inputBtcUtxoValue -> ' + inputBtcUtxoValue)
      console.log('vsize ===> ' + psbtRes.vsize)
      console.log('fee -> ' + fee)

      this.excuteFeeRate = psbtRes.feeRate;

      console.log(psbtRes)
      if(psbtRes && psbtRes.inputInfos){
        // 解析铭文
        const inscriptions = psbtRes.inscriptions;
        for(let inscriptionId in inscriptions){
          const tokenInfo = await MagicApi.getTokenInfo({ tokenId: inscriptionId });
          if(tokenInfo && tokenInfo.data){
            inscriptions[inscriptionId] = Object.assign({}, inscriptions[inscriptionId], tokenInfo.data)
          }
        }
        this.inscriptions = this.parseInscriptions(inscriptions);
        console.log('ooooooooooooo')
        console.log(this.inscriptions)
      }
      this.psbtInfo = psbtRes;
      this.transferPsbt = psbt;
    },
    /**
     * 解析数据
     * @param inscriptions
     * @returns {*}
     */
    parseInscriptions(inscriptions){
      for(let inscriptionId in inscriptions){
        const item = inscriptions[inscriptionId];
        const row = {
          inscriptionId,
          name: item.collection.name,
          symbol: item.collection.symbol,
          inscriptionNumber: item.inscriptionNumber,
          price: item.listedPrice,
          owner: item.address,
          preview: item.preview,
          image: item.image,
          contentType: item.contentType
        };
        inscriptions[inscriptionId] = row;
      }
      return inscriptions;
    },
    /**
     * 执行交易
     */
    async executeTransfer(){
      if(!this.transferPsbt){
        return;
      }
      const signedHex = await window.unisat.signPsbt(this.transferPsbt.toHex(), {
        autoFinalized: true
      });
      console.log(signedHex);
      const signedPsbt = bitcoin.Psbt.fromHex(signedHex);
      const singedTx = signedPsbt.extractTransaction();

      console.log('singedTxHex')
      console.log(singedTx.toHex())

      const res = await MempoolApi.postTx(singedTx.toHex());
      if(res && res.code === 0){
        console.log('广播交易失败：' + res.msg);
        // new feerate 0.00002971 BTC/kvB <= old feerate 0.00002971 BTC/kvB
        if(res.msg.indexOf('new feerate') > -1){
          let msgArr = res.msg.split('new feerate ');
          msgArr = msgArr[1].split(' BTC/kvB <= old feerate ');
          msgArr[1] = msgArr[1].replace(' BTC/kvB', '');
          msgArr[0] = Math.round(msgArr[0]*Math.pow(10, 8)/10)/100;
          msgArr[1] = Math.round(msgArr[1]*Math.pow(10, 8)/10)/100;

          this.$message.error('发送失败！替换交易的费率应大于上一笔交易的费率 ' + msgArr[1] + ' sats/vB.');
        } else if(res.msg.indexOf('not enough additional fees') > -1) {
          let msgArr = res.msg.split('fees to relay; ');
          msgArr = msgArr[1].split(' < ');
          msgArr[0] = msgArr[0]*Math.pow(10, 8);
          msgArr[1] = msgArr[1]*Math.pow(10, 8);
          const addFeeRate = (msgArr[1] - msgArr[0])/this.transferForm.vSize;
          const feeRate = parseFloat(parseFloat(this.excuteFeeRate + parseFloat(addFeeRate)).toFixed(3));

          this.$message.error('发送失败！广播此交易需要足够的额外费用！费率至少为 ' + feeRate + ' sats/vB.');
        } else if(res.msg.indexOf('less fees than conflicting txs') > -1) {
          // less fees than conflicting txs; 0.00000557 < 0.00004248
          let msgArr = res.msg.split('less fees than conflicting txs; ');
          msgArr = msgArr[1].split(' < ');
          msgArr[0] = msgArr[0]*Math.pow(10, 8);
          msgArr[1] = msgArr[1]*Math.pow(10, 8);
          const addFeeRate = (msgArr[1] - msgArr[0])/this.transferForm.vSize;
          const feeRate = parseFloat(parseFloat(this.excuteFeeRate + parseFloat(addFeeRate)).toFixed(3));

          this.$message.error('发送失败！广播此交易需要足够的额外费用！费率至少为 ' + feeRate + ' sats/vB.');
        } else {
          this.$message.error('发送失败！');
        }
      } else if(res && res.data){
        console.log('广播交易：' + res.data);
        this.$message.success('发送成功！');
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.section-container {
  padding-bottom: 60px;
}
.section-title {
  font-size: 13px;
  margin-top: @mg;
  color: @light-color;
}

.data-lists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.utxo-item {
  width: calc((100% - 60px)/6);
  height: 80px;
  background-color: @content-bg-color;
  border-radius: @mg;
  margin: 10px 5px 0 5px;
  padding: 10px;
  display: flex;
  box-sizing: border-box;

  &.on {
    box-shadow: 0 0 5px @light-color;
  }
  .cover {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
      background-color: @input-bg-color;
    }
  }
  .brief {
    flex: 1;
  }
  .amount {
    font-size: 14px;
    a {
      text-decoration: none;
      color: @font-color;
    }
    span, .value {
      font-size: 12px;
      color: @sub-font-color;
    }
    i {
      font-size: 10px;
    }
  }
  .dolor {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
    /deep/.el-button.el-button--mini {
      font-size: 10px;
      padding: 1px 3px;
    }
  }
  .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 3px;
    border: 1px solid @sub-font-color;
    font-size: 10px;
  }
}

.handle-bar {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: @content-bg-color;
  position: fixed;
  bottom : @mg;
  left: @mg;
  right: @mg;
  border-radius: @mg;
  padding: 0 12px;

  .remark {
    font-size: 13px;
    flex: 1;
  }
}

.handle-dialog {
  /deep/.el-dialog{
    width: 880px;
    .el-input {
      background-color: @input-bg-color;
      padding: 0 10px;
      box-sizing: border-box;
    }
    div{
      color: @font-color;
    }
  }
}
.gas-fees {
  display: flex;
  align-items: center;
  background-color: @input-bg-color;
  border-radius: 6px;
  margin-top: 10px;

  .label{
    font-size: 13px;
    color: @sub-font-color;
    margin-left: 10px;
    padding-right: 6px;
    width: 40px;
  }
  .el-tag--plain {
    background-color: transparent;
    margin-left: 3px;
    border-color: @content-bg-color;
    cursor: pointer;
    color: @sub-font-color;

    &.active {
      color: @light-color;
      border-color: @light-color;
    }
  }
  .fee-rate {
    /deep/.el-input__inner {
      color: @light-color !important;
      padding: 0;
    }
  }
}

.tx-info-con {
  display: flex;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .inputs {
    margin-right: 5px;
  }
  .outputs {
    margin-left: 5px;
  }
  .inputs, .outputs {
    flex: 1;
    .section-name {
      font-size: 13px;
      color: @font-color;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .row {
      line-height: 24px;
      font-size: 13px;
      color: @font-color;
      background-color: @input-bg-color;
      padding: 4px 8px;
      margin-top: 4px;
      border-radius: 5px;
      .col {
        font-size: 12px;
        margin: 0 3px;
        color: @sub-font-color;
      }
      .tag {
        font-size: 12px;
        color: @sub-font-color;
        display: flex;
        align-items: center;
        line-height: 20px;

        .cover {
          width: 60px;
          height: 60px;
          border-radius: 3px;
          border: none;
          overflow: hidden;
          margin-right: 8px;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 1800px) {
  .utxo-item {
    width: calc((100% - 50px) / 5);
  }
}
@media only screen and (max-width: 1600px) {
  .utxo-item {
    width: calc((100% - 40px) / 4);
  }
}
@media only screen and (max-width: 1300px) {
  .utxo-item {
    width: calc((100% - 30px) / 3);
  }
}
@media only screen and (max-width: 1100px) {
  .utxo-item {
    width: calc((100% - 20px) / 2);
  }
}
@media only screen and (max-width: 900px) {
  .utxo-item {
    width: calc((100% - 10px) / 1);
  }
}
</style>