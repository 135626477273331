<template>
  <div class="container">
    <div class="left-side">
      <el-input placeholder="Coin symbol" v-model="filterCoin" size="mini" class="filter-coin-input" @input="onFilterCoins">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <div class="menu-item" :class="{active: menuCate == 'all'}" @click="listsCate('all')">
        <span>全部</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuCate == 'news'}" @click="listsCate('news')">
        <span>新闻活跃</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuCate == 'abnormalWeek'}" @click="listsCate('abnormalWeek')">
        <span>异动活跃(周)</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="menu-item" :class="{active: menuCate == 'abnormalDay'}" @click="listsCate('abnormalDay')">
        <span>异动活跃(24H)</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="data-con">
      <el-table
          :data="statisticData"
          :height="windowHeight - 60"
          class="data-lists"
      >
        <el-table-column
            label="Address"
            width="150">
          <template slot-scope="scope">
            <div class="bold">{{scope.row.symbol}}</div>
            <div @click="$com.copyText(scope.row.brief.ethereumAddress)">{{$com.formatString(scope.row.brief.ethereumAddress, 4, 6)}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="一周异动"
            width="100">
          <template slot-scope="scope">
            <div class="count">{{scope.row.analysis.hourGroupDataAction.week.count}}</div>
            <div class="amount">{{scope.row.analysis.hourGroupDataAction.week.outPercent}}%</div>
          </template>
        </el-table-column>

        <el-table-column
            label="24H异动"
            width="100">
          <template slot-scope="scope">
            <div class="count">{{scope.row.analysis.hourGroupDataAction.day.count}}</div>
            <div class="amount">{{scope.row.analysis.hourGroupDataAction.day.outPercent}}%</div>
          </template>
        </el-table-column>

        <el-table-column
            label="一小时异动"
            width="100">
          <template slot-scope="scope">
            <div class="count">{{scope.row.analysis.hourGroupDataAction.hour.count}}</div>
            <div class="amount">{{scope.row.analysis.hourGroupDataAction.hour.outPercent}}%</div>
          </template>
        </el-table-column>

        <el-table-column
            label="异动更新时间"
            width="150">
          <template slot-scope="scope">
            <div class="count">{{scope.row.analysis.updateTime ? $func.getDate(scope.row.analysis.updateTime) : '-'}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="24H均价"
            width="120">
          <template slot-scope="scope">
            <div class="count">{{scope.row.trend && scope.row.trend.day ? parseFloat(scope.row.trend.day.avePrice) : '-'}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="当前价"
            width="100">
          <template slot-scope="scope">
            <div class="amount">{{parseFloat(scope.row.price)}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="已拉盘"
            width="140">
          <template slot-scope="scope">
            <div v-if="scope.row.trend && scope.row.trend.day">
              <div class="count" :class="{'text-danger': scope.row.trend.day.isChange}">
                {{scope.row.trend.day.isChange ? '是' : '否'}}({{scope.row.trend.day.reliableChange}}%)
              </div>
              <div class="amount" v-if="scope.row.trend.day.zeroChange.deadline">
                {{$func.getDate(scope.row.trend.day.zeroChange.deadline)}}
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>

        <el-table-column
            label="15天新闻"
            width="100">
          <template slot-scope="scope">
            <div class="count" :class="{'text-success': scope.row.news.existNews}">
              {{scope.row.news.existNews ? '有' : '无'}}({{scope.row.news.count}}次)
            </div>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot-scope="scope">
            <router-link :to="'/symbol-info?symbol=' + scope.row.symbol" target="_blank">详情</router-link>
            |
            <router-link :to="'/simulate?symbol=' + scope.row.symbol" target="_blank">回测</router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { statistic } from "@/api/predict";

export default {
  data(){
    return {
      allLists: [],
      statisticData: [],
      windowHeight: window.innerHeight,
      filterCoin: null,
      menuCate: 'all'
    }
  },
  mounted() {
    this.loadStatisticResult();
  },
  methods: {
    async loadStatisticResult(){
      const res = await statistic();
      if(res && res.data){
        const lists = [];
        for(let i in res.data){
          const item = res.data[i];
          if(!item.analysis){
            continue;
          }
          const analysis = item.analysis;
          // percent
          item.analysis.hourGroupDataAction.week.outPercent = analysis.hourGroupDataAction.week ? this.$com.fixed(
              analysis.hourGroupDataAction.week.outAmount/item.circulatingSupply * 100,
              4
          ) : 0;
          item.analysis.hourGroupDataAction.day.outPercent = analysis.hourGroupDataAction.day ? this.$com.fixed(
              analysis.hourGroupDataAction.day.outAmount/item.circulatingSupply * 100,
              4
          ) : 0;
          item.analysis.hourGroupDataAction.hour.outPercent = analysis.hourGroupDataAction.hour ? this.$com.fixed(
              analysis.hourGroupDataAction.hour.outAmount/item.circulatingSupply * 100,
              4
          ) : 0;

          lists.push(item);
        }
        this.allLists = lists;
        this.statisticData = lists;
      }
    },
    /**
     * 过滤
     * @param coin
     */
    onFilterCoins(coin){
      if(coin && coin != ''){
        coin = coin.toUpperCase();
        let lists = this.allLists;
        lists = lists.filter(r => r.symbol == coin);
        this.statisticData = lists;
      } else {
        this.statisticData = this.allLists;
      }
    },
    /**
     * 筛选
     * @param cate
     */
    listsCate(cate){
      if(this.menuCate == cate){
        return;
      }
      this.menuCate = cate;
      let lists = this.allLists;
      if(cate == 'news'){
        lists = lists.filter(r => r.news.existNews);
        lists.sort((a, b) => b.news.count - a.news.count);
        this.statisticData = lists;
      } else if(cate == 'abnormalWeek'){
        lists = lists.filter(r => parseFloat(r.analysis.hourGroupDataAction.week.outPercent) > 0.1);
        lists.sort((a, b) => {
          return parseFloat(b.analysis.hourGroupDataAction.week.outPercent) - parseFloat(a.analysis.hourGroupDataAction.week.outPercent);
        });
        this.statisticData = lists;
      } else if(cate == 'abnormalDay'){
        lists = lists.filter(r => parseFloat(r.analysis.hourGroupDataAction.day.outPercent) > 0.05);
        lists.sort((a, b) => {
          return parseFloat(b.analysis.hourGroupDataAction.day.outPercent) - parseFloat(a.analysis.hourGroupDataAction.day.outPercent);
        });
        this.statisticData = lists;
      } else if(cate == 'all'){
        this.statisticData = lists;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container{
  max-width: 100%;
  display: flex;
  justify-content: space-between;

  .data-con{
    flex: 1;
    box-sizing: border-box;
    width: calc(100% - 280px);

    /deep/.el-table {
      .el-table__cell{
        text-align: center;
      }
    }
  }
}
</style>
