<template>
  <div class="chart-con">
    <div id="kline-chart-con"></div>
    <div class="interval-selector" v-if="klineData.length > 0">
      <el-tag size="mini" effect="plain" :type="intervalType == '1m' ? 'warning' : 'info'" @click="onChangeKlineInterval('1m')">1M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '3m' ? 'warning' : 'info'" @click="onChangeKlineInterval('3m')">3M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '5m' ? 'warning' : 'info'" @click="onChangeKlineInterval('5m')">5M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '15m' ? 'warning' : 'info'" @click="onChangeKlineInterval('15m')">15M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1h' ? 'warning' : 'info'" @click="onChangeKlineInterval('1h')">1H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '4h' ? 'warning' : 'info'" @click="onChangeKlineInterval('4h')">4H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1d' ? 'warning' : 'info'" @click="onChangeKlineInterval('1d')">1D</el-tag>
    </div>
    <div id="realtime-values" class="realtime-values"></div>
    <i class="el-icon-loading loading"></i>
  </div>
</template>

<script>
import tradeLibs from "@/libs/trade";
import * as func from "@/utils/func";
import * as klineUtils from "@/utils/kline";
import * as predictApi from "@/api/predict";
import * as indicatorUtil from '@/utils/indicator';
import * as aiV2 from "@/strategy/ai-v2";
import * as aiV6 from "@/strategy/ai-v6";
import * as aiV8 from "@/strategy/ai-v8";

const intervalSecond = {
  '1m': 60,
  '3m': 180,
  '5m': 300,
  '15m': 900,
  '1h': 3600,
  '4h': 4*3600,
  '1d': 24*3600
};
let klineChart;
let candleSeries, volumeBarSeries, volatilitySeries, volatilitySeries2, volatilitySeries3;

export default {
  props: {
    symbolInfo: {
      type: Object
    },
    news: {
      type: Array
    }
  },
  data(){
    return {
      symbol: null,
      pairName: null,
      contractAddress: {},
      circulatingSupply: 0,
      targetTime: null,
      currentPrecision: 8,
      intervalType: '3m',
      isLoadingKlineData: false,
      klineData: [],
      klineMarkersData: [],
      klineChartVisibleRange: null,
      hourDataSource: [],
      trendData: null,
      priceLines: [],
      goodChange: [],
      lastGoodSingal: null
    }
  },
  async mounted() {
    let { symbol, date } = this.$route.query;
    if(date){
      this.targetTime = parseInt(new Date(date).getTime()/1000);
    }
    if(!this.symbolInfo || !this.symbolInfo.baseInfo){
      this.symbol = symbol.replace('USDT', '');
    } else if(this.symbolInfo.baseInfo){
      this.symbol = this.symbolInfo.baseInfo.symbol;
      if(this.symbolInfo.baseInfo.brief){
        this.contractAddress = this.symbolInfo.baseInfo.brief.contractAddress;
      }
      this.circulatingSupply = this.symbolInfo.baseInfo.circulatingSupply;
    }
    this.pairName = this.symbol + 'USDT';

    // 重置chart
    this.$eventBus.$on('RESET_INFO_DATA', (targetTime) => {
      targetTime = parseInt(new Date(targetTime).getTime()/1000);
      if(targetTime == this.targetTime){
        return;
      }
      this.targetTime = targetTime;
      this.resetKlineChart();
      this.initKline();
    });
    // 监听按小时分组的数据
    this.$eventBus.$on('HOUR_DATA', res => {
      this.hourDataSource = res;
      this.generateHourDataMakers();
    });
    /*const goodChange = await predictApi.getGoodChange({ pairName: this.pairName });
    this.goodChange = goodChange.data.lists[0] || [];*/
    await this.initKline();
  },
  methods: {
    /**
     * 初始化k线
     * @returns {Promise<void>}
     */
    async initKline(){
      const startTime = this.getStartTime();
      // 加载首批k
      await this.getKlineData(this.pairName, startTime, this.intervalType);
      this.initKlineChart();
      // 绘制蜡烛
      this.generateKlineChartData(this.klineData);

      // news markers
      this.generateNewsMakers();
      // hour data
      await this.getHourData(
          this.contractAddress.Ethereum ? this.contractAddress.Ethereum : this.contractAddress['BNB Smart Chain (BEP20)'],
          startTime,
          this.klineData[this.klineData.length-1].time,
          this.contractAddress.Ethereum ? 'eth' : 'bsc'
      );
      this.generateHourDataMakers();
      // predict
      this.predictPickPoints(this.hourDataSource);
    },
    /**
     * 获取k线开始时间
     * @returns {number}
     */
    getStartTime() {
      let startTime;
      if(this.targetTime){
        startTime = this.targetTime - 1000 * intervalSecond[this.intervalType];
      } else if(this.news && this.news.length > 0){
        startTime = this.news[0].timestamp - 500 * intervalSecond[this.intervalType];
      } else {
        startTime = new Date().getTime()/1000 - 1000 * intervalSecond[this.intervalType];
      }
      return startTime;
    },
    /**
     * 切换k线时间类型
     * @param iv
     * @returns {Promise<void>}
     */
    async onChangeKlineInterval(iv){
      if(this.intervalType == iv){
        return;
      }
      this.intervalType = iv;
      this.resetKlineChart();
      await this.initKline();

      this.generateHourDataMakers();
    },
    /**
     * kline data
     * @param pairName
     * @param startTime
     * @param interval
     * @returns {Promise<void>}
     */
    async getKlineData(pairName, startTime, interval, scrollDirect){
      if(this.isLoadingKlineData){
        return;
      }
      this.isLoadingKlineData = true;

      const res = await tradeLibs.candles(pairName, interval, startTime, 1000);
      this.nextKlineStartTime = res[res.length - 1].openTime/1000;
      let lists = res.map(item => ({
        time: parseInt(item.openTime/1000),
        open: parseFloat(item.open),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        close: parseFloat(item.close),
        volume: parseFloat(item.volume),
        change: parseFloat((item.close - item.open)/item.open*100).toFixed(2)
      }));

      if(scrollDirect == 'prev'){
        lists = lists.filter(item => item.time < this.klineData[0].time);
      } else if(this.klineData.length > 0) {
        lists = lists.filter(item => item.time > this.klineData[this.klineData.length - 1].time);
      }

      this.klineData = scrollDirect == 'prev' ? lists.concat(this.klineData) : this.klineData.concat(lists);
      this.isLoadingKlineData = false;

      return lists;
    },
    /**
     * init kline chart
     */
    initKlineChart(){
      if(klineChart){
        return;
      }
      // init klineChart
      const domElement = document.getElementById('kline-chart-con');
      const options = {
        layout: {
          background: {
            color: '#242424',
          },
          textColor: '#B0A492',
        },
        crosshair: {
          mode: 0,
        },
        priceScale: {
          mode: LightweightCharts.PriceScaleMode.Normal
        },
        grid: {
          horzLines: { color: '#353945' },
          vertLines: { color: '#353945' },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
          rightOffset: 20
        },
      };
      if(this.$i18n.locale == 'zh'){
        options.timeScale.timezone = 'Asia/Shanghai';
        options.localization = {
          timeFormatter: (businessDayOrTimestamp) => {
            return func.getDate(businessDayOrTimestamp);
          },
        }
      } else {
        options.timeScale.timezone = 'America/New_York';
        options.localization = {
          timeFormatter: (businessDayOrTimestamp) => {
            return new Date(businessDayOrTimestamp*1000).toLocaleString('en-US', { timeZone: 'America/New_York' });
          },
        }
      }
      klineChart = LightweightCharts.createChart(domElement, options);
      // 价格tip
      this.drawToolTip();

      // scroll event
      klineChart.timeScale().subscribeVisibleTimeRangeChange((visibleRange, _) => {
        let scrollDirect = null;
        if(visibleRange && this.klineChartVisibleRange && this.klineChartVisibleRange.length == 3){
          const offsetTime = visibleRange.from - this.klineChartVisibleRange[0];
          if(offsetTime < 0){
            scrollDirect = 'prev';
          } else if(offsetTime > 0) {
            scrollDirect = 'next';
          } else {
            scrollDirect = this.klineChartVisibleRange[2];
          }
        }

        this.klineChartVisibleRange = visibleRange ? [visibleRange.from, visibleRange.to, scrollDirect] : null;
      });
      domElement.addEventListener("mouseup", async (e) => {
        if(!this.klineChartVisibleRange){
          return;
        }
        let isLoadMore = false, startTime = null;
        // 当前已加载的范围
        const currentRange = [this.klineData[0].time, this.klineData[this.klineData.length - 1].time];
        if(
            this.klineChartVisibleRange[2] == 'prev'
            && this.klineChartVisibleRange[0] - currentRange[0] < 1000 * intervalSecond[this.intervalType]
        ){
          startTime = currentRange[0] - 1000 * intervalSecond[this.intervalType];
          isLoadMore = true;
        } else if(
            this.klineChartVisibleRange[2] == 'next'
            && currentRange[1] - this.klineChartVisibleRange[1] < 1000 * intervalSecond[this.intervalType]
            && new Date().getTime()/1000 - currentRange[1] > intervalSecond[this.intervalType]
        ){
          startTime = currentRange[1];
          isLoadMore = true;
        }
        if(isLoadMore){
          console.log('load more')
          const lastKlineData = await this.getKlineData(this.pairName, startTime, this.intervalType, this.klineChartVisibleRange[2]);
          this.generateKlineChartData(this.klineData);

          this.generateNewsMakers();

          const lastHourData = await this.getHourData(
              this.contractAddress.Ethereum ? this.contractAddress.Ethereum : this.contractAddress['BNB Smart Chain (BEP20)'],
              startTime,
              this.klineData[this.klineData.length-1].time,
              this.contractAddress.Ethereum ? 'eth' : 'bsc'
          );
          this.generateHourDataMakers();
          this.predictPickPoints(lastHourData);
        }

      });
    },
    /**
     * 绘制实时数据
     */
    drawToolTip(){
      const toolTip = document.getElementById('realtime-values');
      klineChart.subscribeCrosshairMove(param => {
        if (!param.time) {
          toolTip.style.display = 'none';
        } else {
          toolTip.style.display = 'flex';
          const data = param.seriesData.get(candleSeries);
          if(!data){
            return;
          }
          const volumeData = param.seriesData.get(volumeBarSeries);
          const changeValue = parseFloat((data.close - data.open)/data.open*100).toFixed(2);
          const amplitude = parseFloat((data.high - data.low)/data.low*100).toFixed(2);
          const price = data.value !== undefined ? data.value : data.close;
          const volume = volumeData ? volumeData.value : 0;

          const vData = param.seriesData.get(volatilitySeries);
          const _c = vData ? vData.value : 0;

          const priceStr = `
<span>${this.$t('kline.open')}:${data.open}</span>
<span>${this.$t('kline.close')}:${data.close}</span>
<span>${this.$t('kline.high')}:${data.high}</span>
<span>${this.$t('kline.low')}:${data.low}</span>
            `;
          const changeStr = `
<span>${this.$t('kline.change')}: ${changeValue}</span>
<span>${this.$t('kline.amplitude')}: ${amplitude}</span>
<span>${this.$t('kline.volume')}: ${volume}</span>
<span>_c: ${_c}</span>
          `;

          toolTip.innerHTML = `
<div class="price-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${priceStr}</div>
<div class="change-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${changeStr}</div>
`;
          const coordinate = candleSeries.priceToCoordinate(price);
          if (coordinate === null) {
            return;
          }
        }
      });
    },
    /**
     * 添加marker
     * @param time
     * @param text
     * @param position
     * @param shape
     * @param color
     */
    addMarker(time, text, color, position, shape, type){
      let hasMarker = false;
      if(type){
        hasMarker = this.klineMarkersData.some(r => r.time - time == 0 && r.type == type);
      }
      !hasMarker && this.klineMarkersData.push({
        time,
        position: position || 'belowBar',
        color: color || 'rgb(246, 174, 45)',
        shape: shape || 'arrowUp',
        text: text ? text.toString() : '',
        size: 1,
        type
      });
      if(arguments && typeof(arguments[arguments.length - 1]) == 'boolean'){
        this.klineMarkersData.sort((a, b) => a.time - b.time);
        candleSeries.setMarkers(this.klineMarkersData);
      }
    },
    /**
     * 重置k线数据
     */
    resetKlineChart(){
      this.klineData = [];
      this.klineMarkersData = [];
      this.klineChartVisibleRange = null;

      if(candleSeries){
        for(let i in this.priceLines){
          candleSeries.removePriceLine(this.priceLines[i]);
        }
        this.priceLines = [];
        candleSeries.setData([]);
      }
      volatilitySeries && volatilitySeries.setData([]);
      volatilitySeries2 && volatilitySeries2.setData([]);
      volatilitySeries3 && volatilitySeries3.setData([]);
    },
    /**
     * 绘制k线数据
     * @param klineData
     */
    generateKlineChartData(klineData){
      this.generateCandleSeries(klineData);
      // volume
      // this.generateVolumeSeries(klineData);

      // this.generateDiffMarkers(klineData);

      // 均价异常
      // this.generateAvePriceSeries(klineData);
      // 波动线
      this.generateVolatilitySeries(klineData);
    },
    /**
     * 生成k线蜡烛图
     * @param klineData
     * @returns {Promise<void>}
     */
    async generateCandleSeries(klineData){
      // kline
      if(!candleSeries){
        candleSeries = klineChart.addCandlestickSeries();
        candleSeries.priceScale().applyOptions({
          autoScale: true
        });
        this.resetKlineChartPricePrecision();
      }
      candleSeries.setData(klineData);
    },
    /**
     * 重置价格精度
     */
    resetKlineChartPricePrecision(){
      const firstCandle = this.klineData.length > 0 ? this.klineData[0] : null;
      let precision = 8, minMove = 0.00000001;
      if(firstCandle){
        const priceArr = [
          firstCandle.open.toString(),
          firstCandle.close.toString(),
          firstCandle.high.toString(),
          firstCandle.low.toString()
        ];
        let len = 0;
        for(let i in priceArr){
          const _arr = priceArr[i].split('.');
          if(_arr[1] && _arr[1].length - len > 0){
            len = _arr[1].length;
          }
        }
        precision = len ? len : 2;
        minMove = parseFloat(Math.pow(10, -precision)).toFixed(precision);
      }
      candleSeries.applyOptions({
        priceFormat: {
          type: 'price',
          precision,
          minMove
        },
      });

      this.currentPrecision = precision;
    },
    /**
     * 交易量图
     */
    async generateVolumeSeries(klineData){
      if(!volumeBarSeries){
        volumeBarSeries = klineChart.addHistogramSeries({
          color: "#a300e7",
          lineWidth: 2,
          priceFormat: {
            type: "volume"
          },
          overlay: true,
          priceScaleId: 'VolumeBar',
        });
        volumeBarSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }
      // 预测异常
      const dataPoints = [];
      for(let i in klineData){
        if(i < 2){
          dataPoints.push(0);
        } else {
          const v = parseFloat(Math.abs(klineData[i].volume))
              + parseFloat(Math.abs(klineData[i - 1].volume))
              + parseFloat(Math.abs(klineData[i - 2].volume));
          dataPoints.push(v);
        }

        //dataPoints.push(klineData[i].volume);
      }
      /*const res = await predictApi.pickPoints({
        points: dataPoints.join(',')
      });*/
      const markedIdxs = [];//res.data;
      const dataArr = klineData.map((item, idx) => {
        return {
          time: item.time,
          value: item.volume,
          color: markedIdxs.indexOf(idx) > -1 ? '#49cbf3' : (klineData[idx].change >= 0 ? '#26A59A' : '#ED534F')
        }
      });
      volumeBarSeries.setData(dataArr);
    },
    /**
     * 异常标记
     * 1、波动率增加后再次趋于平稳，相对于上一次平稳阶段，均价上升一个阶梯
     */
    generateDiffMarkers(klineData){
      const trendData = [];
      for(let i = 0; i < klineData.length; i++){
        if(i < 80){
          continue;
        }
        const trendValues = aiV2.getTrendValues(klineData.slice(i - 80, i), trendData.slice(-160));
        trendData.push(trendValues);
      }

      let isShow = 0;
      const markers = [];
      for(let i in trendData){
        const row = trendData[i];
        // 0.35 这个数据后面换成前一段周期平均change的两倍
        /**
         * 蜡烛change小于2
         * 当前波动率和上一组相比上涨 > 50%
         * 和上一组相比均价增长 > 1.5%
         * avePriceChangeRate < 3 过滤已涨起来的异常点
         */
        if(
            // Math.abs(row.change) - 0.35 >= 0
            Math.abs(row.change) - 1.5 < 0
            // && Math.abs(row.aveVolatilityRate) - 50 > 0
            // 过滤噪音
            && Math.abs(row.vv) - 200 > 0
            // 过滤高位的markers
            && row.avePriceChangeRate
            && Math.abs(row.avePriceChangeRate) - 1.5 > 0
            && row.avePriceChangeRate - 3 < 0
            && row.avePriceChangeRate - (-10) > 0

            // && row.aveVolatilityRate_1
            // && row.aveVolatilityRate_1 - 1 < 0
            // && row.aveVolatilityRate_2
            // && row.aveVolatilityRate_2 - 2 < 0

            // && row.aveVolatility - 1.5 < 0

            // && row.diffPrevRate - (-0.3) > 0
            // && row.diffPrevRate - 1.5 < 0
        ){
          // avePriceChangeRate
          // const hasValue1 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.avePriceChangeRate == 0);
          // !hasValue1 && this.addMarker(row.time, row.avePriceChangeRate, 'rgb(210,208,208)');
          // changeRate
          // const hasValue2 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.changeRate == 0);
          // !hasValue2 && this.addMarker(row.time, row.changeRate, 'rgb(117,194,204)');
          // change
          // const hasValue3 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.change == 0);
          // !hasValue3 && this.addMarker(row.time, row.change, 'rgb(170,182,239)');
          // vv
          // const hasValue4 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - vv == 0);
          // !hasValue4 && this.addMarker(row.time, vv, 'rgb(169,137,245)');
          // diffPrevRate
          //const hasValue5 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.diffPrevRate == 0);
          //!hasValue5 && this.addMarker(row.time, row.diffPrevRate, 'rgb(183,253,190)');
          // diffMaxRate
          // const hasValue6 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.diffMaxRate == 0);
          // !hasValue6 && this.addMarker(row.time, row.diffMaxRate, 'rgb(250,225,144)');
          // aveChange
          // const hasValue7 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.aveChange == 0);
          // !hasValue7 && this.addMarker(row.time, row.aveChange, 'rgb(250,225,144)');
          // aveVolatility
          // const hasValue11 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.aveVolatility == 0);
          // !hasValue11 && this.addMarker(row.time, row.aveVolatility, 'rgb(255,204,214)');
          // const hasValue8 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.aveVolatilityRate_1 == 0);
          // !hasValue8 && this.addMarker(row.time, row.aveVolatilityRate_1, 'rgb(250,225,144)');
          // const hasValue10 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.aveVolatilityRate_2 == 0);
          // !hasValue10 && this.addMarker(row.time, row.aveVolatilityRate_2, 'rgb(243,193,128)');
          // aveVolatilityRate
          // row.aveVolatilityRate = parseFloat(row.aveVolatilityRate).toFixed(2);
          // const hasValue9 = this.klineMarkersData.some(r => r.time - row.time == 0 && r.text - row.aveVolatilityRate == 0);
          // !hasValue9 && this.addMarker(row.time, row.aveVolatilityRate, 'rgb(224,227,253)');

          isShow++;
          row.isShow = isShow;
          markers.push(row);
        } else {
          isShow -= 0.5;
          isShow < 0 && (isShow = 0);
        }
      }
      for(let i in markers){
        const row = markers[i];
        if(row.isShow - 1 == 0){
          continue;
        }
        this.addMarker(row.time, null, 'rgba(240,185,11,0.6)', 'belowBar', 'circle', 'KlineAmp');
      }
      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);
    },
    /**
     * news markers
     */
    generateNewsMakers(){
      const klineTimeRange = [this.klineData[0].time, this.klineData[this.klineData.length - 1].time];
      // 新闻
      for(let i in this.news){
        const item = this.news[i];
        // 不在k线时间范围的不显示
        if(item.timestamp - klineTimeRange[0] < 0){
          continue;
        }
        if(item.timestamp - klineTimeRange[1] > 0){
          continue;
        }
        this.addMarker(item.timestamp, 'NEWS', 'rgb(246, 174, 45)', 'belowBar', 'circle', 'News');
      }
      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);
    },
    /**
     * data markers
     */
    async generateHourDataMakers(){
      const klineTimeRange = [this.klineData[0].time, this.klineData[this.klineData.length - 1].time];
      // 小时数据markers
      for(let i in this.hourDataSource){
        const item = this.hourDataSource[i];
        // 不在k线时间范围的不显示
        if(item.timestamp - klineTimeRange[0] < 0){
          continue;
        }
        if(item.timestamp - klineTimeRange[1] > 0){
          break;
        }
        if(!item.is_mark){
          // continue;
        }
        const outPercent = parseFloat(item.out/this.circulatingSupply*100).toFixed(2);
        this.hourDataSource[i].outPercent = outPercent;
        if(outPercent - 0.1 < 0){
          continue;
        }
        this.addMarker(item.timestamp, outPercent + '%', 'rgba(0,234,255, .2)', 'aboveBar', 'arrowDown', 'HourData');
      }
      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);
    },
    async getHourData(address, startTime, endTime, chain){
      console.log('getHourData')
      const res = await predictApi.hourDataSource({ address, startTime, endTime, chain });
      if(res && res.data && res.data.hourData && res.data.hourData.length > 0){
        this.hourDataSource = this.hourDataSource.concat(res.data.hourData);
      }
      console.log('getHourData -> ok')

      return res.data.hourData;
    },
    /**
     * 波动率曲线
     * @param klineData
     */
    generateVolatilitySeries(klineData){
      if(!volatilitySeries){
        volatilitySeries = klineChart.addLineSeries({
          price: 10,
          color: '#fc1459',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine',
          priceLineVisible: true, // 显示价格线
          lastValueVisible: true, // 显示最新值
        });
        volatilitySeries.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.9,
            bottom: 0,
          }
        });
      }

      if(!volatilitySeries2){
        volatilitySeries2 = klineChart.addLineSeries({
          price: 10,
          color: '#ffb600',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine',
          priceLineVisible: true, // 显示价格线
          lastValueVisible: true, // 显示最新值
        });
        volatilitySeries2.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries2.priceScale().applyOptions({
          scaleMargins: {
            top: 0.9,
            bottom: 0,
          }
        });
      }

      if(!volatilitySeries3){
        volatilitySeries3 = klineChart.addLineSeries({
          price: 10,
          color: '#00e1d9',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine',
          priceLineVisible: true, // 显示价格线
          lastValueVisible: true, // 显示最新值
        });
        volatilitySeries3.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries3.priceScale().applyOptions({
          scaleMargins: {
            top: 0.9,
            bottom: 0,
          }
        });
      }

      // 提取异常波动
      let dataArr1 = [], dataArr2 = [], dataArr3 = [];
      this.lastGoodSingal = null;
      for(let i = 0; i < klineData.length; i++){
        if(i < 480) continue;
        const item = klineData[i];
        const res = aiV8.checkBuy(klineData.slice(i - 240, i + 1));
        if(res){
          dataArr1.push({
            time: res.time,
            value: res.avePrice_1h,
            //color: res.mark ? '#00e1d9' : '#f66f99'
          });
          dataArr2.push({
            time: res.time,
            value: res.avePrice_2h
          });
          dataArr3.push({
            time: res.time,
            value: res.avePrice_4h,
            //color: res.avePrice_4h ? 'yellow' : 'gray'
          });

          if(res.singal){
            this.addMarker(
                res.time,
                `${res.remark}|${res.zeroChangeRate}|${res.diffStartChange}|${res.isPriceStable_6h}`,
                res.goodSingal ? 'rgb(255,238,0)' : 'rgba(255,238,0,.3)',
                'belowBar', 'circle', 'TEST3'
            );
          }

          /*if(res.time % 1800 === 0){
            this.addMarker(
                res.time,
                res.zeroChangeRate + '',
                res.zeroChangeRate - 30 < 0 ? 'rgba(255,0,47, .2)' : 'rgb(255,0,47)',
                'belowBar', 'circle', 'TEST5'
            );
          }*/
          /*if(res.unusualRange && res.unusualRange[1] && res.unusualRange[1].endTime){
            this.addMarker(
                res.unusualRange[1].startTime,
                parseFloat(parseFloat(res.unusualRange[1].baseAvePrice).toFixed(8)),
                'rgb(255,84,126)',
                'belowBar', 'circle', 'TEST6'
            );
            this.addMarker(
                res.unusualRange[1].endTime,
                res.unusualRange[1].maxChange,
                'rgb(255,182,0)',
                'belowBar', 'circle', 'TEST6'
            );
          }*/
        }

        /*const sellRes = aiV4.checkSell(klineData.slice(i - 120, i), this.lastGoodSingal);
        if(sellRes && sellRes.singal){
          this.lastGoodSingal = null;
          this.addMarker(
              sellRes.time,
              sellRes.remark + '-' + parseFloat(sellRes.profit).toFixed(2),
              'rgb(252,20,89)',
              'aboveBar', 'circle', 'SELL'
          );
        }*/

        /*
        // 和上一个相比的change
        const changeDiff = Math.abs(item.change) - Math.abs(klineData[i - 1].change);
        // 和前3个相比的change
        const changeDiff2 = Math.abs(item.change) - Math.abs(klineData[i - 3].change);
        // 不符合条件的置0
        if(Math.abs(changeDiff) - 0.3 > 0 || Math.abs(changeDiff2) - 0.6 > 0){
          dataArr.push({
            time: item.time,
            value: changeDiff
          });
        } else {
          dataArr.push({
            time: item.time,
            value: 0,
          });
        }
        if(dataArr.length >= 20) {
          let notZeroCount = 0;
          for(let j = dataArr.length - 1; j >= dataArr.length - 20; j--){
            if(dataArr[j].value != 0 || (dataArr[j-1] && dataArr[j-1].value != 0 && dataArr[j+1] && dataArr[j+1].value != 0)){
              notZeroCount++;
            }
          }
          // 非0数量占比大于60%的区间，打标记
          if(notZeroCount/20 > 0.6) {
            // 重置颜色
            for(let j = dataArr.length - 1; j >= dataArr.length - 20; j--){
              dataArr[j].mark = 1;
              dataArr[j].color = '#2d7532';
            }
          }
        }*/
      }
      dataArr1.sort((a, b) => a.time - b.time);
      dataArr2.sort((a, b) => a.time - b.time);
      dataArr3.sort((a, b) => a.time - b.time);

      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);

      /*let startIdx = null, endIdx = null;
      const groupList = [];
      for(let i = 0; i < dataArr.length; i++){
        const item = dataArr[i];
        if(item.mark == 1 && !startIdx){
          startIdx = i;

          if(endIdx && prevAveClose){
            for(let j = endIdx; j < startIdx; j++){
              const candles = klineData.slice(endIdx, endIdx + 20);
              let totalClose = 0;
              candles.map(row => {
                totalClose += parseFloat(row.close);
              });
              nextAveClose = totalClose/candles.length;

              console.log('prevAveClose : ' + prevAveClose + ' -> nextAveClose : ' +  nextAveClose);

              const change = parseFloat((nextAveClose - prevAveClose)/prevAveClose * 100);
              if(change - 1.5 > 0){
                this.addMarker(dataArr[endIdx].time, parseFloat(change).toFixed(2) + '', 'rgb(0,234,255)', 'aboveBar', 'arrowDown', 'TEST');
                dataArr[j].color = 'yellow';
              }
            }
          }

          endIdx = null;
        }
        if(startIdx && !item.mark){
          endIdx = i;

          let prevAveClose = null;
          if(startIdx - 80 > 0){
            const candles = klineData.slice(startIdx - 80, startIdx - 40);
            let totalClose = 0;
            candles.map(row => {
              totalClose += parseFloat(row.close);
            });
            prevAveClose = totalClose/candles.length;
          }

          groupList.push({
            startIdx,
            endIdx,
            prevAveClose
          });

          startIdx = null;
        }
      }

      console.log(groupList)

      for(let i = 0; i < groupList.length; i++){
        const endIdx = groupList[i].endIdx;
        const prevAveClose = groupList[i].prevAveClose;
        this.addMarker(dataArr[endIdx].time, prevAveClose + '', 'rgb(0,234,255)', 'aboveBar', 'arrowDown', 'TEST');

        if(groupList[i + 1]){
          for(let j = endIdx; j < groupList[i + 1].startIdx; j += 1){
            if(j - endIdx > 240){
              break;
            }
            const candles = klineData.slice(j, j + 40);
            let totalClose = 0;
            candles.map(row => {
              totalClose += parseFloat(row.close);
            });
            const nextAveClose = totalClose/candles.length;
            const change = parseFloat((nextAveClose - prevAveClose)/prevAveClose * 100).toFixed(2);
            this.addMarker(dataArr[endIdx].time, change + '', 'rgb(0,234,255)', 'aboveBar', 'arrowDown', 'TEST');

            if(change - 2 > 0){
              dataArr[j].color = 'yellow';
            }
          }
        }
      }

      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);

      /*let dataArr = [];
      for(let i = klineData.length; i > 0 ; i--){
        if(i < 80){
          break;
        }
        // 每4小时一组
        const groupCandles = klineData.slice(i - 80, i);
        const topChange = klineUtils.filterTopChange(groupCandles);
        for(let n in topChange){
          const item = topChange[n];
          const exist = dataArr.find(r => r.time === item.time);
          if(!exist){
            dataArr.push({
              time: item.time,
              value: item.change
            });
          }
        }
      }
      dataArr.sort((a, b) => a.time - b.time);*/

      /*const volatilityData = [];
      let candlesGroup = [], realValue = [];
      const getRangeLevel = (v) => {
        if(v - 0.2 <= 0){
          return 0;
        } else if(v - 0.2 > 0 && v - 0.5 <= 0) {
          return 1;
        } else if(v - 0.5 > 0 && v - 1 <= 0) {
          return 2;
        } else {
          return 3;
        }
      }

      let prevValue = null;
      const limitRate = 0.8;
      for(let i in klineData){
        const row = klineData[i];
        candlesGroup.push(row);
        if(i % 40 == 0){
          let v = klineUtils.calculateAverageVolatility(candlesGroup);
          if(!v){
            continue;
          }
          v = parseFloat(v).toFixed(2);
          realValue.push(v);
          realValue = realValue.slice(5);
          const rl = getRangeLevel(v);
          const changeRate = prevValue ? (v - prevValue)/prevValue : 0;
          let color = changeRate - limitRate >= 0 ? 'yellow' : 'red';
          /*if(rl == 0){
            v = 0;
            color = 'blue';
            // 上一个 > 0, 上上个 = 0, 将上一个设为0
            if(
                volatilityData.length > 1
                && volatilityData[volatilityData.length - 1].value > v
                && volatilityData[volatilityData.length - 2].value == v
            ){
              volatilityData[volatilityData.length - 1].value = v;
              volatilityData[volatilityData.length - 1].color = color;
            }
          } else if(rl == 1){
            v = 0.35;
            color = 'yellow';
            if(
                volatilityData.length > 1
                && volatilityData[volatilityData.length - 1].value > v
                && volatilityData[volatilityData.length - 2].value == v
            ){
              volatilityData[volatilityData.length - 1].value = v;
              volatilityData[volatilityData.length - 1].color = color;
            }
          } else if(rl == 2){
            v = 0.75;
            color = '#00e1d9';
          } else {
            v = 1;
            color = 'pink';
          }
          volatilityData.push({
            time: row.time,
            date: func.getDate(row.time),
            value: v,
            color
          });*

          if(changeRate - limitRate >= 0){
            const group1 = candlesGroup.slice(0, 10);
            let vv1 = klineUtils.calculateAverageVolatility(group1);
            vv1 = parseFloat(vv1).toFixed(2);
            const cr1 = prevValue ? (vv1 - prevValue)/prevValue : 0;

            const group2 = candlesGroup.slice(10, 20);
            let vv2 = klineUtils.calculateAverageVolatility(group2);
            vv2 = parseFloat(vv2).toFixed(2);
            const cr2 = prevValue ? (vv2 - prevValue)/prevValue : 0;

            const group3 = candlesGroup.slice(20, 30);
            let vv3 = klineUtils.calculateAverageVolatility(group3);
            vv3 = parseFloat(vv3).toFixed(2);
            const cr3 = prevValue ? (vv3 - prevValue)/prevValue : 0;

            const hasValue1 = this.klineMarkersData.some(r => r.time - group1[group1.length - 1].time == 0);
            !hasValue1 && this.addMarker(group1[group1.length - 1].time, '', cr1 - limitRate >= 0 ? 'orange' : '#ff547e');
            const hasValue2 = this.klineMarkersData.some(r => r.time - group2[group2.length - 1].time == 0);
            !hasValue2 && this.addMarker(group2[group2.length - 1].time, '', cr2 - limitRate >= 0 ? 'orange' : '#ff547e');
            const hasValue3 = this.klineMarkersData.some(r => r.time - group3[group3.length - 1].time == 0);
            !hasValue3 && this.addMarker(group3[group3.length - 1].time, '', cr3 - limitRate >= 0 ? 'orange' : '#ff547e');
          }

          candlesGroup = [];

          // const hasValue = this.klineMarkersData.some(r => r.time - row.time == 0);
          // !hasValue && this.addMarker(row.time, v + '%', color);

          prevValue = v;
        }
      }*/
      volatilitySeries.setData(dataArr1);
      volatilitySeries2.setData(dataArr2);
      volatilitySeries3.setData(dataArr3);

      /*this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);*/
    },
    /**
     * 计算变化率
     * @param klineData
     * @returns {*[]}
     */
    cauVolatility(klineData){
      let prevData = null;
      const changeData = [];
      for(let i in klineData){
        if(i == 0){
          prevData = klineData[i];
          continue;
        }
        const row = klineData[i];
        let change = parseFloat((row.high - row.low)/row.low*100).toFixed(2);
        const direct = row.close - row.open > 0 ? 1 : -1;
        change = change * direct;
        // prev check
        let prevChange = parseFloat((prevData.high - prevData.low)/prevData.low*100).toFixed(2);
        const prevDirect = prevData.close - prevData.open > 0 ? 1 : -1;
        prevChange = prevChange * prevDirect;
        if((change > 0 && prevChange > 0) || (change < 0 && prevChange < 0)){
          change += prevChange;
        }

        changeData.push({
          time: row.time,
          value: Math.abs(change) < 0.5 ? 0 : change
        });
      }
      return changeData;
    },
    /**
     * 计算标准差
     * @param klineData
     * @param short
     * @returns {*[]}
     */
    cauStandardDeviation(klineData, short){
      let dataShortSD = [], dataLongSD = [];
      let dataShortLists = [], dataLongLists = [];
      let groupShortData = [], groupLongData = [];
      for(let i in klineData){
        const item = klineData[i];
        let vShort = null, vLong = null;
        groupShortData.push(item);
        groupLongData.push(item);
        // 短线
        if(groupShortData.length == 6){
          vShort = this.$func.cauStandardDeviation(groupShortData, 'close');
          dataShortLists.push({
            time: item.time,
            date: this.$func.getDate(item.time),
            value: vShort
          });
          groupShortData = [];
        }
        // 长线
        if(groupLongData.length == 12){
          vLong = this.$func.cauStandardDeviation(groupLongData, 'close');
          dataLongLists.push({
            time: item.time,
            date: this.$func.getDate(item.time),
            value: vLong
          });
          groupLongData = [];
        }
      }

      const oneData = this.$func.minMaxNormalization(dataLongLists.map(r => r.value), 0, 1);
      dataLongLists = dataLongLists.map((r, idx) => {
        r.value = parseFloat(oneData[idx]).toFixed(2);
        // if(idx > 2){
        //   const slopeRate = this.$func.calculateOverallSlope(dataLongLists.slice(idx - 3, idx), 3, 'value');
        //   r.slopeRate = slopeRate;
        // }
        return r;
      });

      return short ? dataShortLists : dataLongLists;
    },

    /**
     * 进行过处理的波动曲线
     * @param klineData
     */
    generateVolatilitySeriesV2(klineData){
      if(!volatilitySeries2){
        volatilitySeries2 = klineChart.addLineSeries({
          price: 10,
          color: 'green',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine',
        });
        volatilitySeries2.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries2.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }

      // const volatilityData = this.cauStandardDeviation(klineData, true);
      const changeRates = klineUtils.cauStepChangeRatesV1(klineData);

      console.log(changeRates)

      volatilitySeries2.setData(changeRates);
    },
    /**
     * 预测点
     */
    async predictPickPoints(hourData){
      if(this.goodChange){
        for(let i in this.goodChange){
          const row = this.goodChange[i];
          if(!row.time){
            continue;
          }
          this.addMarker(
              row.time,
              null, 'rgb(210,208,208)',
              'belowBar', 'circle', 'KlineP'
          );
        }
        this.klineMarkersData.sort((a, b) => a.time - b.time);
        candleSeries.setMarkers(this.klineMarkersData);
      }

      if(!hourData){
        return;
      }
      const res = await predictApi.pickPoints({
        points: hourData.map(r => parseFloat(r.out/this.circulatingSupply*100).toFixed(2))
      });
      for(let i in res.data){
        const idx = res.data[i];
        if(hourData[idx].outPercent - 1 < 0){
          //continue;
        }
        this.addMarker(
            hourData[idx].timestamp,
            null, 'rgb(0,225,217)',
            'belowBar', 'circle', 'KlineP'
        );
      }
      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries.setMarkers(this.klineMarkersData);
    },

  },
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.chart-con {
  position: relative;
  height: calc(100vh - @header-height - @mg-height - 36px - 60px - 32px - 32px - 16px);
  width: 100%;

  #kline-chart-con{
    width: 100%;
    height: 100%;
  }
}

.interval-selector {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 999;

  /deep/.el-tag{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &.el-tag--plain.el-tag--info{
      color: @font-color;
    }
    &.el-tag--plain.el-tag--warning{
      color: @light-color;
    }
  }
}

.realtime-values {
  position: absolute;
  left: 226px;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  height: 20px;

  /deep/.price-str{
    span {
      padding-right: 10px;
    }
  }
  /deep/.change-str{
    span {
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .realtime-values{
    left: 20px;
    top: 30px;
    display: block !important;

    div{
      clear: both;
    }
  }
}
</style>