<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Task></Task>
  </div>
</template>

<script>
import '@/assets/js/tv-light';

import Header from "@/pages/components/Header";
import Task from "@/pages/components/Task";

export default {
  name: 'app',
  components: {
    Header,
    Task
  },
  created() {
    window.addEventListener('message', (event) => {
      if(event.data.target === 'fireBotResult2'){
        console.log(event)
      }
      if(!event.data || event.data.target !== 'fireBotResult'){
        return;
      }
      const data = event.data;
      const key = data.key;
      delete data.key;
      delete data.target;

      this.$eventBus.$emit('RES_' + key, data);
    });
  },
}
</script>
