<template>
  <div class="section-con">
    <div class="floor-price">
      <span>ME Floor: {{floorPrice/Math.pow(10, 8)}}</span>
      <span>Count: {{lists.length}}</span>
    </div>
    <div class="token-lists scroll-con">
      <div class="token-item" v-for="(item, idx) in lists" :key="idx">
        <iframe :src="item.contentPreviewURI" scrolling="no" class="cover" v-if="item.contentType.indexOf('image') === -1"></iframe>
        <el-image :src="item.contentPreviewURI" fit="cover" lazy class="cover" v-else></el-image>
        <div class="name">{{ item.displayName }}</div>
        <div class="price">Price: {{item.listedPrice/Math.pow(10, 8)}}</div>
        <div class="vs-me" :class="floorPrice - item.listedPrice > 0 ? 'text-green' : 'text-red'">
          vs ME: {{floorPrice - item.listedPrice > 0 ? '+' : ''}}{{parseFloat((floorPrice - item.listedPrice)/item.listedPrice*100).toFixed(2)}}%
        </div>
        <el-button size="mini" type="success" plain round class="fire-btn" @click="goto(item)">狙击</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as magicApi from "@/api/magic";

export default {
  props: ['symbol'],
  data(){
    return {
      lists: [],
      floorPrice: null
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData(){
      while(true){
        await this.loadLists();
        await this.$func.sleep(10*1000);
      }
    },
    async loadLists(){
      const res = await magicApi.getCollectionTokens({
        collectionSymbol: this.symbol
      });
      if(res && res.data && res.data.tokens){
        const tokens = [];
        for(let i in res.data.tokens){
          const token = res.data.tokens[i];
          if(token.mempoolTxId){
            tokens.push(token);
          } else if(!this.floorPrice && !token.mempoolTxId){
            this.floorPrice = token.listedPrice;
          }
        }
        this.lists = tokens;
        console.log(tokens)
      }
    },
    goto(item){
      this.$router.replace('tools?action=magiceden&tokenId=' + item.id);
      location.reload();
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";

.floor-price {
  font-size: 14px;
  color: @light-color;
  padding-bottom: 8px;
  span {
    padding-right: @mg;
  }
}
.token-lists {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin:  0 -8px;
  height: 500px;
}
.token-item {
  width: 150px;
  margin: 8px;
  .cover {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    border: none;
    overflow: hidden;
    background-color: #1B1B1B;
  }
  .name {
    font-size: 12px;
    color: @font-color;
    margin-top: 5px;
  }
  .price {
    font-size: 14px;
    color: @light-color;
    margin-top: 5px;
  }
  .vs-me {
    font-size: 13px;
    margin-top: 5px;
  }
  .fire-btn {
    width: 100%;
    margin-top: 8px;
  }
  .el-button--mini{
    padding: 5px 10px;
  }
}
</style>