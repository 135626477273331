<template>
  <div class="content-con">
    <div class="left-panel">
      <div class="section-con">
        <div class="info-con" v-if="symbolInfo && symbolInfo.baseInfo">
          <div class="base-section">
            <el-avatar size="medium" :src="symbolInfo.baseInfo.brief ? symbolInfo.baseInfo.brief.logo : null"></el-avatar>
            <div class="name-con">
              <div class="symbol">{{symbolInfo.baseInfo.symbol}}</div>
              <div class="name">{{symbolInfo.baseInfo.name}}</div>
            </div>
            <div class="price">${{parseFloat(symbolInfo.price)}}</div>
          </div>
          <div class="brief-section">
            <div class="data-row">
              <div class="label">Circulating supply</div>
              <div class="value">{{parseInt(symbolInfo.baseInfo.circulatingSupply)}}</div>
            </div>
            <div class="data-row">
              <div class="label">Total supply</div>
              <div class="value">{{parseInt(symbolInfo.baseInfo.totalSupply)}}</div>
            </div>
            <div class="data-row">
              <div class="label">Market cap</div>
              <div class="value">${{parseInt(symbolInfo.marketCap)}}</div>
            </div>
          </div>
          <div class="address-section" v-if="symbolInfo.baseInfo.brief">
            <div class="section-title">Contracts</div>
            <div class="data-row" :class="{'more-address': ['Ethereum', 'BNB Smart Chain (BEP20)'].indexOf(name) == -1}" v-for="(address, name) in symbolInfo.baseInfo.brief.contractAddress">
              <div class="label">{{name}}</div>
              <div class="value">{{$com.formatString(address, 6, 8)}}<i class="el-icon-copy-document" @click="$com.copyText(address)"></i></div>
            </div>
          </div>
          <div class="urls-section" v-if="symbolInfo.baseInfo.brief">
            <a v-for="(links, name) in symbolInfo.baseInfo.brief.urls" :href="links[0]" target="_blank" v-if="['website', 'twitter', 'facebook'].indexOf(name) > -1">
              {{name}}
            </a>
          </div>
        </div>
        <!-- news -->
        <el-tabs v-model="activeTab" type="border-card" @tab-click="onChangeTab" class="articles-con scroll-con">
          <el-tab-pane label="Related News">
            <div class="news-lists">
              <div class="news-item" v-for="(item, idx) in newsLists">
                <div class="top-con">
                  <div class="top-desp">
                    <div class="name">
                      <i class="icon-mark" v-if="item.source || item.name"></i>
                      {{item.source ? item.source + ':' : ''}}{{item.name}}
                    </div>
                    <div class="time-con">
                      <div class="time">
                        <i class="icon-mark" v-if="!item.source && !item.name"></i>
                        <span>{{$func.getDate(item.timestamp)}}</span>
                        <span class="new-tag" v-if="currentTime - item.timestamp < 60">NEW</span>
                      </div>
                      <span class="star" v-if="item.isBig">
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                </span>
                    </div>
                  </div>
                </div>

                <div class="news-brief">
                  <div class="sub-title" v-html="$i18n.locale == 'zh' ? (item.bodyCn || item.titleCn || item.body || item.title) : (item.body || item.title)"></div>
                  <div class="brief" v-if="item.suggestions.length > 0">
                    <div class="symbols">
                      <span v-for="(sg, sn) in item.suggestions" :key="sn" :title="sg.exchange" @click="onChangeSymbol(sg, item)">{{sg.symbol}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="no-data" style="height: 100px;" v-if="newsLists.length == 0">
                {{$t('noData')}}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="main-panel">
      <div class="filter-con">
        <div class="filter-form">
          <span class="label">Deadline:</span>
          <el-date-picker
              v-model="deadline"
              type="datetime"
              size="mini"
              placeholder="Deadline"
              v-if="deadline"
          >
          </el-date-picker>
        </div>
        <el-button size="mini" round class="reset-btn" @click="resetData">Reset</el-button>
      </div>

      <div class="statistic-con shadow-border" v-if="hourDataStatistic">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-statistic title="Week Trends">
              <template slot="formatter">
                <div class="grid-value">趋势:-</div>
                <div class="grid-value">均价:-</div>
                <div class="grid-value">最高:-</div>
                <div class="grid-value">最低:-</div>
              </template>
            </el-statistic>
          </el-col>
          <el-col :span="4">
            <el-statistic title="Day Trends">
              <template slot="formatter">
                <div class="grid-value">趋势:-</div>
                <div class="grid-value">均价:-</div>
                <div class="grid-value">最高:-</div>
                <div class="grid-value">最低:-</div>
              </template>
            </el-statistic>
          </el-col>
          <el-col :span="4">
            <el-statistic title="Hour Trends">
              <template slot="formatter">
                <div class="grid-value">趋势:-</div>
                <div class="grid-value">均价:-</div>
                <div class="grid-value">最高:-</div>
                <div class="grid-value">最低:-</div>
              </template>
            </el-statistic>
          </el-col>
          <el-col :span="4">
            <el-statistic title="Week Txns">
              <template slot="formatter">
                <div class="grid-value">累计:{{ hourDataStatistic.weekStatistic.totalAmount || 0 }}</div>
                <div class="grid-value">市值占比:{{ $com.fixed((hourDataStatistic.weekStatistic.totalAmount || 0)/circulatingSupply*100, 3) }}%</div>
                <div class="grid-value">单笔最高:{{ hourDataStatistic.weekStatistic.maxAmount || 0 }}</div>
                <div class="grid-value">市值占比:{{ $com.fixed((hourDataStatistic.weekStatistic.maxAmount || 0)/circulatingSupply*100, 3) }}%</div>
              </template>
            </el-statistic>
          </el-col>
          <el-col :span="4">
            <el-statistic title="Day Txns">
              <template slot="formatter">
                <div class="grid-value">累计:{{ hourDataStatistic.dayStatistic.totalAmount || 0 }}</div>
                <div class="grid-value">市值占比:{{ $com.fixed((hourDataStatistic.dayStatistic.totalAmount || 0)/circulatingSupply*100, 3) }}%</div>
                <div class="grid-value">单笔最高:{{ hourDataStatistic.dayStatistic.maxAmount || 0 }}</div>
                <div class="grid-value">市值占比:{{ $com.fixed((hourDataStatistic.dayStatistic.maxAmount || 0)/circulatingSupply*100, 3) }}%</div>
              </template>
            </el-statistic>
          </el-col>
          <el-col :span="4">
            <el-statistic title="Hour Txns">
              <template slot="formatter">
                <div class="grid-value">累计:{{ hourDataStatistic.hourStatistic.totalAmount || 0 }}</div>
                <div class="grid-value">市值占比:{{ $com.fixed((hourDataStatistic.hourStatistic.totalAmount || 0)/circulatingSupply*100, 3) }}%</div>
                <div class="grid-value">单笔最高:{{ hourDataStatistic.hourStatistic.maxAmount || 0 }}</div>
                <div class="grid-value">市值占比:{{ $com.fixed((hourDataStatistic.hourStatistic.maxAmount || 0)/circulatingSupply*100, 3) }}%</div>
              </template>
            </el-statistic>
          </el-col>
        </el-row>
      </div>

      <div class="section-con-box">
        <div class="tab-menus">
          <div class="tab-item" :class="{active: sectionTabActive == 'marketTrends'}" @click="sectionTabActive = 'marketTrends'">Market trends</div>
          <div class="tab-item" :class="{active: sectionTabActive == 'txnsTrends'}" @click="sectionTabActive = 'txnsTrends'">Txns trends</div>

          <div class="flex-1"></div>
          <div class="tab-right-con">
            <div class="block-con" v-if="sectionTabActive == 'marketTrends'">
              <span class="text-blue">⬇ Txns Anomalies</span>
              <span class="text-yellow">⬆ News Event</span>
            </div>
            <div class="block-con" v-if="sectionTabActive == 'txnsTrends'">
              <span class="text-success">>0 Deposit</span>
              <span class="text-danger"><0 Withdrawals</span>
              <span class="text-blue"><i class="color-block blue"></i> Anomalies</span>
            </div>
          </div>
        </div>

        <div class="kline-con" :class="{'show-section': sectionTabActive == 'marketTrends'}">
          <kline :symbol-info="symbolInfo" :news="newsLists" :key="'Kline-' + currentSymbol" v-if="symbolInfo"></kline>
        </div>

        <div class="txns-con" :class="{'show-section': sectionTabActive == 'txnsTrends'}">
          <transfer :symbol-info="symbolInfo" :news="newsLists" :key="'Transfer-' + currentSymbol" v-if="symbolInfo"></transfer>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as coinsApi from "@/api/coins";
import * as newsApi from "@/api/news";
import Kline from "@/pages/components/info/Kline";
import Transfer from "@/pages/components/info/Transfer";

export default {
  components: {
    Kline,
    Transfer
  },
  data(){
    return {
      activeTab: 0,
      deadline: null,
      currentTime: parseInt(new Date().getTime()/1000),
      newsLists: [],
      currentNews: null,
      currentSymbol: null,
      symbolInfo: null,
      circulatingSupply: 0,
      hourDataStatistic: null,
      sectionTabActive: 'marketTrends'
    }
  },
  async mounted() {
    let { news, symbol, date } = this.$route.query;
    this.deadline = date || this.$func.getDate();
    // 监听统计数据
    this.$eventBus.$on('HOUR_DATA_STATISTIC', res => {
      this.hourDataStatistic = res;
    });
    if(news){
      this.currentNews = JSON.parse(news);
      this.newsLists.unshift(this.currentNews);
      this.currentSymbol = symbol;
    } else if(symbol) {
      this.currentSymbol = symbol;
      this.newsLists = await this.getNews(symbol);
      this.currentNews = this.newsLists.length > 0 ? this.newsLists[0] : null;
    }
    if(this.currentSymbol){
      await this.getCoinInfo(this.currentSymbol.replace('USDT', ''));
    }
  },
  methods: {
    onChangeTab(idx){
      this.activeTab = idx;
    },
    /**
     * symbol info
     * @returns {Promise<void>}
     */
    async getCoinInfo(coin){
      const res = await coinsApi.info({coin});
      this.symbolInfo = res ? res.data : {};
      if(this.symbolInfo && this.symbolInfo.baseInfo){
        this.circulatingSupply = this.symbolInfo.baseInfo.circulatingSupply;
        this.contractAddress = this.symbolInfo.baseInfo.brief.contractAddress;
      }
    },
    /**
     * related news
     * @param symbol
     * @returns {Promise<*>}
     */
    async getNews(symbol){
      const res = await newsApi.searchNews({ symbol });
      const linkRegex = /(https?:\/\/\S+)/g;
      for(let i in res.data){
        const item = res.data[i];
        const body = item.body;
        res.data[i].body = body ? body.replace(linkRegex, '<a href="$1" target="_blank">[<i class="el-icon-link"></i>LINK]</a>') : null;
      }

      return res.data.reverse();
    },
    /**
     * reset data
     */
    resetData(){
      this.$eventBus.$emit('RESET_INFO_DATA', this.deadline);
    },
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.content-con {
  display: flex;

  .main-panel {
    flex: 1;
    margin: 0 @mg 0 0;
    position: relative;
  }
  .left-panel{
    .section-con{
      display: flex;
      flex-direction: column;
    }
  }
}

.info-con {
  margin-bottom: @mg;
  background-color: @content-bg-color;
  padding: @mg;
  border-radius: @mg;

  .section-title{
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
  }
  .base-section {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @sub-font-color;
    padding-bottom: 10px;

    .price {
      font-size: 16px;
    }
    .name-con{
      margin-left: 10px;
      flex: 1;

      .symbol{
        font-size: 15px;
        font-weight: bold;
      }
      .name{
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }

  .data-row{
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 10px;

    .label{
      flex: 1;
    }
    .value{
      i {
        margin-left: 5px;
      }
    }
  }
  .brief-section{
    margin-top: 10px;
  }
  .address-section {
    .more-address {
      display: none;
    }
  }
  .urls-section {
    display: flex;
    margin-top: 10px;
    a{
      font-size: 13px;
      color: @sub-font-color;
      background-color: @input-bg-color;
      margin-right: 10px;
      display: block;
      padding: 5px 10px;
      text-decoration: none;
      cursor: pointer;
      border-radius: 12px;
    }
  }
}

.articles-con{
  flex: 1;
}
.news-lists {
  margin-bottom: @mg;
}

.show-section {
  opacity: 1 !important;
  position: unset !important;
  top: unset !important;
}

.filter-con {
  margin: @mg 0;
  display: flex;
  align-items: center;
  background-color: @section-bg-color;
  padding: @mg;
  border-radius: @mg;

  .filter-form {
    flex: 1;
    display: flex;
    align-items: center;

    .label{
      padding-right: 10px;
      font-size: 14px;
    }
  }
  .reset-btn{
    background-color: transparent;
    color: @font-color;
    border-color: @font-color;
  }
}

.statistic-con {
  padding: 15px 0;

  /deep/.title {
    font-weight: bold;
    font-size: 14px;
  }
  .grid-value {
    font-size: 13px;
  }
}

.section-con-box{
  background-color: @section-bg-color;
  padding: @mg;
  border-radius: @mg;

  .kline-con {
    width: 100%;
    padding: @mg;
    opacity: 0;
    position: absolute;
    top: -10000000px;
    background-color: @content-bg-color;
    box-sizing: border-box;
    border-radius: 0 @mg @mg @mg;
  }

  .txns-con {
    width: 100%;
    box-sizing: border-box;
    padding: @mg;
    opacity: 0;
    position: absolute;
    top: -10000000px;
    background-color: @content-bg-color;
    border-radius: 0 @mg @mg @mg;
  }
}

</style>