<template>
  <div class="chart-con">
    <div id="kline-chart-con"></div>
    <div class="interval-selector">
      <el-tag size="mini" effect="plain" :type="intervalType == '1s' ? 'warning' : 'info'" @click="onChangeKlineInterval('1s')">1S</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1m' ? 'warning' : 'info'" @click="onChangeKlineInterval('1m')">1M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '3m' ? 'warning' : 'info'" @click="onChangeKlineInterval('3m')">3M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '5m' ? 'warning' : 'info'" @click="onChangeKlineInterval('5m')">5M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '15m' ? 'warning' : 'info'" @click="onChangeKlineInterval('15m')">15M</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1h' ? 'warning' : 'info'" @click="onChangeKlineInterval('1h')">1H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '4h' ? 'warning' : 'info'" @click="onChangeKlineInterval('4h')">4H</el-tag>
      <el-tag size="mini" effect="plain" :type="intervalType == '1d' ? 'warning' : 'info'" @click="onChangeKlineInterval('1d')">1D</el-tag>
    </div>
    <div id="realtime-values" class="realtime-values"></div>
    <i class="el-icon-loading loading"></i>
  </div>
</template>

<script>
import tradeLibs from "@/libs/trade";
import * as func from "@/utils/func";
import * as aiV2 from "@/strategy/ai-v2";
import * as aiV3 from "@/strategy/ai-v3";
import * as aiV1 from "@/strategy/ai-v1";
import * as aiV4 from "@/strategy/ai-v4";
import * as predictApi from "@/api/predict";

const intervalSecond = {
  '1s': 1,
  '1m': 60,
  '3m': 180,
  '5m': 300,
  '15m': 900,
  '1h': 3600,
  '4h': 4*3600,
  '1d': 24*3600
};
let klineChart;
let candleSeries, volumeBarSeries, volumeBarSeries2, volatilitySeries, volatilitySeries2, volatilitySeries3, volatilitySeries4, volatilitySeries5;
export default {
  props: {
    symbolInfo: {
      type: Object
    },
    news: {
      type: Array
    },
    startTime: {
      default: null
    }
  },
  data(){
    return {
      symbol: null,
      pairName: null,
      contractAddress: {},
      circulatingSupply: 0,
      intervalType: '3m',
      isLoadingKlineData: false,
      // kline
      klineData: [],
      currentPrecision: 8,
      nextKlineStartTime: null,
      klineMarkersData: [], // 已显示的markers
      hourDataMarkerIdx: -1, // 已经加载到markers的数据索引位置
      newsDataMarkerIdx: -1,
      newsLists: [],
      klineChartVisibleRange: null,
      hourDataSource: [],
      trendData: [],
      priceLines: [],
      // animate
      setStartTime: null,
      isRunSimulate: false,
      animateTimeRange: null,
      animateSpeed: 50,
      pauseAnimate: false,
      showKlineData: [], // 已显示的k线
      // buy and sell
      totalBuyRecords: {}, // 当前总持仓
      tradeRecords: [],
      afterBuyMaxPrice: 0,
      lastGoodSingal: null,
      // 监控
      trendDirect: null,
      trendMsg: null,
      runningLastLog: null
    }
  },
  async mounted() {
    let { symbol } = this.$route.query;
    if(!this.symbolInfo || !this.symbolInfo.baseInfo){
      this.symbol = symbol.replace('USDT', '');
    } else {
      this.symbol = this.symbolInfo.baseInfo.symbol;
      this.contractAddress = this.symbolInfo.baseInfo.brief ? this.symbolInfo.baseInfo.brief.contractAddress : null;
      this.circulatingSupply = this.symbolInfo.baseInfo.circulatingSupply;
    }
    this.pairName = this.symbol + 'USDT';
    // news
    this.newsLists = this.newsLists.concat(this.news);
    this.newsLists.sort((a, b) => a.timestamp - b.timestamp);
    // 监听txs按小时分组的数据
    this.$eventBus.$on('HOUR_DATA', res => {
      // 只保留异动点
      this.hourDataSource = res;//res.filter(r => r.is_mark);
    });
    // 操作动画
    this.$eventBus.$on('HANDLE_ANIMATE', action => {
      switch (action){
        case 'start':
          if(this.pauseAnimate){
            this.pauseAnimate = false;
          } else {
            this.runSimulate();
          }
          break;
        case 'pause':
          this.pauseAnimate = true;
          break;
      }
    });
    // 重置chart
    this.$eventBus.$on('RESET_INFO_DATA', (startTime) => {
      const setStartTime = parseInt(new Date(startTime).getTime()/1000);
      if(setStartTime == this.setStartTime){
        return;
      }
      this.setStartTime = setStartTime;
      this.resetKlineChart();
      this.initKline();
    });
    // 初始化kline chart
    await this.initKline();
    // 启动监听k线更新
    this.runKlineListener();
  },
  methods: {
    /**
     * 运行动画
     * @returns {Promise<void>}
     */
    async runSimulate(){
      if(this.isRunSimulate){
        return;
      }
      this.isRunSimulate = true;
      let idx = this.showKlineData.length;
      while(true) {
        await func.sleep(this.animateSpeed);
        if (this.pauseAnimate) {
          // 暂停
          await func.sleep(1000);
          continue;
        }
        // 推送k线
        const newData = this.klineData[idx];
        if(!newData) break;
        const maxChange = (newData.high - newData.low)/newData.low * 100;

        // 模拟真实行情
        const real = false;
        if(real && maxChange > 0.5){
          const high = newData.high, low = newData.low, close = newData.close, open = newData.open;
          // 第一个点
          newData.high = open;
          newData.low = open;
          newData.close = open;
          newData.timestamp = newData.time;
          this.$eventBus.$emit('KLINE-DATA', newData, idx);
          // 随机位置插入最高最低点
          const num = parseInt(maxChange/0.2);
          const randomIdx1 = Math.floor(Math.random() * (num + 1));
          const randomIdx2 = Math.floor(Math.random() * (num + 1));
          let timestamp = newData.timestamp;
          for(let i = 0; i < num; i ++){
            if (this.pauseAnimate) {
              // 暂停
              await func.sleep(1000);
              continue;
            }
            await func.sleep(this.animateSpeed);
            timestamp += this.animateSpeed;
            const _d = Object.assign({ timestamp }, newData);
            _d.close = Math.random() * (high - low) + low;
            if(_d.close - _d.high > 0) _d.high = _d.close;
            if(_d.close - _d.low < 0) _d.low = _d.close;
            this.$eventBus.$emit('KLINE-DATA', _d, idx);
            // 插入高低点
            if(i == randomIdx1){
              await func.sleep(this.animateSpeed);
              timestamp += this.animateSpeed;
              _d.timestamp = timestamp;
              _d.close = high;
              if(_d.close - _d.high > 0) _d.high = _d.close;
              this.$eventBus.$emit('KLINE-DATA', _d, idx);
            } else if(i == randomIdx2) {
              await func.sleep(this.animateSpeed);
              timestamp += this.animateSpeed;
              _d.timestamp = timestamp;
              _d.close = low;
              if(_d.close - _d.low < 0) _d.low = _d.close;
              this.$eventBus.$emit('KLINE-DATA', _d, idx);
            }
          }
          // 最后更新
          await func.sleep(this.animateSpeed);
          newData.high = high;
          newData.low = low;
          newData.close = close;
          newData.open = open;
          timestamp += this.animateSpeed;
          newData.timestamp = timestamp;
          newData.time = timestamp;
        }

        this.$eventBus.$emit('KLINE-DATA', newData, idx);
        // 加载后续kline
        idx++;
        if(
            this.klineData.length - idx < 500
            && !this.isLoadingKlineData
            && this.nextKlineStartTime < this.animateTimeRange[1]
        ){
          let now = parseInt(new Date().getTime()/1000);
          now = now - now % intervalSecond[this.intervalType];
          if(now - this.nextKlineStartTime > 0){
            this.getKlineData(this.pairName, this.nextKlineStartTime, this.intervalType);
          }
        }
        // 已显示的k线范围
        const klineTimeRange = [
          this.showKlineData[0].time,
          parseInt(newData.time) + intervalSecond[this.intervalType]
        ];
        // 推送新闻
        for(let i = this.newsDataMarkerIdx + 1; i < this.newsLists.length; i++) {
          const item = this.newsLists[i];
          // 不在k线时间范围的不显示
          if (item.timestamp - klineTimeRange[0] < 0) {
            continue;
          }
          if (item.timestamp - klineTimeRange[1] > 0) {
            break;
          }
          this.$eventBus.$emit('NEWS-DATA', item, i);
          this.newsDataMarkerIdx = i;
        }
        // 推送txs小时数据
        for(let i = this.hourDataMarkerIdx + 1; i < this.hourDataSource.length; i++) {
          const item = this.hourDataSource[i];
          // 不在k线时间范围的不显示
          if (item.timestamp - klineTimeRange[0] < 0) {
            continue;
          }
          if (item.timestamp - klineTimeRange[1] > 0) {
            continue;
          }
          if (!item.is_mark) {
            // continue;
          }
          const outPercent = parseFloat(item.out / this.circulatingSupply * 100).toFixed(2);
          if (outPercent - 0.1 < 0) {
            // continue;
          }
          item.outPercent = outPercent;
          const inPercent = parseFloat(item.in / this.circulatingSupply * 100).toFixed(2);
          item.inPercent = inPercent;
          this.$eventBus.$emit('HOUR-TXNS-DATA', item, i);
          this.hourDataMarkerIdx = i;
        }
      }
    },
    /**
     * 监听k线更新
     * @returns {Promise<void>}
     */
    runKlineListener(){
      // 监听新闻推送
      this.$eventBus.$on('NEWS-DATA', (newData, idx) => {
        this.addMarker(newData.timestamp, 'NEWS', 'rgb(246, 174, 45)', true);
      });
      // 监听异动推送
      this.$eventBus.$on('HOUR-TXNS-DATA', (newData, idx) => {
        // console.log(newData)
        // this.addMarker(newData.timestamp, newData.outPercent + '%', 'rgb(0,234,255)', true);
        volumeBarSeries.update({
          time: newData.timestamp,
          value: newData.inPercent
        });
        volumeBarSeries2.update({
          time: newData.timestamp,
          value: -newData.outPercent
        });
      });
      // 监听k线推送
      this.$eventBus.$on('KLINE-DATA', (newData, idx) => {
        // 更新chart
        const lastKline = this.showKlineData[this.showKlineData.length - 1];
        if(lastKline.time == newData.time){
          this.showKlineData[this.showKlineData.length - 1] = newData;
        } else {
          this.showKlineData.push(newData);
        }
        candleSeries && candleSeries.update(newData);

        // 异动
        if(lastKline.time != newData.time && this.showKlineData.length >= 120){
          const res = aiV4.checkBuy(this.showKlineData.slice(-120));
          /*volatilitySeries.update({
            time: newData.time,
            value: res.aveChange,
            color: 'red'
          });
          volatilitySeries2.update({
            time: newData.time,
            value: res.aveChange120,
            color: 'green'
          });*/

          volatilitySeries3.update({
            time: res.time,
            value: res.avePrice_15m,
            color: '#ffc400'
          });
          volatilitySeries5.update({
            time: res.time,
            value: res.avePrice_2h,
            color: '#00e1d9'
          });
          volatilitySeries4.update({
            time: res.time,
            value: res.avePrice_4h,
            color: '#ff547e'
          });

          if(res.singal){
            this.addMarker(
                res.time,
                res.change,
                'rgba(246,212,92,0.3)',
                'belowBar', 'circle', 'TEST1',
                true
            );
          }
          if(!this.lastGoodSingal && res.goodSingal){
            this.lastGoodSingal = res;
            this.addMarker(
                res.time,
                '(' + res.remark + ')' + res.change,
                'rgba(0,255,80,0.63)',
                'belowBar', 'circle', 'TEST3',
                true
            );
          }

          const sellRes = aiV4.checkSell(this.showKlineData.slice(-120), this.lastGoodSingal);
          if(sellRes && sellRes.singal){
            this.lastGoodSingal = null;
            this.addMarker(
                sellRes.time,
                '(' + sellRes.remark + ')' + parseFloat(sellRes.profit).toFixed(2),
                'rgb(252,20,89)',
                'aboveBar', 'circle', 'SELL',
                true
            );
          }

          /*aiV3.getData(this.showKlineData).then(res => {
            if(res.outlierTime && res.outlierTime.length > 0){
              res.outlierTime.map((t, index) => {
                this.addMarker(t, index == res.outlierTime.length-1 ? res.outlierTime.length + '' : null, 'rgba(240,185,11,0.6)', 'belowBar', 'circle', 'outlier');
              });
              this.klineMarkersData.sort((a, b) => a.time - b.time);
              candleSeries && candleSeries.setMarkers(this.klineMarkersData);
            }
          });*/
        }

        // 分析异动
        /*if(lastKline.time != newData.time && this.showKlineData.length >= 40){
          const row = aiV2.getTrendValues(this.showKlineData.slice(-40), this.trendData.slice(-80));
          this.trendData.push(row);
          if(
              Math.abs(row.change) - 1.5 < 0
              && Math.abs(row.aveVolatilityRate) - 50 > 0
              && row.avePriceChangeRate
              && Math.abs(row.avePriceChangeRate) - 1.5 > 0
              && row.avePriceChangeRate - 3 < 0
              && row.avePriceChangeRate - (-10) > 0
              && row.aveVolatilityRate_1
              && row.aveVolatilityRate_1 - 1 < 0
              && row.aveVolatilityRate_2
              && row.aveVolatilityRate_2 - 2 < 0
              && Math.abs(row.vv) - 200 > 0
          ){
            this.addMarker(row.time, null, 'rgba(240,185,11,0.6)', 'belowBar', 'circle', 'KlineAmp', true);
          }
        }*/


        /*const runningData = aiV1.execute('NewKline', newData, null, null, this.pairName);
        this.trendDirect = runningData.trendDirect;
        this.trendMsg = runningData.trendMsg;
        this.runningLastLog = runningData.logs[runningData.logs.length - 1];
        this.$eventBus.$emit('RUNNING-LOGS', runningData.logs);
        // 买
        if(runningData.newBuyPoint){
          const point = runningData.newBuyPoint;
          const buyStatus = this.doBuy(newData, point);
          if(buyStatus){
            aiV1.execute('UpdateTradeRecords', null, null, {
              tradeRecords: this.tradeRecords
            }, this.pairName);
            this.$eventBus.$emit('TRADE-RECORDS', this.tradeRecords);
          }
          this.klineMarkersData.push({
            time: point.time,
            position: 'belowBar' ,
            color: point.level > 1 ? '#ffc300' : (point.level > 0 ?  'rgba(255,196,0,0.59)' : 'rgba(255,196,0,0.27)'),
            shape: 'arrowUp',
            text: parseFloat(point.change).toFixed(2).toString() + '/' + point.level + (buyStatus ? '-B' : ''),
            size: 1
          });
          candleSeries.setMarkers(this.klineMarkersData);
        }
        // 卖
        if(runningData.newSellPoint){
          const point = runningData.newSellPoint;
          const sellStatus = this.doSell(newData, point);
          console.log('执行卖出 -> ' + sellStatus + ' -> ' + point.time);
          if(sellStatus){
            aiV1.execute('UpdateTradeRecords', null, null, {
              tradeRecords: this.tradeRecords
            }, this.pairName);
            this.$eventBus.$emit('TRADE-RECORDS', this.tradeRecords);
          }
          this.klineMarkersData.push({
            time: point.time,
            position: 'aboveBar' ,
            color: '#d000ff',
            shape: 'arrowDown',
            text: point.type + '-S',
            size: 1
          });
          candleSeries.setMarkers(this.klineMarkersData);
        }

        // 更新价格均线
        runningData && this.updateAvePriceLine(runningData.trendData);

        // 更新趋势线
        //this.analysisTrend(newData);

        // 计算趋势
        // volatilitySeries2.update({
        //   time: newData.time,
        //   value: pDay7Day1,
        //   color: 'rgba(91,159,112,0.63)'
        // });
        // volatilitySeries.update({
        //   time: newData.time,
        //   value: pDay1Hour1,
        //   color: '#8a8989'
        // });
        // volatilitySeries3.update({
        //   time: newData.time,
        //   value: pDay7Hour1,
        //   color: '#a4a002'
        // });*/

      });
    },
    /**
     * 初始化k线
     * @returns {Promise<void>}
     */
    async initKline(){
      this.initKlineChart();
      const startTime = this.getStartTime();
      this.animateTimeRange = [startTime, startTime + 15*24*3600];
      await this.getKlineData(this.pairName, startTime, this.intervalType);
      this.showKlineData = this.klineData.slice(0, parseInt(this.klineData.length/2));
      this.initKlineChartData(this.showKlineData);
      this.initMakers(); // markers
      this.getTrendData(); // 计算趋势
    },
    /**
     * 添加marker
     * @param time
     * @param text
     * @param position
     * @param shape
     * @param color
     */
    addMarker(time, text, color, position, shape, type){
      let hasMarker = false;
      if(type){
        hasMarker = this.klineMarkersData.some(r => r.time - time == 0 && r.type == type);
      }
      !hasMarker && this.klineMarkersData.push({
        time,
        position: position && typeof(position) != 'boolean' ? position : 'belowBar',
        color: color && typeof(color) != 'boolean' ? color : 'rgb(246, 174, 45)',
        shape: shape && typeof(shape) != 'boolean' ? shape : 'arrowUp',
        text: text ? text.toString() : '',
        size: 1,
        type: type && typeof(type) != 'boolean' ? type : (text ? text : 'Default')
      });
      if(arguments && typeof(arguments[arguments.length - 1]) == 'boolean'){
        this.klineMarkersData.sort((a, b) => a.time - b.time);
        candleSeries.setMarkers(this.klineMarkersData);
      }
    },
    /**
     * 获取k线开始时间
     * @returns {number}
     */
    getStartTime() {
      return this.setStartTime ? this.setStartTime : parseInt(new Date(this.startTime).getTime()/1000);
    },
    /**
     * 切换k线时间类型
     * @param iv
     * @returns {Promise<void>}
     */
    async onChangeKlineInterval(iv){
      if(this.intervalType == iv){
        return;
      }
      this.intervalType = iv;
      this.resetKlineChart();
      const startTime = this.getStartTime();
      await this.getKlineData(this.pairName, startTime, this.intervalType);
      this.initKlineChartData(this.klineData);
    },
    /**
     * kline data
     * @param pairName
     * @param startTime
     * @param interval
     * @returns {Promise<void>}
     */
    async getKlineData(pairName, startTime, interval, scrollDirect){
      if(this.isLoadingKlineData){
        return;
      }
      this.isLoadingKlineData = true;

      const res = await tradeLibs.candles(pairName, interval, startTime, 1000);
      this.nextKlineStartTime = res[res.length - 1].openTime/1000;
      let lists = res.map(item => ({
        time: parseInt(item.openTime/1000),
        open: parseFloat(item.open),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        close: parseFloat(item.close),
        volume: parseFloat(item.volume),
        // 用high-open更能模拟真实情况
        change: parseFloat((item.high - item.open)/item.open*100).toFixed(2)
      }));
      // 提取异常点
      /*const points = [];
      for(let i in lists){
        if(i == 0){
          points.push(0)
        } else {
          points.push(parseFloat(lists[i].change) + parseFloat(lists[i - 1].change));
        }
      }
      const pickRes = await predictApi.pickPoints({
        points: points.join(',')
      });
      const pickPoints = pickRes ? pickRes.data : [];
      for(let i in pickPoints){
        if(lists[pickPoints[i]]){
          lists[pickPoints[i]].is_mark = 1;
        }
      }*/

      if(scrollDirect == 'prev'){
        lists = lists.filter(item => item.time < this.klineData[0].time);
      } else if(this.klineData.length > 0) {
        lists = lists.filter(item => item.time > this.klineData[this.klineData.length - 1].time);
      }

      this.klineData = scrollDirect == 'prev' ? lists.concat(this.klineData) : this.klineData.concat(lists);
      this.isLoadingKlineData = false;

      return lists;
    },
    async getHourData(address, startTime, endTime){
      console.log('getHourData')
      const res = await predictApi.hourDataSource({ address, startTime, endTime });
      if(res && res.data && res.data.hourData && res.data.hourData.length > 0){
        this.hourDataSource = this.hourDataSource.concat(res.data.hourData);
      }
      console.log('getHourData -> ok')
    },
    /**
     * init kline chart
     */
    initKlineChart(){
      if(klineChart){
        return;
      }
      // init klineChart
      const domElement = document.getElementById('kline-chart-con');
      const options = {
        layout: {
          background: {
            color: '#242424',
          },
          textColor: '#B0A492',
        },
        crosshair: {
          mode: 0,
        },
        priceScale: {
          mode: LightweightCharts.PriceScaleMode.Normal
        },
        grid: {
          horzLines: { color: '#353945' },
          vertLines: { color: '#353945' },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
          rightOffset: 20
        },
      };
      if(this.$i18n.locale == 'zh'){
        options.timeScale.timezone = 'Asia/Shanghai';
        options.localization = {
          timeFormatter: (businessDayOrTimestamp) => {
            return func.getDate(businessDayOrTimestamp);
          },
        }
      } else {
        options.timeScale.timezone = 'America/New_York';
        options.localization = {
          timeFormatter: (businessDayOrTimestamp) => {
            return new Date(businessDayOrTimestamp*1000).toLocaleString('en-US', { timeZone: 'America/New_York' });
          },
        }
      }
      klineChart = LightweightCharts.createChart(domElement, options);
      // 价格tip
      this.drawToolTip();
    },
    /**
     * 绘制实时数据
     */
    drawToolTip(){
      const toolTip = document.getElementById('realtime-values');
      klineChart.subscribeCrosshairMove(param => {
        if (!param.time) {
          toolTip.style.display = 'none';
        } else {
          toolTip.style.display = 'flex';
          const data = param.seriesData.get(candleSeries);
          if(!data){
            return;
          }
          const volumeData = param.seriesData.get(volumeBarSeries);
          const changeValue = parseFloat((data.close - data.open)/data.open*100).toFixed(2);
          const amplitude = parseFloat((data.high - data.low)/data.low*100).toFixed(2);
          const price = data.value !== undefined ? data.value : data.close;
          const volume = volumeData ? volumeData.value : 0;

          const vData = param.seriesData.get(volatilitySeries2);
          const _c = vData ? vData.value : 0;

          const priceStr = `
<span>${this.$t('kline.open')}:${data.open}</span>
<span>${this.$t('kline.close')}:${data.close}</span>
<span>${this.$t('kline.high')}:${data.high}</span>
<span>${this.$t('kline.low')}:${data.low}</span>
            `;
          const changeStr = `
<span>${this.$t('kline.change')}: ${changeValue}</span>
<span>${this.$t('kline.amplitude')}: ${amplitude}</span>
<span>${volume}</span>
<span>_c: ${_c}</span>
          `;

          toolTip.innerHTML = `
<div class="price-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${priceStr}</div>
<div class="change-str ${changeValue >= 0 ? 'text-success' : 'text-danger'}">${changeStr}</div>
`;
          const coordinate = candleSeries.priceToCoordinate(price);
          if (coordinate === null) {
            return;
          }
        }
      });
    },
    /**
     * 重置k线数据
     */
    resetKlineChart(){
      this.klineData = [];
      this.nextKlineStartTime = null;
      this.hourDataMarkerIdx = -1;
      this.newsDataMarkerIdx = -1;
      this.klineMarkersData = [];
      this.klineChartVisibleRange = null;
      this.showKlineData = [];
      this.hourDataSource = [];
      this.animateTimeRange = null;
      this.isRunSimulate = false;
      // animate
      this.pauseAnimate = false;
      this.isRunSimulate = false;
      // buy sell
      this.totalBuyRecords = {};
      this.tradeRecords = [];
      this.afterBuyMaxPrice = 0;
      this.trendDirect = null;
      this.trendMsg = null;
      this.runningLogs = [];

      if(candleSeries){
        for(let i in this.priceLines){
          candleSeries.removePriceLine(this.priceLines[i]);
        }
        this.priceLines = [];
        candleSeries.setData([]);
      }
      volatilitySeries && volatilitySeries.setData([]);
      volatilitySeries2 && volatilitySeries2.setData([]);
    },
    initKlineChartData(klineData){
      // kline
      this.generateCandleSeries(klineData);

      this.generateVolumeSeries(klineData);

      // volatility
      this.generateVolatilitySeries(klineData);

      this.generateVolatilitySeries222(klineData);
      this.generateVolatilitySeries333(klineData);
    },
    /**
     * 生成k线蜡烛图
     * @param klineData
     * @returns {Promise<void>}
     */
    async generateCandleSeries(klineData){
      // kline
      if(!candleSeries){
        candleSeries = klineChart.addCandlestickSeries();
        candleSeries.priceScale().applyOptions({
          autoScale: true
        });
        this.resetKlineChartPricePrecision();
      }
      candleSeries.setData(klineData);
    },
    /**
     * 重置价格精度
     */
    resetKlineChartPricePrecision(){
      const firstCandle = this.klineData.length > 0 ? this.klineData[0] : null;
      let precision = 8, minMove = 0.00000001;
      if(firstCandle){
        const priceArr = [
          firstCandle.open.toString(),
          firstCandle.close.toString(),
          firstCandle.high.toString(),
          firstCandle.low.toString()
        ];
        let len = 0;
        for(let i in priceArr){
          const _arr = priceArr[i].split('.');
          if(_arr[1] && _arr[1].length - len > 0){
            len = _arr[1].length;
          }
        }
        precision = len ? len : 2;
        minMove = parseFloat(Math.pow(10, -precision)).toFixed(precision);
      }
      candleSeries.applyOptions({
        priceFormat: {
          type: 'price',
          precision,
          minMove
        },
      });

      this.currentPrecision = precision;
    },
    /**
     * news markers
     */
    initMakers(){
      // 已显示的k线范围
      const klineTimeRange = [
        this.showKlineData[0].time,
        parseInt(this.showKlineData[this.showKlineData.length - 1].time) + intervalSecond[this.intervalType]
      ];
      // 小时数据markers
      for(let i = this.hourDataMarkerIdx + 1; i < this.hourDataSource.length; i++){
        const item = this.hourDataSource[i];
        // 不在k线时间范围的不显示
        if(item.timestamp - klineTimeRange[0] < 0){
          continue;
        }
        if(item.timestamp - klineTimeRange[1] > 0){
          break;
        }
        if(!item.is_mark){
          continue;
        }
        const outPercent = parseFloat(item.out/this.circulatingSupply*100).toFixed(2);
        if(outPercent - 0.1 < 0){
          continue;
        }
        this.hourDataMarkerIdx = i;
        this.klineMarkersData.push({
          time: item.timestamp,
          position: 'aboveBar',
          color: 'rgb(0,234,255)',
          shape: 'arrowDown',
          text: outPercent + '%',
          size: 1
        });
      }
      // 新闻
      for(let i = this.newsDataMarkerIdx + 1; i < this.newsLists.length; i++){
        const item = this.newsLists[i];
        // 不在k线时间范围的不显示
        if(item.timestamp - klineTimeRange[0] < 0){
          continue;
        }
        if(item.timestamp - klineTimeRange[1] > 0){
          break;
        }
        this.newsDataMarkerIdx = i;
        this.addMarker(item.timestamp, 'NEWS', 'rgb(246, 174, 45)');
      }
      this.klineMarkersData.sort((a, b) => a.time - b.time);
      candleSeries && candleSeries.setMarkers(this.klineMarkersData);
    },

    /**
     * bar
     * @param klineData
     */
    generateVolumeSeries(klineData){
      if(!volumeBarSeries){
        volumeBarSeries = klineChart.addHistogramSeries({
          color: "#75c2cc",
          lineWidth: 2,
          priceFormat: {
            type: "volume"
          },
          overlay: true,
          priceScaleId: 'VolumeBar',
        });
        volumeBarSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0,
            bottom: 0.9,
          }
        });
      }
      volumeBarSeries.setData([]);

      if(!volumeBarSeries2){
        volumeBarSeries2 = klineChart.addHistogramSeries({
          color: "#ff547e",
          lineWidth: 2,
          priceFormat: {
            type: "volume"
          },
          overlay: true,
          priceScaleId: 'VolumeBar',
        });
        volumeBarSeries2.priceScale().applyOptions({
          scaleMargins: {
            top: 0.1,
            bottom: 0.8,
          }
        });
      }
      volumeBarSeries2.setData([]);
    },
    /**
     * 标准差曲线
     * @param klineData
     */
    generateVolatilitySeries(klineData){
      if(!volatilitySeries){
        volatilitySeries = klineChart.addLineSeries({
          price: 10,
          color: 'red',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine',
          priceLineVisible: true, // 显示价格线
          lastValueVisible: true, // 显示最新值
        });
        volatilitySeries.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }

      //const volatilityData = this.cauStandardDeviation(klineData);
      volatilitySeries.setData([]);

      /*for(let i in volatilityData){
        const item = volatilityData[i];
        this.klineMarkersData.push({
          time: item.time,
          position: 'aboveBar' ,
          color: 'rgb(229,224,121)',
          shape: 'arrowDown',
          text: item.value.toString(),
          size: 1
        });
      }
      console.log('makers -> ' + this.klineMarkersData.length)
      candleSeries.setMarkers(this.klineMarkersData);*/
    },
    /**
     * 计算变化率
     * @param klineData
     * @returns {*[]}
     */
    cauVolatility(klineData){
      let prevData = null;
      const changeData = [];
      for(let i in klineData){
        if(i == 0){
          prevData = klineData[i];
          continue;
        }
        const row = klineData[i];
        let change = parseFloat((row.high - row.low)/row.low*100).toFixed(2);
        const direct = row.close - row.open > 0 ? 1 : -1;
        change = change * direct;
        // prev check
        let prevChange = parseFloat((prevData.high - prevData.low)/prevData.low*100).toFixed(2);
        const prevDirect = prevData.close - prevData.open > 0 ? 1 : -1;
        prevChange = prevChange * prevDirect;
        if((change > 0 && prevChange > 0) || (change < 0 && prevChange < 0)){
          change += prevChange;
        }

        changeData.push({
          time: row.time,
          value: Math.abs(change) < 0.5 ? 0 : change
        });
      }
      return changeData;
    },
    /**
     * 计算标准差
     * @param klineData
     * @param short
     * @returns {*[]}
     */
    cauStandardDeviation(klineData, short){
      let dataShortSD = [], dataLongSD = [];
      let dataShortLists = [], dataLongLists = [];
      let groupShortData = [], groupLongData = [];
      for(let i in klineData){
        const item = klineData[i];
        let vShort = null, vLong = null;
        groupShortData.push(item);
        groupLongData.push(item);
        // 短线
        if(groupShortData.length == 6){
          vShort = this.$func.cauStandardDeviation(groupShortData, 'close');
          dataShortLists.push({
            time: item.time,
            date: this.$func.getDate(item.time),
            value: vShort
          });
          groupShortData = [];
        }
        // 长线
        if(groupLongData.length == 12){
          vLong = this.$func.cauStandardDeviation(groupLongData, 'close');
          dataLongLists.push({
            time: item.time,
            date: this.$func.getDate(item.time),
            value: vLong
          });
          groupLongData = [];

          // 长线看近7天趋势
          /*if(dataLongLists.length >= 7){
            const trend = this.checkTrend(dataLongLists.slice(-7));
            console.log(dataLongLists[dataLongLists.length - 1].date + ' -> ' + trend)
            dataLongLists[dataLongLists.length - 1].trend = trend;
            const color = {
              '-1': '#ff0000',
              '0': '#ff7200',
              '1': '#00dc3e'
            };
            dataLongLists[dataLongLists.length - 1].color = color[trend.toString()];
          }*/
        }
      }

      const oneData = this.$func.minMaxNormalization(dataLongLists.map(r => r.value), 0, 1);
      dataLongLists = dataLongLists.map((r, idx) => {
        r.value = parseFloat(oneData[idx]).toFixed(2);
        // if(idx > 2){
        //   const slopeRate = this.$func.calculateOverallSlope(dataLongLists.slice(idx - 3, idx), 3, 'value');
        //   r.slopeRate = slopeRate;
        // }
        return r;
      });

      return short ? dataShortLists : dataLongLists;
    },


    generateVolatilitySeries333(klineData){
      if(!volatilitySeries3){
        volatilitySeries3 = klineChart.addLineSeries({
          price: 10,
          color: '#ff547e',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine2',
        });
        volatilitySeries3.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries3.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }
      if(!volatilitySeries4){
        volatilitySeries4 = klineChart.addLineSeries({
          price: 10,
          color: '#ffb600',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine2',
        });
        volatilitySeries4.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries4.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }
      if(!volatilitySeries5){
        volatilitySeries5 = klineChart.addLineSeries({
          price: 10,
          color: '#00e1d9',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine2',
        });
        volatilitySeries5.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries5.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }
    },
    generateVolatilitySeries222(klineData){
      if(!volatilitySeries2){
        volatilitySeries2 = klineChart.addLineSeries({
          price: 10,
          color: 'green',
          lineWidth: 1,
          lineStyle: LightweightCharts.LineStyle.Solid,
          overlay: true,
          priceScaleId: 'VolatilityLine',
        });
        volatilitySeries2.applyOptions({
          priceLineVisible: false, // 实时价格线
          lastValueVisible: false, // 右侧实时价格
        });
        volatilitySeries2.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          }
        });
      }

      // const volatilityData = this.cauStandardDeviation(klineData, true);
      /*const changeRates = [];
      let lastTwoChanges = [];
      for(let i in klineData){
        const item = klineData[i];
        let change = parseFloat((item.close - item.open)/item.open*100).toFixed(2);

        if(lastTwoChanges.length == 2 && Math.abs(lastTwoChanges[0]) - 3 < 0 && Math.abs(change) - 3 < 0 && changeRates[i - 1].value < 3){
          changeRates[i - 1].value = 0;
        }
        if(Math.abs(change) - 3 < 0){
          change = 0;
        }
        changeRates.push({
          time: item.time,
          date: this.$func.getDate(item.time),
          value: change
        });
        lastTwoChanges.push(change);
        lastTwoChanges = lastTwoChanges.slice(-2);
      }*/
      volatilitySeries2.setData([]);
    },

    //////////////////

    /**
     * 读取趋势分析数据
     */
    async getTrendData(){
      if(this.showKlineData.length == 0){
        return;
      }
      const endTime = this.showKlineData[this.showKlineData.length - 1].time;
      const day7Kline = await tradeLibs.candles(this.pairName, '1h', endTime - 7*24*3600, 7*24);
      const day1Kline = await tradeLibs.candles(this.pairName, '15m', endTime - 24*3600, 60/15*24);
      const hour12Kline = day1Kline.slice(-60/15*12);
      const hour1Kline = await tradeLibs.candles(this.pairName, '3m', endTime - 3600, 20);
      // 初始化trend data source
      /*const trendData = execute('InitTrendDataSource', null, null, {
        trendDataSource: {
          day1Kline,
          day7Kline,
          hour12Kline,
          hour1Kline
        }
      }, this.pairName);
      this.updateAvePriceLine(trendData);*/
    },

    /**
     * 绘制趋势标记线
     */
    updateAvePriceLine(trendData){
      if(!trendData){
        return;
      }
      const {day7, day1, hour1, hour12} = trendData;
      /*this.klineMarkersData.push({
        time: day7.max.time,
        position: 'aboveBar',
        color: 'rgb(255,238,0)',
        shape: 'arrowDown',
        text: 'MAX',
        size: 1
      });
      this.klineMarkersData.push({
        time: day7.min.time,
        position: 'aboveBar',
        color: 'rgb(255,238,0)',
        shape: 'arrowDown',
        text: 'MIN',
        size: 1
      });
      candleSeries.setMarkers(this.klineMarkersData);*/

      // reset
      if(candleSeries){
        for(let i in this.priceLines){
          candleSeries.removePriceLine(this.priceLines[i]);
        }
        this.priceLines = [];
      }
      // week
      const weekAvePricePriceLine = candleSeries.createPriceLine({
        price: hour12.avePrice,
        color: '#a4a002',
        lineWidth: 1,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: 'H12',
      });
      this.priceLines.push(weekAvePricePriceLine);
      // day
      const dayAvePricePriceLine = candleSeries.createPriceLine({
        price: day1.avePrice,
        color: '#8a8989',
        lineWidth: 1,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: 'Day1Hour1-D',
      });
      this.priceLines.push(dayAvePricePriceLine);
      // hour
      const hourAvePricePriceLine = candleSeries.createPriceLine({
        price: hour1.avePrice,
        color: 'rgba(91,159,112,0.63)',
        lineWidth: 1,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: 'Day7Day1-H',
      });
      this.priceLines.push(hourAvePricePriceLine);
    },
    /**
     * 执行买入
     * @param klineData
     * @param buyCheckRes
     */
    doBuy(klineData, point){
      if(point.level < 1){
        return;
      }
      if(this.tradeRecords.length > 0){
        const lastOrder = this.tradeRecords[this.tradeRecords.length - 1];
        if(lastOrder.side == 'buy'){
          return;
        }
        if(lastOrder.rate - 10 > 0){
          // 上一单收益超过10%，不再买入
          return;
        }
        const maxBuyPrice = lastOrder.price - lastOrder.price * (lastOrder.rate/100*0.5);
        if(lastOrder.rate - 5 > 0 && klineData.close - maxBuyPrice > 0){
          // 上次收益大于5%时，再一次开单价格必须回落50%以上
          console.log('等价格回落')
          return;
        }
      }
      let buyAmount = 1000;
      /*if(point.level == 5){
        buyAmount = 500;
      } else if(point.level == 6){
        buyAmount = 800;
      } else if(point.level >= 7){
        buyAmount = 1000;
      }*/
      this.tradeRecords.push({
        side: 'buy',
        time: klineData.time,
        price: klineData.close,
        amount: buyAmount
      });
      if(!this.totalBuyRecords[this.pairName]){
        this.totalBuyRecords[this.pairName] = {
          totalAmount: 0,
          avePrice: 0,
          lastBuyTime: klineData.time
        };
      }
      this.totalBuyRecords[this.pairName].totalAmount += buyAmount;

      this.afterBuyMaxPrice = klineData.close;

      return true;
    },
    /**
     * 执行卖出
     * @param klineData
     * @param point
     */
    doSell(klineData, point){
      const lastOrder = this.tradeRecords[this.tradeRecords.length - 1];
      this.tradeRecords.push({
        side: 'sell',
        time: klineData.time,
        price: klineData.close,
        amount: lastOrder.amount,
        rate: parseFloat((klineData.close - lastOrder.price)/lastOrder.price * 100).toFixed(2),
        beforeMaxPrice: this.afterBuyMaxPrice,
        type: point.type,
        msg: point.msg
      });
      this.afterBuyMaxPrice = null;

      return true;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/vars.less";
@import "../../../assets/css/dark.less";
.chart-con {
  position: relative;
  height: calc(100vh - @header-height - @mg-height - 36px - 60px - 32px - 32px - 16px);
  width: 100%;

  #kline-chart-con{
    width: 100%;
    height: 100%;
  }
}

.interval-selector {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 999;

  /deep/.el-tag{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &.el-tag--plain.el-tag--info{
      color: @font-color;
    }
    &.el-tag--plain.el-tag--warning{
      color: @light-color;
    }
  }
}

.realtime-values {
  position: absolute;
  left: 226px;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  height: 20px;

  /deep/.price-str{
    span {
      padding-right: 10px;
    }
  }
  /deep/.change-str{
    span {
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .realtime-values{
    left: 20px;
    top: 30px;
    display: block !important;

    div{
      clear: both;
    }
  }
}

</style>