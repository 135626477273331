import * as func from '@/utils/func';

/**
 * 波动率曲线
 * @param klineData
 * @returns {*[]}
 */
export function cauChangeRatesV1(klineData){
    const limit = 2;
    const changeRates = [];
    let lastTwoChanges = klineData.slice(-2).map(r => r.change);
    for(let i in klineData){
        const item = klineData[i];
        let change = item.change;
        if(
            i != 0
            && lastTwoChanges.length == 2
            && Math.abs(lastTwoChanges[0]) - limit < 0
            && Math.abs(change) - limit < 0
            && changeRates[i - 1].value < limit
        ){
            changeRates[i - 1].value = 0;
        }
        if(Math.abs(change) - limit < 0){
            change = 0;
        }
        changeRates.push({
            time: item.time,
            date: func.getDate(item.time),
            value: change
        });
    }

    return changeRates;
}

/**
 * 阶梯波动率曲线
 * @param klineData
 * @returns {*[]}
 */
export function cauStepChangeRatesV1(klineData){
    const dataRates = [];
    let groupData = [];
    for(let i in klineData){
        const item = klineData[i];
        groupData.push(item.change);
        if(groupData.length == 10){
            const sd = func.calculateStdDev(groupData);
            const resetSd = sd - 0.25 < 0 ? 0 : sd;

            dataRates.push({
                time: item.time,
                sd,
                value: resetSd
            });
            groupData = [];
        }
    }

    return dataRates;
}

/**
 * 计算收益率
 * @param candlestickData
 * @returns {*[]}
 */
export function calculateReturns(candlestickData) {
    const returns = [];
    for (let i = 1; i < candlestickData.length; i++) {
        const returnPercentage = (candlestickData[i].close - candlestickData[i - 1].close) / candlestickData[i - 1].close;
        returns.push(returnPercentage);
    }
    return returns;
}

/**
 * 计算波动率
 * @param returns
 * @returns {number}
 */
export function calculateVolatility(returns) {
    const mean = returns.reduce((acc, val) => acc + val, 0) / returns.length;
    const variance = returns.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / returns.length;
    const stdDev = Math.sqrt(variance);
    return stdDev;
}

/**
 * 计算平均波动率
 * @param candlestickData
 * @returns {number}
 */
export function calculateAverageVolatility(candlestickData) {
    const returns = calculateReturns(candlestickData);
    const averageVolatility = calculateVolatility(returns);

    return averageVolatility * 100;
}

/**
 * 计算指数加权移动平均，alpha为平滑因子
 */
export function calculateEWMA(data, alpha) {
    let ema = data[0].close; // 初始化ema为第一个数据点的close值
    const result = [{ ...data[0], ewma: ema }];

    for (let i = 1; i < data.length; i++) {
        ema = alpha * data[i].close + (1 - alpha) * ema;
        result.push({ ...data[i], ewma: ema });
    }

    return result;
}

/**
 * 过滤变化较小的change
 * @param candlesData
 * @returns {string}
 */
export function filterTopChange(candlesData){
    const sortedData = candlesData.sort((a, b) => Math.abs(b.change) - Math.abs(a.change));
    const count = candlesData.length;
    const top10Change = sortedData.slice(0, parseInt(count * 0.1));
    // 去掉最大值10%和最小值10%，算平均值
    const lowData = sortedData.slice(parseInt(count * 0.1), parseInt(count * 0.8));
    let totalChange = 0;
    lowData.map(r => {
        totalChange += parseFloat(Math.abs(r.change));
    });
    const aveChange = parseFloat(totalChange/lowData.length).toFixed(2);
    // 保留高于平均值两倍的change
    const topChange = [];
    candlesData.map(r => {
        if(Math.abs(r.change) - aveChange * 3 > 0){
            topChange.push(r);
        }
    });

    return topChange;
}